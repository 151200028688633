import { useState, useEffect, useRef } from "react";
import i18n from "@helpers/i18n";
import { getCookie } from "@helpers/cookies";

const useKycTable = props => {
  const { 
    data,
    totalCases,
    onAction,
    caseFilter,
    setCaseFilter,
    getCases,
    isLoadingCases,
    currentAction
  } = props;

  const [mounted, setMounted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [copyList, setCopyList] = useState(data);
  const [numCases, setNumCases] = useState(totalCases);
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const searchTimeout = useRef(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("kyc_date");

  // check if we have any options saved..
  const cobOptions = JSON.parse(getCookie("cobOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    cobOptions?.rowsPerPage["kyc"] || 10
  );

  const tabs = [
    {
      key: "expired",
      translationKey: "tab_expired",
      color: "#FFD5D5"
    },
    {
      key: "attention",
      translationKey: "tab_attention",
      color: "#FFFBCE"
    },
    {
      key: "upcoming",
      translationKey: "tab_upcoming",
      color: "#FFFFFF"
    },
    {
      key: "uptodate",
      translationKey: "tab_ok",
      color: "#D1FFC8"
    }
  ];

  let columns = [];
  columns.push(      
    {
      Header: "",
      id: 'expander',
      accessor: "expander",
      width: "3%",        
      disablePadding: true,
    },
    {
      Header: "",
      className: "state",
      accessor: "state",
      width: "16%"
    },
    {
      Header: `${i18n("tables", "person_orgnr")}`,
      accessor: "id",
      width: "15%",        
      disablePadding: true,
      disableSort: true
    },
    {
      Header: `${i18n("tables", "name")}`,
      accessor: "name",
      width: "50%",        
      disablePadding: true,
    },
    {
      Header: `${i18n("dashboard_kyc", "header_kyc_updated")}`,
      accessor: "kyc_date",
      // disableFilters: true, // Do we need this?
      width: "20%",        
      disablePadding: true,
    }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(0);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const requestSearch = searched => {
    setSearchString(searched.toLowerCase());
  };

  const handleEntryClick = id => {
    const index = copyList.findIndex(object => {
      return object["id"] === id;
    });

    let updatedData = [...copyList]; // copy prev state data array
    updatedData[index]["toggled"] = !copyList[index].toggled;

    setCopyList(updatedData);
  };
    
  const fetchCases = async (page, searchString, order, orderBy) => {
    setLoaded(false);
    const cases = await getCases(page, searchString, order, orderBy);
    setCopyList(cases.batch);
    setNumCases(parseInt(cases.total));
    setLoaded(true);
  }

  // inital load and page/rowsPerPage changes useEffect
  useEffect(() => {   
    // (ideally we would get rid of this but will need reactoring work on the DashboardKycs component)
    if (!mounted) {      
      setLoaded(true);
      setMounted(true);
    }

    if (mounted) {
      fetchCases(page, searchString, order, orderBy);
    }
  }, [page, rowsPerPage, order, orderBy])
  
  // Search useEffect
  useEffect(() => {   
    if (mounted) {
      if (searchTimeout.current > 0) clearTimeout(searchTimeout.current);
      searchTimeout.current = setTimeout(() => {
        if (page > 0) setPage(0);
        fetchCases(page, searchString, order, orderBy);        
      }, 2000);
    }
  }, [searchString])

  // TAB(data) change useEffect
  useEffect(() => {   
    if (mounted) {
      setOrder("asc");
      setOrderBy("kyc_date"); // default sort - setOrder/setOrderBy trigger adds double or triple (searchString !== "") spending when changing tabs, optimise later!

      setCopyList(data);
      setNumCases(totalCases);
    }
  }, [data])
  
  // CASES loading useEffect
  useEffect(() => {   
    if (mounted) {
      setLoaded(!isLoadingCases);
    }
  }, [isLoadingCases])

  return {
    loaded,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,
    onAction,
    caseFilter,
    setCaseFilter,
    currentAction,
  };
};

export default useKycTable;