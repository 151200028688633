import { mergeViewStateForFulfillment } from "../../Cob.helpers";


export default function(state, prevState) {
    if(import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
    state = mergeViewStateForFulfillment(state, prevState);
    // const { customerState } = state;

    // Check total upload file size
    const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size * 100) / 100000, 0);
    const maxUploadFileSize = Math.round(state.maxUploadFileSize * 100) / 100000;
    if(totalDocsSize > maxUploadFileSize) return false;

    return true;
}