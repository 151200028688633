const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const tableHeightPerTotalPages = {
  10: 446,
  20: 845,
  50: 2046
};

export const customersTableHeightPerTotalPages = {
  10: 393,
  20: 748,
  50: 1813
};