import React from "react";
import OptionsList from "../OptionsList/OptionsList";
import Select from "../../../../components/Select/Select";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import TextField from "../../../../components/TextField/TextField";
import StackLayout from "../../../../components/layouts/StackLayout/StackLayout";
import i18n from "../../../../helpers/i18n";
import CountrySelection from "../CountrySelection/CountrySelection";
import Validator from "../../../../classes/Validator";
import Slider from "../../../../components/Slider/Slider";
import countries from "@helpers/countries";

export default class CompanyOwnership extends React.PureComponent
{
	uboCountOptions = [
		{label: "1", value: 1},
		{label: "2", value: 2},
		{label: "3", value: 3},
		{label: "4", value: 4}
	];

	state = {
		hasControllingOwner: false,
		numberOfControllingOwners: -1,
		company_alt_ubo: null,
		company_ubo_1: null,
		company_ubo_2: null,
		company_ubo_3: null,
		company_ubo_4: null
	};

	componentDidMount()
	{
		this.setState(CompanyOwnership.getInitialStateForComponent(this.props.customer));
	}

	_onChange = (type, uboNumber, name, value) =>
	{
		const keyPrefix = type === "ubo_alternative" ? "company_alt_ubo" : 'company_ubo_' + uboNumber;
		const updatedUbo = Object.assign({}, this.state[keyPrefix]);
		updatedUbo.role = name === keyPrefix + "_role" ? value : updatedUbo.role;
		updatedUbo.missingSwedishSsn = name === keyPrefix + "_missing_personnr" ? value : updatedUbo.missingSwedishSsn;
		updatedUbo.isCompany = name === keyPrefix + "_is_company" ? value : updatedUbo.isCompany;
		updatedUbo.firstName = name === keyPrefix + "_firstname" ? value : updatedUbo.firstName;
		updatedUbo.lastName = name === keyPrefix + "_lastname" ? value : updatedUbo.lastName;
		updatedUbo.ssn = name === keyPrefix + "_ssn" ? value : updatedUbo.ssn;
		updatedUbo.citizenship = name === keyPrefix + "_citizenship" ? value : updatedUbo.citizenship;
		updatedUbo.tax_country = name === keyPrefix + "_tax_country" ? value : updatedUbo.tax_country;
		updatedUbo.foreign_dob = name === keyPrefix + "_dob" ? value : updatedUbo.foreign_dob;
		updatedUbo.foreign_tin = name === keyPrefix + "_tin" ? value : updatedUbo.foreign_tin;
		updatedUbo.foreign_country = name === keyPrefix + "_country" ? value : updatedUbo.foreign_country;
		updatedUbo.foreign_street = name === keyPrefix + "_street" ? value : updatedUbo.foreign_street;
		updatedUbo.foreign_zip = name === keyPrefix + "_zip" ? value : updatedUbo.foreign_zip;
		updatedUbo.foreign_city = name === keyPrefix + "_city" ? value : updatedUbo.foreign_city;
		updatedUbo.isPep = name === keyPrefix + "_pep" ? value : updatedUbo.isPep;
		updatedUbo.ownership = name === keyPrefix + "_ownership" ? value : updatedUbo.ownership;
		updatedUbo.companyName = name === keyPrefix + "_companyname" ? value : updatedUbo.companyName;
		updatedUbo.hasControllingOwner = name === keyPrefix + "_has_controlling_owner" ? value : updatedUbo.hasControllingOwner;
		this.setState({[keyPrefix]: updatedUbo}, () => this.props.onChange(CompanyOwnership.isComponentFulfilled(this.state)));
	};

	render()
	{
		const {state, props} = this;
		const {highlightErrors} = props;

		const companyRepresentatives = [];
		const controllingOwners = state.hasControllingOwner === "Ja" ? state.numberOfControllingOwners : 0;
		for (let i = 1; i <= controllingOwners; i++)
		{
			let entity = i === 1 ? this.state.company_ubo_1 :
				i === 2 ? this.state.company_ubo_2 :
					i === 3 ? this.state.company_ubo_3 :
						i === 4 ? this.state.company_ubo_4 : null;

			companyRepresentatives.push(
				<CompanyRepresentative type={"ubo"}
									   key={"ubo_" + i}
									   uboNumber={i}
									   color={"primary"}
									   entity={entity}
									   onChange={this._onChange}
									   highlightErrors={highlightErrors}/>
			);
		}

		return (
			<React.Fragment>
				<h3>Ägarstruktur / Verkliga huvudmän</h3>

				<p>
					Ange företagets verkliga huvudmän. En fysisk person för vars räkning någon annan person handlar,
					eller om kunden är en juridisk person, den som utövar ett bestämmande inflytande över kunden.
				</p>

				<h4 style={{marginTop: "20px"}}>
					Finns det någon som själv kontrollerar mer än 25 % av rösterna eller ägandet i företaget?
				</h4>
				<OptionsList type="radio"
							 orientation="horizontal"
							 name="company_has_controlling_owner"
							 value={this.state.hasControllingOwner}
							 options={[
								 {label: "Nej", value: "Nej", checked: this.state.hasControllingOwner === "Nej"},
								 {label: "Ja", value: "Ja", checked: this.state.hasControllingOwner === "Ja"}
							 ]}
							 onChange={(checked, hasControllingOwner) => this.setState({hasControllingOwner: hasControllingOwner}, () => this.props.onChange(CompanyOwnership.isComponentFulfilled(this.state)))}
							 validation={(value) => ((value && value.length > 0) || false)}
							 highlightErrors={highlightErrors}
				/>

				{this.state.hasControllingOwner === "Nej" && (
					<CompanyRepresentative type={"ubo_alternative"}
										   uboNumber={0}
										   entity={this.state.company_alt_ubo}
										   color={"primary"}
										   onChange={this._onChange}
										   highlightErrors={highlightErrors}/>
				)}

				{this.state.hasControllingOwner === "Ja" && (
					<React.Fragment>
						<h4 style={{marginTop: "20px"}}>Antal huvudmän</h4>
						<div style={{maxWidth: "200px"}}>
							<Select
								label="Ange antal"
								options={this.uboCountOptions}
								selectedIndex={this.uboCountOptions.map(c => c.value).indexOf(this.state.numberOfControllingOwners)}
								onChange={(option) => this.setState({numberOfControllingOwners: option.value}, () => this.props.onChange(CompanyOwnership.isComponentFulfilled(this.state)))}
							/>
						</div>
					</React.Fragment>
				)}

				{companyRepresentatives}
			</React.Fragment>
		);
	}

	static getInitialStateForComponent(customer)
	{
		let initialState = {
			numberOfControllingOwners: 0,
			hasControllingOwner: customer.getData("company_has_controlling_owner") || false,
			company_alt_ubo: CompanyOwnership.getUboDetailsFromCustomer(customer, "company_alt_ubo_"),
			company_ubo_1: CompanyOwnership.getUboDetailsFromCustomer(customer, "company_ubo_1_"),
			company_ubo_2: CompanyOwnership.getUboDetailsFromCustomer(customer, "company_ubo_2_"),
			company_ubo_3: CompanyOwnership.getUboDetailsFromCustomer(customer, "company_ubo_3_"),
			company_ubo_4: CompanyOwnership.getUboDetailsFromCustomer(customer, "company_ubo_4_")
		};

		initialState.company_alt_ubo.ownership = 0; // Not used for alternative ubo

		if (initialState.company_ubo_4.firstName.length > 0 || initialState.company_ubo_4.companyName.length > 0)
		{
			initialState.numberOfControllingOwners = 4;
		}
		else if (initialState.company_ubo_3.firstName.length > 0 || initialState.company_ubo_3.companyName.length > 0)
		{
			initialState.numberOfControllingOwners = 3;
		}
		else if (initialState.company_ubo_2.firstName.length > 0 || initialState.company_ubo_2.companyName.length > 0)
		{
			initialState.numberOfControllingOwners = 2;
		}
		else if (initialState.company_ubo_1.firstName.length > 0 || initialState.company_ubo_1.companyName.length > 0)
		{
			initialState.numberOfControllingOwners = 1;
		}

		if (initialState.numberOfControllingOwners === 0 &&
			initialState.company_alt_ubo.firstName.length <= 0 &&
			initialState.company_alt_ubo.companyName.length <= 0)
		{
			CompanyOwnership.getUbosFromLookupdata(customer, initialState);

			if (initialState.numberOfControllingOwners > 0)
			{
				initialState.hasControllingOwner = "Ja";
			}
		}

		return initialState;
	};

	static getUbosFromLookupdata(customer, state)
	{
		if (customer.lookupInfo == null || !customer.lookupInfo.ubos || customer.lookupInfo.ubos.length <= 0)
		{
			return;
		}

		const countryList = countries.map(({label, code}) => ({label, value: label, code: code}));

		for (let i = 0; i < customer.lookupInfo.ubos.length; i++)
		{
			const ubo = customer.lookupInfo.ubos[i];
			let uboCitizenship = "";
			let uboResidence = "";

			countryList.forEach(function (c)
			{
				if (ubo.citizenship != null && c.code === ubo.citizenship)
				{
					uboCitizenship = c.value;
				}

				if (ubo.countryOfResidence != null && c.code === ubo.countryOfResidence)
				{
					uboResidence = c.value;
				}
			});

			let ownership = 25;
			if (ubo.extent_code === "INTERVALL5")
			{
				ownership = 100;
			}

			state['company_ubo_' + (i + 1)] = {
				isCompany: "no",
				missingSwedishSsn: "no",
				role: "",
				firstName: ubo.firstName,
				lastName: ubo.lastName,
				ssn: ubo.ssn,
				citizenship: uboCitizenship,
				tax_country: uboResidence,
				foreign_dob: "",
				foreign_tin: "",
				foreign_country: "",
				foreign_street: "",
				foreign_zip: "",
				foreign_city: "",
				isPep: "",
				ownership: ownership,
				companyName: "",
				hasControllingOwner: ""
			};
		}

		state.numberOfControllingOwners = customer.lookupInfo.ubos.length;
	}

	static getUboDetailsFromCustomer(customer, prefix)
	{
		return {
			isCompany: prefix === "company_alt_ubo_" ? "no" : (customer.getData(prefix + "is_company") || "no"),
			missingSwedishSsn: customer.getData(prefix + "missing_personnr") || "no",
			role: customer.getData(prefix + "role"),
			firstName: customer.getData(prefix + "firstname"),
			lastName: customer.getData(prefix + "lastname"),
			ssn: customer.getData(prefix + "ssn"),
			citizenship: customer.getData(prefix + "citizenship"),
			tax_country: customer.getData(prefix + "tax_country"),
			foreign_dob: customer.getData(prefix + "dob"),
			foreign_tin: customer.getData(prefix + "tin"),
			foreign_country: customer.getData(prefix + "country"),
			foreign_street: customer.getData(prefix + "street"),
			foreign_zip: customer.getData(prefix + "zip"),
			foreign_city: customer.getData(prefix + "city"),
			isPep: customer.getData(prefix + "pep"),
			ownership: customer.getData(prefix + "ownership") || 25,
			companyName: customer.getData(prefix + "companyname"),
			hasControllingOwner: customer.getData(prefix + "has_controlling_owner")
		};
	}

	static isComponentFulfilled(componentState)
	{
		if (componentState.hasControllingOwner === "Nej")
		{
			if (componentState.company_alt_ubo === null)
			{
				return false;
			}

			if (componentState.company_alt_ubo.role.length <= 0 ||
				componentState.company_alt_ubo.firstName.length <= 0 ||
				componentState.company_alt_ubo.lastName.length <= 0 ||
				componentState.company_alt_ubo.citizenship.length <= 0 ||
				componentState.company_alt_ubo.tax_country.length <= 0 ||
				componentState.company_alt_ubo.isPep.length <= 0)
			{
				return false;
			}

			if (componentState.company_alt_ubo.missingSwedishSsn !== "yes")
			{
				if (componentState.company_alt_ubo.ssn.length <= 0 ||
					!Validator.validateSsn(componentState.company_alt_ubo.ssn))
				{
					return false;
				}
			}
			else if (componentState.company_alt_ubo.foreign_dob.length !== 8 ||
				componentState.company_alt_ubo.foreign_tin.length <= 0 ||
				componentState.company_alt_ubo.foreign_country.length <= 0 ||
				componentState.company_alt_ubo.foreign_street.length <= 0 ||
				componentState.company_alt_ubo.foreign_zip.length <= 0 ||
				componentState.company_alt_ubo.foreign_city.length <= 0)
			{
				return false;
			}

			return true;
		}
		else if (componentState.hasControllingOwner === "Ja")
		{
			if (componentState.numberOfControllingOwners <= 0 || componentState.numberOfControllingOwners > 4)
			{
				return false;
			}

			for (let i = 1; i <= componentState.numberOfControllingOwners; i++)
			{
				const ubo = componentState["company_ubo_" + i];
				if (ubo.isCompany === "yes")
				{
					if (ubo.companyName == null ||
						ubo.companyName.length <= 0 ||
						ubo.hasControllingOwner.length <= 0)
					{
						return false;
					}

					if (ubo.missingSwedishSsn !== "yes")
					{
						if (ubo.ssn.length <= 0)
						{
							return false;
						}
					}
					else if (ubo.foreign_tin.length <= 0 ||
						ubo.tax_country.length <= 0 ||
						ubo.foreign_country.length <= 0 ||
						ubo.foreign_street.length <= 0 ||
						ubo.foreign_zip.length <= 0 ||
						ubo.foreign_city.length <= 0)
					{
						return false;
					}
				}
				else
				{
					if (ubo.firstName == null ||
						ubo.firstName.length <= 0 ||
						ubo.lastName == null ||
						ubo.lastName.length <= 0 ||
						ubo.citizenship.length <= 0 ||
						ubo.tax_country.length <= 0 ||
						ubo.isPep.length <= 0)
					{
						return false;
					}

					if (ubo.missingSwedishSsn !== "yes")
					{
						if (ubo.ssn.length <= 0 ||
							!Validator.validateSsn(ubo.ssn))
						{
							return false;
						}
					}
					else if (ubo.foreign_dob.length !== 8 ||
						ubo.foreign_tin.length <= 0 ||
						ubo.foreign_country.length <= 0 ||
						ubo.foreign_street.length <= 0 ||
						ubo.foreign_zip.length <= 0 ||
						ubo.foreign_city.length <= 0)
					{
						return false;
					}
				}
			}

			return true;
		}

		return true;
	}
}

class CompanyRepresentative extends React.PureComponent
{

	state = {
		type: "ubo",
		uboNumber: -1
	};

	componentDidMount()
	{
		const {type, uboNumber} = this.props;

		this.setState({
			type: type,
			uboNumber: uboNumber,
		});
	}

	render()
	{
		const {entity, highlightErrors} = this.props;

		const fieldNamePrefix = this.state.type === "ubo" ? "ubo_" + this.state.uboNumber : "alt_ubo";

		return (
			<div className={"company-ubo"}>
				<div className={"header"}>
					<h5>{this.state.type === "ubo" ? "Huvudman " + this.state.uboNumber : "Alternativ Huvudman"}</h5>
					<div className={"option"}>
						{this.state.type === "ubo" && (
							<Checkbox
								label="Huvudman är juridisk person"
								name={"company_" + fieldNamePrefix + "_is_company"}
								value="yes"
								checked={entity.isCompany === "yes"}
								onChange={checked => this._onChangeField("company_" + fieldNamePrefix + "_is_company", checked ? "yes" : "no")}
							/>
						)}

						<Checkbox
							label="Saknar svenskt org-/personnr"
							name={"company_" + fieldNamePrefix + "_missing_personnr"}
							value="yes"
							checked={entity.missingSwedishSsn === "yes"}
							onChange={checked => this._onChangeField("company_" + fieldNamePrefix + "_missing_personnr", checked ? "yes" : "no")}
							style={{paddingTop: "1px", paddingRight: "30px"}}
						/>
					</div>
				</div>

				{this.state.type === "ubo_alternative" && (
					<React.Fragment>
						<p>
							Om det inte finns någon verklig huvudman enligt ägande eller rösträtt, uppge en alternativ
							verklig huvudman, t.ex. VD eller styrelseordförande.
						</p>
						<StackLayout className="Cob-fields-collection" rowWrap fullWidth style={{marginTop: "0"}}>
							<div>
								<TextField
									name={"company_" + fieldNamePrefix + "_role"}
									autocomplete="nope"
									value={entity.role}
									validation={value => value.length > 0}
									label="Personens roll på företaget"
									onChange={this._onChangeTextField}
									highlightErrors={highlightErrors}
								/>
							</div>
						</StackLayout>
					</React.Fragment>
				)}

				{entity.isCompany === "yes" && (
					<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
						<div>
							<TextField
								value={entity.companyName}
								name={"company_" + fieldNamePrefix + "_companyname"}
								autocomplete="nope"
								label={"Företagets firma"}
								validation={value => value.length > 0}
								onChange={this._onChangeTextField}
								highlightErrors={highlightErrors}
							/>
						</div>
						<div>
							<TextField
								autocomplete="nope"
								name={"company_" + fieldNamePrefix + "_ssn"}
								value={entity.missingSwedishSsn === "yes" ? "" : entity.ssn}
								label={"Organisationsnummer"}
								validation={entity.isCompany === "yes" ? null : Validator.validateSsn}
								placeholder={"YYYYYYYYXXXX"}
								disabled={entity.missingSwedishSsn === "yes"}
								onChange={this._onChangeTextField}
								highlightErrors={highlightErrors}
							/>
						</div>
					</StackLayout>
				)}

				{entity.isCompany !== "yes" && (
					<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
						<div>
							<TextField
								value={entity.firstName}
								name={"company_" + fieldNamePrefix + "_firstname"}
								autocomplete="nope"
								label={i18n("general", "first_name")}
								validation={value => value.length > 0}
								onChange={this._onChangeTextField}
								highlightErrors={highlightErrors}
							/>
						</div>

						<div>
							<TextField
								value={entity.lastName}
								name={"company_" + fieldNamePrefix + "_lastname"}
								autocomplete="nope"
								label={i18n("general", "last_name")}
								validation={value => value.length > 0}
								onChange={this._onChangeTextField}
								highlightErrors={highlightErrors}
							/>
						</div>

						<div>
							<TextField
								autocomplete="nope"
								name={"company_" + fieldNamePrefix + "_ssn"}
								value={entity.missingSwedishSsn === "yes" ? "" : entity.ssn}
								label={i18n("general", "social_security_number")}
								validation={entity.isCompany === "yes" ? null : Validator.validateSsn}
								placeholder={"ÅÅÅÅMMDDXXXX"}
								disabled={entity.missingSwedishSsn === "yes"}
								onChange={this._onChangeTextField}
								highlightErrors={highlightErrors}
							/>
						</div>

						<div>
							<CountrySelection label={i18n("general", "citizenship")}
											  field={"company_" + fieldNamePrefix + "_citizenship"}
											  selected={entity.citizenship}
											  mandatory={true}
											  onChange={(option) => this._onChangeField("company_" + fieldNamePrefix + "_citizenship", option.value)}
											  highlightErrors={highlightErrors}
							/>
						</div>

						<div>
							<CountrySelection label={i18n("general", "tax_country")}
											  field={"company_" + fieldNamePrefix + "_tax_country"}
											  selected={entity.tax_country}
											  mandatory={true}
											  onChange={(option) => this._onChangeField("company_" + fieldNamePrefix + "_tax_country", option.value)}
											  highlightErrors={highlightErrors}/>
						</div>
					</StackLayout>
				)}

				{entity.missingSwedishSsn === "yes" && (
					<div className={"foreign"}>

						{this.state.type === "ubo_alternative" && (
							<h3>Uppgifter om utländsk alternativ huvudman</h3>
						)}

						{this.state.type === "ubo" && (
							<h3>Uppgifter om utländsk huvudman</h3>
						)}

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth
									 style={{marginTop: "0"}}>

							{entity.isCompany !== "yes" && (
								<div>
									<TextField
										name={"company_" + fieldNamePrefix + "_dob"}
										value={entity.foreign_dob}
										label={"Födelsedatum"}
										placeholder={"ÅÅÅÅMMDD"}
										autocomplete="nope"
										validation={value => value.length === 8}
										onChange={this._onChangeTextField}
										highlightErrors={highlightErrors}
									/>
								</div>
							)}

							<div>
								<TextField
									name={"company_" + fieldNamePrefix + "_tin"}
									value={entity.foreign_tin}
									label={entity.isCompany !== "yes" ? "TIN" : "Utländskt org-nr"}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={this._onChangeTextField}
									highlightErrors={highlightErrors}
								/>
							</div>

							{entity.isCompany === "yes" && (
								<div>
									<CountrySelection label={i18n("general", "tax_country")}
													  field={"company_" + fieldNamePrefix + "_tax_country"}
													  selected={entity.tax_country}
													  mandatory={true}
													  onChange={(option) => this._onChangeField("company_" + fieldNamePrefix + "_tax_country", option.value)}
													  highlightErrors={highlightErrors}/>
								</div>
							)}

							<div>
								<CountrySelection label={"Land"}
												  field={"company_" + fieldNamePrefix + "_country"}
												  selected={entity.foreign_country}
												  mandatory={true}
												  onChange={(option) => this._onChangeField("company_" + fieldNamePrefix + "_country", option.value)}
												  highlightErrors={highlightErrors}/>
							</div>

							<div>
								<TextField
									name={"company_" + fieldNamePrefix + "_street"}
									value={entity.foreign_street}
									label={i18n("general", "street_address")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={this._onChangeTextField}
									highlightErrors={highlightErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_" + fieldNamePrefix + "_zip"}
									value={entity.foreign_zip}
									label={i18n("general", "zip_code")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={this._onChangeTextField}
									highlightErrors={highlightErrors}
								/>
							</div>
							<div>
								<TextField
									name={"company_" + fieldNamePrefix + "_city"}
									value={entity.foreign_city}
									label={i18n("general", "city")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={this._onChangeTextField}
									highlightErrors={highlightErrors}
								/>
							</div>
						</StackLayout>
					</div>
				)}

				{entity.isCompany !== "yes" && (
					<OptionsList
						type="radio"
						name={"company_" + fieldNamePrefix + "_pep"}
						description={"Är personen själv, en närstående till denne, eller någon av dennes medarbetare en person i politiskt utsatt ställning (PEP)?"}
						value={entity.isPep}
						options={[
							{
								label: "Nej",
								value: "Nej",
								checked: entity.isPep === "no" || entity.isPep === "Nej"
							},
							{
								label: "Ja",
								value: "Ja",
								checked: entity.isPep === "yes" || entity.isPep === "Ja"
							}
						]}
						onChange={(checked, value) => this._onChangeField("company_" + fieldNamePrefix + "_pep", value)}
						style={{marginTop: "15px"}}
						validation={(value) => ((value && value.length > 0) || false)}
						highlightErrors={highlightErrors}
					/>
				)}

				{this.state.type === "ubo" && (
					<div className={"ownership"}>
						<Slider
							label="Omfattning av kontroll"
							value={entity.ownership}
							unit="%"
							min={25}
							visibleValues={[25, 50, 75, 100]}
							onChange={(value) => this._onChangeField("company_" + fieldNamePrefix + "_ownership", value)}
						/>
						<input type={"hidden"} name={"company_" + fieldNamePrefix + "_ownership"} value={entity.ownership}/>
					</div>
				)}

				{entity.isCompany === "yes" && (
					<React.Fragment>
						<h4 style={{marginTop: "20px"}}>
							Finns det någon som själv kontrollerar mer än 25 % av rösterna eller ägandet i företaget?
						</h4>
						<OptionsList type="radio"
									 orientation="horizontal"
									 name={"company_" + fieldNamePrefix + "_has_controlling_owner"}
									 options={[
										 {label: "Nej", value: "Nej", checked: entity.hasControllingOwner === "Nej"},
										 {label: "Ja", value: "Ja", checked: entity.hasControllingOwner === "Ja"}
									 ]}
									 onChange={(checked, value) => this._onChangeField("company_" + fieldNamePrefix + "_has_controlling_owner", value)}
						/>

						{entity.hasControllingOwner === "Ja" && (
							<p>
								<strong>Notera.</strong> Efter avslutad onboarding kommer du behöva fylla i ett
								formulär där du får ange verkliga huvudmän i alla led.
							</p>
						)}
					</React.Fragment>
				)}
			</div>
		);
	}

	_onChangeTextField = ({target}) =>
	{
		this._onChangeField(target.name, target.value);
	};

	_onChangeField = (name, value) =>
	{
		this.props.onChange(this.state.type, this.state.uboNumber, name, value);
	};
}