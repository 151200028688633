// MODULES
import React from "react";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import Button from "@components/Button/Button";
import {createB64Context, stringifyQueryParams} from "@helpers/utils";

// CLASSES
import Settings from "@classes/Settings";

// HELPERS
// import i18n from "@helpers/i18n";

const BankDetails = props => {
  const { advisor, client, standaloneApplications } = props;
  const changeDetailsUrl = standaloneApplications.find(app => app.key === 'changeCustomerData')?.url;

  const handleChangeDetailsClick = () => {
    if (!changeDetailsUrl) {
      console.error("The change details url has not been properly configured.");
      return;
    }
    // Create a checksum that the cob odin client will check to make sure that the request
    // originated in a correct way.
    const encodedContext = createB64Context("fairclient", advisor, client.id.replace(/-/g, ""), null);
    window.location.href = changeDetailsUrl + stringifyQueryParams({
      context: encodedContext,
      token: Settings.get("AUTH_TOKEN"),
      key: "details"
    });
  };

  return (
    <Box sx={{
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      // mt: 2, 
      padding: 2,
    }}>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: 0.6
      }}>
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          <b>Bank:</b>
        </Typography>
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          SEB                              
        </Typography>
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: 0.6
      }}>
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          <b>Kontonummer:</b>
        </Typography>                                
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          5100 0012345
        </Typography>
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: 0.6
      }}>
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          <b>Periodisk dragning</b>
        </Typography>                                
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: 0.6
      }}>
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          Belopp:
        </Typography>                                
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          5 000 kr
        </Typography>
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: 0.6
      }}>
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>
          Frekvens:
        </Typography>                                
        <Typography color="inherit" variant="body2" sx={{width: "48%"}}>Mdnadsvis</Typography>
      </Box>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        mt: 2.5
      }}>
        <Box sx={{ width: "45%", alignItems: "flex-start"}}>
          <Link 
            underline="none"
            color="#4cb7d5"
            onClick={handleChangeDetailsClick}
            sx={{cursor: "pointer"}}
          >
            <Typography><b>Ändra</b></Typography>
          </Link>
        </Box>
        {/* <Box sx={{ width: "45%", alignItems: "flex-start"}}>
          <Link href="#" underline="none" color="#4cb7d5">
              <Typography><b>Villkor för autogiro</b></Typography>
            </Link>
        </Box> */}
      </Box>
    </Box>
  );
};

export default BankDetails;