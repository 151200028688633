import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./FinancialSituation3.fulfillment";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextView from "../../../../../components/TextView/TextView";
import NumberField from "../../../components/NumberField/NumberField";
import i18n from "@helpers/i18n";

export default class FinancialSituation3 extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render() {
		const {state} = this;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("financial_situation", "financial_situation")}</h2>

					<div className="Cob-view2-fields">
						<h3>{i18n("financial_situation", "scope_capital")}</h3>

						<h4>{i18n("financial_situation", "parts_not_to_be_included")}?</h4>
						<TextView
							name="situation_scope_comment"
              label={`${i18n("general", "free_text")} (${200 - customerState.situation_scope_comment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.situation_scope_comment}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_scope_comment: target.value})}
							fullWidth
							minHeight={90}
						/>

						<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "capital_not_to_be_included")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_scope_outside"}
											 value={customerState.situation_scope_outside}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_scope_outside: target.value})} />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "investable_capital")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_scope_investable"}
											 value={customerState.situation_scope_investable}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_scope_investable: target.value})} />
							</div>

						</StackLayout>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		isFulfilled: false,
		customerState: {
			situation_scope_outside: customer.getData("situation_scope_outside"),
			situation_scope_investable: customer.getData("situation_scope_investable"),
			situation_scope_comment: customer.getData("situation_scope_comment") || ""
		}
	};
}

function _getFields() {
	return [
		"situation_scope_outside",
		"situation_scope_investable",
		"situation_scope_comment",
	];
}