const palette = {
  common: { black: "#000", white: "#fff" },
  gray: {
    0: "#FFFFFF",
    100: "#F9FAFB",
    200: "#F4F6F8",
    300: "#DFE3E8",
    400: "#C4CDD5",
    500: "#919EAB",
    600: "#637381",
    700: "#454F5B",
    800: "#212B36",
    900: "#161C24"
  },
  primary: {
    main: "#2E457E",
  },
  secondary: {
    main: "#F0F4FF",
  },
  tertiary: {
    main: "#fff6eb",
  },
};

export default palette;
