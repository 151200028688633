import React from "react";
import PropTypes from "prop-types";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";

// HELPERS
import {createClassName} from "@helpers/utils";

export default class FileDrop extends React.PureComponent {
	static propTypes = {
		dropText: PropTypes.string,
		busyText: PropTypes.string,
		files: PropTypes.array.isRequired,
		accept: PropTypes.string,
		disabled: PropTypes.bool.isRequired,
		onDrop: PropTypes.func,
	};

	static defaultProps = {
		files: [],
		disabled: false,
	};

	state = {
		busy: false,
		dragOver: false,
	};

	render() {
		const {props, state} = this;
		const {dropText, busyText, files, accept, disabled, onDrop, style} = props;
		const classes = createClassName(props.className, {
			"FileDrop": true,
			"files": files.length > 0,
			"disabled": disabled || !onDrop,
			"busy": state.busy,
			"drag-over": state.dragOver,
		});

		return (
			<div className={classes} style={style}>
				<input
					ref={ref => this.input = ref}
					type="file"
					className="FileDrop-input"
					accept={accept}
					disabled={disabled || !onDrop}
					onChange={onDrop ? this._onInputChange : undefined}
					multiple
				/>
				<div className="FileDrop-dropbox"
					 onClick={onDrop ? this._onClick : undefined}
					 onDrop={onDrop ? this._onDropboxDrop : undefined}
					 onDragOver={onDrop ? this._onDragOver : undefined}
					 onDragLeave={onDrop ? this._onDragLeave : undefined}
					 draggable>
					<div className="FileDrop-dropbox-icon">
						{state.busy ? (
							<ActivityIndicator busy/>
						) : (
							<div className="icon material-icons">attachment</div>
						)}
					</div>
					<div className="FileDrop-dropbox-text">{state.busy ? busyText : dropText}</div>
				</div>
			</div>
		);
	}

	// Internal methods
	_onClick = ({target}) => this.input.click();
	_onDragLeave = () => this.setState({dragOver: false});

	_onDragOver = (e) => {
		e.preventDefault();
		this.setState({dragOver: true});
	};

	_onDropboxDrop = (e) => {
		e.preventDefault();
		const filesList = e.dataTransfer.files;
		_handleOnChange.call(this, filesList);
	};

	_onInputChange = (e) => {
		const filesList = e.target.files;
		_handleOnChange.call(this, filesList);
	}
}


// PRIVATE FUNCTIONS
function _mapFilesList(files) {
	const {accept} = this.props;
	const result = [];

	const acceptFileTypes = accept ? accept.split(",") : [];

	for (let i = 0, n = files.length; i < n; i++) {
		const file = files[i];
		acceptFileTypes.forEach(function (type) {
			if (file.type === type.trim()) {
				result.push(file);
			}
		});
	}

	return result;
}

function _readFilesAsync(filesList) {
	const files = _mapFilesList.call(this, filesList);
	const {filterKey} = this.props;

	return new Promise(async (resolve) => {
		const filesData = [];

		await _processArrayAsync(files, (file) => new Promise(res => {
			const reader = new FileReader();
			const onReaderLoad = (e) => {
				reader.removeEventListener("load", onReaderLoad);
				filesData.push(e.target.result);
				res();
			};

			// Setup load listener
			reader.addEventListener("load", onReaderLoad, false);

			// // Read the file
			reader.readAsDataURL(file);
		}));

		// Reset input value before resolving
		this.input.value = "";

		resolve(filesData.map((data, index) => {
			const file = files[index];
			return {
				name: file.name,
				type: file.type,
				size: file.size,
				filterKey: filterKey,
				data,
			};
		}));
	});
}

function _handleOnChange(filesList) {
	const {onDrop} = this.props;

	this.setState({busy: true, dragOver: false}, async () => {
		const filesData = await _readFilesAsync.call(this, filesList);

		this.setState({busy: false}, () => {
			onDrop(filesData);
		});
	});
}

function _processArrayAsync(array, fn) {
	const result = [];
	return array.reduce((p, item) => p.then(() => fn(item).then(data => {
		result.push(data);
		return result;
	})), Promise.resolve());
}