import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";

const TableExpanderIcon = props => {
  const { toggled } =
    props;

  return (
    <Typography
      sx={{
        height: 15,
        margin: "0 0 1px 0",
        lineHeight: "1.5",
        fontSize: "11px",
        background: "#FFF",
        display: "flex",
        width: 15,
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid #dddddd`,
        borderRadius: "50%",
        color: "#000",
      }}
    >
      {toggled ? "-" : "+"}
    </Typography>
  );
};

TableExpanderIcon.propTypes = {
  toggled: PropTypes.bool,
};

export default TableExpanderIcon;
