const CoreIDClient = window.coreid_client;

export default class Assently {

    // Static methods
    static init(token, callback) {
        CoreIDClient.init({
            config: {
                allowedEids: ["se"],   // Enable all eID providers
                mode: "auth",          // Sets the mode to authentication
                language: "sv",        // Set the client to use swedish language
                provider: "se-bankid", // Default provider to show on start
            },
            token,
            callback
        });
    }

    static start() {
        CoreIDClient.start();
    }

    static close() {
        CoreIDClient.close();
    }
}