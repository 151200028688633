import React, { Fragment } from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from '@mui/material/Tooltip';

import { formatSSN, swedishLocaleSettings } from "@helpers/utils";
import { getComparator, tableHeightPerTotalPages } from "@helpers/muiTable";
import i18n from "@helpers/i18n";

import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import AdviceAvatarIcon from '@mui/icons-material/SupervisorAccountOutlined';
import TableExpanderIcon from "@components/TableExpanderIcon";
import ExpandableRow from "@views/Dashboard/ExpandableRow";

import EnhancedTableHead from "./EnhancedTableHead";

const MobileTable = props => {
  const {
    loaded,
    user,
    partner,
    type,
    tabs,
    copyList,
    numCases,
    page,
    setPage,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    handleRequestSort,
    handleChangePage,
    handleEntryClick,
    onAction,
    onMessage,
    onShareOpen,
    currentAction,
    resetPaginationAndFilters
  } = props;

  let columns = [
    {
      Header: `${i18n("tables", "person-company")}`,
      className: "person-company",
      accessor: "person-company",
      width: "100%",
      align: "left"
    }
  ];

  return (
    <TableContainer component={Box} sx={{ overflowY: "hidden"}}>
      <Box
        sx={{
          display: "block",
          width: "100%",
          minHeight: "unset",
          border: 0
        }}
      >
        <Table aria-label="Sessions Table"
          className="mui-table"
          sx={{
            width: "100%",
            mt: 1,
            border: 0,
            tableLayout: "fixed"
          }}
        >
          <EnhancedTableHead
            columns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
          {!loaded ? (
              <TableRow
                sx={{
                  height: "100%",
                  "&:last-child td": { border: 0 }
                }}
              >
                <TableCell colSpan={columns.length}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: tableHeightPerTotalPages[rowsPerPage]*0.7
                    }}
                  >
                    <ActivityIndicator color="#4cb7d5"  busy/>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (   
              <Fragment>
                {copyList.sort(getComparator(order, orderBy))
                  .map((entry, key) => {
                    const msgTitleRef = React.createRef();
                    const msgTextRef = React.createRef();
    
                    //TODO: check at a later stage of COB3 implementation if we can remove type condition from this component and OtherTable
                    const isExpandable = 
                      (type === "main" && entry.state === "draft" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", ""))) ||
                      (entry.state === "sent" || entry.state === "signed" || entry.state === "recalled") ||
                      (type === "main" && entry.state === "processing" && user.hasPermission("case.change_state")) ||
                      // (user.role !== "assistant" && !entry.is_shared_case && onShareOpen !== undefined) ||
                      ((entry.state === "processing" || entry.state === "sent") && (user.hasPermission("case.msgs_write") || entry.messages.length > 0)) ||
                      entry?.signatories?.length > 0 ||
                      entry.commission_sharing && entry.commission_sharing !== "" ||
                      (entry.message_to_bo !== null && entry.message_to_bo !== "") ||
                      entry.audit.length > 0 && user.hasPermission("case.audit_log");
    
                    const entryState = tabs.find(tab => tab.key === entry.state);
    
                    const entryIsSharable = user.role !== "assistant" && !entry.is_shared_case && onShareOpen !== undefined && formatSSN(user.ssn) === formatSSN(entry.advisorSsn);
    
                    // Apply Swedish locale settings to moment
                    moment.updateLocale('sv', swedishLocaleSettings);
    
                    // Format updated date
                    const date = moment(entry.changed, "YYYY-MM-DD HH:mm");
    
                    const today = moment().startOf('day');
                    const yesterday = moment().subtract(1, 'days').startOf('day');
    
                    const wasUpdatedToday = date.isSame(today, 'd');
                    const wasUpdatedYesterday = date.isSame(yesterday, 'd');
                    const updatedDate = date.format("YYYY-MM-DD");
                    const updatedHours = date.format("HH:mm");
    
                    // consider the colspan for the expandable row
                    return (
                      <Fragment key={key}>
                        <TableRow
                          sx={{
                            height: 40,
                              "&:nth-child(even)": { background: "#f5f9ff" }
                          }}
                        >
                          <TableCell
                            sx={{
                              p: 1.5,
                              borderBottom: "none",
                              color: "secondary.lighter",
                              height: 40,
                              // textAlign: "center",
                              // whiteSpace: "nowrap",
                              // overflow: "hidden",
                              // textOverflow: "ellipsis"
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                height: "100%"
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  width: "50%",
                                }}
                              >
                                <Typography variant="tableCell">
                                  <span style={{ display: "block", fontWeight: 700 }}>{entry.name}</span>
                                  <em style={{ display: "block", color: "#95a4ac" }}>{entry.ssn}</em>
                                  
                                  {entry.workflow === 'futur' && (
                                    <em style={{ display: "block", color: "#95a4ac" }}>Depåöpping inför tecknande av KF</em>
                                  )}
    
                                  {entry.is_shared_case && user.role === "advisor" && (
                                    <em style={{ display: "block", color: "#95a4ac" }}>Huvudrådgivare: {entry.advisorName} </em>
                                  )}
                                </Typography>
                                <Typography variant="tableCell" sx={{mt: 1}}>
                                    {entry.custom_data.type}
                                    {entry.custom_data.product && (
                                        <em style={{ display: "block", color: "#95a4ac" }}>
                                            {entry.custom_data.product}
                                        </em>
                                    )}
                                    {entry.custom_data.type_2 && (
                                        <span style={{ display: "block"}}>
                                          {entry.custom_data.type_2}
                                      </span>
                                    )}
                                </Typography>
                                <Typography variant="tableCell" sx={{my: 1}}>
                                    {entry.custom_data.amount}
                                </Typography>
                                  <Box sx={{
                                      display: "inline-block",
                                      backgroundColor: entryState.color,
                                      borderRadius: "9px",
                                      alignContent: "center",
                                      padding: "4px 8px",
                                      lineHeight: "10px",
                                      border: "1px solid #EFEFEF"
                                  }}>
	                                <span style={{
                                        fontSize: "10px",
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                        letterSpacing: "0.3px",
                                        color: "#686f77"
                                    }}>
	                                    {i18n("dashboard_sessions", entryState.translationKey)}
	                                </span>
                                  </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  justifyContent: "space-between",
                                  height: "100%",
                                  width: "50%"
                                }}
                              >
                                <Typography variant="tableCell">
                                  {wasUpdatedToday? "Idag" : wasUpdatedYesterday ? "Igår" : updatedDate}, {updatedHours}
                                </Typography>
                                <Box 
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    justifyContent: isExpandable ? "space-between" : "flex-end",
                                  }}
                                  >
                                  {isExpandable && (
                                    <Box
                                      onClick={() => handleEntryClick(entry.caseId)}
                                      sx={{
                                        cursor: "pointer"
                                      }}
                                    >
                                      <TableExpanderIcon
                                        toggled={entry?.toggled}
                                      />
                                    </Box>
                                  )}
                                  <Box                                     
                                    onClick={entryIsSharable ? onShareOpen.bind(this, entry.caseId) : undefined
                                    }
                                  >
                                    <Tooltip title={i18n("table_container", "sharing_action")}>
                                      <AdviceAvatarIcon
                                        sx={{
                                          mt: -0.125,
                                          mr: 1.5,
                                          color: entryIsSharable && entry.is_shared_case ? "primary.main" : entryIsSharable ? "common.black" : "grey.300",
                                          cursor: entryIsSharable ? "pointer" : "not-allowed"
                                        }}
                                      />
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                        {entry?.toggled && (
                          <ExpandableRow
                            colSpan={1}
                            entry={entry}
                            user={user}
                            partner={partner}
                            type={type}
                            msgTitleRef={msgTitleRef}
                            msgTextRef={msgTextRef}
                            onShareOpen={onShareOpen}
                            onAction={onAction}
                            currentAction={currentAction}
                            resetPaginationAndFilters={resetPaginationAndFilters}
                          />
                        )}                  
                      </Fragment>
                    )
                  })
                }
              </Fragment>
            )}
          </TableBody>
        </Table>
      </Box>
      <CustomTableFooter
        loaded={loaded}
        type="sessions"
        totalCases={numCases}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage === -1 && numCases > 100 ? 100 : rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
      />
    </TableContainer>
  );
};

export default MobileTable;