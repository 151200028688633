import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import Validator from "../../../../../classes/Validator";
import addonPlacementFulfillment from "./subcomponents/AddonPlacement.fulfillment";


export default function BasicProductSelectionFulfillment(state, prevState) {

	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	/**
	 * At least one förvaltningstjänst needs to be selected
	 */
	if (customerState.radio_product_selection_mode === "")
	{
		return false;
	}

	/**
	 * If no change is selected we don't need to validate the rest of the form
	 */
	if (customerState.radio_product_selection_mode === "nochange")
	{
		return true;
	}


	// Kunden måste vara i målgruppen för produkten.
	if ((customerState.radio_product_selection_mode === "addonchange" ||
		customerState.radio_product_selection_mode === "new") &&
		customerState.checkbox_customer_is_audience !== "Ja") {
		return false;
	}

	/**
	 * Delegate fulfillment to AddonProduct subcomponent
	 */
	if (customerState.radio_product_selection_mode === "addonchange")
	{
		if (customerState.addon_placement_fulfilled === "")
		{
			return addonPlacementFulfillment.call(this, customerState);
		}
		return customerState.addon_placement_fulfilled;
	}

	/**
	 * New product is still handled here below...
	 */

	/**
	 * Åtminstone en produktmåste vara vald
	 */
	if (!customerState.checkbox_product_guru &&
		!customerState.checkbox_product_spets &&
		!customerState.checkbox_product_other &&
		!customerState.checkbox_product_advice &&
		!customerState.checkbox_product_svan)
	{
		return false;
	}

	/**
	 * Kolla att alla gurufält är ifyllda om rutan är kryssad
	 */
	if (customerState.checkbox_product_guru)
	{
		if (customerState.allocation_amount_guru.length <= 0 ||
			customerState.radio_allocation_risk_guru.length <= 0 ||
			customerState.radio_allocation_perc_guru.length <= 0 ||
			customerState.checkbox_accounttype_guru.length <= 0)
		{
			return false;
		}

		/**
		 * Man måste välja ja eller nej på frågan om fast arvode
		 */
		if (customerState.radio_guru_fixed_fee.length <= 0)
		{
			return null;
		}

		// Väljer man ej fast arvode måste man fylla i fält om vinstdelning, förvaltningsavgift samt rådgivarens ersättning
		if (customerState.radio_guru_fixed_fee === "no" &&
			(
				customerState.allocation_fee_guru_revenueshare.length <= 0 ||
				customerState.allocation_fee_guru_rebate.length <= 0
			))
		{
			return false;
		}
	}

	/**
	 * Kolla att alla spetsfält är ifyllda om rutan är kryssad
	 */
	if (customerState.checkbox_product_spets)
	{
		if (customerState.allocation_amount_spets.length <= 0 ||
			customerState.radio_allocation_risk_spets.length <= 0 ||
			customerState.radio_allocation_perc_spets.length <= 0 ||
			customerState.checkbox_accounttype_spets.length <= 0)
		{
			return false;
		}

		/**
		 * Man måste välja ja eller nej på frågan om fast arvode
		 */
		if (customerState.radio_spets_fixed_fee.length <= 0)
		{
			return null;
		}

		// Väljer man ej fast arvode måste man fylla i fält om vinstdelning, förvaltningsavgift samt rådgivarens ersättning
		if (customerState.radio_spets_fixed_fee === "no" &&
			(
				customerState.allocation_fee_spets_revenueshare.length <= 0 ||
				customerState.allocation_fee_spets_rebate.length <= 0
			))
		{
			return false;
		}
	}

	/**
	 * Kolla att alla spetsfält är ifyllda om rutan är kryssad
	 */
	if (customerState.checkbox_product_svan)
	{
		if (customerState.allocation_amount_svan.length <= 0 ||
			customerState.allocation_svan_exponeringsbelopp.length <= 0 ||
			customerState.radio_allocation_risk_svan.length <= 0 ||
			customerState.checkbox_accounttype_svan.length <= 0)
		{
			return false;
		}
	}

	/**
	 * Kolla att alla spetsfält är ifyllda om rutan är kryssad
	 */
	if (customerState.checkbox_product_other)
	{
		if (customerState.allocation_type_other.length <= 0 ||
			customerState.allocation_amount_other.length <= 0 ||
			customerState.radio_allocation_perc_other.length <= 0 ||
			customerState.radio_allocation_risk_other.length <= 0 ||
			customerState.checkbox_accounttype_other.length <= 0 ||
			customerState.allocation_horizon_other.length <= 0)
		{
			return false;
		}
	}

	if (customerState.checkbox_product_advice)
	{
		if (customerState.checkbox_allocation_advice_understand !== "Ja" ||
			(
				customerState.checkbox_allocation_advice_is_fund !== "Ja" &&
				customerState.allocation_product_advice_select.length <= 0
			) ||
			customerState.allocation_amount_advice.length <= 0 ||
			customerState.radio_allocation_perc_advice.length <= 0 ||
			customerState.radio_allocation_risk_advice.length <= 0 ||
			customerState.checkbox_accounttype_advice.length <= 0 ||
			customerState.allocation_horizon_advice.length <= 0 ||
			customerState.allocation_fee_advice.length <= 0)
		{
			return false;
		}

		if (customerState.allocation_product_advice_select === "Annan" && customerState.allocation_product_advice.length <= 0)
		{
			return false;
		}

		if (customerState.allocation_amount_advice === "0" || customerState.allocation_amount_advice === "0 SEK")
		{
			return false;
		}
	}

	/**
	 * Man måste kryssa i om kunden förstått produkterna
	 */
	if (customerState.checkbox_advice_customer.length === 0)
	{
		return false;
	}

	/**
	 * If special terms checkbox has been checked we need to fill out the textfield
	 */
	if (customerState.checkbox_special_terms_agreed === "yes" &&
		customerState.discagreement_other.length === 0)
	{
		return false;
	}

	/**
	 * If special terms checkbox has been checked we need to fill out the textfield
	 */
	if (customerState.allocation_separate_deposit === "yes")
	{
		if (customerState.allocation_amount_initial.length === 0 || customerState.allocation_amount_initial < 0)
		{
			return false;
		}

		const initalAmount = customerState.allocation_amount_initial.split(' ').join('').replace("SEK", "");
		if (initalAmount < 0)
		{
			return false;
		}

		if (customerState.allocation_amount_spets)
		{
			const investInSpetsAmount = customerState.allocation_amount_spets.split(' ').join('').replace("SEK", "");
			if (parseInt(initalAmount, 10) > parseInt(investInSpetsAmount, 10))
			{
				return false;
			}
		}

		if (customerState.allocation_amount_text.length === 0)
		{
			return false;
		}
	}

	/**
	 * Investment hidden fields need to be entered
	 */
	if (customerState.investment_product.length === 0 ||
		customerState.investment_amount.length === 0 ||
		customerState.investment_share_of_assets.length === 0 ||
		customerState.investment_time_horizon.length === 0 ||
		customerState.investment_risk_class.length === 0 ||
		customerState.investment_account_type.length === 0 ||
		customerState.investment_fee_revshare.length === 0 ||
		customerState.investment_fee_rebate.length === 0 ||
		customerState.investment_fee_explained.length === 0 ||
		customerState.investment_fee_other.length === 0 ||
		customerState.investment_fee_other_sek.length === 0) {
		return false;
	}

	/**
	 * Bank account validation follows -- all other validations need to take place before these lines.
	 *
	 * If we've checked the box to not include account, we don't check the rest
	 */
	if (customerState.checkbox_skip_account)
	{
		return true;
	}

	/**
	 * Föranmält konto är obligatoriskt
	 */
	if (customerState.customer_account_bank_owner.length <= 0 ||
		customerState.customer_account_bank.length <= 0 ||
		customerState.customer_account_bank_clearingno.length <= 0 ||
		customerState.customer_account_bank_no.length <= 0)
	{
		return false;
	}

	/**
	 * Övriga banks + skipping validation should... skip validation
	 */
	if (customerState.customer_account_bank === '-' || customerState.checkbox_override_validation)
	{
		return true;
	}

	let availableBanks = state.availableBanks;
	if (this.props && this.props.availableBanks)
	{
		availableBanks = this.props.availableBanks;
	}
	const clearingNoValid = Validator.validateClearingNumber(
		customerState.customer_account_bank_clearingno,
		customerState.customer_account_bank,
		availableBanks
	);

	if (!clearingNoValid)
	{
		return false;
	}

	/**
	 * For the banks where we know the validation rules, check if the number should be validated.
	 */
	let shouldValidateAccountNumber = false;
	for (const bankName in availableBanks)
	{
		if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
		{
			continue;
		}

		if (customerState.customer_account_bank === bankName)
		{
			const bankInfo = availableBanks[bankName];
			if (bankInfo.length > 0)
			{
				if (bankInfo[0]['validationEnabled'] === "1")
				{
					shouldValidateAccountNumber = true;
				}
			}
			break;
		}
	}

	if (shouldValidateAccountNumber &&
		customerState.customer_account_bank_clearingno.length > 0 &&
		customerState.customer_account_bank_no.length > 0)
	{
		let bnk = customerState.customer_account_bank_clearingno + ", " + customerState.customer_account_bank_no;
		if (!window.kontonummer(bnk))
		{
			return false;
		}
	}

	return true;
}