import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import NumberField from "../../components/NumberField/NumberField";
import Select from "@components/Select/Select";
import SimpleTextField from "../../components/SimpleTextField/SimpleTextField";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import Table from "@components/Table/Table";
import TableSummaryField from "../../components/TableSummaryField/TableSummaryField";

// HELPERS
import {createClassName, loop, parseLocaleNumber} from "@helpers/utils";
import i18n from "@helpers/i18n";
import {calcSum, calcRisk, onTableFieldChange, TotalValue, numberIsBetweenRange} from "../../Cob.helpers";

// OTHER
import fulfillment from "./FamilyAndAssets.fulfillment";


export default class FamilyAndAssets extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	setTableData(fields) {
		this.setState(prevState => ({
			customerState: {
				...prevState.customerState,
				tableData: {...prevState.customerState.tableData, ...fields}
			}
		}));
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render() {
		const {props, state} = this;
		const {customer} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view4", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				{customer.isCompany && (
					<React.Fragment>
						<input type="hidden" name="customer_savingsprofile_realestate" value="-"/>
						<input type="hidden" name="customer_savingsprofile_debt" value="-"/>
					</React.Fragment>
				)}

				<ContentBox>
					<h2>{i18n("cob", "savings_profile")}</h2>

					<div className="Cob-view4-fields">
						{!customer.isCompany && (
							<React.Fragment>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div>
										<NumberField
											name="customer_service_obligation_nr"
											label={i18n("cob", "total_service_obligation")}
											unit=" st"
											value={customerState.serviceObligationNo || ""}
											decimals={0}
											onChange={({target}) => this.setCustomerState({serviceObligationNo: target.value})}
										/>
									</div>
									<div>
										<Select
											name="customer_martail_status"
											label={i18n("cob", "martail_status")}
											selectedIndex={customerState.martailStatusIndex}
											options={[
												{label: "Ogift", value: "Ogift"},
												{label: "Gift", value: "Gift"},
												{label: "Änka/Änkling", value: "Änka/Änkling"},
												{label: "Skild", value: "Skild"}
											]}
											onChange={(option, martailStatusIndex) => this.setCustomerState({
												martailStatus: option.value,
												martailStatusIndex
											})}
										/>
									</div>
									<div>
										<Select
											name="employment_status"
											label={i18n("cob", "employment_status")}
											selectedIndex={customerState.employmentStatusIndex}
											options={[
												{label: "Heltidsanställd", value: "Heltidsanställd"},
												{label: "Visstidsanställd", value: "Visstidsanställd"},
												{label: "Egenföretagare", value: "Egenföretagare"},
												{label: "Arbetssökande", value: "Arbetssökande"},
												{label: "Student", value: "Student"},
												{label: "Pensionär", value: "Pensionär"}
											]}
											onChange={(option, employmentStatusIndex) => this.setCustomerState({
												employmentStatus: option.value,
												employmentStatusIndex
											})}
										/>
										<input type="hidden"
											   name="checkbox_customer_kyc_empl_other_until_further_notice"
											   value={customerState.employmentStatusIndex === 0 ? "yes" : "no"}/>
										<input type="hidden"
											   name="checkbox_customer_kyc_empl_other_temporary_employment"
											   value={customerState.employmentStatusIndex === 1 ? "yes" : "no"}/>
										<input type="hidden" name="checkbox_customer_kyc_empl_self_employed"
											   value={customerState.employmentStatusIndex === 2 ? "yes" : "no"}/>
										<input type="hidden" name="checkbox_customer_kyc_empl_unemployed"
											   value={customerState.employmentStatusIndex === 3 ? "yes" : "no"}/>
										<input type="hidden" name="checkbox_customer_kyc_empl_student"
											   value={customerState.employmentStatusIndex === 4 ? "yes" : "no"}/>
										<input type="hidden" name="checkbox_customer_kyc_empl_retired"
											   value={customerState.employmentStatusIndex === 5 ? "yes" : "no"}/>
									</div>
								</StackLayout>

								<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
									<div>
										<NumberField
											name="customer_salary"
											label={i18n("cob", "salary_after_tax")}
											unit=" SEK"
											value={customerState.salary || ""}
											showSeparators={true}
											onChange={this._onChangeMonthlyIncome}
										/>
										<input type="hidden" name="checkbox_customer_kyc_net_income_0_15000"
											   value={numberIsBetweenRange(customerState.salary, 0, 15000) ? "yes" : "no"}/>
										<input type="hidden" name="checkbox_customer_kyc_net_income_15000_30000"
											   value={numberIsBetweenRange(customerState.salary, 15001, 30000) ? "yes" : "no"}/>
										<input type="hidden" name="checkbox_customer_kyc_net_income_30000_50000"
											   value={numberIsBetweenRange(customerState.salary, 30001, 50000) ? "yes" : "no"}/>
										<input type="hidden" name="checkbox_customer_kyc_net_income_50000"
											   value={customerState.salary >= 50001 ? "yes" : "no"}/>
									</div>
									<div>
										<NumberField
											name="customer_monthly_payment"
											label={i18n("cob", "monthly_payment")}
											showSeparators={true}
											unit=" SEK"
											value={customerState.monthlyPayment || ""}
											onChange={this._onChangeMonthlyPayment}
										/>
									</div>
									<div>
										<NumberField
											name="customer_savingsprofile_realestate"
											label={i18n("general", "real_estate")}
											unit=" SEK"
											showSeparators={true}
											value={customerState.savingsProfileRealEstate || ""}
											decimals={0}
											onChange={({target}) => {
												this.setCustomerState({savingsProfileRealEstate: target.value.split(' ').join('')});
											}}
										/>
									</div>
									<div>
										<NumberField
											name="customer_savingsprofile_debt"
											label={i18n("general", "debt")}
											showSeparators={true}
											unit=" SEK"
											value={customerState.savingsProfileDebt || ""}
											onChange={({target}) => {
												this.setCustomerState({savingsProfileDebt: target.value.split(' ').join('')});
											}}
										/>
									</div>
								</StackLayout>

								<div className="h-line spacing"/>
							</React.Fragment>
						)}

						<h3 className="center" style={{marginBottom: 20}}>Kundens befintliga sparande</h3>

						<Table
							className="Cob-Table"
							columns={_getTableColumns.call(this)}
							rows={_getTableRows.call(this)}
						/>
						<TableSummary getTableData={this._getTableData} customerState={customerState}/>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_getTableData = (fieldName, newState) => {
		const {tableData} = this.state.customerState;
		const td = {...tableData, ...newState};
		return td[fieldName] ? parseLocaleNumber(td[fieldName]) : 0;
	};


	_onChangeMonthlyIncome = ({target}) => {
		/**
		 * Make sure no unallowed characters are entered and that we don't have any spaces
		 */
		let paymentValue = target.value;
		paymentValue = paymentValue.replace(/\D/g,'');
		paymentValue = paymentValue.split(' ').join('');
		this.setCustomerState({salary: paymentValue});
	};


	_onChangeMonthlyPayment = ({target}) => {
		/**
		 * Make sure no unallowed characters are entered and that we don't have any spaces
		 */
		let paymentValue = target.value;
		paymentValue = paymentValue.replace(/\D/g,'');
		paymentValue = paymentValue.split(' ').join('');
		this.setCustomerState({monthlyPayment: paymentValue});
	};
}


// PRIVATE COMPONENTS
function TableSummary({getTableData, customerState}) {
	const totalSavings = (
		getTableData("portfolio_buffer_sum") +
		getTableData("portfolio_1_3_sum") +
		getTableData("portfolio_3_5_sum") +
		getTableData("portfolio_longterm_sum")
	);

	const currentRiskLevelForSavings = totalSavings > 0 ? (
		Math.round((
			loop(4, index => getTableData(
				index === 0 ? "portfolio_buffer_sum" :
					index === 1 ? "portfolio_1_3_sum" :
						index === 2 ? "portfolio_3_5_sum" :
							"portfolio_longterm_sum"
			) * getTableData(
				index === 0 ? "portfolio_buffer_sum_risk" :
					index === 1 ? "portfolio_1_3_sum_risk" :
						index === 2 ? "portfolio_3_5_sum_risk" :
							"portfolio_longterm_sum_risk"
			), true).reduceRight((a, b) => a + b, 0) / totalSavings) * 10
		) / 10
	) : 0;

	const monthlySalary = _getAmountAsIntWithoutCurrencyCode(customerState.salary);
	const monthlyPayments = _getAmountAsIntWithoutCurrencyCode(customerState.monthlyPayment);
	const monthlySum = monthlySalary - monthlyPayments;

	return (
		<div className="Cob-TableSummary">
			<div className="Cob-TableSummary-container">
				<TableSummaryField
					name="savingsprofile_portfolio_tot_sum" label="Total summa placeringsbara tillgångar"
					value={totalSavings} valueSuffix=" SEK" decimals={2}
				/>
				<TableSummaryField
					name="savingsprofile_portfolio_tot_sum_risk"
					label="Nuvarande riskklass för placeringsbara tillgångar"
					value={currentRiskLevelForSavings} decimals={1}
				/>
				<TableSummaryField
					name="customer_savingsprofile_buffer_under_1_year" label="Buffertsparande (under 1 år)"
					description="Sparande för att täcka planerade och oförutsedda utgifter"
					value={getTableData("portfolio_buffer_sum")} valueSuffix=" SEK" decimals={2}
				/>
				<TableSummaryField
					name="customer_savingsprofile_1_3_year" label="Målsparande (1–3 år)"
					description="Sparande för tex bil, båt boende och resa"
					value={getTableData("portfolio_1_3_sum")} valueSuffix=" SEK" decimals={2}
				/>
				<TableSummaryField
					name="customer_savingsprofile_3_5_year" label="Målsparande (3–5 år)"
					description="Sparande för tex bil, båt boende och resa"
					value={getTableData("portfolio_3_5_sum")} valueSuffix=" SEK" decimals={2}
				/>
				<TableSummaryField
					name="customer_savingsprofile_longterm_over_5_year" label="Långsiktigt sparande (över 5 år)"
					description="Sparande för tex pension, arv"
					value={getTableData("portfolio_longterm_sum")} valueSuffix=" SEK" decimals={2}
				/>
				<TableSummaryField
					name="customer_savingsprofile_total_savings" label="Totalt sparande"
					description="Buffert + målsparande + långsiktigt sparande"
					value={totalSavings} valueSuffix=" SEK" decimals={2}
				/>
				<TableSummaryField
					name="customer_savingsprofile_sum" label="Summa tillgångar"
					value={
						_getAmountAsIntWithoutCurrencyCode(totalSavings)
						+ _getAmountAsIntWithoutCurrencyCode(customerState.savingsProfileRealEstate)
						- _getAmountAsIntWithoutCurrencyCode(customerState.savingsProfileDebt)
					}
					valueSuffix=" SEK" decimals={2}
				/>
				<TableSummaryField
					name="customer_sum_monthly_net" label="Summa månadsnetto"
					value={monthlySum}
					valueSuffix=" SEK" decimals={2}
				/>
			</div>
		</div>
	);
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		customerState: {
			isCompany: customer.isCompany,
			serviceObligationNo: customer.getData("customer_service_obligation_nr"),
			martailStatus: customer.getData("customer_martail_status"),
			employmentStatus: customer.getData("employment_status"),
			salary: customer.getData("customer_salary"),
			monthlyPayment: customer.getData("customer_monthly_payment"),
			savingsProfileRealEstate: customer.isCompany ? "-" : customer.getData("customer_savingsprofile_realestate"),
			savingsProfileDebt: customer.isCompany ? "-" : customer.getData("customer_savingsprofile_debt"),
			tableData: customer.view4TableData,

			// Select indexes
			martailStatusIndex: (
				customer.getData("customer_martail_status") === "Ogift" ? 0 :
					customer.getData("customer_martail_status") === "Gift" ? 1 :
						customer.getData("customer_martail_status") === "Änka/Änkling" ? 2 :
							customer.getData("customer_martail_status") === "Skild" ? 3 :
								-1
			),
			employmentStatusIndex: (
				customer.getData("employment_status") === "Heltidsanställd" ? 0 :
					customer.getData("employment_status") === "Visstidsanställd" ? 1 :
						customer.getData("employment_status") === "Egenföretagare" ? 2 :
							customer.getData("employment_status") === "Arbetssökande" ? 3 :
								customer.getData("employment_status") === "Student" ? 4 :
									customer.getData("employment_status") === "Pensionär" ? 5 :
										-1
			)
		}
	};

	newState.isFulfilled = false;
	return newState;
}

function _getAmountAsIntWithoutCurrencyCode(amount) {

	if (!amount) {
		return 0;
	}

	if (!isNaN(amount)) {
		return parseInt(amount, 10);
	}

	// Remove spaces and everything after comma and dot (since in this case decimals are not important)

	amount = amount.trim();
	amount = amount.replace(new RegExp(" ", 'g'), "");

	if (amount.indexOf(",") > -1) {
		amount = amount.substring(0, amount.indexOf(","));
	}

	if (amount.indexOf(".") > -1) {
		amount = amount.substring(0, amount.indexOf("."));
	}

	/**
	 * Check if amount contains currency code and in that case remove it
	 */
	if (amount.length > 3) {
		var currencyCode = amount.substring(amount.length - 3);
		if (isNaN(currencyCode)) { // Stripping currency code
			amount = amount.substring(0, amount.length - 3);
		}
	}

	return parseInt(amount, 10);
}


function _getFields() {
	return [
		"customer_service_obligation_nr",
		"customer_martail_status",
		"employment_status",
		"customer_salary",
		"customer_monthly_payment",
		"customer_savingsprofile_realestate",
		"customer_savingsprofile_debt",

		"checkbox_customer_kyc_empl_other_until_further_notice",
		"checkbox_customer_kyc_empl_other_temporary_employment",
		"checkbox_customer_kyc_empl_self_employed",
		"checkbox_customer_kyc_empl_unemployed",
		"checkbox_customer_kyc_empl_student",
		"checkbox_customer_kyc_empl_retired",

		// Table data
		...Object.keys(this.state.customerState.tableData)
	];
}

function _getTableColumns() {
	return [
		{key: "riskClass", label: i18n("cob", "nord_risk_class"), width: "15%", verticalAlign: "top"},
		{
			key: "financialInstruments",
			label: i18n("cob", "financial_instruments"),
			width: "40%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "savingsBuffer",
			label: i18n("cob", "savings_buffer") + ` ${i18n("general", "under", true)} 1 ` + i18n("general", "years", true),
			width: "10%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
		{
			key: "savingsGoal1",
			label: i18n("cob", "savings_goal") + " 1–3 " + i18n("general", "years", true),
			width: "10%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
		{
			key: "savingsGoal2",
			label: i18n("cob", "savings_goal") + " 3–5 " + i18n("general", "years", true),
			width: "10%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
		{
			key: "longTermSavings",
			label: i18n("cob", "long_term_savings"),
			width: "15%",
			horizontalAlign: "right",
			verticalAlign: "top"
		}
	];
}

function _getTableRows() {
	const {tableData} = this.state.customerState;

	return [
		{
			riskClass: {
				className: "risk-class bg-success",
				title: i18n("general", "low"),
				value: "1"
			},
			financialInstruments: {
				className: "financial-instruments",
				value: "Konto, Statsobligationer."
			},
			savingsBuffer: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_buffer_1"
										value={tableData.portfolio_buffer_1 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_buffer_", 1)}/>
			},
			savingsGoal1: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_1_3_1"
										value={tableData.portfolio_1_3_1 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_1_3_", 1)}/>
			},
			savingsGoal2: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_3_5_1"
										value={tableData.portfolio_3_5_1 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_3_5_", 1)}/>
			},
			longTermSavings: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_longterm_1"
										value={tableData.portfolio_longterm_1 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_longterm_", 1)}/>
			}
		},
		{
			riskClass: {
				className: "risk-class bg-success",
				title: i18n("general", "low"),
				value: "2"
			},
			financialInstruments: {
				value: "Fonder och strukturerade produkter enligt ESMA, SPIS eller PRIIPs risk 2. Även AAA-AA kreditratade och börsnoterade obligationer."
			},
			savingsBuffer: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_buffer_2"
										value={tableData.portfolio_buffer_2 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_buffer_", 2)}/>
			},
			savingsGoal1: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_1_3_2"
										value={tableData.portfolio_1_3_2 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_1_3_", 2)}/>
			},
			savingsGoal2: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_3_5_2"
										value={tableData.portfolio_3_5_2 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_3_5_", 2)}/>
			},
			longTermSavings: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_longterm_2"
										value={tableData.portfolio_longterm_2 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_longterm_", 2)}/>
			}
		},
		{
			riskClass: {
				className: "risk-class bg-warning",
				title: i18n("general", "medium"),
				value: "3"
			},
			financialInstruments: {
				value: "Fonder och strukturerade produkter enligt ESMA, SPIS eller PRIIPs risk 3. Även A-BBB+ kreditratade och börsnoterade obligationer."
			},
			savingsBuffer: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_buffer_3"
										value={tableData.portfolio_buffer_3 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_buffer_", 3)}/>
			},
			savingsGoal1: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_1_3_3"
										value={tableData.portfolio_1_3_3 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_1_3_", 3)}/>
			},
			savingsGoal2: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_3_5_3"
										value={tableData.portfolio_3_5_3 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_3_5_", 3)}/>
			},
			longTermSavings: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_longterm_3"
										value={tableData.portfolio_longterm_3 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_longterm_", 3)}/>
			}
		},
		{
			riskClass: {
				className: "risk-class bg-warning",
				title: i18n("general", "medium"),
				value: "4"
			},
			financialInstruments: {
				value: "Fonder och strukturerade produkter enligt ESMA, SPIS eller PRIIPs risk 4. Även börsnoterade obligationer med goda säkerheter."
			},
			savingsBuffer: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_buffer_4"
										value={tableData.portfolio_buffer_4 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_buffer_", 4)}/>
			},
			savingsGoal1: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_1_3_4"
										value={tableData.portfolio_1_3_4 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_1_3_", 4)}/>
			},
			savingsGoal2: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_3_5_4"
										value={tableData.portfolio_3_5_4 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_3_5_", 4)}/>
			},
			longTermSavings: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_longterm_4"
										value={tableData.portfolio_longterm_4 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_longterm_", 4)}/>
			}
		},
		{
			riskClass: {
				className: "risk-class bg-warning",
				title: i18n("general", "medium"),
				value: "5"
			},
			financialInstruments: {
				value: "Fonder och strukturerade produkter enligt ESMA, SPIS eller PRIIPs risk 5. Även börsnoterade obligationer med bra säkerheter alternativt Onoterade obligationer med mycket goda säkerheter eller korg med >15 börsnoterade aktier."
			},
			savingsBuffer: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_buffer_5"
										value={tableData.portfolio_buffer_5 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_buffer_", 5)}/>
			},
			savingsGoal1: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_1_3_5"
										value={tableData.portfolio_1_3_5 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_1_3_", 5)}/>
			},
			savingsGoal2: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_3_5_5"
										value={tableData.portfolio_3_5_5 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_3_5_", 5)}/>
			},
			longTermSavings: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_longterm_5"
										value={tableData.portfolio_longterm_5 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_longterm_", 5)}/>
			}
		},
		{
			riskClass: {
				className: "risk-class bg-danger",
				title: i18n("general", "high"),
				value: "6"
			},
			financialInstruments: {
				value: "Fonder och strukturerade produkter enligt ESMA, SPIS eller PRIIPs risk 6. Även onoterade obligationer med bra säkerheter eller korg med 4-15 börsnoterade aktier."
			},
			savingsBuffer: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_buffer_6"
										value={tableData.portfolio_buffer_6 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_buffer_", 6)}/>
			},
			savingsGoal1: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_1_3_6"
										value={tableData.portfolio_1_3_6 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_1_3_", 6)}/>
			},
			savingsGoal2: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_3_5_6"
										value={tableData.portfolio_3_5_6 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_3_5_", 6)}/>
			},
			longTermSavings: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_longterm_6"
										value={tableData.portfolio_longterm_6 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_longterm_", 6)}/>
			}
		},
		{
			riskClass: {
				className: "risk-class bg-danger",
				title: i18n("general", "high"),
				value: "7"
			},
			financialInstruments: {
				value: "Fonder och strukturerade produkter enligt ESMA, SPIS eller PRIIPs risk 7. Även onoterade obligationer med aktieliknande risk eller 1-4 börsnoterade aktier och onoterade aktier."
			},
			savingsBuffer: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_buffer_7"
										value={tableData.portfolio_buffer_7 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_buffer_", 7)}/>
			},
			savingsGoal1: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_1_3_7"
										value={tableData.portfolio_1_3_7 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_1_3_", 7)}/>
			},
			savingsGoal2: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_3_5_7"
										value={tableData.portfolio_3_5_7 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_3_5_", 7)}/>
			},
			longTermSavings: {
				value: <SimpleTextField type="number" name="savingsprofile_portfolio_longterm_7"
										value={tableData.portfolio_longterm_7 || ""}
										placeholder="SEK" unit=" SEK"
										showSeparators={true}
										onChange={onTableFieldChange.bind(this, "portfolio_longterm_", 7)}/>
			}
		},
		{
			riskClass: {className: "label h-right", value: i18n("general", "sum"), colSpan: 2},
			savingsBuffer: {
				value: (
					<TotalValue unit=" SEK" namePrefix="savingsprofile_" name="portfolio_buffer_sum"
								value={calcSum("portfolio_buffer_", 7, this._getTableData)}/>
				)
			},
			savingsGoal1: {
				value: (
					<TotalValue unit=" SEK" namePrefix="savingsprofile_" name="portfolio_1_3_sum"
								value={calcSum("portfolio_1_3_", 7, this._getTableData)}/>
				)
			},
			savingsGoal2: {
				value: (
					<TotalValue unit=" SEK" namePrefix="savingsprofile_" name="portfolio_3_5_sum"
								value={calcSum("portfolio_3_5_", 7, this._getTableData)}/>
				)
			},
			longTermSavings: {
				value: (
					<TotalValue unit=" SEK" namePrefix="savingsprofile_" name="portfolio_longterm_sum"
								value={calcSum("portfolio_longterm_", 7, this._getTableData)}/>
				)
			}
		},
		{
			riskClass: {className: "label h-right", value: i18n("general", "weighted_risk"), colSpan: 2},
			savingsBuffer: {
				value: (
					<TotalValue decimals={1} namePrefix="savingsprofile_" name="portfolio_buffer_sum_risk"
								value={calcRisk("portfolio_buffer_", 7, this._getTableData)}/>
				)
			},
			savingsGoal1: {
				value: (
					<TotalValue decimals={1} namePrefix="savingsprofile_" name="portfolio_1_3_sum_risk"
								value={calcRisk("portfolio_1_3_", 7, this._getTableData)}/>
				)
			},
			savingsGoal2: {
				value: (
					<TotalValue decimals={1} namePrefix="savingsprofile_" name="portfolio_3_5_sum_risk"
								value={calcRisk("portfolio_3_5_", 7, this._getTableData)}/>
				)
			},
			longTermSavings: {
				value: (
					<TotalValue decimals={1} namePrefix="savingsprofile_" name="portfolio_longterm_sum_risk"
								value={calcRisk("portfolio_longterm_", 7, this._getTableData)}/>
				)
			}
		}
	];
}