import React from "react";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class Icon extends React.PureComponent {

    render() {
        const { props } = this;
        const { value, size } = props;
        const classes = createClassName(props.className, {
            "Icon": true,
            "material-icons": true,
            "small": size === "small",
            "large": size === "large",
            "xlarge": size === "xlarge",
        });

        return <div className={ classes }>{ value }</div>;
    }
}