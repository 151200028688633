// OTHER
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (!state.hasValidIdentityDocument)
	{
		return false;
	}

	const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size / 1024), 0);
	const maxUploadFileSize = Math.round(state.maxUploadFileSize / 1024);
	if (totalDocsSize > maxUploadFileSize)
	{
		return false;
	}

	// If relationship with USA is selected or the customer has a lot of dealings in foreign countries we require
	// the explanation text to be filled out.
	if ((customerState.rdo_kyc_usperson === "Ja" || customerState.rdo_kyc_inttrans === "Ja") && customerState.kyc_us_or_foreign_text.length <= 0)
	{
		return false;
	}

	// One or more citizenships must be selected
	if (customerState.citizenship.length <= 0)
	{
		return false;
	}

	if (state.citizenshipCount === 2 && customerState.citizenship_2.length <= 0)
	{
		return false;
	}

	// Tax residency must be selected
	if (customerState.taxCountry.length <= 0)
	{
		return false;
	}

	// Fatca and foreign transactions needs to be selected
	if (customerState.rdo_kyc_usperson !== "Nej" || customerState.rdo_kyc_inttrans !== "Nej")
	{
		// return false;
	}

	// Purpose needs to be selected
	if (!(customerState.kyc_purpose_savings === "yes" ||
		customerState.kyc_purpose_security === "yes" ||
		customerState.kyc_purpose_pension === "yes" ||
		customerState.kyc_purpose_consumption === "yes" ||
		customerState.kyc_purpose_gift === "yes" ||
		customerState.kyc_purpose_beneficiary === "yes" ||
		customerState.kyc_purpose_other === "yes"))
	{
		return false;
	}

	// If other is checked the text needs to be entered as well
	if (customerState.kyc_purpose_other === "yes" && customerState.kyc_purpose_other_details.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_invest_on_behalf.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_invest_on_behalf === "Annat" && customerState.kyc_invest_on_behalf_other.length <= 0)
	{
		return false;
	}

	// Validate that POA documents has been attached if we select that option
	let hasPoaDocument = false;
	state.docs.forEach(function (d)
	{
		if (d.filterKey !== undefined && d.filterKey === "poa-copy")
		{
			hasPoaDocument = true;
		}
	});

	if (customerState.kyc_invest_on_behalf === "För någon annan genom fullmakt" &&
		(customerState.kyc_invest_on_behalf_poa.length <= 0 || !hasPoaDocument))
	{
		return false;
	}

	if (customerState.kyc_occupation.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_occupation === "Anställd" || customerState.kyc_occupation === "Egen företagare")
	{
		if (customerState.kyc_occupation_details.length <= 0)
		{
			return false;
		}
	}

	if (customerState.kyc_income.length <= 0)
	{
		return false;
	}

	// If not owner of funds we need to fill out the explanation
	if (customerState.kyc_owner_of_funds !== "Ja" && customerState.kyc_owner_of_funds_text.length <= 0)
	{
		return false;
	}

	// Source of funds needs to be specified
	if (!(customerState.kyc_src_funds_salary_pension === "yes" ||
		customerState.kyc_src_funds_savings === "yes" ||
		customerState.kyc_src_funds_exit === "yes" ||
		customerState.kyc_src_funds_gift_inheritance === "yes" ||
		customerState.kyc_src_funds_real_estate === "yes" ||
		customerState.kyc_src_funds_other === "yes"))
	{
		return false;
	}

	if (customerState.kyc_src_funds_other === "yes" && customerState.kyc_src_funds_other_details.length <= 0)
	{
		return false;
	}

	// Source institution needs to be selected
	if (!(customerState.kyc_src_bank_sweden === "yes" ||
		customerState.kyc_src_insurance_sweden === "yes" ||
		customerState.kyc_src_bank_eu === "yes" ||
		customerState.kyc_src_insurance_eu === "yes" ||
		customerState.kyc_src_bank_world === "yes" ||
		customerState.kyc_src_insurance_world === "yes"))
	{
		return false;
	}

	if (customerState.kyc_first_deposit.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_first_deposit === "Över 5 000 000 kr")
	{
		let estimate = customerState.kyc_first_deposit_estimate;
		if (!estimate || estimate.length <= 0)
		{
			return false;
		}

		estimate = estimate.split(" ").join("");
		estimate = parseInt(estimate, 10);
		if (isNaN(estimate) || estimate <= 5000000)
		{
			return false;
		}
	}

	if (customerState.kyc_yearly_deposit.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_yearly_deposit === "Över 5 000 000 kr")
	{
		let estimate = customerState.kyc_yearly_deposit_estimate;
		if (!estimate || estimate.length <= 0)
		{
			return false;
		}

		estimate = estimate.split(" ").join("");
		estimate = parseInt(estimate, 10);
		if (isNaN(estimate) || estimate <= 5000000)
		{
			return false;
		}
	}

	if (customerState.kyc_transaction_size.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_transaction_size === "Över 5 000 000 kr")
	{
		let estimate = customerState.kyc_transaction_size_estimate;
		if (!estimate || estimate.length <= 0)
		{
			return false;
		}

		estimate = estimate.split(" ").join("");
		estimate = parseInt(estimate, 10);
		if (isNaN(estimate) || estimate <= 5000000)
		{
			return false;
		}
	}

	if (customerState.kyc_deposit_frequency.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_withdrawal_frequency.length <= 0)
	{
		return false;
	}

	if (!customerState.kyc_is_pep_fulfilled)
	{
		return false;
	}

	return true;
}