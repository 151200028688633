import React from "react";
import PropTypes from "prop-types";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class Notice extends React.PureComponent {
    static propTypes = {
        message: PropTypes.string,
        title: PropTypes.string,
        appearance: PropTypes.string,
    }
    static defaultProps = {
        appearance: "secondary",
    }

    render() {
        const { props } = this;
        const { children, message, title, appearance } = props;
        const classes = createClassName(props.className, {
            "Notice": true,
            [appearance]: true,
            "custom-message": !!children,
        });

        return (
            <div style={ props.style } className={ classes } data-title={ title }>{ message || children }</div>
        );
    }
}