// MODULES
import React from "react";
import PropTypes from "prop-types";

// CLASSES
import Validator from "@classes/Validator";

// COMPONENTS
import FieldsCollection from "@components/FieldsCollection/FieldsCollection";
import SimpleCheckbox from "@routes/Cob/components/SimpleCheckbox/SimpleCheckbox";
import TextField from "@components/TextField/TextField";
import Checkbox from "@components/Checkbox/Checkbox";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";


export default class Signatory extends React.PureComponent {
	static propTypes = {
		data: PropTypes.object.isRequired,
		index: PropTypes.number.isRequired,
		selectable: PropTypes.bool.isRequired,
		selectedSignatoriesAmount: PropTypes.number.isRequired,
		onChange: PropTypes.func.isRequired,
	};

	static defaultProps = {
		selectable: true,
		selected: true,
	};

	state = {
		editMode: false
	};

	render() {
		const {props, state} = this;
		const {data, selectable, selectedSignatoriesAmount} = props;
		const classNamePrefix = "Cob-SignAgreement-Signatory";
		const classes = createClassName(classNamePrefix, {
			"edit": state.editMode,
			"selectable": selectable,
			"selected": data.selected === true,
		});

		const ssn = (data.ssn && data.ssn.length === 13) ? data.ssn.replace("-", "") : data.ssn;

		return (
			<div className={classes}>
				{selectable && (
					<div className={classNamePrefix + "-bar"}>
						<SimpleCheckbox
							checked={data.selected !== false}
							disabled={data.selected !== false && selectedSignatoriesAmount <= 1}
							onChange={this._onSelectedChange}
						/>
						<div className={classNamePrefix + "-name"}>{data.firstName + " " + data.lastName}</div>
						<div className={classNamePrefix + "-editToggle"} onClick={this._onEditToggle}>edit</div>
					</div>
				)}

				<div className={classNamePrefix + "-fields"}>
					<FieldsCollection
						fields={[
							{
								render: (
									<FieldsCollection
										className="name-collection"
										fieldsPerRow={2}
										fields={[
											{
												render: (
													<EditableField
														identifier="firstName"
														label={`${i18n("general", "first_name")} *`}
														value={data.firstName}
														validation={this._validation}
														onChange={this._onFieldChange}
													/>
												),
											}, {
												render: (
													<EditableField
														identifier="lastName"
														label={`${i18n("general", "last_name")} *`}
														value={data.lastName}
														validation={this._validation}
														onChange={this._onFieldChange}
													/>
												),
											}
										]}
									/>
								),
							}, {
								render: (
									<EditableField
										type="number"
										identifier="ssn"
                    label={`${i18n("general", "social_security_number")} *`}
										value={ssn}
										validation={Validator.validateSsn}
										onChange={this._onFieldChange}
									/>
								),
							}, {
								render: (
									<EditableField
										type="email"
										identifier="email"
                    label={`${i18n("general", "email_address")} *`}
										value={data.email}
										validation={Validator.validateEmail}
										onChange={this._onFieldChange}
									/>
								),
							}, {
								render: (
									<Checkbox
										identifier="sms_checkbox"
										label={i18n("sign_agreement", "send_sms")}
										onChange={this._onSmsRequestedChanged}
										checked={data.isSmsRequested}
									/>
								),
							}, {
								display: data.isSmsRequested,
								render: (
									<EditableField
										type="tel"
										identifier="phoneNumber"
										label={`${i18n("general", "phone_number")} (${i18n("general", "phone_number_begin_with")})`}
										placeholder="+46 XXX XXX XXX"
										value={data.phoneNumber}
										validation={Validator.validatePhoneForSmsSigning}
										onChange={this._onFieldChange}
									/>
								),
							}
						]}
					/>
				</div>
			</div>
		);
	}

	// Internal methods
	_validation = (value) => (value.length > 0);
	_onEditToggle = () => this.setState({editMode: !this.state.editMode});

	_onSelectedChange = (selected) => {
		const {data, index, selectedSignatoriesAmount, onChange} = this.props;
		if (data.selected === false || selectedSignatoriesAmount > 1) onChange({...data, selected}, index);
	};

	_onFieldChange = (identifier, value) => {
		const {data, index, onChange} = this.props;
		onChange({...data, [identifier]: value}, index);
	};

	_onSmsRequestedChanged = (checked) => {
		const {data, index, onChange} = this.props;
		onChange({...data, isSmsRequested: checked}, index);
	};
}


// PRIVATE COMPONENTS
class EditableField extends React.PureComponent {

	render() {
		const {props} = this;
		const {type, identifier, label, placeholder, value, validation, onChange} = props;

		return (
			<TextField
				type={type}
				name={identifier}
				label={label}
				placeholder={placeholder}
				value={value}
				validation={validation}
				onChange={onChange ? this._onChange : undefined}
			/>
		);
	}

	// Internal methods
	_onChange = ({target}) => {
		const {identifier, onChange} = this.props;
		onChange(identifier, target.value);
	}
}