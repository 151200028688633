import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./FinancialSituation.fulfillment";
import TextView from "../../../../../components/TextView/TextView";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import NumberField from "../../../components/NumberField/NumberField";
import i18n from "@helpers/i18n";

export default class FinancialSituation extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render() {
		const {customerState} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		let surplus = 0;

		surplus += parseInt(customerState.situation_income_work.replaceAll(" ", "") || 0, 10);
		surplus += parseInt(customerState.situation_income_other.replaceAll(" ", "") || 0, 10);
		surplus -= parseInt(customerState.situation_expenses.replaceAll(" ", "") || 0, 10);

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("financial_situation", "financial_situation")}</h2>

					<div className="Cob-view2-fields">
						<h3>{i18n("financial_situation", "income_and_expenses")}</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<h4>{i18n("financial_situation", "income_from_employment")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_income_work"}
											 value={customerState.situation_income_work}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_income_work: target.value})} />
							</div>
							<div>
								<h4>{i18n("financial_situation", "other_income")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_income_other"}
											 value={customerState.situation_income_other}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_income_other: target.value})} />
							</div>
							<div>
								<h4>{i18n("financial_situation", "expenses")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_expenses"}
											 value={customerState.situation_expenses}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_expenses: target.value})} />
							</div>
						</StackLayout>

						<h4 style={{marginBottom: "15px", marginTop: "0"}}>{i18n("financial_situation", "surplus")}</h4>
						{this._monetaryAmountFormatter(surplus)} kr

						<h4 style={{marginTop: "30px"}}>{i18n("financial_situation", "comment")}</h4>
						<TextView
							name="situation_income_comment"
							label={`${i18n("general", "free_text")} (${800 - customerState.situation_income_comment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.situation_income_comment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_income_comment: target.value})}
							fullWidth
							minHeight={120}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	_monetaryAmountFormatter = (value) => value.toLocaleString("sv-SE");
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		isFulfilled: false,
		customerState: {
			situation_income_work: customer.getData("situation_income_work"),
			situation_income_other: customer.getData("situation_income_other"),
			situation_expenses: customer.getData("situation_expenses"),
			situation_surplus: customer.getData("situation_surplus"),
			situation_income_comment: customer.getData("situation_income_comment") || "",
		}
	};
}

function _getFields() {
	return [
		"situation_income_work",
		"situation_income_other",
		"situation_expenses",
		"situation_surplus",
		"situation_income_comment",
	];
}