import React from "react";

// COMPONENTS
import Button from "@components/Button/Button";
import Icon from "@components/Icon/Icon";
import StackLayout from "@components/layouts/StackLayout/StackLayout";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class Modal extends React.PureComponent {
    state = {
        visible: false,
    }

    visible(value) {
        this._visibleTimeout = setTimeout(() => {
            this.setState({ visible: value === true });
        });
    }

    componentDidMount() {
        const { visible } = this.props;

        if(visible === true) this.visible(true);
    }

    componentWillUnmount() {
        if(this._visibleTimeout) clearTimeout(this._visibleTimeout);
    }

    componentDidUpdate(prevProps) {
        const { visible } = this.props;

        if(prevProps.visible !== visible) {
            this.visible(visible === true);
        }
    }

    render() {
        const { props, state } = this;
        const { view, children, direction, title, subtitle, status, loggedOut } = props;
        const modalWrapperClasses = createClassName(props.className, {
            "Modal": true,
            "visible": state.visible,
            "start": view === 1,
            "horizontal": direction === "horizontal",
            "vertical": direction && direction !== "horizontal",
            [status]: !!status,
        });

        const modalContentClasses = createClassName(props.className, {
            "logged-in": !(loggedOut === true) || loggedOut === undefined,
        });

        return (
            <div className={ modalWrapperClasses }>
                <div className="Modal-overlay" />
                <div className={`Modal-content-container ${modalContentClasses}`}>
                    <div className="Modal-content">
                        { !!title && (
                            <div className="Modal-title">
                                { !!status && (
                                    <Icon
                                        className="Modal-status-icon"
                                        size="large"
                                        value={
                                            status === "success" ? "check" :
                                            status === "warning" ? "warning" :
                                            status === "danger" ? "error" :
                                            null
                                        }
                                    />
                                )}
                                <h2>{ title }</h2>
                                { !!subtitle && (
                                    <h3>{ subtitle }</h3>
                                )}
                            </div>
                        )}
                        { children }
                    </div>
                </div>
            </div>
        );
    }

    // Static components
    static Button(props) {
        return (
            <Button { ...props } appearance={ props.appearance || "medium" } size="small" />
        );
    }

    static ButtonsContainer({ justifyContent, children }) {
        return (
            <StackLayout className="Modal-buttons-container" justifyContent={justifyContent || "end"} fullWidth>{ children }</StackLayout>
        );
    }
}