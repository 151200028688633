import {mergeViewStateForFulfillment} from "../../../Cob.helpers";

export default function (state, prevState)
{
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	let optionCorrectlySelected = false;
	if (customerState.selectedOption === "new" || customerState.selectedOption === "futur" || customerState.selectedOption === "standalone")
	{
		optionCorrectlySelected = true;
	}
	else if (customerState.selectedOption === "drafts" || customerState.selectedOption === "sent-or-signed")
	{
		optionCorrectlySelected = customerState.selectedCaseId !== null;
	}
	else if (customerState.standaloneApplicationsChecked.length > 0)
	{
		optionCorrectlySelected = true;
	}

	// One of the dropdown options need to be selected
	if (!optionCorrectlySelected)
	{
		return false;
	}  

  return true;
}

export function inputIsCompany(input) {
  // trim spaces
  input = input.replace(/\s/g, "");

  // check if 5th character from the end is a dash and trim it
  if (input[input.length - 5] === "-") {
    input = input.replace(/-/g, "");
  }

  if (input.length === 12 || input.length === 13) {
    return input.substring(0, 2) === "16" && input[4] >= 2;
  }

	return input.length === 10 && input[2] >= 2;
}