// MODULES
import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const Card = props => {
  const { title, icon: Icon, sxStyle, children } = props;

  const isMobileOrTablet = useResponsive("down", "md");

  return (
    <Box sx={{
      width: isMobileOrTablet ? "100%" : "28%",
      maxWidth: isMobileOrTablet ? "100%" : "560px",
      minWidth: isMobileOrTablet ? "unset" : "340px",
      minHeight: "200px",
      display: "flex",
      mr: isMobileOrTablet ? 0 : "3%",
      mt: isMobileOrTablet ? 6 : 0,
      padding: 0,
      alignItems: "flex-start",
      backgroundColor: "#f5f4f4",
      ...sxStyle
    }}>
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",        
        alignItems: "flex-start",
        padding: 1.2,
        borderBottom: "4px solid #FFF",
      }}>
        {Icon && <Icon sx={{ fontSize: "30px", marginTop: -0.4, marginRight: 1.2}}/>}
        <Typography color="inherit" variant="h5">
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default Card;