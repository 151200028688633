import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./FinancialSituation2.fulfillment";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextView from "../../../../../components/TextView/TextView";
import NumberField from "../../../components/NumberField/NumberField";
import i18n from "@helpers/i18n";

export default class FinancialSituation2 extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});
	}

	render() {
		const {customerState, hasFormValidationErrors} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Ekonomisk situation</h2>

					<div className="Cob-view2-fields">

						<h3 style={{marginBottom: "20px"}}>Finansiella tillgångar</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>Finansiella investeringar*</h4>
								<NumberField 
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_assets_financial"}
                  value={customerState.situation_assets_financial}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_assets_financial: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>Likvida tillgångar*</h4>
								<NumberField
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_assets_liquid"}
                  value={customerState.situation_assets_liquid}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_assets_liquid: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>Pensionssparande*</h4>
								<NumberField
                  label={i18n("general", "provide_amount")}
                    unit=" kr"
                    name={"situation_assets_pension"}
                    value={customerState.situation_assets_pension}
                    showSeparators={true}
                    validation={value => (value.length > 0)}
                    onChange={({target}) => this.setCustomerState({situation_assets_pension: target.value})}
                    highlightErrors={hasFormValidationErrors}
                />
              </div>

							<div>
								<h4 style={{marginTop: "0"}}>Andra tillgångar*</h4>
								<NumberField
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_assets_other"}
                  value={customerState.situation_assets_other}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_assets_other: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

						</StackLayout>

						<h4 style={{marginTop: "0"}}>Kommentar</h4>
						<TextView
							name="situation_assets_comment"
							label={`Fritext (${800 - customerState.situation_assets_comment.length} tecken kvar)`}
							value={customerState.situation_assets_comment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_assets_comment: target.value})}
							fullWidth
							minHeight={120}
						/>

						<div className="h-line spacing" style={{margin: "40px 0"}}/>

						<h3 style={{marginBottom: "20px"}}>Fasta tillgångar</h3>

						<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>Bostad*</h4>
								<NumberField
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_assets_fixed_home"}
                  value={customerState.situation_assets_fixed_home}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_assets_fixed_home: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>Övriga fasta tillgångar*</h4>
								<NumberField 
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_assets_fixed_other"}
                  value={customerState.situation_assets_fixed_other}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_assets_fixed_other: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

						</StackLayout>

						<h4 style={{marginTop: "0"}}>Kommentar</h4>
						<TextView
							name="situation_assets_fixed_comment"
							label={`Fritext (${800 - customerState.situation_assets_fixed_comment.length} tecken kvar)`}
							value={customerState.situation_assets_fixed_comment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_assets_fixed_comment: target.value})}
							fullWidth
							minHeight={120}
						/>

						<div className="h-line spacing" style={{margin: "40px 0"}}/>

						<h3 style={{marginBottom: "20px"}}>Skulder</h3>

						<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>Bostad*</h4>
								<NumberField 
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_debts_mortgage"}
                  value={customerState.situation_debts_mortgage}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_debts_mortgage: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>Övriga skulder*</h4>
								<NumberField
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_debts_other"}
                  value={customerState.situation_debts_other}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_debts_other: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

						</StackLayout>

						<h4 style={{marginTop: "0"}}>Kommentar</h4>
						<TextView
							name="situation_debts_comment"
							label={`Fritext (${200 - customerState.situation_debts_comment.length} tecken kvar)`}
							value={customerState.situation_debts_comment}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_debts_comment: target.value})}
							fullWidth
							minHeight={90}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		isFulfilled: false,
		customerState: {
			situation_assets_financial: customer.getData("situation_assets_financial"),
			situation_assets_liquid: customer.getData("situation_assets_liquid"),
			situation_assets_pension: customer.getData("situation_assets_pension"),
			situation_assets_other: customer.getData("situation_assets_other"),
			situation_assets_comment: customer.getData("situation_assets_comment") || "",

			situation_assets_fixed_home: customer.getData("situation_assets_fixed_home"),
			situation_assets_fixed_other: customer.getData("situation_assets_fixed_other"),
			situation_assets_fixed_comment: customer.getData("situation_assets_fixed_comment") || "",

			situation_debts_mortgage: customer.getData("situation_debts_mortgage"),
			situation_debts_other: customer.getData("situation_debts_other"),
			situation_debts_comment: customer.getData("situation_debts_comment") || "",
		}
	};
}

function _getFields() {
	return [
		"situation_assets_financial",
		"situation_assets_liquid",
		"situation_assets_pension",
		"situation_assets_other",
		"situation_assets_comment",

		"situation_assets_fixed_home",
		"situation_assets_fixed_other",
		"situation_assets_fixed_comment",

		"situation_debts_mortgage",
		"situation_debts_other",
		"situation_debts_comment",
	];
}