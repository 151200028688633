import React from "react";
import {Route, Switch} from "react-router-dom";
import Keycloak from 'keycloak-js';

// CLASSES
import Settings from "@classes/Settings";
import Partner from "@classes/Partner";

// COMPONENTS
import ActivityIndicator from "./components/ActivityIndicator/ActivityIndicator";
import Header from "./components/Header";
import Modal from "./components/Modal/Modal";

// CONTEXTS
import MenuDrawerProvider from "@contexts/MenuDrawerProvider";

// HELPERS
import {connectRedux, searchModules} from "./helpers/utils";
import i18n from "@helpers/i18n";

// ROUTES
import CobRoute from "./routes/Cob/Cob";
import HomeRoute from "./routes/Home/Home";
import PartnerErrorRoute from "./routes/PartnerError/PartnerError";
import PartnerNotFoundRoute from "./routes/PartnerNotFound/PartnerNotFound";

// REDUCERS
import {actions as appActions} from "./reducers/app";
import api from "./helpers/api";

// MUI
import ThemeProvider from "@mui-theme";
import Typography from "@mui/material/Typography";
import SectionHeaderPaper from "@mui-theme/styled/SectionHeaderPaper";

const leApp =  connectRedux(state => ({
	router: state.router,
	app: state.app
}), {
	setAppState: appActions.setState
}, class App extends React.PureComponent
{
	state = {
		isLoading: true
	};

	async componentDidMount()
	{
		this._isMounted = true;
		const {history} = this.props;
		const authToken = Settings.get("AUTH_TOKEN");
    const urlParams = new URLSearchParams(window.location.search);

		console.log("Advisory application starting...");

		/**
		 * Map the current domain to a configured partner that contains all application specific settings. Once we create
		 * a new partner we need to enter the domain and mapping in the configuration file (backend project).
		 * If we don't manage to map the domain to a partner we'll show an error page.
		 */
		const partnerDomainMapping = await api("partner/domains");
		const validPartnerDomains = Object.keys(partnerDomainMapping);
		const hostname = window.location.host.toLowerCase();
		let mappedPartnerSite = null;
		validPartnerDomains.forEach(function (partner)
		{
			if (mappedPartnerSite == null && hostname.startsWith(partner))
			{
				mappedPartnerSite = partnerDomainMapping[partner];
			}
		});

		// Handle case when partner mapping hasn't been set up in the app-config.json file
		if (mappedPartnerSite == null)
		{
			Settings.clear();
			this.setState({isLoading: false}, () => history.push("/partner-error"));
			return;
		}

		// TODO Set up and store partner object based on the mapped partner

		console.log("Loading partner data...");

		const partner = new Partner(mappedPartnerSite);
		try
		{
			await partner.loadData();
		}
		catch (e)
		{
			console.log(e);
			Settings.clear();
			this.setState({isLoading: false}, () => history.push("/partner-error"));
			return;
		}

		const {setAppState} = this.props.actions;
		await setAppState({partner: partner});
		console.log("Partner info fetched.");

		/**
		 * Configure the elements of the html document like favicon and document.title
		 */
		partner.setupDocument();    
    
		let backgroundStyle = {};
		if (partner) {      
      const backgroundImage = await import(`./assets/${partner.name}/${partner.backgroundImage}.jpg`);
			backgroundStyle.backgroundImage = `url(${backgroundImage.default})`;
		}
		else {
			backgroundStyle.background = "#EEE";
		}    
    this.setState({backgroundStyle});

		try
		{
			if (partner.faSSO)
			{
				const _this = this;
				const urlParameters = Object.fromEntries(
					new URLSearchParams(window.location.search)
				);


				// init faSSO
				const getSsoRedirectUri = () =>
				{
					const url = new URL(
						`${import.meta.env.BASE_URL}/keycloak-silent-check-sso.html`,
						window.location.origin
					);
					return url.href;
				};

				const keycloakInitConfig = {
					onLoad: "check-sso",
					silentCheckSsoRedirectUri: getSsoRedirectUri(),
					pkceMethod: "S256",
				};

				const keycloak = new Keycloak(`${import.meta.env.BASE_URL}/keycloak.json`);

				keycloak.init(keycloakInitConfig).then(async function (authenticated)
				{
					if (!authenticated)
					{
						Settings.set("AUTH_TOKEN", "");
						return keycloak.login();
					}

					const {token} = keycloak;
					const profile = await keycloak.loadUserProfile();

					const linkedContactName = profile.attributes.linked_contact_name[0];
					const ssn = linkedContactName.split('(').pop().split(')')[0];

					console.log("FA SSO :: Logging in");
					// Create and set authToken
					const authToken = await api("/auth/tokenSSO", {data: ssn});
					Settings.set("AUTH_TOKEN", authToken.token);

					await setAppState({
						faAuth: {profile, token, activeUserSSN: urlParameters.ssn}
					});

					const lastView = Settings.get("LAST_VIEW") || 1;
					return _this.setState({isLoading: false}, () => history.push("/cob/" + lastView));
				}).catch(function (err)
				{
					console.log(err);
				});
				return;
			}

			if (authToken)
			{
				const lastView = Settings.get("LAST_VIEW") || 1;
				this.setState({isLoading: false}, () => history.push("/cob/" + lastView));
			}
			else
			{
				// Auth token was NOT defined. We need to clear all auth data and redirect the user to the start page
				// where he/she can log in again.
				Settings.clear();
				console.log("Authentication required...");
        
        //check if tester to set bankid app accordingly
        const isTester = (urlParams.get('glofitest') === "1");
				this.setState({isLoading: false}, () => history.push(`${isTester? "/?glofitest=1" : "/"}`));
			}
		}
		catch (e)
		{
			console.error(e.message);
			this.setState({isLoading: false});
		}
	}

	componentWillUnmount()
	{
		this._isMounted = false;
	}

	render()
	{
    const appVersion = import.meta.env.VITE_REACT_APP_VERSION;
		const {props, state} = this;
		const {reducers, location} = props;
		const {user, customer, customers, errorModalVisible, errorModalForceRestart, partner, advisor, dashboardSelected} = reducers.app;
		const errorModalMessage = reducers.app.errorModalMessage ? _convertErrorMessage(reducers.app.errorModalMessage) : null;
    const isHomePage = (location.pathname === "" || location.pathname === "/");
    const isStartPage = (location.pathname === "/cob/1");

    let appContentClassNames = `App-content${user ? " logged-in" : ""}${isStartPage ? " start" : ""}`;
    if (partner && partner.iframed) appContentClassNames = appContentClassNames + " iframed";

		if (partner && partner.language)
		{
			Settings.set("LANGUAGE", partner.language);
		}

    if (partner && advisor && (searchModules(partner.modules, "InitSession") || partner.headerWidgets.includes("SearchBar")) && !customers) {
      this._getCustomers(advisor.ssn);
    }

		return (      
      <ThemeProvider theme={partner?.theme}>        
        <MenuDrawerProvider>
          <div id="leApp" className={`App ${isHomePage ? "home" : ""}`}>
            <div className={isHomePage ? "App-bg" : "App-no-bg"} style={this.state.backgroundStyle}/>
            <Header
              user={user}
              customer={customer}
              customers={customers}
              location={location}
              partner={partner}
            />
            {isStartPage && user && (
              <SectionHeaderPaper>
                <Typography variant="sectionHeader" align="left">
                  {dashboardSelected !== "start" ?
                    i18n("dashboard_selector", dashboardSelected)
                    : 
                    `Välkommen ${user?.firstName} ${user?.lastName}`
                  }
                </Typography>
              </SectionHeaderPaper>
            )}
            <div className={appContentClassNames} data-iframe-height>
              {state.isLoading ? (
                <div style={{width: "100%", display: "absolute", top:"40vh", left:"-8vw"}}>
                  <ActivityIndicator className="App-ActivityIndicator" color={partner?.theme.colors.primary.main} fixed busy/>
                </div>
              ) : (
                <Switch>
                  <Route path="/partner-error" component={PartnerErrorRoute}/>
                  <Route path="/partner-not-found" component={PartnerNotFoundRoute}/>
                  <Route path="/cob/:view?" component={CobRoute}/>
                  <Route path="/" component={HomeRoute}/>
                </Switch>
              )}
            </div>

            {errorModalMessage && (
              <Modal visible={errorModalVisible} title="Ett fel uppstod" status="danger">
                {typeof errorModalMessage === "string" ?
                  <p dangerouslySetInnerHTML={{__html: errorModalMessage}}/> : errorModalMessage}

                <Modal.ButtonsContainer>
                  {errorModalForceRestart && (
                    <Modal.Button label="Starta om" appearance="primary" onClick={this._onRestartModal}/>
                  )}

                  {!errorModalForceRestart && (
                    <Modal.Button label="Stäng" appearance="primary" onClick={this._closeErrorModal}/>
                  )}
                </Modal.ButtonsContainer>
              </Modal>
            )}
          </div>
		  {isHomePage && (
		    <div className={`App-Version white`}>
			  <p>{`v${appVersion}`}</p>
		    </div>
		  )}
        </MenuDrawerProvider>
      </ThemeProvider>
		);
	}

	// Internal methods
  _getCustomers = async advisorSsn => {
		const {setAppState} = this.props.actions;
		const {reducers} = this.props;
		const {customers} = reducers.app;

    if (customers || this?.fetchingCustomers) return;
    
    try
    {
      this.fetchingCustomers = true;
      const customers = await api("/advisor/clients", {"ssn": advisorSsn});
      // sort alphabetically
      customers.sort((a, b) =>
        a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
      );
      await setAppState({customers});      
      return this.fetchingCustomers = false;
    }
    catch (e)
    {
      console.error(e.message);
    }
  }

	_closeErrorModal = async () =>
	{
		const {setAppState} = this.props.actions;
		await setAppState({errorModalVisible: false});
		setTimeout(() => setAppState({errorModalMessage: null}), 300);
	};

	_onRestartModal = async () =>
	{
		const {setAppState} = this.props.actions;
		const {reducers} = this.props;
		const {customer} = reducers.app;

		await setAppState({busy: false, errorModalVisible: false, signModalVisible: false, errorModalMessage: null});

		customer.resetCustomer();
		Settings.remove("CASE_ID");

		const {authToken, newCaseId} = await api("auth/clean-token");
		Settings.set("AUTH_TOKEN", authToken);
		customer._caseId = newCaseId;

		window.location.reload();
		Settings.set("LAST_VIEW", "1");
	};
});


// PRIVATE FUNCTIONS
function _convertErrorMessage(message)
{
	if (message === "REQUEST_TIMED_OUT")
	{
		return "Anropet tog för lång tid. Var god försök igen.";
	}

	return message;
}

export default leApp;