import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {

	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	/**
	 * Om man kryssat annan bedömning måste textfältet fyllas i
	 */
	if (customerState.checkbox_advice_suitability_other === "Ja" && customerState.advice_suitability_knowledge_other.length <= 0) {
		return false;
	}
  
	return !!(
		// customerState.advice_notes.length > 0
    true
	);
}