import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}
	state = mergeViewStateForFulfillment(state, prevState);

	const {customerState} = state;
	const {tableData} = customerState;

	if (customerState.isCompany)
	{
		/**
		 * Rådgivningen måste ha ett datum
		 */
		if (customerState.date.length <= 0)
		{
			return false;
		}

		// Rådgivningens utförande måste vara valt
		if (customerState.meetingFormatIndex === -1)
		{
			return false;
		}

		if ((customerState.meetingFormat === "phone" || customerState.meetingFormat === "video")
			&& (customerState.checkbox_customer_extcheck !== "yes" || customerState.checkbox_customer_countercheck !== "yes"))
		{
			return false;
		}

		// Någon av boxarna för identitetskontroll måste kryssas
		if (customerState.checkbox_idcheck_id !== 'yes' &&
			customerState.checkbox_idcheck_drivinglic !== 'yes' &&
			customerState.checkbox_idcheck_passport !== 'yes' &&
			customerState.checkbox_idcheck_known !== 'Ja'
		)
		{
			return false;
		}
	}

	// Hur stor del av investeringen som kan förloras måste fyllas i
	if (customerState.checkbox_customer_kyc_loss_1.length <= 0)
	{
		return false;
	}

	if (customerState.checkbox_customer_kyc_loss_7.length <= 0)
	{
		return false;
	}

	// antal affärer sista åren för varje produkttyp måste vara vald
	if (tableData.radio_kyc_transactions_aktier.length <= 0 ||
		tableData.radio_kyc_transactions_onot_aktier.length <= 0 ||
		tableData.radio_kyc_transactions_fonder.length <= 0 ||
		tableData.radio_kyc_transactions_aio.length <= 0 ||
		tableData.radio_kyc_transactions_autocalls.length <= 0 ||
		tableData.radio_kyc_transactions_derivat.length <= 0 ||
		tableData.radio_kyc_transactions_index_products.length <= 0)
	{
		return false;
	}

	// kunskapsnivå om varje produkttyp måste vara vald
	if (tableData.radio_kyc_knowledge_aktier.length <= 0 ||
		tableData.radio_kyc_knowledge_onot_aktier.length <= 0 ||
		tableData.radio_kyc_knowledge_fonder.length <= 0 ||
		tableData.radio_kyc_knowledge_aio.length <= 0 ||
		tableData.radio_kyc_knowledge_autocalls.length <= 0 ||
		tableData.radio_kyc_knowledge_derivat.length <= 0 ||
		tableData.radio_kyc_knowledge_index_products.length <= 0)
	{
		return false;
	}

	// genomsnittlig transaktion för varje produkttyp måste vara vald
	if (tableData.radio_aml_transaction_size_aktier.length <= 0 ||
		tableData.radio_aml_transaction_size_onot_aktier.length <= 0 ||
		tableData.radio_aml_transaction_size_fonder.length <= 0 ||
		tableData.radio_aml_transaction_size_aio.length <= 0 ||
		tableData.radio_aml_transaction_size_autocalls.length <= 0 ||
		tableData.radio_aml_transaction_size_derivat.length <= 0 ||
		tableData.radio_aml_transaction_size_index_products.length <= 0)
	{
		return false;
	}

	// kunskapsnivå om varje försäkringstyp måste vara vald
	if (tableData.radio_understands_aktier.length <= 0 ||
		tableData.radio_understands_onot_aktier.length <= 0 ||
		tableData.radio_understands_fonder.length <= 0 ||
		tableData.radio_understands_aio.length <= 0 ||
		tableData.radio_understands_autocalls.length <= 0 ||
		tableData.radio_understands_derivat.length <= 0 ||
		tableData.radio_understands_index_products.length <= 0)
	{
		return false;
	}

	// kunskapsfrågorna måste vara besvarade
	if (customerState.checkbox_customer_kyc_avoid.length <= 0 ||
		customerState.checkbox_customer_kyc_horisont.length <= 0)
	{
		return false;
	}

	// Förståelse / utbildning av tjänster / försäkringar
	if (tableData.radio_understands_depa.length <= 0 ||
		tableData.radio_understands_isk.length <= 0 ||
		tableData.radio_understands_tpk.length <= 0 ||
		tableData.radio_understands_investment.length <= 0)
	{
		return false;
	}

	if (tableData.radio_experience_depa.length <= 0 ||
		tableData.radio_experience_isk.length <= 0 ||
		tableData.radio_experience_tpk.length <= 0 ||
		tableData.radio_experience_investment.length <= 0)
	{
		return false;
	}

	if (tableData.radio_education_depa.length <= 0 ||
		tableData.radio_education_isk.length <= 0 ||
		tableData.radio_education_tpk.length <= 0 ||
		tableData.radio_education_investment.length <= 0)
	{
		return false;
	}

	// Har kunden utbildats?
	if (customerState.checkbox_kyc_educated.length <= 0)
	{
		return false;
	}

	// If Har kunden utbildats then we need to fill out the text field below, otherwise it is optional
	if (customerState.checkbox_kyc_educated === "Ja" && customerState.advice_notes2.length <= 0)
	{
		return false;
	}

	return true;
}