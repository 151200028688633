import {mergeViewStateForFulfillment} from "../../Cob.helpers";


export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;
	const {tableData} = customerState;

	if (!(
		customerState.radio_kyc_experience_education.length > 0
		&& customerState.kyc_experience_year.length > 0
		&& (
			tableData.kyc_experience_aktier_investments.length > 0
			&& tableData.kyc_experience_fonder_investments.length > 0
			&& tableData.kyc_experience_obligationer_investments.length > 0
			&& tableData.kyc_experience_aio_investments.length > 0
			&& tableData.kyc_experience_sprinter_investments.length > 0
			&& tableData.kyc_experience_kreditcertifikat_investments.length > 0
			&& tableData.kyc_experience_autocalls_investments.length > 0
			&& tableData.kyc_experience_derivat_investments.length > 0
		))) {
		return false;
	}

	if (customerState.sharesKnowledgeIndex === -1 ||
		customerState.stocksKnowledgeIndex === -1 ||
		customerState.obligationsKnowledgeIndex === -1 ||
		customerState.aioKnowledgeIndex === -1 ||
		customerState.sprintsKnowledgeIndex === -1 ||
		customerState.creditCertificateKnowledgeIndex === -1 ||
		customerState.autocallsKnowledgeIndex === -1 ||
		customerState.derivativeKnowledgeIndex === -1) {
		return false;
	}

	return true;
}