import {mergeViewStateForFulfillment} from "../../Cob.helpers";


export default function (state, prevState) {

	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	return !!(
		customerState.advice_notes.length > 0 &&
		customerState.advice_notes2.length > 0 &&
		customerState.advice_notes3.length > 0 &&
		customerState.advice_notes4.length > 0 &&
		customerState.advice_notes5.length > 0 &&
		customerState.radio_advice_insurance
	);
}