// HELPERS
import Validator from "@classes/Validator";
import {validateEmail} from "@helpers/utils";

// OTHER
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;
	const {isCompany, companyReps, companyRepsActiveAmount} = customerState;

	/**
	 * Rådgivningen måste ha ett datum
	 */
	if (customerState.date.length <= 0)
	{
		return false;
	}

	/**
	 * Rådgivningens utförande måste vara valt
	 */
	if (customerState.meetingFormatIndex === -1)
	{
		return false;
	}

	// Person-/orgnr måste fyllas i
	if (customerState.ssnOrg.length <= 0)
	{
		return false;
	}

	// Förnamn måste fyllas i, det är detta som anges som företagsnamn
	if (customerState.firstName.length <= 0)
	{
		return false;
	}

	// Om det inte är ett företag måste efternamn också fyllas i
	if (!isCompany && customerState.lastName.length <= 0)
	{
		return false;
	}

	// Adress måste fyllas i
	if (customerState.street.length <= 0 || customerState.zipCode.length <= 0 || customerState.city.length <= 0)
	{
		return false;
	}

	// E-mail måste stämma
	if (!isCompany && !validateEmail(customerState.email))
	{
		return false;
	}

	// Telefonnr är inte optional och formatet måste stämma om det fylls i
	if (!isCompany && (customerState.phoneNumber.length <= 0 ||
		customerState.phoneNumber === "-" ||
		(customerState.phoneNumber !== "-" && !Validator.validatePhoneNum(customerState.phoneNumber))))
	{
		return false;
	}

	// Övriga telefonnr är optional men formatet måste stämma om det fylls i
	if (customerState.phoneNumberHome.length > 0 && customerState.phoneNumberHome !== "-" && !Validator.validatePhoneNum(customerState.phoneNumberHome))
	{
		return false;
	}

	if (customerState.phoneNumberAlternative.length > 0 && customerState.phoneNumberAlternative !== "-" && !Validator.validatePhoneNum(customerState.phoneNumberAlternative))
	{
		return false;
	}

	/**
	 * Privatpersoner måste ha land ifyllt
	 */
	if (!isCompany && customerState.country.length < 0)
	{
		return false;
	}

	/**
	 * Företagsspecifik validering
	 */
	if (isCompany)
	{
		if (!customerState.leiCodeValid)
		{
			return false;
		}

		let atLeastOneSignatorySelected = _companyRepIsSignatory(companyReps[0]) ||
			_companyRepIsSignatory(companyReps[1]) ||
			_companyRepIsSignatory(companyReps[2]) ||
			_companyRepIsSignatory(companyReps[3]) ||
			_companyRepIsSignatory(companyReps[4]) ||
			_companyRepIsSignatory(companyReps[5]);

		let atLeastOnePoaSelected = _companyRepIsPoa(companyReps[0]) ||
			_companyRepIsPoa(companyReps[1]) ||
			_companyRepIsPoa(companyReps[2]) ||
			_companyRepIsPoa(companyReps[3]) ||
			_companyRepIsPoa(companyReps[4]) ||
			_companyRepIsPoa(companyReps[5]);

		if (atLeastOnePoaSelected && state.docs.length <= 0)
		{
			return false;
		}

		if (!atLeastOneSignatorySelected ||
			!_validateCompanyRep(companyReps[0], false, customerState.checkRepDocs) ||
			!_validateCompanyRep(companyReps[1], companyRepsActiveAmount < 2, customerState.checkRepDocs) ||
			!_validateCompanyRep(companyReps[2], companyRepsActiveAmount < 3, customerState.checkRepDocs) ||
			!_validateCompanyRep(companyReps[3], companyRepsActiveAmount < 4, customerState.checkRepDocs) ||
			!_validateCompanyRep(companyReps[4], companyRepsActiveAmount < 5, customerState.checkRepDocs) ||
			!_validateCompanyRep(companyReps[5], companyRepsActiveAmount < 6, customerState.checkRepDocs))
		{
			return false;
		}

		if (!state.docsFulfilled)
		{
			return false;
		}
	}

	return true;
}


function _companyRepIsSignatory(rep)
{
	return rep.types.length > 0 && (rep.types.includes("signatory") || rep.types.includes("poa"));
}

function _companyRepIsPoa(rep)
{
	return rep.types.length > 0 && (rep.types.includes("poa"));
}

function _validateCompanyRep(rep, fallbackValue = false, checkDocs = true)
{
	if (fallbackValue)
	{
		return true;
	}

	/**
	 * For Swedish citizens we validate the SSN. Not selected citizenship will default to SE.
	 */
	if (rep.citizenship.length === 0 || rep.citizenship === "SE")
	{
		if (!Validator.validateSsn(rep.ssn))
		{
			return false;
		}
	}

	const validDocs = !checkDocs || rep.hasDocument;

	return !!(rep.types.length > 0 && (
		rep.firstName.length > 0
		&& rep.lastName.length > 0
		&& rep.email.length > 0
		&& rep.phone.length > 0
		&& rep.ssn.length > 0
		&& validDocs
	));
}