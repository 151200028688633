import React from "react";

// COMPONENTS
import Select from "@components/Select/Select";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class SimpleTextField extends React.PureComponent {
    render() {
        const { props } = this;
        const { label, name, required, disabled, options, selectedIndex, onChange } = props;
        const classes = createClassName("Cob-TableSelect", {
            
        });

        return (
            <Select
                className={ classes }
                placeholder={ label }
                name={ name }
                required={ required }
                disabled={ disabled }
                options={ options }
                selectedIndex={ selectedIndex }
                onChange={ onChange }
            />
        );
    }
}


// PRIVATE FUNCTIONS
