import React from "react";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import OptionsList from "../../components/OptionsList/OptionsList";
import SimpleCheckbox from "../../components/SimpleCheckbox/SimpleCheckbox";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import Table from "@components/Table/Table";
import TableSelect from "../../components/TableSelect/TableSelect";
import SimpleTextField from "../../components/SimpleTextField/SimpleTextField";

// HELPERS
import {createClassName, parseLocaleNumber} from "@helpers/utils";
import i18n from "@helpers/i18n";

// OTHER
import fulfillment from "./PreviousExperience.fulfillment";


export default class PreviousExperience extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	setTableData(fields, callbackFn) {
		this.setCustomerState({tableData: {...this.state.customerState.tableData, ...fields}}, callbackFn);
	}

	allProductRisksChecked() {
		const names = [
			"radio_kyc_broker_risk_aktier",
			"radio_kyc_broker_risk_fonder",
			"radio_kyc_broker_risk_obligationer",
			"radio_kyc_broker_risk_aio",
			"radio_kyc_broker_risk_sprinter",
			"radio_kyc_broker_risk_kreditcertifikat",
			"radio_kyc_broker_risk_autocalls",
			"radio_kyc_broker_risk_derivat",
		];

		return names.filter(name => this.state.customerState.tableData[name] === "yes").length === names.length;
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			},
		});
	}

	render() {
		const {state} = this;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view5", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("cob", "experience_history")}</h2>

					<div className="Cob-view5-fields">
						<StackLayout className="Cob-fields-collection" justifyContent="center" rowWrap fullWidth>
							<OptionsList
								type="radio"
								name="radio_kyc_experience_education"
								description={"Har du utbildning och/eller pågående eller tidigare yrke som medfört kunskap om hur finansiella placeringar och marknader fungerar?"}
								options={[
									{
										label: i18n("general", "no"),
										value: "no",
										checked: customerState.radio_kyc_experience_education === "no"
									},
									{
										label: i18n("general", "yes"),
										value: "yes",
										checked: customerState.radio_kyc_experience_education === "yes"
									},
								]}
								onChange={(checked, radio_kyc_experience_education) => this.setCustomerState({radio_kyc_experience_education})}
							/>
						</StackLayout>

						<StackLayout className="Cob-fields-collection" justifyContent="center" rowWrap fullWidth>
							<div>
								<p className="Cob-description">Hur länge har du handlat med värdepapper?</p>
								<StackLayout>
									<SimpleTextField
										type="number"
										placeholder="Ange år"
										value={customerState.kyc_experience_year}
										textAlign="left"
										onChange={({target}) => this.setCustomerState({kyc_experience_year: target.value})}
									/>
									{!!customerState.kyc_experience_year && <div style={{marginLeft: 10}}>år</div>}
									<input type="hidden" name="kyc_experience_year"
										   value={customerState.kyc_experience_year ? customerState.kyc_experience_year + " år" : ""}/>
								</StackLayout>
							</div>
						</StackLayout>

						<div className="h-line spacing"/>

						<StackLayout style={{marginBottom: 30}} justifyContent="end" fullWidth>
							<Checkbox
								label="Rådgivaren anser att kunden förstått riskerna med samtliga produkter"
								labelAlign="left"
								checked={this.allProductRisksChecked(true)}
								onChange={(checked) => this.setAllProductRisksChecked(checked)}
							/>
						</StackLayout>

						<Table
							className="Cob-Table"
							columns={_getTableColumns.call(this)}
							rows={_getTableRows.call(this)}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_getTableData = (fieldName, newState) => {
		const {tableData} = this.state.customerState;
		const td = {...tableData, ...newState};
		return td[fieldName] ? parseFloat(td[fieldName], 10) : 0;
	};

	setAllProductRisksChecked(checked) {
		const names = [
			"radio_kyc_broker_risk_aktier",
			"radio_kyc_broker_risk_fonder",
			"radio_kyc_broker_risk_obligationer",
			"radio_kyc_broker_risk_aio",
			"radio_kyc_broker_risk_sprinter",
			"radio_kyc_broker_risk_kreditcertifikat",
			"radio_kyc_broker_risk_autocalls",
			"radio_kyc_broker_risk_derivat",
		];
		const newState = {};

		for (let i = 0; i < names.length; i++) {
			const name = names[i];
			newState[name] = checked === true ? "yes" : "no";
		}

		this.setTableData(newState);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		customerState: {
			radio_kyc_experience_education: customer.getData("radio_kyc_experience_education"),
			kyc_experience_year: (v => v || v === "0" ? parseLocaleNumber(v).toString() : "")(customer.getData("kyc_experience_year")),
			tableData: customer.view5TableData,

			// TableSelect
			sharesKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_aktier") === "ingen" ?  (customer.view5TableData["kyc_experience_aktier_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_aktier") === "nagon" ? 1:
						customer.getData("radio_kyc_knowledge_aktier") === "omfattande" ? 2 :
							-1
			),
			stocksKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_fonder") === "ingen" ? (customer.view5TableData["kyc_experience_fonder_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_fonder") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_fonder") === "omfattande" ? 2 :
							-1
			),
			obligationsKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_obligationer") === "ingen" ? (customer.view5TableData["kyc_experience_obligationer_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_obligationer") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_obligationer") === "omfattande" ? 2 :
							-1
			),
			aioKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_aio") === "ingen" ? (customer.view5TableData["kyc_experience_aio_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_aio") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_aio") === "omfattande" ? 2 :
							-1
			),
			sprintsKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_sprinter") === "ingen" ? (customer.view5TableData["kyc_experience_sprinter_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_sprinter") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_sprinter") === "omfattande" ? 2 :
							-1
			),
			creditCertificateKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_kreditcertifikat") === "ingen" ? (customer.view5TableData["kyc_experience_kreditcertifikat_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_kreditcertifikat") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_kreditcertifikat") === "omfattande" ? 2 :
							-1
			),
			autocallsKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_autocalls") === "ingen" ? (customer.view5TableData["kyc_experience_autocalls_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_autocalls") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_autocalls") === "omfattande" ? 2 :
							-1
			),
			derivativeKnowledgeIndex: (
				customer.getData("radio_kyc_knowledge_derivat") === "ingen" ? (customer.view5TableData["kyc_experience_derivat_investments"] !== "0" ? 1 : 0) :
					customer.getData("radio_kyc_knowledge_derivat") === "nagon" ? 1 :
						customer.getData("radio_kyc_knowledge_derivat") === "omfattande" ? 2 :
							-1
			),
		}
	};

	newState.isFulfilled = false;

	return newState;
}

function _getFields() {
	return [
		"radio_kyc_experience_education",
		"kyc_experience_year",

		// Table data
		...Object.keys(this.state.customerState.tableData)
	];
}

function _getTableColumns() {
	return [
		{
			key: "investment",
			label: i18n("general", "investment"),
			width: "15%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "amountOfbusinessLast3Years",
			label: "Antal affärer under senaste 3 åren",
			width: "20%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{
			key: "knowledge",
			label: i18n("cob", "knowledge_and_experience"),
			width: "20%",
			horizontalAlign: "left",
			verticalAlign: "top"
		},
		{key: "education", label: "Kunden har utbildats", width: "15%", horizontalAlign: "right", verticalAlign: "top"},
		{
			key: "brokerRisk",
			label: "Rådgivaren anser att kunden förstått riskerna med produkten",
			width: "30%",
			horizontalAlign: "right",
			verticalAlign: "top"
		},
	];
}

function _getTableRows() {
	return [
		_createTableRow.call(this, {
			investment: {name: "kyc_experience_aktier_investments", label: i18n("general", "shares")},
			knowledge: {name: "radio_kyc_knowledge_aktier", indexName: "sharesKnowledgeIndex"},
			education: {name: "radio_kyc_education_aktier"},
			brokerRisk: {name: "radio_kyc_broker_risk_aktier"},
		}),
		_createTableRow.call(this, {
			investment: {name: "kyc_experience_fonder_investments", label: i18n("general", "stocks")},
			knowledge: {name: "radio_kyc_knowledge_fonder", indexName: "stocksKnowledgeIndex"},
			education: {name: "radio_kyc_education_fonder"},
			brokerRisk: {name: "radio_kyc_broker_risk_fonder"},
		}),
		_createTableRow.call(this, {
			investment: {name: "kyc_experience_obligationer_investments", label: i18n("general", "obligations")},
			knowledge: {name: "radio_kyc_knowledge_obligationer", indexName: "obligationsKnowledgeIndex"},
			education: {name: "radio_kyc_education_obligationer"},
			brokerRisk: {name: "radio_kyc_broker_risk_obligationer"},
		}),
		_createTableRow.call(this, {
			investment: {name: "kyc_experience_aio_investments", label: i18n("general", "equity_linked_notes")},
			knowledge: {name: "radio_kyc_knowledge_aio", indexName: "aioKnowledgeIndex"},
			education: {name: "radio_kyc_education_aio"},
			brokerRisk: {name: "radio_kyc_broker_risk_aio"},
		}),
		_createTableRow.call(this, {
			investment: {name: "kyc_experience_sprinter_investments", label: i18n("general", "sprints")},
			knowledge: {name: "radio_kyc_knowledge_sprinter", indexName: "sprintsKnowledgeIndex"},
			education: {name: "radio_kyc_education_sprinter"},
			brokerRisk: {name: "radio_kyc_broker_risk_sprinter"},
		}),
		_createTableRow.call(this, {
			investment: {
				name: "kyc_experience_kreditcertifikat_investments",
				label: i18n("general", "credit_certificate")
			},
			knowledge: {name: "radio_kyc_knowledge_kreditcertifikat", indexName: "creditCertificateKnowledgeIndex"},
			education: {name: "radio_kyc_education_kreditcertifikat"},
			brokerRisk: {name: "radio_kyc_broker_risk_kreditcertifikat"},
		}),
		_createTableRow.call(this, {
			investment: {name: "kyc_experience_autocalls_investments", label: "Autocalls"},
			knowledge: {name: "radio_kyc_knowledge_autocalls", indexName: "autocallsKnowledgeIndex"},
			education: {name: "radio_kyc_education_autocalls"},
			brokerRisk: {name: "radio_kyc_broker_risk_autocalls"},
		}),
		_createTableRow.call(this, {
			investment: {name: "kyc_experience_derivat_investments", label: "Derivat, Råvarucertifikat"},
			knowledge: {name: "radio_kyc_knowledge_derivat", indexName: "derivativeKnowledgeIndex"},
			education: {name: "radio_kyc_education_derivat"},
			brokerRisk: {name: "radio_kyc_broker_risk_derivat"},
		}),
	];
}

function _createTableRow({investment, knowledge, education, brokerRisk}) {
	const {customerState} = this.state;
	const {tableData} = customerState;

	let knowledgeOptions = [
		{label: i18n("general", "none"), value: "ingen"},
		{label: i18n("general", "some"), value: "nagon"},
		{label: i18n("general", "extensive"), value: "omfattande"}
	];

	return {
		// Investment
		investment: {
			className: "investment",
			value: investment.label
		},

		amountOfbusinessLast3Years: {
			value: (
				<SimpleTextField
					type="number"
					name={investment.name}
					value={tableData[investment.name] || ""}
					decimals={0}
					onChange={({target}) => {
						let numberOfDeals = parseInt(target.value, 10);
						if (numberOfDeals > 0 && tableData[knowledge.name] === 'ingen') {
							this.setCustomerState({
								[knowledge.indexName]: 1,
								tableData: {
									...this.state.customerState.tableData,
									[knowledge.name]: "nagon",
									[investment.name]: target.value ? numberOfDeals.toString() : ""
								}
							});
						}
						else {
							this.setTableData({[investment.name]: target.value ? numberOfDeals.toString() : ""});
						}
					}}
				/>
			),
		},

		// Knowledge
		knowledge: {
			value: (
				<TableSelect
					label={"Välj..."}
					name={knowledge.name}
					selectedIndex={customerState[knowledge.indexName]}
					options={knowledgeOptions}
					onChange={(option, index) => {

						let numberOfDealsValue = tableData[investment.name];
						if (index === 0) {
							numberOfDealsValue = "0";
						}

						this.setCustomerState({
							[knowledge.indexName]: index,
							tableData: {
								...this.state.customerState.tableData,
								[knowledge.name]: option.value,
								[investment.name]: numberOfDealsValue
							}
						});
					}}
				/>
			),
		},

		// Education
		education: {
			className: "education",
			value: <SimpleCheckbox name={education.name} value={tableData[education.name] === "yes" ? "yes" : "no"}
								   checked={tableData[education.name] === "yes"}
								   onChange={checked => this.setTableData({[education.name]: checked ? "yes" : "no"})}/>,
		},

		// Broker risk
		brokerRisk: {
			className: "broker-risk",
			value: <SimpleCheckbox name={brokerRisk.name} value={tableData[brokerRisk.name] === "yes" ? "yes" : "no"}
								   checked={tableData[brokerRisk.name] === "yes"}
								   onChange={checked => this.setTableData({[brokerRisk.name]: checked ? "yes" : "no"})}/>,
		},
	};
}