import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./RiskProfil.fulfillment";
import SriRiskTable from "../../../components/SriRiskTable/SriRiskTable";
import Select from "../../../../../components/Select/Select";
import i18n from "@helpers/i18n";

export default class RiskProfil extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	riskLevels = [
		{
			label: `1 - ${i18n("risk_profile", "very_low_risk")}`,
			value: "1 - Mycket låg risk"
		},
		{
			label: `2 - ${i18n("risk_profile", "low_risk")}`,
			value: "2 - Låg risk"
		},
		{
			label: `3 - ${i18n("risk_profile", "medium_low_risk")}`,
			value: "3 - Medellåg risk"
		},
		{
			label: `4 - ${i18n("risk_profile", "medium_risk")}`,
			value: "4 - Medel risk"
		},
		{
			label: `5 - ${i18n("risk_profile", "medium_high_risk")}`,
			value: "5 - Medelhög risk"
		},
		{
			label: `6 - ${i18n("risk_profile", "high_risk")}`,
			value: "6 - Hög risk"
		},
		{
			label: `7 - ${i18n("risk_profile", "very_high_risk")}`,
			value: "7 - Mycket hög risk"
		},
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render()
	{
		const {customerState} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		const riskCalculated = customerState.risk.sriLevel + " - " + customerState.risk.sriLevelDescription;
		const riskLevelSelected = customerState.risk_sri_level_selected.length > 0 ? customerState.risk_sri_level_selected.substring(0, 1) : 0;

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("risk_profile", "risk_profile")}</h2>

					<div className="Cob-view2-fields">
						<h3>{i18n("risk_profile", "risk")}</h3>

						<p>{i18n("risk_profile", "risk_p1")}</p>
						<p>{i18n("risk_profile", "risk_p2")}</p>

						<SriRiskTable color1={"#78B142"}
									  color2={"#6DAA49"}
									  color3={"#63A65F"}
									  color4={"#5EA272"}
									  color5={"#529E7F"}
									  color6={"#4B9B8C"}
									  color7={"#4497A1"}/>

						<div className="h-line spacing"/>

						<h3>{i18n("risk_profile", "risk_classification_proposal")}</h3>

						{this.state.workflow !== "futur" && customerState.risk.sriLevel === 0 && (
							<p>{i18n("risk_profile", "risk_level_could_not_be_calculated")}</p>
						)}

						{customerState.risk.sriLevel !== 0 && (
							<p>
								{i18n("risk_profile", "glofitech_assessesment")}
								<strong> {i18n("risk_profile", "risk_class")} {customerState.risk.sriLevel} ({customerState.risk.sriLevelDescription}) </strong>
								{i18n("risk_profile", "is_suitable")}.
							</p>
						)}

						<input type={"hidden"} name={"risk_sri_level_calculated"} value={riskCalculated}/>

						<Select
							name="risk_sri_level_selected"
							label={i18n("risk_profile", "selected_risk_level")}
							selectedIndex={this.riskLevels.map(c => c.value).indexOf(customerState.risk_sri_level_selected)}
							options={this.riskLevels}
							onChange={(option) => this.setCustomerState({risk_sri_level_selected: option.value})}
							style={{marginBottom: "40px"}}
						/>

						{customerState.risk.sriLevel !== 0 &&
							riskCalculated !== customerState.risk_sri_level_selected && (
								<div style={{
									width: "100%",
									padding: "0 15px",
									border: "1px solid #df4c43",
									borderRadius: "5px",
									alignItems: "flex-start",
									fontSize: "16px"
								}}>
									<p style={{fontStyle: "italic"}}>
                    {i18n("risk_profile", "not_recommend_changing_risk_level")}.
									</p>

									{riskLevelSelected > customerState.risk.sriLevel && (
										<p style={{fontStyle: "italic"}}>											
                      {i18n("risk_profile", "risk_level_likely_to_be")} <strong>{i18n("risk_profile", "higher_than_desired")}</strong>.
										</p>
									)}

									{riskLevelSelected < customerState.risk.sriLevel && (
										<p style={{fontStyle: "italic"}}>
                      {i18n("risk_profile", "expected_returns_likely_to_be")} <strong>{i18n("risk_profile", "lower_than_desired")}</strong>.
										</p>
									)}
								</div>
							)}

					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, currentWorkflow} = this.props;
	let newState = getViewInitialStateForCustomer(customer, currentWorkflow);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer, workflow)
{
	workflow = workflow == null ? "default" : workflow;

	let initialState = {
		workflow: workflow,
		customerState: {
			risk: calculateRiskResult(customer),
			risk_sri_level_selected: customer.getData("risk_sri_level_selected") || ""
		}
	};

	if (initialState.customerState.risk_sri_level_selected.length === 0 && initialState.customerState.risk.sriLevel > 0)
	{
		initialState.customerState.risk_sri_level_selected = initialState.customerState.risk.sriLevel + " - " + initialState.customerState.risk.sriLevelDescription;
	}

	return initialState;
}

function _getFields()
{
	return [];
}

function calculateRiskResult(customer)
{

	const sriResult = {
		sriLevel: 0,
		sriLevelDescription: ""
	};
	let sriScoringPercentages = [];

	// Q
	const answerDefinedReturn = customer.getData("risk_defined_return") || "";
	let definedReturn = 0;
	if (answerDefinedReturn === "1%")
	{
		sriScoringPercentages.push(10);
		definedReturn = 10;
	}
	else if (answerDefinedReturn === "3%")
	{
		sriScoringPercentages.push(25);
		definedReturn = 25;
	}
	else if (answerDefinedReturn === "5%")
	{
		sriScoringPercentages.push(50);
		definedReturn = 50;
	}
	else if (answerDefinedReturn === "7%")
	{
		sriScoringPercentages.push(75);
		definedReturn = 75;
	}
	else if (answerDefinedReturn === "10%")
	{
		sriScoringPercentages.push(100);
		definedReturn = 100;
	}
	else if (answerDefinedReturn === "15%")
	{
		sriScoringPercentages.push(150);
		definedReturn = 150;
	}
	else if (answerDefinedReturn === "20%")
	{
		sriScoringPercentages.push(200);
		definedReturn = 200;
	}
	else
	{
		console.log("Cannot calculate SRI risk at this time: Incomplete anwers (risk_defined_return).");
		return sriResult;
	}

	// Q
	const answerExpectations = customer.getData("risk_expected_return") || "";
	if (answerExpectations === "I linje med bankkontoräntan")
	{
		sriScoringPercentages.push(0);
	}
	else if (answerExpectations === "Något över bankkontoräntan")
	{
		sriScoringPercentages.push(25);
	}
	else if (answerExpectations === "I linje med aktiemarknaden")
	{
		sriScoringPercentages.push(50);
	}
	else if (answerExpectations === "Det finns ett definierat avkastningsmål")
	{
		sriScoringPercentages.push(definedReturn);
	}
	else
	{
		console.log("Cannot calculate MRM risk at this time: Incomplete anwers (risk_expected_return).");
		return sriResult;
	}

	// Q
	const answerPhilosophy = customer.getData("risk_investment_philosophy") || "";
	if (answerPhilosophy === "Fokus på att kontrollera risken för förlust och accepterar därmed en förhållandevis låg avkastning.")
	{
		sriScoringPercentages.push(33);
	}
	else if (answerPhilosophy === "Accepterar viss risk för att få högre potential till avkastning.")
	{
		sriScoringPercentages.push(65);
	}
	else if (answerPhilosophy === "Fokus på avkastning och kan därmed acceptera stora fluktuationer i värdet på investeringarna.")
	{
		sriScoringPercentages.push(100);
	}
	else
	{
		console.log("Cannot calculate MRM risk at this time: Incomplete anwers (risk_investment_philosophy).");
		return sriResult;
	}

	// Q
	const answerNegativeNews = customer.getData("risk_affected_by_negative_news") || "";
	if (answerNegativeNews === "Inte nämnvärt och förosakar ingen oro över portföljen.")
	{
		sriScoringPercentages.push(100);
	}
	else if (answerNegativeNews === "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.")
	{
		sriScoringPercentages.push(75);
	}
	else if (answerNegativeNews === "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.")
	{
		sriScoringPercentages.push(55);
	}
	else if (answerNegativeNews === "Ökad uppmärksamhet på portföljen men agerar med is i magen.")
	{
		sriScoringPercentages.push(75);
	}
	else
	{
		console.log("Cannot calculate MRM risk at this time: Incomplete anwers (risk_affected_by_negative_news).");
		return sriResult;
	}

	// Q
	const answerReactionDrawdown = customer.getData("risk_reaction_to_drawdown") || "";
	if (answerReactionDrawdown === "En vilja att minska risken i portföljen och till exempel sälja aktier.")
	{
		sriScoringPercentages.push(10);
	}
	else if (answerReactionDrawdown === "Portföljen lämnas intakt, men minska ned på utdelningen från portföljen.")
	{
		sriScoringPercentages.push(90);
	}
	else if (answerReactionDrawdown === "Kontakta rådgivaren för att diskutera portföljen.")
	{
		sriScoringPercentages.push(75);
	}
	else if (answerReactionDrawdown === "Ingen, det är naturligt med stora svängningar på marknaderna.")
	{
		sriScoringPercentages.push(100);
	}
	else if (answerReactionDrawdown === "En möjlighet att ta till vara på och öka risken och exempelvis köpa aktier.")
	{
		sriScoringPercentages.push(125);
	}
	else
	{
		console.log("Cannot calculate MRM risk at this time: Incomplete anwers (risk_reaction_to_drawdown).");
		return sriResult;
	}

	// Q
	const answerReactionDrawdown40 = customer.getData("risk_reaction_to_drawdown_40") || "";
	if (answerReactionDrawdown40 === "Ingen, stora och små rörelser är en naturlig del av investeringar.")
	{
		sriScoringPercentages.push(100);
	}
	else if (answerReactionDrawdown40 === "Kontakta rådgivaren för att diskutera portföljen.")
	{
		sriScoringPercentages.push(75);
	}
	else if (answerReactionDrawdown40 === "Små svängningar kan jag förstå, men stora fall gör mig klart stressad.")
	{
		sriScoringPercentages.push(25);
	}
	else
	{
		console.log("Cannot calculate MRM risk at this time: Incomplete anwers (risk_reaction_to_drawdown_40).");
		return sriResult;
	}

	// Q
	const answerReactionLowerReturns = customer.getData("risk_reaction_to_lower_returns") || "";
	if (answerReactionLowerReturns === "Frustration över den missade möjligheten och av att halka efter börsen.")
	{
		sriScoringPercentages.push(100);
	}
	else if (answerReactionLowerReturns === "Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj.")
	{
		sriScoringPercentages.push(50);
	}
	else if (answerReactionLowerReturns === "Samtal med rådgivaren för att förstå varför portföljen presterar sämre.")
	{
		sriScoringPercentages.push(75);
	}
	else if (answerReactionLowerReturns === "Ingen, portföljen är balanserad och kommer inte att hänga med enskilda marknadsrörelser.")
	{
		sriScoringPercentages.push(25);
	}
	else
	{
		console.log("Cannot calculate MRM risk at this time: Incomplete anwers (risk_reaction_to_lower_returns).");
		return sriResult;
	}

	// Q
	const answerPortfolioCompared = customer.getData("risk_portfolio_compared") || "";
	if (answerPortfolioCompared === "Jag jämför mitt resultat med svenska aktier/Stockholmsbörsen.")
	{
		sriScoringPercentages.push(125);
	}
	else if (answerPortfolioCompared === "Jag jämför mitt resultat med en global aktieportfölj.")
	{
		sriScoringPercentages.push(100);
	}
	else if (answerPortfolioCompared === "Jag har ett specifikt benchmark.")
	{
		sriScoringPercentages.push(75);
	}
	else if (answerPortfolioCompared === "Jag jämför min portfölj med en fond/förvaltare.")
	{
		sriScoringPercentages.push(50);
	}
	else
	{
		console.log("Cannot calculate MRM risk at this time: Incomplete anwers (risk_portfolio_compared).");
		return sriResult;
	}

	const sum = sriScoringPercentages.reduce((a, b) => a + b, 0);
	const avg = (sum / sriScoringPercentages.length) || 0;

	let sriLevel = Math.floor((avg / 100) * 7);
	sriLevel = sriLevel > 7 ? 7 : sriLevel;
	sriLevel = sriLevel < 1 ? 1 : sriLevel;

	sriResult.sriLevel = sriLevel;

	switch (sriLevel)
	{
		case 1:
			sriResult.sriLevelDescription = "Mycket låg risk";
			break;
		case 2:
			sriResult.sriLevelDescription = "Låg risk";
			break;
		case 3:
			sriResult.sriLevelDescription = "Medellåg risk";
			break;
		case 4:
			sriResult.sriLevelDescription = "Medel risk";
			break;
		case 5:
			sriResult.sriLevelDescription = "Medelhög risk";
			break;
		case 6:
			sriResult.sriLevelDescription = "Hög risk";
			break;
		case 7:
			sriResult.sriLevelDescription = "Mycket hög risk";
			break;
		default:
			console.log("Invalid MRM level", sriLevel);
			break;
	}

	return sriResult
}