import React, { useState } from "react";

import ExitIcon from "@mui/icons-material/ExitToAppOutlined";

import useResponsive from "@hooks/useResponsive";
import Settings from "@classes/Settings";
import { MenuDrawerConsumer } from "@contexts/MenuDrawerProvider";
import {createClassName} from "@helpers/utils";


const useHeader = props => {
  const {location, user, partner, customers} = props;
  const locationPath = location.pathname; 
  const isLaptopOrDesktop = useResponsive("up", "md");
  const menuDrawerCtxt = MenuDrawerConsumer();
  const [logoSrc, setLogoSrc] = useState('');
  const classes = createClassName("Header", {});

  if (partner && !logoSrc) {
    import(`@assets/${partner.name}/${partner.logo}.png`).then( logo  => {
      setLogoSrc(logo.default);
    });
  }

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogoClick = () => {
    setAnchorElNav(null);
    setAnchorElUser(null);
    // return navigate("/dashboard");
  };

  const handleOpenNavMenu = () => {
    return menuDrawerCtxt.toggleDrawer();
  };

  const handleOpenUserMenu = event => {
    setMenuOpen(true);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setMenuOpen(false);
    setAnchorElUser(null);
  };
  
  const toggleUserMenu = () => {
    setMenuOpen(prevState => !prevState);
  };
  
  const handleLogout = () => {
    Settings.clear();
    window.location.href = "/";
  };

  const settings = [
    {
      icon: <ExitIcon fontSize="small" />,
      label: <span style={{ verticalAlign: "top" }}>Logga ut</span>,
      handleClick: () => {
        setAnchorElNav(null);
        setAnchorElUser(null);
        handleLogout();
      }
    }
  ];

  return {
    user,
    partner,
    customers,
    isLaptopOrDesktop,
    locationPath,
    classes,
    logoSrc,
    settings,
    anchorElNav,
    anchorElUser,
    menuOpen,
    handleLogoClick,
    handleOpenNavMenu,
    handleOpenUserMenu,
    toggleUserMenu,
    handleCloseUserMenu
  };
};

export default useHeader;
