import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import TablePaginationActions from "./TablePaginationActions";

import {
  getCookie,
  setCookie
} from "@helpers/cookies";

const CustomTableFooter = props => {
  const { loaded, type, totalCases, page, setPage, rowsPerPage, setRowsPerPage, handleChangePage } =
    props;
    
  const [rowsPerPageSelect, setRowsPerPageSelect] = useState(rowsPerPage > totalCases ? -1 : rowsPerPage);

  const rowsPerPageOptions = {
    10: 10,
    20: 20,
    50: 50,
    ...(totalCases > 100 ? { 100: 100 } : { Allt: -1 })
  };

  const handleRowsPerPageChange = useCallback(
    event => {      
      const cobOptions = JSON.parse(getCookie("cobOptions")) || {};
      setPage(0);
      setRowsPerPage(event.target.value);
      setRowsPerPageSelect(event.target.value);
      // set options cookie as per footer type
      return setCookie(
        "cobOptions",
        JSON.stringify({
          rowsPerPage: {
            ...cobOptions?.rowsPerPage,
            [type]: event.target.value
          }
        })
      );
    },
    [setPage, setRowsPerPage]
  );

  const getPageCountDisplayText = () => {
    switch (type) {
      case "sessions":
        return <>Hittade {totalCases} rådgivningstillfällen</>;
      case "futur":
        return <>Hittade {totalCases} ansökningar</>;
      case "poa":
        return <>Hittade {totalCases} fullmakter</>;
      case "kyc":            
        return <>Hittade {totalCases} {totalCases === 1 ? "kund" : "kunder"}</>;
      case "other":
        return <>Hittade {totalCases} dokument</>;
      default:
        break;
    }
  };

  useEffect(() => {
    if (rowsPerPage > totalCases) {
      setRowsPerPageSelect(-1);
    } else {
      setRowsPerPageSelect(rowsPerPage);
    }
  }, [totalCases])  

  const pageCountDisplayText = getPageCountDisplayText();

  return (
    <Container
      sx={{
        maxWidth: "none !important",
        minHeight: 82,
        pl: "0 !important",
        pr: "0 !important",
        flexDirection: "column",
        alignItems: "unset",
        mt: 0,
        mb: { xs: 2.5, md: 0 },
        mx: 0,
        borderTop: 1,
        borderColor: "#F1F3F4"
      }}
    >
      <Box
        sx={{
          display: "block",
          minHeight: 20,
          mt: 2.5,
          mb: 0,
          mx: 0,
          fontSize: "0.875rem"
        }}
      >
        <b style={{ marginRight: 10 }}>{pageCountDisplayText}</b>
        {totalCases > 10 && setRowsPerPage && (
          <>
            {"Sidstorlek: "}
            <FormControl
              variant="standard"
              sx={{
                m: 0,
                width: 55
              }}
            >
              <Select
                disableUnderline
                size="small"
                value={rowsPerPageSelect}
                onChange={handleRowsPerPageChange}
                inputProps={{MenuProps: {disableScrollLock: true}}} // fix body padding issue
                sx={{
                  fontSize: "0.8rem",
                  lineHeight: "1.2em",
                  height: 20,
                  zIndex: 0,
                  "& .MuiInputBase-input": {
                    padding: 0,
                    textAlign: "center",
                    "&:focus": {
                      backgroundColor: "transparent"
                    }
                  },
                  "& .MuiSelect-iconStandard": {
                    fontSize: 25,
                    top: -5.5
                  }
                }}
              >
                {Object.entries(rowsPerPageOptions).map(
                  ([key, option]) =>
                    option < totalCases && (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{
                          fontSize: "0.8rem"
                        }}
                      >
                        {key}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </>
        )}
      </Box>
      <Box
        sx={{
          minHeight: 42,
        }}
      >
        {totalCases > rowsPerPage && (
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  // colSpan={7}
                  disabled={loaded}
                  count={totalCases}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[rowsPerPage]}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    border: 0,
                    fontSize: "0.8rem",
                    ".MuiTablePagination-spacer": {
                      display: "none"
                    },
                    ".MuiToolbar-root": {
                      display: "flex",
                      flexDirection: "row",
                      minHeight: "0",
                      px: 0,
                      ".MuiBox-root": {
                        display: "block",
                        float: "right",
                      },
                    },
                    ".MuiTablePagination-displayedRows": {
                      flex: 1,
                      fontSize: "0.8rem",
                      color: "#666"
                    },
                    // disable pagination buttons when loading
                    ".MuiButtonBase-root": {                      
                      ...(!loaded && { 
                        color: "rgba(0, 0, 0, 0.26)",
                        backgroundColor: "#F1F3F4 !important",
                        pointerEvents: "none !important",
                        cursor: "default !important"
                      }),
                    }
                  }}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `Visar ${from} till ${to} av ${
                      count !== -1 ? count : `more than ${to}`
                    }`;
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </Box>
    </Container>
  );
};

CustomTableFooter.propTypes = {
  type: PropTypes.string.isRequired,
  totalCases: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func.isRequired
};

export default CustomTableFooter;
