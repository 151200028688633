import React from "react";

// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import StackLayout from "@components/layouts/StackLayout/StackLayout";

// HELPERS
import {createClassName} from "@helpers/utils";

// OTHER
import fulfillment from "./PreviousExperience.fulfillment";
import Select from "../../../../../components/Select/Select";
import TextField from "../../../../../components/TextField/TextField";


export default class PreviousExperience extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	static careerOptions = [
		"Annan serviceverksamhet",
		"Byggverksamhet",
		"Fastighetsverksamhet",
		"Finans- och försäkringsverksamhet",
		"Försörjning av el, gas, värme och kyla",
		"Förvärvsarbete i hushåll; hushållens produktion av diverse varor och tjänster för eget bruk",
		"Handel; reparation av motorfordon och motorcyklar",
		"Hotell- och restaurangverksamhet",
		"Informations- och kommunikationsverksamhet",
		"Jordbruk, skogsbruk och fiske",
		"Kultur, nöje och fritid",
		"Offentlig förvaltning och försvar; obligatorisk socialförsäkring",
		"Pensionär",
		"Student",
		"Tillverkning",
		"Transport och magasinering",
		"Utbildning",
		"Uthyrning, fastighetsservice, resetjänster och andra stödtjänster",
		"Utvinning av mineral",
		"Vård och omsorg; sociala tjänster",
		"Vattenförsörjning; avloppsrening, avfallshantering och sanering",
		"Verksamhet inom juridik, ekonomi, vetenskap och teknik",
		"Verksamhet vid internationella organisationer, utländska ambassader o.d."
	];

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
          }
        }
			}
		});
	}

	render() {
		const {state} = this;
		const {customerState, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view5", {});

		const careerOptionsForDisplay = [];
		for (let i = 0; i < PreviousExperience.careerOptions.length; i++) {
			careerOptionsForDisplay.push({
				label: PreviousExperience.careerOptions[i],
				value: PreviousExperience.careerOptions[i]
			});
		}

		return (
			<div className={classes} style={{width: "100% !important"}}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Kunskapsnivå</h2>

					<div className="Cob-view5-fields">
						<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
							<div style={{width: "100% important"}}>
								<p className="Cob-description">
									I hur många år har kunden handlat med finansiella instrument?
								</p>
								<StackLayout>
									<Select
										label="Ange"
										name="radio_kyc_yrsexp_aktier"
										selectedIndex={customerState.yearsExperienceIndex}
										options={[
											{label: "0-2 år", value: "0-2"},
											{label: "2-5 år", value: "2-5"},
											{label: "5-10 år", value: "5-10"},
											{label: ">10 år", value: ">10"}
										]}
										onChange={(option, yearsExperienceIndex) => this.setCustomerState({
											yearsExperience: option.value,
											yearsExperienceIndex
										})}
                    highlightErrors={hasFormValidationErrors}
									/>
								</StackLayout>

								<p className="Cob-description" style={{marginTop: "20px"}}>
									Vad har kunden för utbildning/utbildningsnivå?
								</p>
								<StackLayout style={{width: "300px"}}>
									<Select
										label="Ange"
										name="radio_kyc_edu"
										selectedIndex={customerState.highestEducationIndex}
										options={[
											{label: "Grundskola", value: "grund"},
											{label: "Gymnasium", value: "gym"},
											{label: "Högskola/Universitet", value: "uni"}
										]}
										onChange={(option, highestEducationIndex) => this.setCustomerState({
											radio_kyc_edu: option.value,
											highestEducationIndex
										})}
                    highlightErrors={hasFormValidationErrors}
									/>
								</StackLayout>

								{customerState.highestEducationIndex === 2 && (
									<StackLayout fullWidth orientation="vertical">
										<TextField
											style={{marginTop: "10px"}}
											name="customer_university"
											type="text"
											label="Högskola/universitet: ange inriktning"
											validation={value => value.length > 0}
											value={customerState.customer_university}
											onChange={({target}) => this.setCustomerState({customer_university: target.value})}
                      highlightErrors={hasFormValidationErrors}
										/>
									</StackLayout>
								)}

								<StackLayout style={{marginTop: "20px", width: "100%"}} orientation="vertical">

									<p className="Cob-description" style={{marginBottom: "0"}}>
										Inom vilket område är eller har kunden varit verksam?
									</p>

									<Select
										style={{maxWidth: "640px"}}
										label="Ange"
										name="customer_kyc_empl"
										selectedIndex={customerState.currentOrPreviousCareerIndex}
										options={careerOptionsForDisplay}
										onChange={(option, currentOrPreviousCareerIndex) => this.setCustomerState({
											customer_kyc_empl: option.value,
											currentOrPreviousCareerIndex
										})}
                    highlightErrors={hasFormValidationErrors}
									/>
								</StackLayout>

							</div>
						</StackLayout>
					</div>
				</ContentBox>
			</div>
		);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		customerState: {
			yearsExperience: customer.getData("radio_kyc_yrsexp_aktier"),
			yearsExperienceIndex: -1,

			highestEducationIndex: (
				customer.getData("radio_kyc_edu") === "grund" ? 0 :
					customer.getData("radio_kyc_edu") === "gym" ? 1 :
						customer.getData("radio_kyc_edu") === "uni" ? 2 :
							-1
			),

			customer_university: customer.getData("customer_university") || "",

			customer_kyc_empl: customer.getData("customer_kyc_empl") || "",

			advice_notes: customer.getData("advice_notes") || "",
			advice_notes_experience: customer.getData("advice_notes_experience") || "",

			customer_occupation_selectIndex: -1,
			customer_occupation_select: "",
		}
	};

	const careerValue = customer.getData("customer_kyc_empl") || "";
	let selectedIndex = -1;
	for (let i = 0; i < PreviousExperience.careerOptions.length; i++) {
		if (careerValue === PreviousExperience.careerOptions[i]) {
			selectedIndex = i;
			break;
		}
	}
	newState.customerState.currentOrPreviousCareerIndex = selectedIndex;

	if (customer.getData("radio_kyc_yrsexp_aktier") === "0-2") {
		newState.customerState.yearsExperienceIndex = 0;
	}

	if (customer.getData("radio_kyc_yrsexp_aktier") === "2-5") {
		newState.customerState.yearsExperienceIndex = 1;
	}

	if (customer.getData("radio_kyc_yrsexp_aktier") === "5-10") {
		newState.customerState.yearsExperienceIndex = 2;
	}

	if (customer.getData("radio_kyc_yrsexp_aktier") === ">10") {
		newState.customerState.yearsExperienceIndex = 3;
	}

	newState.isFulfilled = false;

	return newState;
}

function _getFields() {
	return [
		
	];
}
