import React from "react";
import PropTypes from "prop-types";

import DatePicker from "react-datepicker";
import FileDrop from "../../../../components/FileDrop/FileDrop";
import {DocsList} from "../../../../components/DocsList/DocsList";
import StackLayout from "../../../../components/layouts/StackLayout/StackLayout";
import TextField from "../../../../components/TextField/TextField";
import {createClassName, decodeBase64Document} from "@helpers/utils";
import i18n from "@helpers/i18n";

export default class IdentityDocument extends React.PureComponent
{
	static propTypes = {
		identityDocuments: PropTypes.array,
		customer: PropTypes.object.isRequired,
		companyMode: PropTypes.bool.isRequired,
		hasValidIdentityDocument: PropTypes.bool.isRequired,
		standaloneMode: PropTypes.bool,
		updatedDocument: PropTypes.func.isRequired,
		identityDocumentAdded: PropTypes.string,
		identityDocumentNumber: PropTypes.string,
		identityDocumentExpiration: PropTypes.string,
    highlightErrors: PropTypes.bool
	}

	state = {
		docs: [],
		docNames: [],
		maxUploadFileSize: 0,
		fileDropVisible: true,
		hasValidDocument: false,
		documentAddedDate: null,
		documentExpirationDate: null,
		documentNumber: null,
		identityNumber: null,
	}

	constructor(props)
	{
		super(props);
		const {
      identityDocuments,
			customer,
			hasValidIdentityDocument,
			identityDocumentAdded,
			identityDocumentNumber,
			identityDocumentExpiration,
			identityNumber
		} = props;

    const docs = identityDocuments && identityDocuments.length > 0 ? identityDocuments : customer.documents;

    this.state.docs = docs.map(doc => {
      const decodedDoc = decodeBase64Document(doc);

      return {name: doc.name || doc.document_name, size: doc.size || decodedDoc.length, filterKey: doc.filterKey ||doc.type}
    });
		this.state.docNames = docs.map(doc => doc.name || doc.document_name);
		this.state.maxUploadFileSize = customer.maxUploadFileSize;

		this.state.identityNumber = identityNumber;
		this.state.hasValidDocument = hasValidIdentityDocument;
		this.state.fileDropVisible = !hasValidIdentityDocument;
		this.state.documentAddedDate = identityDocumentAdded;
		this.state.documentNumber = identityDocumentNumber;
		this.state.documentExpirationDate = identityDocumentExpiration;

		if (this.state.documentExpirationDate)
		{
			let expirationDate = null;
			try
			{
				expirationDate = new Date(this.state.documentExpirationDate);

				if (expirationDate < new Date())
				{
					console.log("Clearing passed expiration date", this.state.documentExpirationDate);
          this.state.docNames = [];
          this.state.hasValidDocument = false;
					this.state.documentExpirationDate = null;
				}
			}
			catch (e)
			{
				console.log("Could not parse expiration date", this.state.documentExpirationDate);
        this.state.docs = [];
        this.state.docNames = [];
        this.state.hasValidDocument = false;
        this.state.documentExpirationDate = null;
			}
		}
	}

	render()
	{
		const {state, props} = this;
		const {companyMode, index, highlightErrors} = props;

		const totalDocsSize = state.docs.reduceRight((a, b) => a + Math.round(b.size / 1024), 0);
		const maxUploadFileSize = Math.round(state.maxUploadFileSize / 1024);

		// Min allowed expiration date in the date picker is one day from today
		const minimumAllowedExpirationDate = new Date();
		minimumAllowedExpirationDate.setDate(minimumAllowedExpirationDate.getDate() + 1);

		return (
      <React.Fragment>
        {!this.props.standaloneMode && (
          <React.Fragment>
            {companyMode && (
              <h4 className={"bottom-margin"}>{i18n("identity_document", "upload_company_registration_certificate")}</h4>
            )}

            {!companyMode && (
              <h4 className={"bottom-margin"}>{i18n("identity_document", "upload_passport_id_card")}</h4>
            )}
          </React.Fragment>
        )}

        {state.hasValidDocument && (
          <React.Fragment>
            <p style={{margin: "0 0 10px 0"}}>
              <span style={{
                color: "#04cd04",
                display: "inline-block",
                margin: "0 10px 0 5px",
                fontSize: "15px"
              }}>&#10004;</span>

              {companyMode && (
                <React.Fragment>
                  {i18n("identity_document", "registration_certificate_obtained")} {state.documentAddedDate}.
                </React.Fragment>
              )}

              {!companyMode && (
                <React.Fragment>
                  {i18n("identity_document", "identity_document_obtained")} {state.documentAddedDate} ({i18n("identity_document", "valid_until")} {(new Intl.DateTimeFormat("sv-SE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit"
                }).format(state.documentExpirationDate ? new Date(state.documentExpirationDate) : null))}).
                </React.Fragment>
              )}
            </p>

					<span style={{
						display: "block",
						cursor: "pointer",
						fontSize: "12px",
						fontWeight: "bold",
						color: "#4cb7d5",
						margin: "0 0 20px 0"
					}} onClick={() => this.setState({fileDropVisible: !state.fileDropVisible})}>
						{companyMode && (
							<React.Fragment>
								{i18n("identity_document", "upload_registration_certificate")}
							</React.Fragment>
						)}

						{!companyMode && (
							<React.Fragment>
								{i18n("identity_document", "upload_new_identity_document")}
							</React.Fragment>
						)}
					</span>
				</React.Fragment>
			)}

			{!state.hasValidDocument && totalDocsSize <= 0 && (
				<p style={{margin: "0 0 20px 0"}}>
					<span style={{color: "red", display: "inline-block", margin: "0 10px 0 5px", fontSize: "15px"}}>X</span>
					{companyMode && (
						<React.Fragment>
							{i18n("identity_document", "registration_certificate_missing")}
						</React.Fragment>
					)}
					{!companyMode && (
						<React.Fragment>
							{i18n("identity_document", "valid_identity_document_missing")}
						</React.Fragment>
					)}
				</p>
			)}

        {state.fileDropVisible && (
          <React.Fragment>
            <div style={{maxWidth: "500px", width: "100%"}}>
              <FileDrop
                filterKey={"passport-id" + (index !== undefined ? "-" + index : "")}
                dropText={companyMode ? i18n("identity_document", "drag_and_drop_registration_certificate") : i18n("identity_document", "drag_and_drop_id_doc")}
                busyText={`${i18n("upload_and_sign", "loading_document")}...`}
                accept="image/png, image/jpeg, application/pdf"
                onDrop={this._onDocDrop}
                style={{minHeight: "0", paddingTop: "20px", paddingBottom: "30px"}}
              />

              <DocsList
                filterKey={"passport-id" + (index !== undefined ? "-" + index : "")}
                items={state.docs}
                totalSize={totalDocsSize}
                maxSize={maxUploadFileSize}
                onDocRemove={this._onDocRemove}
                alignBottom={true}
                required={!state.hasValidDocument}
              />
            </div>

            {!companyMode && (<React.Fragment>
              <StackLayout className="Cob-fields-collection per-row-3 align-start" rowWrap fullWidth>
                <div>
                  <strong style={{margin: "0 0 10px 0"}}>
                    {i18n("identity_document", "id_doc_control_num")}
                  </strong>
                  <TextField
                    name={"identity_doc_number" + (index !== undefined ? "_" + index : "")}
                    validation={value => (value.length > 0 && value.length <= 30)}
                    value={state.documentNumber}
                    label= {i18n("identity_document", "enter_control_num")}
                    onChange={this._onDocumentNumberChanged}
                    highlightErrors={highlightErrors}
                  />
                </div>
              </StackLayout>

              <StackLayout className="Cob-fields-collection per-row-3 align-start" rowWrap fullWidth>
                <div>
                  <strong style={{margin: "0 0 10px 0"}}>
                    {i18n("identity_document", "id_doc_expiration_date")}
                  </strong>
                  <DatePicker
                    placeholderText={i18n("identity_document", "select_date")}
                    calendarStartDay={1}
                    selected={state.documentExpirationDate ? new Date(state.documentExpirationDate) : null}
                    dateFormat="yyyy-MM-dd"
                    minDate={minimumAllowedExpirationDate}
                    className={state.documentExpirationDate != null ? "datepicker-valid" : highlightErrors ? "datepicker-invalid" : ""}
                    onChange={this._onExpirationDateChanged}
                  />
                  {!state.documentExpirationDate && highlightErrors ?
                        <DatePickerInvalidStatus icon="error"/>
                      : null}

                  <input type={"hidden"}
                      name={"identity_doc_expiration" + (index !== undefined ? "_" + index : "")}
                      value={(new Intl.DateTimeFormat("sv-SE", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                      }).format(state.documentExpirationDate ? new Date(state.documentExpirationDate) : null))}/>
                </div>
              </StackLayout>
              </React.Fragment>
            )}
          </React.Fragment>)}
      </React.Fragment>
    );
	}

	_onDocumentNumberChanged = ({target}) =>
	{
		const {customer, index} = this.props;
		this.setState({documentNumber: target.value}, () =>
		{
			let formDataKey = "identity_doc_number" + (index !== undefined ? "_" + index : "");
			customer._formData[formDataKey] = target.value;
			this.props.updatedDocument(this._checkFulfillment());
		});
	}

	_onExpirationDateChanged = (date) =>
	{
		const {customer, index} = this.props;
		this.setState({documentExpirationDate: date}, () =>
		{
			let formDataKey = "identity_doc_expiration" + (index !== undefined ? "_" + index : "");
			customer._formData[formDataKey] = (new Intl.DateTimeFormat("sv-SE", {
				year: "numeric", month: "2-digit", day: "2-digit"
			}).format(date));
			this.props.updatedDocument(this._checkFulfillment());
		});
	}

	// Internal methods
	_onDocDrop = (documents) =>
	{
    // Max 1 document. Will take last document if multiple documents are dropped
		const {props, state} = this;
		const {customer, index} = props;
		const docs = [];
		const docNames = [];

    const doc = documents[documents.length -1];

    docs.push({
      name: doc.name,
      size: doc.size,
      filterKey: doc.filterKey,
      expiration: state.documentExpirationDate != null ? (new Intl.DateTimeFormat("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }).format(state.documentExpirationDate ? new Date(state.documentExpirationDate) : null)) : null,
      number: state.documentNumber,
      index: index
    });
    docNames.push(doc.name);

    let documentToAdd = {
      name: doc.name,
      size: doc.size,
      data: doc.data,
      filterKey: doc.filterKey,
      expiration: state.documentExpirationDate != null ? (new Intl.DateTimeFormat("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }).format(state.documentExpirationDate ? new Date(state.documentExpirationDate) : null)) : null,
      number: state.documentNumber,
      identityNumber: state.identityNumber,
      index: index
    };
    customer.addDocument(documentToAdd);

		this.setState({docs, docNames}, () =>
		{
			this.props.updatedDocument(this._checkFulfillment());
		});
	};

	_onDocRemove = (item) =>
	{
		const {props, state} = this;
		const {customer} = props;

		const docs = [...state.docs].filter((d) =>
		{
			return d.name !== item.name && d.document_name !== item.name;
		});

		const docNames = [...state.docNames].filter((d) =>
		{
			return d !== item.name && d !== item.document_name;
		});

		customer.removeDocumentWithName(item.name, "identity");
		this.setState({docs, docNames}, () =>
		{
			this.props.updatedDocument(this._checkFulfillment());
		});
	}

	_checkFulfillment = () =>
	{
		if (this.state.docs.length === 0)
		{
      this.setState({hasValidDocument: false});
			return false;
		}

		if (this.props.companyMode)
		{
			// Company customers, it's enough if there's a copy uploaded, no need for expiration dates or doc numbers
      this.setState({hasValidDocument: true});
			return true;
		}

		if (!this.state.documentExpirationDate || this.state.documentExpirationDate.length === 0)
		{
      this.setState({hasValidDocument: false});
			return false;
		}

		if (!this.state.documentNumber || this.state.documentNumber.length === 0)
		{
      this.setState({hasValidDocument: false});
			return false;
		}

    this.setState({hasValidDocument: true});
		return true;
	}
}

// PRIVATE COMPONENTS
function DatePickerInvalidStatus({icon, requiredSymbol}) {
	return (
		<div className="DatePickerInvalid-status">
			<div className={createClassName("material-icons", {
				"icon": !!icon,
				"required-symbol": requiredSymbol === true,
			})}>{requiredSymbol === true ? "*" : icon}</div>
		</div>
	);
}