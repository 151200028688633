import React from "react";
import PropTypes from "prop-types";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class ActivityIndicator extends React.PureComponent {
    static propTypes = {
        busy: PropTypes.bool,
        scale: PropTypes.number,
        width: PropTypes.number,
        color: PropTypes.string,
    }
    static defaultProps = {
        busy: false,
        scale: 1,
        width: 2,
    }

    size = 38

    render() {
        const { props, size } = this;
        const { fixed, busy, scale, width, color, style } = props;
        const classes = createClassName(props.className, {
            "ActivityIndicator": true,
            "active": busy,
            "fixed": fixed,
        });
        const circleStyle = {
            stroke: color,
        };

        return (
            <div className={ classes } style={{ width: size * scale, height: size * scale, ...style }}>
                <svg width={ size } height={ size } viewBox={ `0 0 ${ size } ${ size }` } xmlns="http://www.w3.org/2000/svg" style={{
                    transform: `scale(${ scale })`,
                }}>
                    <circle style={ circleStyle } fill="none" strokeWidth={ width } strokeLinecap="round" cx={ size / 2 } cy={ size / 2 } r={ (size / 2) - width } />
                </svg>
            </div>
        );
    }
}