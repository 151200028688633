import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./FinancialSituation3.fulfillment";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextView from "../../../../../components/TextView/TextView";
import NumberField from "../../../components/NumberField/NumberField";
import i18n from "@helpers/i18n";

export default class FinancialSituation3 extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});
	}

	render() {
		const {state} = this;
		const {customerState, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Ekonomisk situation</h2>

					<div className="Cob-view2-fields">
						<h3>Omfattning och investeringsbart kapital</h3>

						<h4>Finns det delar som ej ska omfattas av rådgivningen?</h4>
						<TextView
							name="situation_scope_comment"
							label={`Fritext (${200 - customerState.situation_scope_comment.length} tecken kvar)`}
							value={customerState.situation_scope_comment}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_scope_comment: target.value})}
							fullWidth
							minHeight={90}
						/>

						<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>Kapital som ej ska omfattas av rådgivningen*</h4>
								<NumberField 
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_scope_outside"}
                  value={customerState.situation_scope_outside}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_scope_outside: target.value})}
                  highlightErrors={hasFormValidationErrors}
                />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>Investeringsbart kapital*</h4>
								<NumberField 
                  label={i18n("general", "provide_amount")}
                  unit=" kr"
                  name={"situation_scope_investable"}
                  value={customerState.situation_scope_investable}
                  showSeparators={true}
                  validation={value => (value.length > 0)}
                  onChange={({target}) => this.setCustomerState({situation_scope_investable: target.value})}
                  highlightErrors={hasFormValidationErrors}
                  />
							</div>

						</StackLayout>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		isFulfilled: false,
		customerState: {
			situation_scope_outside: customer.getData("situation_scope_outside"),
			situation_scope_investable: customer.getData("situation_scope_investable"),
			situation_scope_comment: customer.getData("situation_scope_comment") || ""
		}
	};
}

function _getFields() {
	return [
		"situation_scope_outside",
		"situation_scope_investable",
		"situation_scope_comment",
	];
}