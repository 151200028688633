import React, {useState} from "react";
import PropTypes from "prop-types";

// COMPONENTS
import MenuIcon from "@mui/icons-material/Menu";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// CLASSES
import Settings from "@classes/Settings";

// HELPERS
import {createB64Context, stringifyQueryParams} from "@helpers/utils";

const CustomerMenu = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const { advisor, client, partner, setCustomerState, cobForm, nextStepOptions } = props;
  const ssnOrg = (client.id).replace("-", "");

  const handleOptionSelection = async index =>
  {
    if (index === 0)
    {
      const newStateProps = {
        isCompany: false,
        ssnOrg,
        needsNewDocumentation: false,
        kycCaseId: null,
        kycInfoLoading: true,
        overrideNewDocumentation: false,
        casesLoading: false,
        cases: null,
        selectedCaseId: null,
        selectedCaseIndex: -1,
        isNewCase: index === 0,
        standaloneApplicationsChecked: []
      };

      newStateProps.selectedCaseId = null;
      newStateProps.selectedCaseIndex = -1;

      return setCustomerState(newStateProps, () =>
      {
        cobForm.current._onSubmit();
        handleClose();
      });
    }
    else if (index > 1) // Standalone applications
    {
      const applicationIndex = index - 2;
      const applications = partner.standaloneApplications;
      const app = applications[applicationIndex];

      if (app === null)
      {
        console.error("Invalid app configuration", app.key);
        return;
      }

      // Create a checksum that the cob odin client will check to make sure that the request
      // originated in a correct way.
      const encodedContext = createB64Context("fairclient", advisor, ssnOrg, null);
      return window.location.href = app.url + stringifyQueryParams({
        context: encodedContext,
        token: Settings.get("AUTH_TOKEN"),
        key: app.key
      });
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Actions">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ height: "auto", p: 0, pb: 0.3, m: 0 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuIcon 
              sx={{
                color: "common.black",
                width: 18,
                height: 18,
                mr: 1
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            pb: 0,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              topx: 1,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box 
        sx={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-start",
          px: 1,
          height: 35,
          minHeight: "unset",
        }}
        >
          <Typography 
            color="common.black"
            sx={{
              fontFamily: "Roboto",
              fontSize: "1.1rem",
              fontWeight: 800,
            }}
          >
            Actions
          </Typography>
        </Box>
        <Divider flexItem />
        {nextStepOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={()=> handleOptionSelection(index)}
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-start",
              px: 1,
              height: 35,
              minHeight: "unset",
              display: index === 1 ? "none" : "flex",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default CustomerMenu;

CustomerMenu.propTypes = {
  handlers: PropTypes.object
};
