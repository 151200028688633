import React from "react";
import moment from "moment";

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import SignModal from "../../SignAgreement/subcomponents/SignModal/SignModal";

// HELPERS
import {createClassName} from "@helpers/utils";
import api from "@helpers/api";

// OTHER
import fulfillment from "./SignAgreement.fulfillment";
import Modal from "@components/Modal/Modal";
import i18n from "../../../../../helpers/i18n";
import AssistantModal from "../../SignAgreement/subcomponents/AssistantModal/AssistantModal";


export default class SignAgreement extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state)
	}

	setCustomerState = (customerState, callbackFn) =>
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	};

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {label: i18n("sign_agreement", "completion"), appearance: "success"}
			},
		});
	}

	render()
	{
		const {props, state} = this;
		const {signModalVisible, signModalBusy, signModalData, onRestartSession, user, advisor, customer} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-SignAgreement", {});
		const signDate = moment();

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<Modal
					busy={signModalBusy}
					className="Cob-SignAgreement-SignModal"
					title={i18n("cob", "sign_agreement")}
					visible={signModalVisible && !customerState.includeAssentlyDocumentation}>
					<React.Fragment>
						{state.signStatus !== "success" && (
							<React.Fragment>
								<p>
									Då rådgivningen enbart innefattar en uppdatering av kundens grunddokumentation behöver inget
									dokument signeras den här gången.
								</p>
								<p>
									Uppgifterna sparas när du fortsätter och finns tillgängliga i FA Solutions.
								</p>

								<Modal.ButtonsContainer>
									<Modal.Button
										label="Avbryt"
										appearance="medium"
										onClick={this._onSignModalClose}
										disabled={signModalBusy}
										filled
									/>
									<Modal.Button
										label="Spara och stäng"
										appearance="success"
										onClick={this._onSignModalSign}
										busy={signModalBusy}
										filled
									/>
								</Modal.ButtonsContainer>
							</React.Fragment>
						)}

						{state.signStatus === "success" && (
							<React.Fragment>
								<p>
									Dokumentationen har sparats.
								</p>

								<Modal.ButtonsContainer>
									<Modal.Button
										label="Stäng rådgivningen"
										appearance="success"
										onClick={onRestartSession}
										busy={signModalBusy}
										filled
									/>
								</Modal.ButtonsContainer>
							</React.Fragment>
						)}
					</React.Fragment>
				</Modal>

				{signModalVisible && user.role !== "assistant"  && (
					<SignModal
						busy={signModalBusy}
						visible={signModalVisible && customerState.includeAssentlyDocumentation}
						data={signModalData}
						status={state.signStatus}
						errorMessage={state.signErrorMessage}
						signUrls={state.signUrls}
						signUrlsLabel={"Kontoöppning"}
						signUrlsSecond={state.signUrlsSecond}
						signUrlsSecondLabel={"Rådgivning"}
						user={user}
						customer={customer}
						onSign={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRetry={this._onSignModalRetry}
						onRestart={onRestartSession}
					/>
				)}

				{signModalVisible && user.role === "assistant" && (
					<AssistantModal
						busy={signModalBusy}
						visible={signModalVisible}
						user={user}
						advisor={advisor}
						onSend={this._onSignModalSign}
						onClose={this._onSignModalClose}
						onRestart={onRestartSession}
					/>
				)}

				<input type="hidden" name="sign_date" value={signDate.format("YYYY-MM-DD")}/>

				<ContentBox>
					<h2>{i18n("sign_agreement", "signing")}</h2>

					<div className="Cob-SignAgreement-fields">
						<Checkbox
							name="sign_agreed_gdpr"
							label={i18n("sign_agreement", "personal_data_acknowledgement")}
							value="Ja"
							checked={customerState.sign_agreed_gdpr === "Ja"}
							onChange={checked => this.setCustomerState({sign_agreed_gdpr: checked ? "Ja" : "Nej"})}
							alignTop
						/>

						<Checkbox
							name="sign_seen_relevant_information"
							label={i18n("sign_agreement", "relevant_info_reviewed")}
							value="Ja"
							checked={customerState.sign_seen_relevant_information === "Ja"}
							onChange={checked => this.setCustomerState({sign_seen_relevant_information: checked ? "Ja" : "Nej"})}
						/>

						<Checkbox
							name="sign_agreed_processing_personal_info"
							label={i18n("sign_agreement", "data_storage_consent")}
							value="Ja"
							checked={customerState.sign_agreed_processing_personal_info === "Ja"}
							onChange={checked => this.setCustomerState({sign_agreed_processing_personal_info: checked ? "Ja" : "Nej"})}
							alignTop
						/>

						<Checkbox
							name="sign_understands_risk"
							label={i18n("sign_agreement", "understand_risk")}
							value="Ja"
							checked={customerState.sign_understands_risk === "Ja"}
							onChange={checked => this.setCustomerState({sign_understands_risk: checked ? "Ja" : "Nej"})}
							alignTop
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_onSignModalClose = async () =>
	{
		const {setAppState} = this.props;
		await setAppState({signModalVisible: false});
	};

	_onSignModalSign = ({signatories, signOnScreen}) =>
	{
		return new Promise(async resolve =>
		{
			const {props} = this;
			const {user, setAppState, customer} = props;
			const newStateProps = {};

			await setAppState({signModalBusy: true});

			try
			{
				// If accounts should be created the keys are included in this array
				const generateAccounts = [];

				if (customer.getData("chk_accounttype_naked") === "yes")
				{
					generateAccounts.push("account_no_depa");
				}

				if (customer.getData("chk_accounttype_isk") === "yes")
				{
					generateAccounts.push("account_no_isk");
				}

				if (customer.getData("chk_accounttype_kf") === "yes")
				{
					generateAccounts.push("account_no_kf");
				}

				if (customer.getData("chk_accounttype_tp") === "yes")
				{
					generateAccounts.push("account_no_tp");
				}

				// Depending on whether it was a private individual or a company advisory session, and what combination
				// of actions we have taken we include different documents.
				const excludedDocuments = [];

				if (customer.isCompany)
				{
					excludedDocuments.push(0); // Dokumentation Privat
					console.log("Sign :: Excluding \"Grunduppgifter Privat\"");
				}
				else
				{
					excludedDocuments.push(1); // Dokumentation Företag
					console.log("Sign :: Excluding \"Grunduppgifter Företag\"");
				}

				if (customer.getData("checkbox_advice_onboarding") !== "yes")
				{
					excludedDocuments.push(2); // Uppdragsavtal
					console.log("Sign :: Excluding \"Uppdragsavtal\"");
				}

				if (customer.getData("checkbox_advice_recommendation") !== "yes")
				{
					excludedDocuments.push(3); // Rådgivningsdokument
					console.log("Sign :: Excluding \"Rekommendation\"");
				}

				// Sign the case and receive its sign URL
				const datasheetUrls = [];
				const {signUrls, signUrlsSecond} = await api("/case/sign", {
					caseId: Settings.get("CASE_ID"),
					signatories,
					signOnScreen,
					partner: user.getStakeholderData(),
					excludeDocs: excludedDocuments,
					generateAccounts: generateAccounts,
					generateDocuments: [],
					datasheetUrls,
					generateAssentlyDocumentation: true
				});

				newStateProps.signStatus = "success";
				newStateProps.signUrls = signUrls;
				newStateProps.signUrlsSecond = signUrlsSecond;
			}
			catch (e)
			{
				console.error(e.message);
				newStateProps.signStatus = "fail";
				newStateProps.signErrorMessage = e.message;
			}

			this.setState(newStateProps, async () =>
			{
				await setAppState({signModalBusy: false});
				resolve();
			});
		});
	};

	_onSignModalRetry = () =>
	{
		this.setState({
			signStatus: null,
			signUrls: null,
			signUrlsSecond: null,
			signErrorMessage: null,
		});
	}
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer} = this.props;
	const newState = {
		signStatus: null,
		signUrls: null,
		signUrlsSecond: null,
		signErrorMessage: null,

		customerState: {
			sign_agreed_gdpr: customer.getData("sign_agreed_gdpr"),
			sign_seen_relevant_information: customer.getData("sign_seen_relevant_information"),
			sign_agreed_processing_personal_info: customer.getData("sign_agreed_processing_personal_info"),
			sign_understands_risk: customer.getData("sign_understands_risk"),
			includeAssentlyDocumentation: true
		}
	};

	newState.isFulfilled = this.isFulfilled(newState);

	return newState;
}

function _getFields()
{
	return [
		"sign_agreed_gdpr",
		"sign_seen_relevant_information",
		"sign_agreed_processing_personal_info",
		"sign_understands_risk"
	];
}