import React from "react";
// COMPONENTS
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
// HELPERS
import {createClassName} from "@helpers/utils";
// OTHER
import fulfillment from "./Autogiro.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import NumberField from "../../../components/NumberField/NumberField";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextField from "../../../../../components/TextField/TextField";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import Select from "../../../../../components/Select/Select";
import Validator from "../../../../../classes/Validator";


export default class AutogiroView extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});
	}

	render() {
		const {state, props} = this;
		const {customerState, hasFormValidationErrors} = state;
		const {availableBanks} = props;
		const classes = createClassName("Cob-view Cob-autogiro", {});

		/**
		 * Set up the dropdown with selectable banks.
		 */
		let availableBankOptions = [];
		for (const bankName in availableBanks) {
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName)) continue;
			availableBankOptions.push({
				label: bankName,
				value: bankName
			});
		}

		availableBankOptions.push({
			label: "Övriga",
			value: "-"
		});

		const textClasses = createClassName("description", {
			"centered": true,
			"contractable": true,
			"expanded": state.textFieldExpanded
		});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Autogiromedgivande</h2>

					<div className="Cob-autogiro-fields">

						<div style={{marginTop: "20px"}}>
							<OptionsList
								type="radio"
								orientation="horizontal"
								name="radio_customer_autogiro"
								description="Kunden vill i samband med rådgivningen lämna medgivande till betalning via autogiro"
								options={[
									{
										label: "Nej",
										value: "no",
										checked: customerState.radio_customer_autogiro === "no"
									},
									{
										label: "Ja",
										value: "yes",
										checked: customerState.radio_customer_autogiro === "yes"
									},
								]}
								onChange={(checked, radio_customer_autogiro) => this.setCustomerState({radio_customer_autogiro})}
							/>
						</div>

						{customerState.radio_customer_autogiro === "yes" && (
							<div className={textClasses} style={{marginTop: "30px"}}>
								<strong>
									För att du ska kunna sätta in och ta ut pengar, samt månadsspara via Autogiro
									måste nedan Autogiromedgivande fyllas i:
								</strong>
								<p>
									Undertecknad (”betalaren”), medger att betalning får göras genom uttag från angivet
									konto eller av betalaren senare angivet Konto, på begäran av angiven
									betalningsmottagare för betalning till denne på viss dag (”förfallodagen”) via
									Autogiro. Betalaren medger att insättning och uttag från angivet konto eller av
									betalaren föranmält konto får göras på begäran av betalaren. Betalaren
									samtycker till att behandling av personuppgifter som lämnats i detta medgivande
									behandlas av betalarens betaltjänstleverantör, betalningsmottagaren,
									betalningsmottagarens betaltjänstleverantör och Bankgirocentralen BGC AB för
									administration av tjänsten. Personuppgiftsansvariga för denna
									personuppgiftsbehandling är betalarens betaltjänstleverantör, betalningsmottagaren
									samt betalningsmottagarens betaltjänstleverantör. Betalaren kan när som helst begära
									att få tillgång till eller rättelse av personuppgifterna genom att kontakta
									betalarens betaltjänstleverantör. Ytterligare information om behandling av
									personuppgifter i samband med betalningar kan finnas i villkoren för kontot och i
									avtalet med betalningsmottagaren. Betalaren kan när som helst återkalla sitt
									samtycke, vilket medför att tjänsten i sin helhet avslutas.
								</p>
								<div className="expand">
									<div className="first"></div>
									<div className="second"></div>
									<div className="third" onClick={this._onExpandText}>Expandera texten</div>
								</div>
								<div className="contract" onClick={this._onExpandText}>
									Minimera texten
								</div>
							</div>
						)}

						<div className="centered">
							{customerState.radio_customer_autogiro === "yes" && (
								<React.Fragment>
									<h4 style={{margin: "50px 0 5px 0"}}>Kontouppgifter</h4>

									<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
										<div>
											<Select
												name="customer_autogiro_bank"
												label="Bank"
												selectedIndex={customerState.selectedBankIndex}
												options={availableBankOptions}
												onChange={(option, selectedBankIndex) => {
													this.setCustomerState({
														customer_autogiro_bank: option.value,
														selectedBankIndex
													});
												}}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
										<div>
											<TextField
												name="customer_autogiro_bank_clearingno"
												label="Clearingnummer"
												value={customerState.customer_autogiro_bank_clearingno || ""}
												validation={customerState.checkbox_override_validation ? () => true : this._validateClearingNumber}
												onChange={this._onAccountClearingChange}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<TextField
												name="customer_autogiro_bankno"
												label="Depå-/kontonummer"
												value={customerState.customer_autogiro_bankno || ""}
												validation={customerState.checkbox_override_validation ? () => true : this._numberValidation}
												onChange={this._onAccountNumberChange}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									{customerState.customer_autogiro_bank === 'Swedbank' && (
										<p style={{
											background: "#FFFFD5",
											padding: "10px",
											fontSize: "12px",
											margin: "-10px 0 20px 0",
											maxWidth: "600px"
										}}>
											Det förekommer att Swedbank har en femte siffra i clearingnumret. Vänligen
											ange enbart de fyra första innan bindestrecket. Exempel: 8214-9 ska anges som
											8214.
										</p>
									)}

									<h4 style={{margin: "20px 0 10px 0"}}>
										Insättning via autogiro
									</h4>

									<Checkbox
										type="checkbox"
										name="checkbox_autogiro_onetime"
										label="Kunden vill göra en engångsinsättning via autogiro"
										value={customerState.checkbox_autogiro_onetime === "Ja" ? "Ja" : "Nej"}
										checked={customerState.checkbox_autogiro_onetime === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_autogiro_onetime: checked ? "Ja" : "Nej"})}
										style={{marginTop: "10px"}}
									/>

									{customerState.checkbox_autogiro_onetime === "Ja" && (
										<div style={{
											width: "100%",
											display: "block",
											margin: "15px 0",
											borderBottom: "1px solid #EEE"
										}}>
											<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
												<div>
													<NumberField
														name="customer_autogiro_amount_onetime"
														label="Belopp engångsinsättning"
														unit=" SEK"
														value={customerState.customer_autogiro_amount_onetime || ""}
														showSeparators={true}
														onChange={({target}) => {
															this.setCustomerState({customer_autogiro_amount_onetime: target.value.split(' ').join('')});
														}}
                            highlightErrors={hasFormValidationErrors}
													/>
												</div>
											</StackLayout>
										</div>
									)}

									{customerState.checkbox_autogiro_onetime !== "Ja" && (
										<input type="hidden" name="customer_autogiro_amount_onetime" value=""/>
									)}

									<Checkbox
										type="checkbox"
										name="checkbox_autogiro_recurring"
										label="Kunden vill starta en periodisk dragning via autogiro"
										value={customerState.checkbox_autogiro_recurring === "Ja" ? "Ja" : "Nej"}
										checked={customerState.checkbox_autogiro_recurring === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_autogiro_recurring: checked ? "Ja" : "Nej"})}
										style={{marginTop: "20px"}}
									/>

									{customerState.checkbox_autogiro_recurring === "Ja" && (
										<div style={{
											width: "100%",
											display: "block",
											margin: "15px 0 0 0"
										}}>
											<StackLayout className="Cob-fields-collection no-margin" alignItems="start"
														 fullWidth>
												<div>
													<NumberField
														name="customer_autogiro_amount_recurring"
														label="Belopp periodisk dragning"
														unit=" SEK"
														value={customerState.customer_autogiro_amount_recurring || ""}
														showSeparators={true}
														onChange={({target}) => {
															this.setCustomerState({customer_autogiro_amount_recurring: target.value.split(' ').join('')});
														}}
                            highlightErrors={hasFormValidationErrors}
													/>
												</div>
												<div>
													<Select
														label="Dragningsfrekvens"
														name="radio_autogiro_recurring"
														selectedIndex={customerState.recurringIndex}
														options={[
															{label: "Månadsvis", value: "monthly"},
															{label: "Kvartalsvis", value: "quarterly"},
															{label: "Halvårsvis", value: "half-yearly"},
															{label: "Årsvis", value: "yearly"}
														]}
														onChange={(option, recurringIndex) => this.setCustomerState({
															radio_autogiro_recurring: option.value,
															recurringIndex
														})}
                            highlightErrors={hasFormValidationErrors}
													/>
												</div>
											</StackLayout>

											<div style={{
												width: "100%",
												display: "block",
												padding: "15px",
												background: "#F9F9F9"
											}}>
												<strong style={{display: "block"}}>
													Antal dragningar totalt
												</strong>
												<p style={{margin: "5px 0 10px 0"}}>
													Lämnas fältet tomt sker de periodiska dragningarna tillsvidare.
												</p>

												<div style={{
													width: "100%",
													maxWidth: "300px",
													display: "block"
												}}>
													<NumberField
														name="customer_autogiro_amount_quantity"
														label="Ange antal"
														value={customerState.customer_autogiro_amount_quantity || ""}
														showSeparators={true}
														onChange={({target}) => {
															this.setCustomerState({customer_autogiro_amount_quantity: target.value.split(' ').join('')});
														}}
                            highlightErrors={hasFormValidationErrors}
													/>
												</div>
											</div>
										</div>
									)}

									{customerState.checkbox_autogiro_recurring !== "Ja" && (
										<input type="hidden" name="customer_autogiro_amount_recurring" value=""/>
									)}

									{customerState.customer_autogiro_amount_recurring !== "" &&
									customerState.customer_autogiro_amount_recurring > 0 && (
										<input type="hidden" name="radio_autogiro_recurring" value="monthly"/>
									)}

									<h4 style={{margin: "40px 0 5px 0"}}>
										Till konto
									</h4>

									<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
										<div>
											<Select
												label="Bankgiro Fair"
												name="house_bgno"
												selectedIndex={customerState.bgIndex}
												options={[
													{label: "Depå (876-8228)", value: "876-8228"},
													{label: "Fond (5498-3614)", value: "5498-3614"}
												]}
												onChange={(option, bgIndex) => this.setCustomerState({
													house_bgno: option.value,
													bgIndex
												})}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<TextField
												name="customer_accountno_fair"
												value={customerState.customer_accountno_fair}
												label="Kundens depå-/ISK-nummer hos Fair"
												validation={value => value.length > 0}
												disabled={customerState.radio_new_isk === "yes"}
												onChange={({target}) => this.setCustomerState({customer_accountno_fair: target.value})}
                        highlightErrors={customerState.radio_new_isk !== "yes" && hasFormValidationErrors}
											/>

											<Checkbox
												type="checkbox"
												name="radio_new_isk"
												label="Öppna ny depå"
												value={customerState.radio_new_isk === "yes" ? "yes" : "no"}
												checked={customerState.radio_new_isk === "yes"}
												onChange={checked => this.setCustomerState({radio_new_isk: checked ? "yes" : "no"})}
											/>
										</div>

										{customerState.radio_new_isk === "yes" && (
											<input type="hidden" name="customer_accountno_fair" value=""/>
										)}
									</StackLayout>
								</React.Fragment>
							)}

						</div>
					</div>
				</ContentBox>
			</div>
		);
	}

	_onExpandText = () => {
		this.setState({
			textFieldExpanded: !this.state.textFieldExpanded
		});
	};

	_onAccountClearingChange = ({target}) => {
		// Remove all non-numbers
		let value = target.value + "";
		value = value.replace(/[^0-9]+/g, "");
		value = value.replace(/\+/g, "");
		value = value.replace(/-/g, "");

		// Limit clearing numbers to 4 numbers, we'll show a message that says to enter only first four for the
		// banks with longer numbers
		if (value.length >= 4) {
			value = value.substring(0, 4);
		}

		this.setCustomerState({customer_autogiro_bank_clearingno: value});
	};

	_validateClearingNumber = (value) => {
    const {customerState} = this.state;
		if (customerState.checkbox_override_validation) {
			return true;
		}

		return Validator.validateClearingNumber(value, customerState.customer_autogiro_bank, this.props.availableBanks);
	};

	_numberValidation = (value) => {
    const {customerState} = this.state;
		if (customerState.checkbox_override_validation) {
			return true;
		}

		if (value.length < 5 || isNaN(value)) {
			return false;
		}

		/**
		 * For the banks where we know the validation rules, check if the number should be validated.
		 */
		let shouldValidateAccountNumber = false;
		const availableBanks = this.props.availableBanks;
		for (const bankName in availableBanks) {
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName)) continue;

			if (customerState.customer_autogiro_bank === bankName) {
				const bankInfo = availableBanks[bankName];
				if (bankInfo.length > 0) {
					if (bankInfo[0]['validationEnabled'] === "1") {
						shouldValidateAccountNumber = true;
					}
				}
				break;
			}
		}

		if (!shouldValidateAccountNumber) {
			return true;
		}

		let bankNoToValidate = customerState.customer_autogiro_bank_clearingno + ", " + value;
		let kontonummer = window.kontonummer(bankNoToValidate);
		return kontonummer !== false;
	};

	_onAccountNumberChange = ({target}) => !isNaN(target.value) ? this.setCustomerState({customer_autogiro_bankno: target.value}) : undefined;
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;

	let selectedBankIndex = -1;
	if (newState.customerState.customer_autogiro_bank && newState.customerState.customer_autogiro_bank.length > 0) {
		let bankFoundInList = false;
		for (const bankName in this.props.availableBanks) {
			if (bankName === 'length' || !this.props.availableBanks.hasOwnProperty(bankName)) {
				continue;
			}

			selectedBankIndex++;
			if (bankName === newState.customerState.customer_autogiro_bank) {
				bankFoundInList = true;
				break;
			}
		}

		/**
		 * No bank found => Övrig bank
		 */
		if (!bankFoundInList) {
			selectedBankIndex++;
			newState.customerState.customer_autogiro_bank_custom = newState.customerState.customer_autogiro_bank;
			newState.customerState.customer_autogiro_bank = "-";
		}
	}

	newState.customerState.checkbox_override_validation = false;
	newState.customerState.selectedBankIndex = selectedBankIndex;
	newState.availableBanks = this.props.availableBanks;

	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	let initialState = {
		isFulfilled: false,
		customerState: {
			textFieldExpanded: false,
			radio_customer_autogiro: customer.getData("radio_customer_autogiro"),
			customer_autogiro_amount_recurring: customer.getData("customer_autogiro_amount_recurring"),
			customer_autogiro_amount_onetime: customer.getData("customer_autogiro_amount_onetime"),

			checkbox_autogiro_onetime: customer.getData("checkbox_autogiro_onetime") ? "Ja" : "Nej",
			checkbox_autogiro_recurring: customer.getData("checkbox_autogiro_recurring") ? "Ja" : "Nej",

			customer_autogiro_bank: customer.getData("customer_autogiro_bank"),
			customer_autogiro_bank_clearingno: customer.getData("customer_autogiro_bank_clearingno"),
			customer_autogiro_bankno: customer.getData("customer_autogiro_bankno"),
			customer_autogiro_amount_quantity: customer.getData("customer_autogiro_amount_quantity"),

			radio_autogiro_recurring: customer.getData("radio_autogiro_recurring"),
			house_bgno: customer.getData("house_bgno"),
			customer_accountno_fair: customer.getData("customer_accountno_fair"),
			radio_new_isk: customer.getData("radio_new_isk"),

			recurringIndex: (
				customer.getData("radio_autogiro_recurring") === "monthly" ? 0 :
					customer.getData("radio_autogiro_recurring") === "quarterly" ? 1 :
						customer.getData("radio_autogiro_recurring") === "half-yearly" ? 2 :
							customer.getData("radio_autogiro_recurring") === "yearly" ? 3 :
								-1),

			bgIndex: (
				customer.getData("house_bgno") === "876-8228" ? 0 :
					customer.getData("house_bgno") === "5498-3614" ? 1 :
						-1)
		}
	};

	if (initialState.customerState.customer_autogiro_amount_recurring &&
		initialState.customerState.customer_autogiro_amount_recurring.length > 0) {
		initialState.customerState.checkbox_autogiro_recurring = "Ja";
	}

	if (initialState.customerState.customer_autogiro_amount_onetime &&
		initialState.customerState.customer_autogiro_amount_onetime.length > 0) {
		initialState.customerState.checkbox_autogiro_onetime = "Ja";
	}

	return initialState;
}

function _getFields() {
	return [
		"radio_customer_autogiro",
		"customer_autogiro_amount_recurring",
		"customer_autogiro_amount_onetime",
		"radio_autogiro_recurring",
		"radio_new_isk",
		"house_bgno",
		"customer_accountno_fair",

		"customer_autogiro_bank",
		"customer_autogiro_bank_clearingno",
		"customer_autogiro_bankno",
		"customer_autogiro_amount_quantity"
	];
}