import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./FinancialSituation2.fulfillment";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import TextView from "../../../../../components/TextView/TextView";
import NumberField from "../../../components/NumberField/NumberField";
import i18n from "@helpers/i18n";

export default class FinancialSituation2 extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render() {
		const {customerState} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("financial_situation", "financial_situation")}</h2>

					<div className="Cob-view2-fields">

						<h3 style={{marginBottom: "20px"}}>{i18n("financial_situation", "financial_assets")}</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "financial_investments")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_assets_financial"}
											 value={customerState.situation_assets_financial}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_assets_financial: target.value})} />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "liquid_assets")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_assets_liquid"}
											 value={customerState.situation_assets_liquid}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_assets_liquid: target.value})} />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "pension_savings")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_assets_pension"}
											 value={customerState.situation_assets_pension}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_assets_pension: target.value})} />							</div>

							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "other_assets")}</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_assets_other"}
											 value={customerState.situation_assets_other}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_assets_other: target.value})} />
							</div>

						</StackLayout>

						<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "comment")}</h4>
						<TextView
							name="situation_assets_comment"
              label={`${i18n("general", "free_text")} (${800 - customerState.situation_assets_comment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.situation_assets_comment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_assets_comment: target.value})}
							fullWidth
							minHeight={120}
						/>

						<div className="h-line spacing" style={{margin: "40px 0"}}/>

						<h3 style={{marginBottom: "20px"}}>{i18n("financial_situation", "fixed_assets")}</h3>

						<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "residential_property")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_assets_fixed_home"}
											 value={customerState.situation_assets_fixed_home}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_assets_fixed_home: target.value})} />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "other_fixed_assets")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_assets_fixed_other"}
											 value={customerState.situation_assets_fixed_other}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_assets_fixed_other: target.value})} />
							</div>

						</StackLayout>

						<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "comment")}</h4>
						<TextView
							name="situation_assets_fixed_comment"
              label={`${i18n("general", "free_text")} (${800 - customerState.situation_assets_fixed_comment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.situation_assets_fixed_comment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_assets_fixed_comment: target.value})}
							fullWidth
							minHeight={120}
						/>

						<div className="h-line spacing" style={{margin: "40px 0"}}/>

						<h3 style={{marginBottom: "20px"}}>{i18n("financial_situation", "liabilities")}</h3>

						<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth>
							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "residential_property")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_debts_mortgage"}
											 value={customerState.situation_debts_mortgage}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_debts_mortgage: target.value})} />
							</div>

							<div>
								<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "other_liabilities")}*</h4>
								<NumberField label={i18n("general", "provide_amount")}
											 unit=" kr"
											 name={"situation_debts_other"}
											 value={customerState.situation_debts_other}
											 showSeparators={true}
											 validation={value => (value.length > 0)}
											 onChange={({target}) => this.setCustomerState({situation_debts_other: target.value})} />
							</div>

						</StackLayout>

						<h4 style={{marginTop: "0"}}>{i18n("financial_situation", "comment")}</h4>
						<TextView
							name="situation_debts_comment"
              label={`${i18n("general", "free_text")} (${200 - customerState.situation_debts_comment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.situation_debts_comment}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({situation_debts_comment: target.value})}
							fullWidth
							minHeight={90}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		isFulfilled: false,
		customerState: {
			situation_assets_financial: customer.getData("situation_assets_financial"),
			situation_assets_liquid: customer.getData("situation_assets_liquid"),
			situation_assets_pension: customer.getData("situation_assets_pension"),
			situation_assets_other: customer.getData("situation_assets_other"),
			situation_assets_comment: customer.getData("situation_assets_comment") || "",

			situation_assets_fixed_home: customer.getData("situation_assets_fixed_home"),
			situation_assets_fixed_other: customer.getData("situation_assets_fixed_other"),
			situation_assets_fixed_comment: customer.getData("situation_assets_fixed_comment") || "",

			situation_debts_mortgage: customer.getData("situation_debts_mortgage"),
			situation_debts_other: customer.getData("situation_debts_other"),
			situation_debts_comment: customer.getData("situation_debts_comment") || "",
		}
	};
}

function _getFields() {
	return [
		"situation_assets_financial",
		"situation_assets_liquid",
		"situation_assets_pension",
		"situation_assets_other",
		"situation_assets_comment",

		"situation_assets_fixed_home",
		"situation_assets_fixed_other",
		"situation_assets_fixed_comment",

		"situation_debts_mortgage",
		"situation_debts_other",
		"situation_debts_comment",
	];
}