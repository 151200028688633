import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.risk_expected_return.length <= 0)
	{
		return false;
	}

	if (customerState.risk_expected_return === "Det finns ett definierat avkastningsmål" &&
		customerState.risk_defined_return.length <= 0)
	{
		return false;
	}

	if (customerState.risk_placement_horizon.length <= 0)
	{
		return false;
	}

	if (state.workflow !== "futur")
	{
		if (customerState.risk_investment_philosophy.length <= 0)
		{
			return false;
		}

		if (customerState.risk_affected_by_negative_news.length <= 0)
		{
			return false;
		}

		if (customerState.risk_affected_by_market_news.length <= 0)
		{
			return false;
		}

		if (customerState.risk_reaction_to_lower_returns.length <= 0)
		{
			return false;
		}

		if (customerState.risk_reaction_to_drawdown.length <= 0)
		{
			return false;
		}
	}

	if (customerState.risk_portfolio_compared.length <= 0)
	{
		return false;
	}

	return true;
}