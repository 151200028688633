import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") {
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.kyc_company_form.length <= 0) {
		return false;
	}

	if (customerState.kyc_company_business.length <= 0) {
		return false;
	}

	if (customerState.kyc_tax_country.length <= 0) {
		return false;
	}

	if (customerState.kyc_is_financial_institution.length <= 0) {
		return false;
	}

	if (!(customerState.kyc_is_active === "Aktivt" || customerState.kyc_is_active === "Passivt")) {
		return false;
	}

	if (customerState.kyc_has_auditor.length <= 0) {
		return false;
	}

	if (customerState.kyc_company_employee_count.length <= 0) {
		return false;
	}

	if (customerState.kyc_company_net_turnover.length <= 0) {
		return false;
	}

	if (!customerState.kyc_company_ownership_fulfilled) {
		return false;
	}


	// Purpose needs to be selected
	if (!(customerState.kyc_purpose_savings === "yes" ||
		customerState.kyc_purpose_security === "yes" ||
		customerState.kyc_purpose_pension === "yes" ||
		customerState.kyc_purpose_consumption === "yes" ||
		customerState.kyc_purpose_gift === "yes" ||
		customerState.kyc_purpose_beneficiary === "yes" ||
		customerState.kyc_purpose_other === "yes"))
	{
		return false;
	}

	// If other is checked the text needs to be entered as well
	if (customerState.kyc_purpose_other === "yes" && customerState.kyc_purpose_other_details.length <= 0)
	{
		return false;
	}

	// If not owner of funds we need to fill out the explanation
	if (customerState.kyc_owner_of_funds !== "Ja" && customerState.kyc_owner_of_funds_text.length <= 0)
	{
		return false;
	}

	// Source institution needs to be selected
	if (!(customerState.kyc_src_bank_sweden === "yes" ||
		customerState.kyc_src_insurance_sweden === "yes" ||
		customerState.kyc_src_bank_eu === "yes" ||
		customerState.kyc_src_insurance_eu === "yes" ||
		customerState.kyc_src_bank_world === "yes" ||
		customerState.kyc_src_insurance_world === "yes"))
	{
		return false;
	}

	if (customerState.kyc_first_deposit.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_first_deposit === "Över 5 000 000 kr")
	{
		let estimate = customerState.kyc_first_deposit_estimate;
		if (!estimate || estimate.length <= 0)
		{
			return false;
		}

		estimate = estimate.split(" ").join("");
		estimate = parseInt(estimate, 10);
		if (isNaN(estimate) || estimate <= 5000000)
		{
			return false;
		}
	}


	if (customerState.kyc_yearly_deposit.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_yearly_deposit === "Över 5 000 000 kr")
	{
		let estimate = customerState.kyc_yearly_deposit_estimate;
		if (!estimate || estimate.length <= 0)
		{
			return false;
		}

		estimate = estimate.split(" ").join("");
		estimate = parseInt(estimate, 10);
		if (isNaN(estimate) || estimate <= 5000000)
		{
			return false;
		}
	}

	if (customerState.kyc_transaction_size.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_transaction_size === "Över 5 000 000 kr")
	{
		let estimate = customerState.kyc_transaction_size_estimate;
		if (!estimate || estimate.length <= 0)
		{
			return false;
		}

		estimate = estimate.split(" ").join("");
		estimate = parseInt(estimate, 10);
		if (isNaN(estimate) || estimate <= 5000000)
		{
			return false;
		}
	}

	if (customerState.kyc_deposit_frequency.length <= 0)
	{
		return false;
	}

	if (customerState.kyc_withdrawal_frequency.length <= 0)
	{
		return false;
	}

	return true;
}