import {mergeViewStateForFulfillment} from "../../../Cob.helpers";
import {inArray} from "../../../../../helpers/utils";


export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.checkbox_advice_skip_placement === "yes")
	{
		return true;
	}

	if (customerState.checkbox_advice_riskinfo !== "Ja")
	{
		return false;
	}

	if (customerState.radio_advice_portfolio_risk.length <= 0)
	{
		return false;
	}

	if (customerState.radio_advice_riskpropensity.length <= 0)
	{
		return false;
	}

	if (customerState.checkbox_advice_savehoriz_placement.length <= 0)
	{
		return false;
	}

	if (customerState.radio_advice_needing_investment.length <= 0)
	{
		return false;
	}

	if (customerState.customer_kyc_purpose_savinggoal_description.length <= 0)
	{
		return false;
	}

	if (customerState.radio_investment_preferences.length <= 0)
	{
		return false;
	}

	if (customerState.radio_investment_preferences === "yes" && customerState.investment_preferences_text.length <= 0)
	{
		return false;
	}

	// Är kunden införstådd med risken?
	if (customerState.radio_advice_customer_riskrev.length <= 0 || customerState.radio_advice_customer_riskrev === 'no')
	{
		return false;
	}

	// Hållbarhetspreferenser needs to be checked
	if (!(customerState.radio_hallbarhet_has_preferences === "no" || customerState.radio_hallbarhet_has_preferences === "yes"))
	{
		return false;
	}

	if (customerState.radio_hallbarhet_has_preferences === "yes")
	{
		// If the customer has a preference regarding sustainability (Hållbarhet) then at least one of the checkboxes
		// needs to be checked.
		if (customerState.checkbox_hallbarhet_852 !== "yes" &&
			customerState.checkbox_hallbarhet_2088 !== "yes" &&
			customerState.checkbox_hallbarhet_customer !== "yes")
		{
			return false;
		}

		if (customerState.checkbox_hallbarhet_852 === "yes")
		{
			if (customerState.customer_hallbarhet_852_minimum.length === 0 ||
				customerState.customer_hallbarhet_852_minimum <= 0 ||
				customerState.customer_hallbarhet_852_minimum > 100)
			{
				return false;
			}
		}

		if (customerState.checkbox_hallbarhet_2088 === "yes")
		{
			if (customerState.customer_hallbarhet_2088_text.length === 0 ||
				customerState.customer_hallbarhet_2088_text <= 0 ||
				customerState.customer_hallbarhet_2088_text > 100)
			{
				return false;
			}
		}

		if (customerState.checkbox_hallbarhet_customer === "yes" &&
			customerState.hallbarhet_customer_text.length === 0)
		{
			return false;
		}

		// Beskrivning av kundens hållbarhetspreferenser
		if (customerState.customer_hallbarhet_customer_text.length <= 0)
		{
			return false;
		}

		if (customerState.checkbox_hallbarhet_considered.length <= 0)
		{
			return false;
		}

		if (customerState.checkbox_hallbarhet_considered === "no")
		{
			if (customerState.checkbox_hallbarhet_changed.length <= 0)
			{
				return false;
			}

			if (customerState.checkbox_hallbarhet_changed === "no" && customerState.customer_hallbarhet_not_considered_text.length <= 0)
			{
				return false;
			}
		}
	}

	/**
	 * Om risken vid detta tillfälle inte är lika med önskade risken för portföljen måste någon av anledningsrutorna
	 * kryssas.
	 */
	if (customerState.radio_advice_riskpropensity !== customerState.radio_advice_portfolio_risk)
	{
		if (customerState.checkbox_advice_invrisk_same !== "Ja" &&
			customerState.checkbox_advice_invrisk_changed !== "Ja" &&
			customerState.checkbox_advice_invrisk_other !== "Ja")
		{
			return false;
		}

		// Om man kryssat annat måste texten även fyllas i
		if (customerState.checkbox_advice_invrisk_other === 'Ja' && customerState.advice_invrisk_other_expl.length <= 0)
		{
			return false;
		}
	}

	return true;
}