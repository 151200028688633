import React from "react";
import moment from "moment";

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import Notice from "@components/Notice/Notice";
import TextField from "@components/TextField/TextField";
import Select from "@components/Select/Select";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import OptionsList from "../../components/OptionsList/OptionsList";

// HELPERS
import {createClassName, stringifyQueryParams} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from '@helpers/api';
import Validator from "@classes/Validator";

// OTHER
import fulfillment, {inputIsCompany} from "./Start.fulfillment";
import PageHeader from "./PageHeader";


export default class Start extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {display: false},
				back: {display: false},
				next: {label: "Påbörja", disabled: true}
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const {props, state} = this;
		const {setAppState, user, customer, navigationButtons, partner} = props;
		const {customerState} = state;
		const updatedStateProps = {
			selectedCaseId: prevState.customerState.selectedCaseId !== state.customerState.selectedCaseId,
			isNewCase: prevState.customerState.isNewCase !== state.customerState.isNewCase,
			standaloneApplicationsChecked: prevState.customerState.standaloneApplicationsChecked !== state.customerState.standaloneApplicationsChecked
		};
		const newCustomerState = {};
		const newAppStateProps = {};

		if (!!customer && prevProps.customer !== customer) {
			newCustomerState.isCompany = customer.isCompany;
			newCustomerState.ssnOrg = customer.getData("customer_social_security_number");
		}

		if (updatedStateProps.selectedCaseId || updatedStateProps.isNewCase || updatedStateProps.standaloneApplicationsChecked) {
			newAppStateProps.navigationButtons = {
				...navigationButtons,
				next: {
					...navigationButtons.next,
					disabled: !!customerState.selectedCaseId === customerState.isNewCase && customerState.standaloneApplicationsChecked.length === 0,
					willSubmitForm: customerState.standaloneApplicationsChecked.length === 0,
					onClick: customerState.standaloneApplicationsChecked.length === 0 ? undefined : () => {

						let standaloneApp = null;
						for (let i = 0; i < partner.standaloneApplications.length; i++) {
							if (partner.standaloneApplications[i].label === customerState.standaloneApplicationsChecked[0]) {
								standaloneApp = partner.standaloneApplications[i];
								break;
							}
						}

						if (standaloneApp === null) {
							console.error("Invalid app configuration", customerState.standaloneApplicationsChecked);
							return;
						}

						let partnerString = JSON.stringify({
							partner: user.getData(),
							customer: {id: customerState.ssnOrg}
						});

						/**
						 * GLOF-1017 If the partner name or data contains non-latin1 characters we'll get an error.
						 * A workaround for this is to encode the string before encoding the string...
						 */
						let b64 = btoa(unescape(encodeURIComponent(partnerString)));
						window.location.href = standaloneApp.url + stringifyQueryParams({
							context: b64,
							token: Settings.get("AUTH_TOKEN"),
							key: standaloneApp.key
						});
					}
				}
			};
		}

		if (Object.keys(newCustomerState).length > 0) {
			this.setState({isLoading: false}, () => this.setCustomerState(newCustomerState));
		}

		if (Object.keys(newAppStateProps).length > 0) {
			setAppState(newAppStateProps);
		}
	}

	render() {
		const {props, state} = this;
		const {user, advisor, partner, assentlyStatus} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view1", {});

		if (state.isLoading) {
			return <ActivityIndicator busy/>;
		}

		const nextStepOptions = [];
		if (user.hasPermission("case.create")) {
			nextStepOptions.push({
				label: "Skapa ett nytt rådgivningstillfälle",
				checked: customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === true
			});
		}

		if (user.hasPermission("case.edit")) {
			nextStepOptions.push({
				label: "Hämta ett tidigare rådgivningstillfälle",
				checked: customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === false
			});
		}

		/**
		 * Add links to all standalone applications (autogiro, kontoöppning, futur etc)
		 */
		for (let i = 0; i < partner.standaloneApplications.length; i++) {
			const standaloneApplication = partner.standaloneApplications[i];
			nextStepOptions.push({
				label: standaloneApplication.label, checked: customerState.standaloneApplicationsChecked.includes(standaloneApplication.label)
			});
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<PageHeader user={user} advisor={advisor} />

					<div className="Cob-view1-fields">
						<p className="Cob-description tightened">Ange kundens person-/organisationsnummer</p>

						<Checkbox
							label={i18n("cob", "customer_is_a_company")}
							checked={customerState.isCompany}
							onChange={isCompany => this.setCustomerState({isCompany})}
						/>
						<input
							type="hidden"
							name="checkbox_company"
							value={customerState.isCompany ? "yes" : "no"}
						/>

						<TextField
							type="number"
							name="customer_social_security_number"
							value={customerState.ssnOrg}
							label={i18n("cob", customerState.isCompany ? "customers_organizational_number" : "customers_social_security_number")}
							placeholder="12-siffrigt utan bindestreck"
							validation={this.ssnValidation(customerState)}
							onChange={this._onSsnOrgChange}
						/>

						<p className="Cob-miniNote">
							<strong>Exempel:</strong> <em>YYYYMMDDXXXX</em> eller <em>16XXXXXXXXXX</em>
						</p>

						{this.validateSsnInForm(customerState) && (
							<React.Fragment>
								<p className="Cob-description">Alternativ</p>

								<OptionsList
									type="radio"
									orientation="vertical"
									options={nextStepOptions}
									onChange={this._onCaseTypeSelectChange}
								/>

								{customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === false && (
									<div className="Cob-view1-cases">
										{!customerState.cases ? (
											<div className="loading">
												<ActivityIndicator color="#91a0ad" scale={0.75} busy/>
												<span>Laddar...</span>
											</div>
										) : (
											customerState.cases.length > 0 ? (
												<React.Fragment>
													<Select
														className="cases-select"
														name="caseId"
														label="Välj rådgivningstillfälle"
														renderOption={this._renderCaseOption}
														options={customerState.casesOptions}
														selectedIndex={customerState.selectedCaseIndex}
														customFilterer={this._casesSelectFilterer}
														onChange={this._onCasesSelectChange}
													/>

													{!!customerState.cases[customerState.selectedCaseIndex] &&
													!customerState.cases[customerState.selectedCaseIndex].isDraft &&
													!customerState.cases[customerState.selectedCaseIndex].isRecalled && (
														<Notice className="no-draft-notice" appearance="warning"
																message="Eftersom det valda rådgivningstillfället redan slutförts så kommer ett nytt förifyllt rådgivningstillfälle att skapas baserat på dessa uppgifter."/>
													)}

													{!!customerState.cases[customerState.selectedCaseIndex] && customerState.cases[customerState.selectedCaseIndex].isRecalled && (
														<Notice className="no-draft-notice" appearance="warning"
																message="Eftersom det valda rådgivningstillfället har återkallats så kommer ett nytt förifyllt rådgivningstillfälle att skapas baserat på dessa uppgifter."/>
													)}
												</React.Fragment>
											) : (
												<p className="empty">
													<em>Hittade inga tidigare rådgivningstillfällen.</em>
												</p>
											)
										)}
									</div>
								)}
							</React.Fragment>
						)}
					</div>

					{assentlyStatus && assentlyStatus.length > 0 && (
						<div style={{margin: "40px 0 0 0", background: "#FFF5F5", width: "100%", display: "block", padding: "20px", fontSize: "13px"}}>
							<strong style={{display: "block", padding: "0 0 5px 0"}}>Driftstörning Assently (digital signering)</strong>
							För närvarande påverkas följande system: {assentlyStatus.join(", ")} <br /><br />
							<a href="https://assently.statuspage.io/" target="_blank" rel="noopener noreferrer">För mer information, klicka här</a>
						</div>
					)}
				</ContentBox>
			</div>
		);
	}

	/**
	 * SSN numbers need to be 12 digits and start with 19 or 20
	 */
	ssnValidation(customerState) {
		return value => !isNaN(value) && (
			(
				Validator.validateSsn(value)
			)
			||
			(
				customerState.isCompany && inputIsCompany(value)
			)
		);
	}

	validateSsnInForm(customerState) {
		return !isNaN(customerState.ssnOrg) && (
			(
				Validator.validateSsn(customerState.ssnOrg)
			)
			||
			(
				customerState.isCompany && inputIsCompany(customerState.ssnOrg)
			)
		);
	}

// Internal methods
	_onCasesSelectChange = ({value}, index) => this.setCustomerState({selectedCaseId: value, selectedCaseIndex: index});

	_onSsnOrgChange = ({target}) => this.setCustomerState({
		isCompany: inputIsCompany(target.value),
		ssnOrg: target.value,
		isNewCase: null,
		standaloneApplicationsChecked: [],
		casesLoading: false,
		cases: null,
		selectedCaseId: null,
		selectedCaseIndex: -1
	});

	_onCaseTypeSelectChange = (checked, value, index) => {
		const newStateProps = {
			isNewCase: index === 0,
			standaloneApplicationsChecked: []
		};

		if (index === 0) {
			newStateProps.selectedCaseId = null;
			newStateProps.selectedCaseIndex = -1;
		}
		else if (index > 1) {
			const applicationIndex = index - 2;
			const applications = this.props.partner.standaloneApplications;
			const app = applications[applicationIndex];
			newStateProps.standaloneApplicationsChecked.push(app.label);
		}

		this.setCustomerState(newStateProps, () => {
			if (index === 1 && !this.state.customerState.cases) {
				_getCustomerCases.call(this);
			}
		});
	};

	_casesSelectFilterer = ({option}, inputValue) => {
		inputValue = inputValue.toLowerCase();
		return option.label.indexOf(inputValue) === 0 || option.value.indexOf(inputValue) === 0;
	};

	_renderCaseOption = ({label, isDraft, isRecalled}) => {
		return (
			<div className="Cob-view1-caseOption">
				<div className="label">{label}</div>

				{isRecalled && (
					<div className="info recalled">Återkallat</div>
				)}

				{isDraft && !isRecalled && (
					<div className="info draft">Utkast</div>
				)}
			</div>
		);
	};
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		isLoading: !customer,
		isFulfilled: false,
		customerState: {
			isCompany: customer ? customer.isCompany : false,
			ssnOrg: (!!customer && customer.getData("customer_social_security_number")) || "",
			isNewCase: null,
			casesLoading: false,
			cases: null,
			selectedCaseId: null,
			selectedCaseIndex: -1,
			standaloneApplicationsChecked: []
		}
	};
}

function _getFields() {
	return [
		"checkbox_company",
		"customer_social_security_number"
	];
}

function _getCustomerCases() {
	return new Promise((resolve) => {
		this.setCustomerState({casesLoading: true}, async () => {
			try {
				const {props, state} = this;
				const {user} = props;
				const {customerState} = state;

				const cases = await api("/case/customer-cases", {
					partnerSsn: user.ssn,
					customerId: customerState.ssnOrg
				});

				/**
				 * Sort cases according to when they were last modified
				 */
				cases.sort(function (a, b) {
					return b.createdOn - a.createdOn;
				});

				const casesOptions = cases.map(({createdOn, caseId, isDraft, isRecalled}) => ({
					label: moment(createdOn).format("YYYY-MM-DD H:mm"),
					value: caseId,
					title: caseId,
					caseId,
					isDraft,
					isRecalled
				}));

				this.setCustomerState({cases, casesOptions, casesLoading: false}, () => resolve());
			}
			catch (e) {
				console.error(e.message);
				resolve();
			}
		});
	});
}