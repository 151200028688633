import { useState, useEffect } from "react";

// HELPERS
import api from "@helpers/api";

// HOOKS
import useResponsive from "@hooks/useResponsive";

const useCustomersTable = props => {
  const isMobileOrTablet = useResponsive("down", "md");
  const { partner, advisor, customers, showHeader, setCustomerState, caseFilterContext, nextStepOptions } = props;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [copyList, setCopyList] = useState([]);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const updateCustomers = customer => {
    const index = customers.findIndex(c => c.id === customer.id);
    customers[index] = customer;
    // sort alphabetically
    customers.sort((a, b) =>
      a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
    );
    return setCopyList(customers);
  };

  const requestSearch = searched => {
    if (page > 0) setPage(0);
    const searchString = searched.toLowerCase();
    setCopyList(
      customers.filter(
        customer =>
          (customer.first_name + " " + customer.last_name)
            .toLowerCase()
            .includes(searchString) ||
          customer.id?.toLowerCase().includes(searchString) ||
          customer.name?.toLowerCase().includes(searchString)
      )
    );
  };

  const handleClientClick = client => async e => {
    e.preventDefault();
    setCustomerState({
      isCompany: false,
      ssnOrg: (client.id).replace("-", ""),
      isNewCase: null,
      standaloneApplicationsChecked: [],
      needsNewDocumentation: false,
      kycCaseId: null,
      kycInfoLoading: false,
      overrideNewDocumentation: false,
      casesLoading: false,
      cases: null,
      selectedCaseId: null,
      selectedCaseIndex: -1
    }, () =>
		{
      setSelectedClient(client);
      document.documentElement.style.overflow = "hidden";
		});
  };

  const handleClientModalClose = () => {
    setCustomerState({
      isCompany: false,
      ssnOrg: "",
    }, () =>
		{
      setSelectedClient(null);
		});
  };

  useEffect(() => {
    if (customers) setCopyList(customers);
  },[customers]);

  return {
    isMobileOrTablet,
    partner,
    advisor,
    showHeader,
    copyList,
    page,
    setPage,
    rowsPerPage,
    selectedClient,
    setRowsPerPage,
    handleChangePage,
    requestSearch,
    handleClientClick,
    handleClientModalClose,
    updateCustomers,
    caseFilterContext,
    nextStepOptions,
    setCustomerState
  };
};

export default useCustomersTable;