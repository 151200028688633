import React from "react";

// CLASSES
import Validator from "@classes/Validator";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import Button from "@components/Button/Button";
import Checkbox from "@components/Checkbox/Checkbox";
import CompanyReps from "./subcomponents/CompanyReps/CompanyReps";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import Modal from "@components/Modal/Modal";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import TextField from "@components/TextField/TextField";

// HELPERS
import {createClassName, loop} from "@helpers/utils";
import i18n from "@helpers/i18n";

// OTHER
import fulfillment from "./CustomerInformation.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import PartnerHiddenFields from "../../../components/PartnerHiddenFields/PartnerHiddenFields";

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		isFulfilled: false,
		isLoading: true,
		lookupNotFound: false,
		fraudFlag: false,
		errorMessage: null,
		customerState: {
			isCompany: customer.isCompany,
			date: customer.getData("date") || "",
			ssnOrg: customer.getData("customer_social_security_number"),
			firstName: customer.getData("customer_firstname"),
			lastName: customer.getData("customer_lastname"),
			street: customer.getData("customer_address_street"),
			zipCode: customer.getData("customer_address_zipcode"),
			city: customer.getData("customer_address_city"),
			phoneNumberHome: customer.getData("customer_phonenumber_home"),
			email: customer.getData("customer_email"),

			child_caretaker: customer.getData("child_caretaker"),

			leiCode: customer.getData("customer_lei"),
			companyReps: customer.companyReps,
			companyRepsActiveAmount: Math.max(customer.companyReps.reduce((a, b) => a + (b.firstName ? 1 : 0), 0), 1),

			isForeigner: false,
			isNonEuPerson: false,
			isUsPerson: customer.getData("radio_customer_us_person", null),
			checkbox_partner_vp: customer.getData("checkbox_partner_vp") ? "yes" : "no",
			checkbox_partner_fft: customer.getData("checkbox_partner_fft") ? "yes" : "no",
			isPep: customer.getData("radio_customer_pep", null),
			isPepPast: customer.getData("radio_customer_pep_past", null),

			caretaker_name_1: customer.getData("caretaker_name_1"),
			caretaker_ssn_1: customer.getData("caretaker_ssn_1"),
			caretaker_name_2: customer.getData("caretaker_name_2"),
			caretaker_ssn_2: customer.getData("caretaker_ssn_2"),
			caretaker_name_3: customer.getData("caretaker_name_3"),
			caretaker_ssn_3: customer.getData("caretaker_ssn_3"),

			customer_inv_poa_firstname: customer.getData("customer_inv_poa_firstname"),
			customer_inv_poa_lastname: customer.getData("customer_inv_poa_lastname"),
			customer_inv_poa_ssn: customer.getData("customer_inv_poa_ssn"),

			customer_inv_poa_firstname_2: customer.getData("customer_inv_poa_firstname_2"),
			customer_inv_poa_lastname_2: customer.getData("customer_inv_poa_lastname_2"),
			customer_inv_poa_ssn_2: customer.getData("customer_inv_poa_ssn_2"),

			customer_inv_poa_firstname_3: customer.getData("customer_inv_poa_firstname_3"),
			customer_inv_poa_lastname_3: customer.getData("customer_inv_poa_lastname_3"),
			customer_inv_poa_ssn_3: customer.getData("customer_inv_poa_ssn_3"),

			customer_inv_poa_firstname_4: customer.getData("customer_inv_poa_firstname_4"),
			customer_inv_poa_lastname_4: customer.getData("customer_inv_poa_lastname_4"),
			customer_inv_poa_ssn_4: customer.getData("customer_inv_poa_ssn_4")

		}
	};

	/**
	 * Check if the person is a minor. If so, we will need to enter their guardians.
	 */
	let needsGuardianToSign = false;
	if (newState.customerState.ssnOrg.startsWith("20")) {
		const dateOfBirthYear = newState.customerState.ssnOrg.substring(0, 4);
		const dateOfBirthMonth = newState.customerState.ssnOrg.substring(4, 6);
		const dateOfBirthDay = newState.customerState.ssnOrg.substring(6, 8);
		const dateOfBirth = new Date(dateOfBirthYear + "-" + dateOfBirthMonth + "-" + dateOfBirthDay);

		const date18yearsAgo = new Date();
		date18yearsAgo.setFullYear(date18yearsAgo.getFullYear() - 18);

		if (dateOfBirth > date18yearsAgo) {
			needsGuardianToSign = true;
		}
	}

	newState.customerState.needsGuardian = !customer.isCompany && needsGuardianToSign;

	if (newState.customerState.date === "") {
		const today = new Date();
		let dayDate = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
		let monthDate = (today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		newState.customerState.date = today.getFullYear() + '-' + monthDate + '-' + dayDate;
	}

	return newState;
}

export default class CustomerInformation extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	setLookupInfo(lookupInfo) {
		const {props, state} = this;
		const {customerState} = state;
		const {isCompany} = props.customer;

		const clientInfoPhone = lookupInfo.client.phone && lookupInfo.client.phone !== "-" ? lookupInfo.client.phone : "";

		this.setCustomerState({
			firstName: customerState.firstName || lookupInfo[isCompany ? "companyName" : "firstName"] || "",
			lastName: isCompany ? "" : (customerState.lastName || lookupInfo.lastName || ""),
			street: customerState.street || lookupInfo.street || "",
			zipCode: customerState.zipCode || lookupInfo.zipCode || "",
			city: customerState.city || lookupInfo.city || "",
			email: customerState.email || lookupInfo.email || lookupInfo.client.email || "",
			phoneNumberHome: customerState.phoneNumberHome || lookupInfo.phoneNumberHome || clientInfoPhone || "",
			leiCode: customerState.leiCode || lookupInfo.client.lei_code || "",
		});
	}

	async componentDidMount() {
		const {props} = this;
		const {customer, history, setAppState} = props;
		const ssnOrg = customer.getData("customer_social_security_number", null);

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {disabled: true},
				next: {
          onClick: () => {
            if (this.state.isFulfilled === true) {
              return;
            }
            
            this.setState({hasFormValidationErrors: true});
            return setAppState({
              showValidationWarning: true
            });
          }
        }
			}
		});

		if (!!ssnOrg) {
			if (!customer.lookupInfo) {
				try {
					await customer.lookupAsync();
					const lookupNotFound = customer.lookupInfo.type === undefined;
					this.setState({isLoading: false, lookupNotFound}, () => this.setLookupInfo(customer.lookupInfo));
				}
				catch (e) {
					if (e.message === 'FRAUD_FLAG') {
						console.warn("Customer information locked. Case can not be completed.");
						this.setState({isLoading: false, lookupNotFound: true, fraudFlag: true});
					}
					else {
						console.warn("Customer information not found. Information must be entered manually.");
						this.setState({isLoading: false, lookupNotFound: true, fraudFlag: false});
					}
				}
			}
			else {
				this.setState({isLoading: false}, () => this.setLookupInfo(customer.lookupInfo));
			}
		}
		else {
			history.push("/cob/1");
		}
	}

	render() {
		const {props, state} = this;
		const {user, customer, onRestartSession} = props;
		const {customerState, lookupNotFound, hasFormValidationErrors} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		if (state.isLoading) {
			return <ActivityIndicator busy/>;
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>
				<PartnerHiddenFields customer={customer} user={user} />
				<input type="hidden" name="customer_phonenumber_home" value={customerState.phoneNumberHome || "-"}/>
				<input type="hidden" name="company_city_hq" value=""/>
				<input type="hidden" name="radio_meeting_type" value=""/>
				<input type="hidden" name="radio_customer_type" value={customer.isCompany ? "company" : "person"}/>

				<Modal visible={state.fraudFlag} status="danger" title="Personnummer låst">
					<React.Fragment>
						<p>
							Kundens personnummer är låst via en extern ID-spärr.
							Vänligen kontakta Glofitech.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label="Avsluta rådgivning" appearance="primary" onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<Modal visible={customerState.isUsPerson === "yes"} status="danger" title="FATCA">
					<React.Fragment>
						{!!state.errorMessage && <p>{state.errorMessage}</p>}

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  isUsPerson: this.state.customerState.isUsPerson === "yes" ? null : this.state.customerState.isUsPerson
										  })}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary" onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<Modal visible={customerState.isPep === "yes" || customerState.isPepPast === "yes"} status="danger" title="PEP">
					<React.Fragment>
						<p>
							Om kund eller eventuell huvudman är eller har varit person i politiskt utsatt ställning,
							alternativt har någon närstående eller medarbetare i politiskt utsatt ställning, behöver
							detta hanteras manuellt av Glofitech.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  isPep: this.state.customerState.isPep === "yes" ? null : this.state.customerState.isPep,
											  isPepPast: this.state.customerState.isPepPast === "yes" ? null : this.state.customerState.isPepPast
										  })}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary" onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>{i18n("cob", "customer_information")}</h2>

					<div className="Cob-view2-fields">
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name="date"
									value={customerState.date}
									autocomplete="nope"
									label="Datum för rådgivningen"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({date: target.value})}
									highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>

						<div className="h-line spacing"/>

						<h3>Grunduppgifter</h3>

						{lookupNotFound && !customer.isCompany && (
							<React.Fragment>
								<StackLayout orientation="vertical" alignItems="start">
									<Checkbox
										name="checkbox_customer_foreigner"
										label={i18n("cob", "lives_overseas_foreign_citizen")}
										checked={customerState.isForeigner}
										onChange={isForeigner => this.setCustomerState({isForeigner})}
									/>
									{customerState.isForeigner && (
										<Checkbox
											style={{marginTop: 10}}
											label="Är bosatt i EU"
											checked={customerState.isNonEuPerson}
											onChange={
												isNonEuPerson => this.setState({
													errorMessage: "För hantering av personer bosatta utanför EU, var god kontakta backoffice."
												}, () => this.setCustomerState({isNonEuPerson}))
											}
										/>
									)}
								</StackLayout>
							</React.Fragment>
						)}

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>

							<div>
								<TextField
									name="customer_firstname"
									value={customerState.firstName}
									autocomplete="nope"
									label={i18n("general", customer.isCompany ? "organization_name" : "first_name")}
									validation={lookupNotFound ? value => value.length > 0 : undefined}
									onChange={lookupNotFound ? ({target}) => this.setCustomerState({firstName: target.value}) : undefined}
									readOnly={!lookupNotFound}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>

							{!customer.isCompany && (
								<div>
									<TextField
										name="customer_lastname"
										value={customerState.lastName}
										autocomplete="nope"
										label={i18n("general", "last_name")}
										validation={lookupNotFound ? value => value.length > 0 : undefined}
										onChange={lookupNotFound ? ({target}) => this.setCustomerState({lastName: target.value}) : undefined}
										readOnly={!lookupNotFound}
                    highlightErrors={hasFormValidationErrors}
									/>
								</div>
							)}

							<div>
								<TextField
									name="customer_social_security_number"
									autocomplete="nope"
									value={customerState.ssnOrg}
									label={i18n("general", customer.isCompany ? "organization_number" : "social_security_number")}
									readOnly
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>

							{customer.isCompany && (
								<div>
									<TextField
										name="customer_lei"
										label="LEI-kod (20 tecken)"
										autocomplete="nope"
										value={customerState.leiCode}
										validation={Validator.validateLeiCode}
										onChange={({target}) => this.setCustomerState({leiCode: target.value})}
                    highlightErrors={hasFormValidationErrors}
									/>
								</div>
							)}
						</StackLayout>
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name="customer_address_street"
									value={customerState.street}
									label={i18n("general", "street_address")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({street: target.value})}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_zipcode"
									value={customerState.zipCode}
									label={i18n("general", "zip_code")}
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({zipCode: target.value})}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_city"
									value={customerState.city}
									label="Ort"
									autocomplete="nope"
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({city: target.value})}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
							<div>
								<TextField
									name="customer_phonenumber_home_input"
									value={customerState.phoneNumberHome === "-" ? "" : customerState.phoneNumberHome}
									label={"Telefon"}
									autocomplete="nope"
									validation={customerState.phoneNumberHome && customerState.phoneNumberHome !== "-" ? Validator.validatePhoneNum : false}
									placeholder={"+46 XX XXXXXX"}
									onChange={({target}) => this.setCustomerState({phoneNumberHome: target.value === "" ? "-" : target.value})}
                  highlightErrors={hasFormValidationErrors}
                  
								/>
							</div>
							<div>
								<TextField
									type="email"
									name="customer_email"
									value={customerState.email}
									autocomplete="nope"
									label={i18n("general", "email_address")}
									validation={Validator.validateEmail}
									onChange={({target}) => this.setCustomerState({email: target.value})}
                  highlightErrors={hasFormValidationErrors}
								/>
							</div>
						</StackLayout>


						{customer.isCompany && (

							<React.Fragment>
								<input type="hidden" name="checkbox_customer_id_poa" value="yes"/>
								<input type="hidden" name="checkbox_customer_Certificate_of_registration" value="yes"/>

								<div className="h-line spacing"/>

								<CompanyReps
									reps={customerState.companyReps}
									onRepChange={this._onCompanyRepChange}
									activeAmount={customerState.companyRepsActiveAmount}
                  highlightErrors={hasFormValidationErrors}
								/>

								{!this._hasAtLeastOneSignatory(customerState.companyReps) && (
									<div style={{
										padding: "20px",
										background: "#FFECEC",
										borderRadius: "3px",
										maxWidth: "500px",
										margin: "0 0 20px 0"
									}}>
										Åtminstone en firmatecknare måste väljas.
									</div>
								)}

								{customerState.companyRepsActiveAmount <= 6 && (
									<StackLayout fullWidth>
										<Button
											size="small"
											label={i18n("general", "remove_last")}
											appearance={customerState.companyRepsActiveAmount <= 1 ? "medium" : "danger"}
											disabled={customerState.companyRepsActiveAmount <= 1}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount - 1})}
										/>
										<Button
											size="small"
											label={i18n("general", "add")}
											appearance={customerState.companyRepsActiveAmount >= 6 ? "medium" : "primary"}
											disabled={customerState.companyRepsActiveAmount >= 6}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount + 1})}
											filled
										/>
									</StackLayout>
								)}

							</React.Fragment>
						)}

						{customerState.needsGuardian && (
							<React.Fragment>
								<div className="h-line spacing"/>

								<h3>Uppgift om vårdnadshavare</h3>
								<p style={{paddingBottom: "10px"}}>
									Då personen är minderårig måste vårdnadshavare anges.
								</p>

								<OptionsList
									style={{paddingBottom: "0", marginBottom: "0"}}
									type="radio"
									name="child_caretaker"
                  value={customerState.child_caretaker}
									options={[
										{
											label: "Ensam vårdnadshavare",
											value: "alone",
											checked: customerState.child_caretaker === "alone"
										},
										{
											label: "Två vårdnadshavare",
											value: "two",
											checked: customerState.child_caretaker === "two"
										}
									]}
									onChange={(checked, child_caretaker) => this.setCustomerState({child_caretaker})}
                  validation={this._optionsValidation}
                  highlightErrors={hasFormValidationErrors}
								/>
							</React.Fragment>
						)}

						{customerState.needsGuardian && customerState.child_caretaker === "alone" && (
							<React.Fragment>
								<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
									<div>
										<h4>Vårdnadshavare</h4>

										<TextField
											name="caretaker_name_1"
											label="Namn"
											autocomplete="nope"
											value={customerState.caretaker_name_1}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({caretaker_name_1: target.value})}
											style={{marginBottom: "10px"}}
                      highlightErrors={hasFormValidationErrors}
										/>

										<TextField
											name="caretaker_ssn_1"
											label="Personnr"
											autocomplete="nope"
											value={customerState.caretaker_ssn_1}
											validation={this._ssnValidation}
											onChange={({target}) => this.setCustomerState({caretaker_ssn_1: target.value})}
                      highlightErrors={hasFormValidationErrors}
										/>

										<p style={{
											padding: "10px",
											lineHeight: "22px",
											background: "#ffffcc"
										}}>
											Intyg om ensam vårdnadshavare kommer behöva bifogas på sidan &quot;Övriga dokument&quot;.
										</p>

										<input type="hidden" name="child_caretaker_intyg" value="yes" />
									</div>
								</StackLayout>
							</React.Fragment>
						)}

						{customerState.needsGuardian && customerState.child_caretaker === "two" && (
							<React.Fragment>
								<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
									<div>
										<h4>Vårdnadshavare 1</h4>

										<TextField
											name="caretaker_name_2"
											label="Namn"
											autocomplete="nope"
											value={customerState.caretaker_name_2}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({caretaker_name_2: target.value})}
											style={{marginBottom: "10px"}}
                      highlightErrors={hasFormValidationErrors}
										/>

										<TextField
											name="caretaker_ssn_2"
											label="Personnr"
											autocomplete="nope"
											value={customerState.caretaker_ssn_2}
											validation={this._ssnValidation}
											onChange={({target}) => this.setCustomerState({caretaker_ssn_2: target.value})}
                      highlightErrors={hasFormValidationErrors}
										/>
									</div>
									<div>
										<h4>Vårdnadshavare 2</h4>

										<TextField
											name="caretaker_name_3"
											label="Namn"
											autocomplete="nope"
											value={customerState.caretaker_name_3}
											validation={this._textValidation}
											onChange={({target}) => this.setCustomerState({caretaker_name_3: target.value})}
											style={{marginBottom: "10px"}}
                      highlightErrors={hasFormValidationErrors}
										/>

										<TextField
											name="caretaker_ssn_3"
											label="Personnr"
											autocomplete="nope"
											value={customerState.caretaker_ssn_3}
											validation={this._ssnValidation}
											onChange={({target}) => this.setCustomerState({caretaker_ssn_3: target.value})}
                      highlightErrors={hasFormValidationErrors}
										/>
									</div>
								</StackLayout>
							</React.Fragment>
						)}

						<div className="h-line spacing"/>

						<div className="blockingQuestions">
							<h3>FATCA</h3>
							<OptionsList
								style={{paddingBottom: "15px"}}
								type="radio"
								name="radio_customer_us_person"
                value={customerState.isUsPerson}
								description="Har kunden eller eventuellt förekommande verklig huvudman bosättning, green-card, skattskyldighet, adress i eller annan liknande anknytning till USA?"
								options={[
									{
										label: i18n("general", "no"),
										value: "no",
										checked: customerState.isUsPerson === "no"
									},
									{
										label: i18n("general", "yes"),
										value: "yes",
										checked: customerState.isUsPerson === "yes"
									}
								]}
                validation={this._optionsValidation}
								onChange={
									(checked, isUsPerson) => this.setState({
										errorMessage: isUsPerson === "yes" ? "Personer som lyder under FATCA måste hanteras manuellt. Vänligen kontakta Glofitech." : null
									}, () => this.setCustomerState({isUsPerson}))
								}
                highlightErrors={hasFormValidationErrors}
							/>


							{!customer.isCompany && (
								<React.Fragment>
									<div className="h-line spacing"/>

									<h3>PEP</h3>

									<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
										<div>
											<OptionsList
												type="radio"
												name="radio_customer_pep"
                        value={customerState.isPep}
												description={"Är kunden, en närstående till kunden, eller någon av kundens medarbetare en person i politiskt utsatt ställning (PEP)?"}
												options={[
													{
														label: i18n("general", "no"),
														value: "no",
														checked: customerState.isPep === "no"
													},
													{
														label: i18n("general", "yes"),
														value: "yes",
														checked: customerState.isPep === "yes"
													}
												]}
                        validation={this._optionsValidation}
												onChange={(checked, isPep) => this.setCustomerState({isPep})}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
										<div>
											<OptionsList
												type="radio"
												name="radio_customer_pep_past"
                        value={customerState.isPepPast}
												description={"Har kunden, en närstående till kunden, eller någon av kundens medarbetare varit en person i politiskt utsatt ställning (PEP)?"}
												options={[
													{
														label: i18n("general", "no"),
														value: "no",
														checked: customerState.isPepPast === "no"
													},
													{
														label: i18n("general", "yes"),
														value: "yes",
														checked: customerState.isPepPast === "yes"
													}
												]}
                        validation={this._optionsValidation}
												onChange={(checked, isPepPast) => this.setCustomerState({isPepPast})}
                        highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>
								</React.Fragment>
							)}

						</div>
					</div>
				</ContentBox>
			</div>
		);
	}

  // Internal methods
	_textValidation = (value) => (value.length > 0);

	_optionsValidation = (value) => ((value && value.length > 0) || false);

	/**
	 * SSN numbers need to be 12 digits and start with 19 or 20
	 */
	_ssnValidation = (value) => (!isNaN(value) && value.length === 12 &&
		(value.substring(0, 2) === "19" || value.substring(0, 2) === "20")
	);

	_hasAtLeastOneSignatory(companyReps) {
		for (let i = 0; i < companyReps.length; i++) {
			const rep = companyReps[i];
			if (rep.types.length > 0 && rep.types.includes("signatory")) {
				return true;
			}
		}
		return false;
	}

	_onCompanyRepChange = (rep, index) => {
		const companyReps = this.state.customerState.companyReps.map((r, i) => i === index ? rep : r);
		this.setCustomerState({companyReps});
	};
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

function _getFields() {
	return [
		"customer_social_security_number",
		"customer_firstname",
		"customer_lastname",
		"customer_address_street",
		"customer_address_zipcode",
		"customer_address_city",
		"customer_phonenumber_home",
		"customer_phonenumber_cell",
		"customer_email",
		"customer_lei",
		"radio_customer_pep",
		"radio_customer_pep_past",
		"customer_inv_poa_firstname",
		"customer_inv_poa_lastname",
		"customer_inv_poa_ssn",
		"customer_inv_poa_firstname_2",
		"customer_inv_poa_lastname_2",
		"customer_inv_poa_ssn_2",
		"customer_inv_poa_firstname_3",
		"customer_inv_poa_lastname_3",
		"customer_inv_poa_ssn_3",
		"customer_inv_poa_firstname_4",
		"customer_inv_poa_lastname_4",
		"customer_inv_poa_ssn_4",
		"child_caretaker",

		"caretaker_name_1",
		"caretaker_ssn_1",
		"child_caretaker_intyg",

		"caretaker_name_2",
		"caretaker_ssn_2",

		"caretaker_name_3",
		"caretaker_ssn_3",


		// Get company reps
		...(() => {
			const returnValue = [];
			loop(6, i => {
				returnValue.push("checkbox_signatory_" + i);
				returnValue.push("checkbox_ubo_" + i);
				returnValue.push("checkbox_poa_" + i);
				returnValue.push("checkbox_board_" + i);
				returnValue.push("checkbox_mng_" + i);
				returnValue.push("customer_representative_firstname_" + i);
				returnValue.push("customer_representative_lastname_" + i);
				returnValue.push("customer_representative_social_security_number_" + i);
				returnValue.push("customer_representative_percentage_" + i);
			});
			return returnValue;
		})()

	];
}