// ----------------------------------------------------------------------

export default function TableCell() {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          verticalAlign: "middle"
        }
      }
    }
  };
}
