import React from "react";

import imgCloud from "@assets/img/ic_cloud.png";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ContentBox from "@components/ContentBox/ContentBox";
import FileDrop from "../../../../components/FileDrop/FileDrop";
import Button from "../../../../components/Button/Button";
import {DocsList} from "../../../../components/DocsList/DocsList";
import TextField from "../../../../components/TextField/TextField";
import api from '@helpers/api';
import Validator from "../../../../classes/Validator";
import TextView from "../../../../components/TextView/TextView";
import i18n from "@helpers/i18n";
import {formatSSN} from "@helpers/utils";

import IdentityNoInputField from "@cobComponents/IdentityNoInputField";

export default class UploadAndSign extends React.PureComponent {
  // TODO: maybe change how we set isCustomerModalCard later when we have a better understanding of the use cases
  isCustomerModalCard = this.props.widget && this.props.customer;

	state = _getInitialState.call(this);

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState
		});
	}

	render() {
		const {state, props, isCustomerModalCard} = this;
		const {customerState} = state;
		const {widget, inline} = props;

		let uploadFormIsDisabled = (
			state.docs.length <= 0 ||
			!Validator.validateSsn(customerState.customDocSsn, { personsOnly: true}) ||
			!Validator.validateEmail(customerState.customDocEmail) ||
			customerState.customDocName.length === 0 ||
			(customerState.customDocPhone.length > 0 && !Validator.validatePhoneNumSweden(customerState.customDocPhone))
		);

    const componentContent = () => {
      return (
        <React.Fragment>
          <FileDrop
            dropText={`${i18n("upload_and_sign", "drag_n_drop_here")}`}
            busyText={`${i18n("upload_and_sign", "loading_document")}...`}
            accept="application/pdf"
            disabled={state.isSendingCustomDoc}
            onDrop={this._onDocDrop}
            style={{minHeight: "141px"}}
          />
          {(state.docs.length > 0 || state.isUploadAreaExpanded || state.isSendingCustomDocSuccess) && (
            <React.Fragment>
              <DocsList
                items={state.docs}
                required={true}
                onDocRemove={this._onDocRemove}
              />


              {!isCustomerModalCard && (
                <>
                  <IdentityNoInputField
                    name="custom_doc_ssn"
                    value={customerState.customDocSsn}
                    label={`${i18n("upload_and_sign", "customer_personal_id_no")}*`}
                    placeholder="12-siffrigt utan bindestreck"
                    onChangeCallback={this._onIdentityNoChange}
                    disabled={state.isSendingCustomDoc}
                    style={{maxWidth: "unset", marginTop: "20px", marginBottom: widget ? "10px" : ""}}
                    personsOnly
                  />
      
                  <TextField
                    name="custom_doc_name"
                    value={customerState.customDocName}
                    label={`${i18n("upload_and_sign", "customer_name")}*`}
                    autocomplete="nope"
                    validation={value => value.length > 0}
                    onChange={({target}) => this.setState({
                      isSendingCustomDocSuccess: false,
                      isSendingCustomDocError: false,
                      customerState: {...this.state.customerState, customDocName: target.value}
                    })}
                    disabled={state.isSendingCustomDoc}
                    style={{maxWidth: "unset", marginTop: "0", marginBottom: widget ? "10px" : ""}}
                  />
      
                  <TextField
                    name="custom_doc_email"
                    value={customerState.customDocEmail}
                    label={`${i18n("upload_and_sign", "customer_email")}*`}
                    autocomplete="nope"
                    validation={Validator.validateEmail}
                    onChange={({target}) => this.setState({
                      isSendingCustomDocSuccess: false,
                      isSendingCustomDocError: false,
                      customerState: {...this.state.customerState, customDocEmail: target.value}
                    })}
                    disabled={state.isSendingCustomDoc}
                    style={{maxWidth: "unset", marginTop: "0", marginBottom: widget ? "10px" : ""}}
                  />
      
                  <TextField
                    name="custom_doc_phone"
                    value={customerState.customDocPhone}
                    label={`${i18n("upload_and_sign", "customer_phone")}*`}
                    autocomplete="nope"
                    placeholder={"+46XXXXXXXX"}
                    validation={Validator.validatePhoneNumSweden}
                    onChange={({target}) => this.setState({
                      isSendingCustomDocSuccess: false,
                      isSendingCustomDocError: false,
                      customerState: {...this.state.customerState, customDocPhone: target.value}
                    })}
                    disabled={state.isSendingCustomDoc}
                    style={{maxWidth: "unset", marginTop: "0", marginBottom: widget ? "10px" : ""}}
                  />
                </>
              )}

              {isCustomerModalCard && (
                <div 
                  style={{
                    color: "#000",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                    margin: "10px 0",
                    cursor: "pointer"                  
                  }}
                  onClick={this.expandAddMessageArea}
                >
                  <PlayArrowIcon/>
                  <a href={"#/"} style={{ marginLeft: "5px" }}>
                    {i18n("upload_and_sign", "add_message")}
                  </a>
                </div>
              )}

              {state.isAddMessageAreaExpanded && (
                <TextView
                  name="situation_assets_comment"
                  label={`${i18n("upload_and_sign", "message_to_customer")} (${300 - customerState.customDocMsg.length} ${i18n("general", "remaining_chars")})`}
                  value={customerState.customDocMsg}
                  maxLength={300}
                  validation={value => value.length > 0}
                  resize="vertical"
                  onChange={({target}) => this.setState({
                    isSendingCustomDocSuccess: false,
                    isSendingCustomDocError: false,
                    customerState: {...this.state.customerState, customDocMsg: target.value}
                  })}
                  disabled={state.isSendingCustomDoc}
                  fullWidth
                  minHeight={110}
                  style={{marginTop: isCustomerModalCard ? "0px" : "20px", marginBottom: widget ? "10px" : ""}}
                />              
              )}



              {state.isSendingCustomDocSuccess && (
                <p style={{
                  background: "#d7ffca",
                  padding: "10px",
                  width: "100%",
                  margin: "10px 0"
                }}>
                  <strong>{i18n("upload_and_sign", "done")}!</strong> {i18n("upload_and_sign", "sent_for_signing")}!
                </p>
              )}

              {state.isSendingCustomDocError && (
                <p style={{
                  background: "#ffe2e2",
                  padding: "10px",
                  width: "100%",
                  margin: "10px 0"
                }}>
                  <strong>Något gick snett!</strong> {state.isSendingCustomDocErrorMessage}
                </p>
              )}

              <Button
                className="Cob-Footer-button"
                style={{marginTop: "20px"}}
                label={i18n("upload_and_sign", "send_to_customer")}
                appearance={uploadFormIsDisabled ? "medium" : "secondary"}
                disabled={uploadFormIsDisabled}
                busy={state.isSendingCustomDoc}
                onClick={this._onCustomDocSend}
                filled
                fullWidth={widget}
              />

              </React.Fragment>
            )}
        </React.Fragment>
      );
    }

    return (
      widget ? (
        <ContentBox
          className={!isCustomerModalCard && inline ? "inline" : ""}           
          style={{
            marginTop: 0,
            marginBottom: isCustomerModalCard ? 0 : "15px",
            marginLeft: 0,
            marginRight: 0,
            background: isCustomerModalCard ? "transparent" : "#FFF",
            alignItems: isCustomerModalCard ? "flex-start" : "center",
            boxShadow: isCustomerModalCard ? "none" : "0 4px 12px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05)"
          }}>
            {!isCustomerModalCard && <h2>{i18n("upload_and_sign", "upload_for_signing")}</h2>}
          {componentContent()}
        </ContentBox>
      ) : 
      (
        <React.Fragment>
          <div className="h-line spacing" />
            {componentContent()}
        </React.Fragment>
      )
    );
	}

	expandUploadArea = (event) => {
		this.setState({isUploadAreaExpanded: !this.state.isUploadAreaExpanded});
		event.preventDefault();
	};

	expandAddMessageArea = (event) => {
		event.preventDefault();
		this.setState({isAddMessageAreaExpanded: !this.state.isAddMessageAreaExpanded});
	};

  _onIdentityNoChange = (value, validated, valueCameFromPaste) => {
    if (validated) {
      this.setState({
        isSendingCustomDocSuccess: false,
        isSendingCustomDocError: false,
        customerState: {...this.state.customerState, customDocSsn: valueCameFromPaste ? formatSSN(value) : value}
      });
    } else {
      this.setState({
        isSendingCustomDocSuccess: false,
        isSendingCustomDocError: false,
        customerState: {...this.state.customerState, customDocSsn: value}
      });
    }
  };

	_onDocDrop = (documents) => {
		const docs = [...this.state.docs];

		docsloop:
			for (let i = 0, n = documents.length; i < n; i++) {
				const doc = documents[i];

				// Prevent duplicates
				for (let j = 0; j < docs.length; j++) {
					if (docs[j].name === doc.name) {
						continue docsloop; // Duplicate document, continue with next one
					}
				}
				docs.push(doc);
			}

		this.setState({docs, isSendingCustomDocSuccess: false});
	};

	_onDocRemove = (clickedDocument) => {
		const docs = [...this.state.docs].filter((d, i) => {
			return d.name !== clickedDocument.name;
		});
		this.setState({docs, isSendingCustomDocSuccess: false});
	};

	_onCustomDocSend = () => {
		return new Promise((resolve) => {
			this.setState({isSendingCustomDoc: true}, async () => {
				try {
					await api("/case/standalone", {
						partnerSsn: this.props.partnerSsn,
						ssn: this.state.customerState.customDocSsn,
						name: this.state.customerState.customDocName,
						email: this.state.customerState.customDocEmail,
						phone: this.state.customerState.customDocPhone,
						msg: this.state.customerState.customDocMsg,
						documents: this.state.docs
					});

					this.setState({
						docs: [],
						isSendingCustomDoc: false,
						isSendingCustomDocSuccess: true,
						isSendingCustomDocError: false,
						isSendingCustomDocErrorMessage: "",
            customerState: {
              // do not reset customer state for card version, only the message
              ...( !this.isCustomerModalCard ? { 
                customDocSsn: "",
                customDocName: "",
                customDocEmail: "",
                customDocPhone: "",
              } : {...this.state.customerState}),
              customDocMsg: ""						
            }
					}, () => resolve());
				}
				catch (e) {
					console.error(e.message);
					this.setState({isSendingCustomDoc: false, isSendingCustomDocError: true, isSendingCustomDocErrorMessage: e.message}, () => resolve());
				}
			});
		});
	};
}

// PRIVATE FUNCTIONS
function _getInitialState() {
  const { customer } = this.props;
	return getViewInitialStateForCustomer(customer, this.isCustomerModalCard);
}

export function getViewInitialStateForCustomer(customer, isCustomerModalCard) {
	return {
		isUploadAreaExpanded: isCustomerModalCard,
		isAddMessageAreaExpanded: !isCustomerModalCard,
		isSendingCustomDoc: false,
		isSendingCustomDocSuccess: false,
		isSendingCustomDocError: false,
		isSendingCustomDocErrorMessage: "",
		docs: [],
		customerState: {
			customDocSsn: customer?.id && customer.id.replace("-", "") || "",
			customDocName: customer?.name || "",
			customDocEmail: customer?.email || "",
			customDocPhone: customer?.phone && customer.phone !== "-" && customer.phone || customer?.phone_alternative || "",
			customDocMsg: "",
		}
	};
}