export default class User {
	_data = null;

	constructor(data) {
		this._data = data;
	}

	// Properties
	get firstName() {
		return this._data.firstName
	}

	get lastName() {
		return this._data.lastName
	}

	get phoneNumber() {
		return this._data.phoneNumber
	}

	get email() {
		return this._data.email
	}

	get companyName() {
		return this._data.companyName
	}

	get companyIsPartner() {
		return this._data.company_is_partner
	}

	get ssn() {
		return this._data.ssn
	}

	get orgNumber() {
		return this._data.orgNumber
	}

	get street() {
		return this._data.street
	}

	get zipCode() {
		return this._data.zipCode
	}

	get city() {
		return this._data.city
	}

	get role() {
		return this._data.role
	}

	get customerNumber() {
		return this._data.customerNumber
	}

	get data() {
		return this._data;
	}

	// Methods
	getFullName() {
		return this.firstName + " " + this.lastName;
	}

	getStakeholderData() {
		return {
			name: this.firstName + " " + this.lastName,
			email: this.email,
			phoneNumber: this.phoneNumber,
			ssn: this.ssn,
		};
	}

	hasPermission(scope) {
		if (!this._data.scope) {
			return false;
		}

		const userPermissions = this._data.scope.split(",");
		if (userPermissions.length === 0) {
			return false;
		}

		return userPermissions.includes(scope);
	}

	getData() {
		return JSON.parse(JSON.stringify(this._data));
	}
}


// PRIVATE FUNCTIONS
