import Settings from "@classes/Settings";
import api from "@helpers/api";
import axios from "axios";
import React from "react";
import Modal from "../components/Modal/Modal";
import Button from "../components/Button/Button";
import User from "./User";

import i18n from "@helpers/i18n";

let loginResponseHandler = 0;
let qrCodeKey = "";
let isPending = false;
let bankidURL = import.meta.env.VITE_BANKID_URL

export async function startBankIDLogin(statusCallback, completedCallback)
{
  const urlParams = new URLSearchParams(window.location.search);
  const isTester = (urlParams.get('glofitest') === "1");
  if (isTester)
  {
    console.log("BankID :: Running in demo mode");
    bankidURL = "https://demobankid.glofitech.com";
  }

	let url = import.meta.env.VITE_API_URL;
	if (url === "/")
	{ // If we set the api url we will use the same host on port 80 as the api server
		url = window.location.protocol + "//" + window.location.hostname;
	}
	if (import.meta.env.VITE_NODE_ENV === "development")
	{
    const apiServerPort = import.meta.env.VITE_API_SERVER_PORT;
		url = apiServerPort ? url + ":" + apiServerPort : url;
	}
	url += "/bankid/auth";
	isPending = false;

	axios({
		method: "post",
		url,
    data: {isTester}
	}).then(response =>
	{
		const data = response.data;
		if (data.orderRef == null)
		{
			console.log("BankID :: Cancelled pending login");
			qrCodeKey = "";
			isPending = false;
			statusCallback("cancelled");
		}
		else
		{
			console.log("BankID :: Authentication started");
			qrCodeKey = data.autoStartToken;
			isPending = false;
			statusCallback("started");

			// Start listener
			loginResponseHandler = setInterval(() =>
			{
				const data = {orderRef: response.data.orderRef};

				axios({
					method: "post",
					data,
					url: bankidURL + "/api/collect/v6"
				}).then(response =>
				{
					if (response.data.status === null)
					{
						clearInterval(loginResponseHandler);
						console.log("BankID :: Status was null");
						qrCodeKey = "";
						isPending = false;
						statusCallback("cancelled");
					}
					else if (response.data.status === 'complete')
					{
						clearInterval(loginResponseHandler);
						console.log("BankID :: Authenticated");
						isPending = false;
						completedCallback(response.data);
					}
					else if (response.data.status === 'failed' && response.data.hintCode === 'userCancel')
					{
						clearInterval(loginResponseHandler);
						console.log("BankID :: User cancelled");
						qrCodeKey = "";
						isPending = false;
						statusCallback("cancelled");
					}
					else if (response.data.status === 'failed')
					{
						clearInterval(loginResponseHandler);
						console.log("BankID :: Failed!");
						qrCodeKey = "";
						isPending = false;
						statusCallback("error");
					}
					else if (response.data.status === 'pending' && response.data.hintCode === 'outstandingTransaction')
					{
						console.log("BankID :: Waiting for user to start bank id app...");

						if (isPending)
						{
							statusCallback("pending");
						}
						else
						{
							statusCallback("started");
						}
					}
					else if (response.data.status === 'pending' && response.data.hintCode === 'userSign')
					{
						console.log("BankID :: Waiting for user to sign...");
						isPending = true;
						statusCallback("pending");
					}
				}).catch(error =>
				{
					console.log("BankID :: Error");
					console.log(error);

					clearInterval(loginResponseHandler);
					qrCodeKey = "";
					isPending = false;
					statusCallback("cancelled");
				});
			}, 3000);
		}
	}).catch(error =>
	{
		console.log("BankID :: Error");
		console.log(error);
		statusCallback("cancelled");
	});
}

export async function handleBankIDLoginResponse(data, callback)
{
	console.log("BankID :: Logging in");

	// Create and set authToken
	const authToken = await api("/auth/tokenGlofi", {data: data});
	Settings.set("AUTH_TOKEN", authToken.token);
	console.log("BankID :: Saved AUTH_TOKEN");

	// Fetch partner data
	console.log("BankID :: Fetching partner data");
	const {partner, advisor} = await api("/auth/data");

	return callback(partner, advisor);
}

export default class GlofitechBankIDModal extends React.PureComponent
{

	state = {
		visible: false,
		loginState: "not-started",
		timeNow: 0
	};

	// Reset fields when we hide / show the window
	componentDidUpdate(prevProps, prevState)
	{

		const updatedStateProps = {visible: this.props.visible};
		if (this.props.visible && this.state.visible !== this.props.visible)
		{
			updatedStateProps.loginState = "not-started";
			this.setState(updatedStateProps);
			this._doLogin();
		}

		this.setState(updatedStateProps);
	}

	render()
	{
		const {props, state} = this;
		const {visible, onCancel} = props;

		return (
			<Modal className="loginGlofitech"
				   visible={visible}
				   title={i18n("bankid", "login")}
           loggedOut
      >
				<React.Fragment>
					<div className="img">
						<img src="https://coreid.assently.com/embed/se-bankid-static/icon_64x64@2x.png" alt="BankID"/>
					</div>

					{(state.loginState === 'not-started' || state.loginState === 'loading') && (
						<React.Fragment>
							<p style={{
								textAlign: "center",
								margin: "30px 0 10px 0",
								fontSize: "18px",
								width: "100%",
								padding: "0 25px"
							}}>
								{i18n("bankid", "please_wait")}...
							</p>
						</React.Fragment>
					)}

					{state.loginState === 'started' && (
						<React.Fragment>
							<img src={bankidURL + "/api/DynamicQR?autoStartToken=" + qrCodeKey + "&r=" + state.timeNow}
								 alt="QR-kod" height="120"
								 style={{
									 height: "120px",
									 alignSelf: "center",
									 marginTop: "10px"
								 }}/>

							<p style={{
								textAlign: "center",
								margin: "10px 0",
								fontSize: "14px",
								width: "100%",
								padding: "0 25px"
							}}>
								{i18n("bankid", "open_bankid")}.<br/><br/>
								{i18n("bankid", "align_camera")}.
							</p>

						</React.Fragment>
					)}

					{state.loginState === 'pending' && (
						<React.Fragment>
							<p style={{
								textAlign: "center",
								margin: "30px 0 10px 0",
								fontSize: "18px",
								width: "100%",
								padding: "0 25px"
							}}>
								{i18n("bankid", "enter_security_code")}.
							</p>
						</React.Fragment>
					)}

					{state.loginState === 'cancelled' && (
						<React.Fragment>
							<p style={{
								textAlign: "center",
								margin: "30px 0 10px 0",
								fontSize: "18px",
								width: "100%",
								padding: "0 25px"
							}}>
								{i18n("bankid", "login_cancelled")}.
							</p>

							<Button
								type="submit"
								label={i18n("bankid", "try_again")}
								appearance="primary"
								onClick={this._retryLogin.bind(this)}
							/>
						</React.Fragment>
					)}

					{state.loginState === 'error' && (
						<React.Fragment>
							<p style={{
								textAlign: "center",
								margin: "30px 0 10px 0",
								fontSize: "18px",
								width: "100%",
								padding: "0 25px"
							}}>
								{i18n("bankid", "login_error")}.
							</p>

							<Button
								type="submit"
								label={i18n("bankid", "try_again")}
								appearance="primary"
								onClick={this._retryLogin.bind(this)}/>
						</React.Fragment>
					)}

					{state.loginState === 'loading-app-after-login' && (
						<React.Fragment>
							<p style={{
								textAlign: "center",
								margin: "30px 0 10px 0",
								fontSize: "18px",
								width: "100%",
								padding: "0 25px"
							}}>
								{i18n("bankid", "loggin_in")}...
							</p>
						</React.Fragment>
					)}

					{state.loginState !== 'loading-app-after-login' && (
						<Modal.ButtonsContainer>
							<a href={"#/"}
							   onClick={function ()
							   {
								   clearInterval(loginResponseHandler);
								   onCancel();
							   }}>
								{i18n("bankid", "cancel")}
							</a>
						</Modal.ButtonsContainer>
					)}

				</React.Fragment>
			</Modal>
		)
	}

	_retryLogin()
	{
		this._doLogin();
	}

	_doLogin()
	{
		this.setState({loginState: "loading"});

		startBankIDLogin(
			(status) =>
			{
				if (status === 'cancelled')
				{
					this.setState({loginState: "cancelled"});
				}
				else if (status === 'error')
				{
					this.setState({loginState: "error"});
				}
				else if (status === 'started')
				{
					const date = new Date();
					this.setState({loginState: "started", timeNow: date.getTime()});
				}
				else if (status === 'pending')
				{
					this.setState({loginState: "pending"});
				}
			},
			(data) =>
			{
				this.setState({loginState: "loading-app-after-login"});
				try
				{
					handleBankIDLoginResponse(data, (partner, advisor) =>
					{
						if (partner !== null)
						{
							// Create user and set in app reducer
							this.props.actions.setAppState({busy: false});
							this.props.actions.setUser(new User(partner));
							this.props.actions.setAdvisor(new User(advisor));
							console.log("BankID :: Set user");
							this.props.history.push("/cob");
						}
						else
						{
							Settings.clear();
							console.log("BankID :: Partner not found!");
							this.props.history.push("/partner-not-found");
						}
					});
				}
				catch (error)
				{
					Settings.clear();
					this.setState({loginState: "cancelled"});
				}
			});
	}
}