import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./Uppdragsavtal.fulfillment";
import Checkbox from "../../../../../components/Checkbox/Checkbox";
import StackLayout from "../../../../../components/layouts/StackLayout/StackLayout";
import NumberField from "../../../components/NumberField/NumberField";
import Select from "../../../../../components/Select/Select";
import TextView from "../../../../../components/TextView/TextView";
import TextField from "../../../../../components/TextField/TextField";
import Validator from "../../../../../classes/Validator";
import i18n from "../../../../../helpers/i18n";

export default class Uppdragsavtal extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {customerState, hasFormValidationErrors} = this.state;
		const {customer, availableBanks} = this.props;
		const classes = createClassName("Cob-view Cob-view2", {});

		/**
		 * Set up the dropdown with selectable banks.
		 */
		let availableBankOptions = [];
		for (const bankName in availableBanks)
		{
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}
			availableBankOptions.push({
				label: bankName,
				value: bankName
			});
		}

		availableBankOptions.push({
			label: "Övriga",
			value: "-"
		});

		const textClasses = createClassName("description", {
			"citroneer": true,
			"fine-print": true,
			"contractable": true,
			"expanded": this.state.textFieldExpanded
		});

		if (this.state.pageDisabled)
		{
			return (
				<div className={classes}>
					<DefaultFields fields={this.fields}/>

					<ContentBox>
						<h2>Uppdragsavtal</h2>

						<em>
							Uppdragsavtal behöver inte fyllas i.
							Onboarding är redan genomförd och inget nytt konto öppnas vid tillfället.
						</em>
					</ContentBox>
				</div>
			);
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Uppdragsavtal</h2>

					<div className="Cob-view2-fields">

						<h3>Avtal avseende investeringsrådgivning och relaterade tjänster</h3>

						<h4>Uppdrag och avgifter*</h4>

						<FeeDefinition prefix="uppdragsavtal_af_"
						               label={"Aktie- & fondkonto"}
						               customer={customer}
						               currentWorkflow={this.props.currentWorkflow}
						               onChange={this._onFeeDefinitionChange}
						               highlightErrors={hasFormValidationErrors}/>

						<FeeDefinition prefix="uppdragsavtal_isk_"
						               label={"Investeringssparkonto (ISK)"}
						               customer={customer}
						               currentWorkflow={this.props.currentWorkflow}
						               onChange={this._onFeeDefinitionChange}
						               highlightErrors={hasFormValidationErrors}/>

						<FeeDefinition prefix="uppdragsavtal_kf_"
						               label={"Depå kapitalförsäkring"}
						               customer={customer}
						               currentWorkflow={this.props.currentWorkflow}
						               onChange={this._onFeeDefinitionChange}
						               highlightErrors={hasFormValidationErrors}/>

						<FeeDefinition prefix="uppdragsavtal_tp_"
						               label={"Depå tjänstepensionsförsäkring"}
						               customer={customer}
						               currentWorkflow={this.props.currentWorkflow}
						               onChange={this._onFeeDefinitionChange}
						               highlightErrors={hasFormValidationErrors}/>

						<div className="h-line spacing"/>
						<h4>Förvalskonto</h4>
						<p>
							För utbetalningar från Kundens konton hos Citroneer ska nedanstående konto användas.
							Citroneer kommer uteslutande använda detta konto för utbetalningar.
						</p>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<Select
									name="bank_name"
									label="Bank"
									selectedIndex={customerState.selectedBankIndex}
									options={availableBankOptions}
									onChange={(option, selectedBankIndex) =>
									{
										this.setCustomerState({
											bank_name: option.value,
											selectedBankIndex
										});
									}}
								/>
							</div>
							<div>
								<NumberField label="Clearingnummer"
								             name="bank_clearingno"
								             value={customerState.bank_clearingno}
								             validation={this._validateClearingNumber}
								             onChange={this._onAccountClearingChange}/>
							</div>
							<div>
								<TextField
									name="bank_accountno"
									value={customerState.bank_accountno}
									label={"Kontonummer"}
									validation={this._numberValidation}
									onChange={this._onAccountNumberChange}
								/>
							</div>
						</StackLayout>

						<Checkbox
							style={{margin: "0 0 20px 5px"}}
							type="checkbox"
							name="checkbox_override_validation"
							label="Tillåt konto- och clearingnummer som inte kan valideras"
							value={customerState.checkbox_override_validation ? "yes" : "no"}
							checked={customerState.checkbox_override_validation === "yes"}
							onChange={checked => this.setCustomerState({checkbox_override_validation: checked ? "yes" : "no"})}
						/>

						{customerState.bank_name === 'Swedbank' && (
							<p style={{
								background: "#FFFFD5",
								padding: "10px",
								fontSize: "12px",
								margin: "-10px 0 20px 0",
								maxWidth: "600px"
							}}>
								Det förekommer att Swedbank har en femte siffra i clearingnumret. Vänligen
								ange enbart de fyra första innan bindestrecket. Exempel: 8214-9 ska anges som
								8214.
							</p>
						)}

						<div className="h-line spacing"/>

						<h4>Avtalstext</h4>

						<div className={textClasses}>
							<strong>
								Detta Avtal (”Avtalet”) har idag ingåtts mellan Citroneer AB (organisationsnummer
								559159–1192, Artillerigatan 42, 114 45 Stockholm, e-postadress: kontakt@citroneer.com,
								härefter också kallad ”Citroneer”) och följande kund (härefter också kallad ”Kunden”).
							</strong>
							<p>
								<strong>{customer.isCompany ? "Företagsnamn" : "Namn"}: </strong>
								{customer.getData("customer_firstname") + " " + customer.getData("customer_lastname")}
								<br/>

								<strong>{customer.isCompany ? "Organisationsnummer" : "Personnummer"}: </strong>
								{customer.getData("customer_ssn")}<br/>

								<strong>Address: </strong>
								{customer.getData("customer_address_street")}, {customer.getData("customer_address_zipcode")} {customer.getData("customer_address_city")}<br/>

								<strong>Epost: </strong>
								{customer.getData("customer_email")}<br/>

								<strong>Telefon: </strong>
								{customer.getData("customer_phonenumber_cell")}<br/>

								<strong>Kundkategorisering: </strong> {customer.getData("customer_classification")}<br/><br/>

								Ovanstående avtalsparter benämns härefter gemensamt som ”Parterna” och var för sig som
								”Part” eller ”Parten”. Med ”Avtal” menas detta Avtal jämte Citroneers Förköpsinformation
								(på www.citroneer.com/forkopsinformation) samt Citroneers Allmänna Villkor (på
								www.citroneer.com/allmannavillkor).<br/><br/>

								<strong>1. Om Citroneer</strong><br/>
								För information om Citroneer och dess tjänster, information om risker med investeringar
								i finansiella instrument, framställande av klagomål, bästa genomförande av order med
								mera hänvisas till Citroneers Förköpsinformation.<br/><br/>

								<strong>2. Uppdrag och avgifter</strong><br/>
								Kunden ger Citroneer i uppdrag att öppna nedanstående konton hos Citroneer. Vidare ger
								Kunden Citroneer i uppdrag att löpande eller på Kundens begäran, fortsättningsvis
								benämnt ”Uppdraget”, utföra de tjänster som beskrivs i detta Avtal samt i Citroneers
								Allmänna Villkor och enligt Citroneers Prislista (på
								www.citroneer.com/prislista).<br/><br/>

								{this.state.accountNaked && (
									<React.Fragment>
										<strong>Aktie- & fondkonto </strong><br/>
										{customerState.uppdragsavtal_af_task_1}: {customerState.uppdragsavtal_af_fee_1}
										{customerState.uppdragsavtal_af_task_2 !== "-" &&
											customerState.uppdragsavtal_af_task_2 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_af_task_2}: {customerState.uppdragsavtal_af_fee_2}
												</React.Fragment>
											)}
										{customerState.uppdragsavtal_af_task_3 !== "-" &&
											customerState.uppdragsavtal_af_task_3 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_af_task_3}: {customerState.uppdragsavtal_af_fee_3}
												</React.Fragment>
											)}
										<br/><br/>
									</React.Fragment>
								)}

								{this.state.accountIsk && (
									<React.Fragment>
										<strong>ISK</strong><br/>
										{customerState.uppdragsavtal_isk_task_1}: {customerState.uppdragsavtal_isk_fee_1}
										{customerState.uppdragsavtal_isk_task_2 !== "-" &&
											customerState.uppdragsavtal_isk_task_2 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_isk_task_2}: {customerState.uppdragsavtal_isk_fee_2}
												</React.Fragment>
											)}
										{customerState.uppdragsavtal_isk_task_3 !== "-" &&
											customerState.uppdragsavtal_isk_task_3 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_isk_task_3}: {customerState.uppdragsavtal_isk_fee_3}
												</React.Fragment>
											)}
										<br/><br/>
									</React.Fragment>
								)}

								{this.state.accountKf && (
									<React.Fragment>
										<strong>Depå kapitalförsäkring</strong><br/>
										{customerState.uppdragsavtal_kf_task_1}: {customerState.uppdragsavtal_kf_fee_1}
										{customerState.uppdragsavtal_kf_task_2 !== "-" &&
											customerState.uppdragsavtal_kf_task_2 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_kf_task_2}: {customerState.uppdragsavtal_kf_fee_2}
												</React.Fragment>
											)}
										{customerState.uppdragsavtal_kf_task_3 !== "-" &&
											customerState.uppdragsavtal_kf_task_3 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_kf_task_3}: {customerState.uppdragsavtal_kf_fee_3}
												</React.Fragment>
											)}
										<br/><br/>
									</React.Fragment>
								)}

								{this.state.accountTp && (
									<React.Fragment>
										<strong>Depå tjänstepensionsförsäkring</strong><br/>
										{customerState.uppdragsavtal_tp_task_1}: {customerState.uppdragsavtal_tp_fee_1}
										{customerState.uppdragsavtal_tp_task_2 !== "-" &&
											customerState.uppdragsavtal_tp_task_2 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_tp_task_2}: {customerState.uppdragsavtal_tp_fee_2}
												</React.Fragment>
											)}
										{customerState.uppdragsavtal_tp_task_3 !== "-" &&
											customerState.uppdragsavtal_tp_task_3 !== "" && (
												<React.Fragment>
													<br/>{customerState.uppdragsavtal_tp_task_3}: {customerState.uppdragsavtal_tp_fee_3}
												</React.Fragment>
											)}
										<br/><br/>
									</React.Fragment>
								)}

								<strong>3. Allmänna villkor</strong><br/>
								För detta Avtal gäller Citroneers Allmänna Villkor (villkoren) som vid var tid
								publicerats på www.citroneer.com/allmannavillkor. Citroneer förbehåller sig rätten att
								när som helst uppdatera sina Allmänna Villkor genom att meddela Kunden senast 2 veckor
								innan sådan uppdatering sker och från vilket datum den nya versionen av de Allmänna
								Villkoren gäller. Om Kunden fortsätter att använda de licensierade modulerna eller
								tjänsterna från Citroneer efter detta datum skall Kunden anses ha accepterat den nya
								versionen av Citroneers Allmänna Villkor.<br/><br/>

								<strong>4. Avtalstid, uppsägning</strong><br/>
								Detta Avtal gäller från dagen för undertecknandet och tills vidare. Uppsägning ska ske
								enligt punkten Uppsägning i de allmänna villkoren.<br/><br/>

								<strong>5. Förvalskonto</strong><br/>
								För utbetalningar från Kundens konton hos Citroneer ska nedanstående konto användas.
								Citroneer kommer uteslutande använda detta konto för utbetalningar.<br/><br/>

								{customerState.bank_name + " " + customerState.bank_clearingno + " " + customerState.bank_accountno}
								<br/><br/>

								<strong>5. Ansvarsbegränsning</strong><br/>
								Citroneer ansvarar inte för det ekonomiska utfallet av uppdraget eller för skada som
								uppkommit genom att Kunden lämnat felaktiga eller ofullständiga uppgifter till
								Citroneer. Det åligger inte Citroneer beakta skatte- mässiga konsekvenser av det
								ekonomiska utfallet enligt detta Avtal. Föreligger hinder för Citroneer att helt eller
								delvis verkställa order eller annan åtgärd som framgår av detta Avtal får order eller
								annan åtgärd skjutas upp till dess hindret upphört.<br/>

								Citroneer är inte ansvarigt om skada uppkommer för Kunden med anledning av sådant
								dröjsmål. Citroneer ansvarar inte för skada som orsakats av att den av Citroneer lagda
								kundordern inte effektuerats eller endast delvis effektuerats av Citroneers motpart
								eller av den handelsplats som Citroneer eller motparten
								anlitat. Citroneer ansvarar inte för skada som skulle ha kunnat undvikas om reklamation
								skett omgående.<br/>


								Part ansvarar inte för skada som beror av svensk eller utländsk lag, åtgärd av svensk
								eller utländsk myndig- het, krigshändelse, strejk, blockad, bojkott, lockout eller annan
								liknande omständighet. Förbehållet om strejk, blockad, bojkott eller lockout gäller även
								om Part är föremål för eller själv vidtar sådan konfliktåtgärd. Skada som kan uppkomma i
								annat fall ska ersättas av Part endast i den mån skadan orsakats av att Parten varit
								grovt oaktsam eller det skett med uppsåt.<br/>

								I intet fall är Part ansvarig för indirekt skada eller för skador orsakade av tredje
								man.<br/><br/>

								<strong>7. Tillämplig lag och tvist</strong><br/>
								Vid tolkning av detta Avtal ska svensk lag tillämpas. Tvist rörande tolkningen eller
								tillämpningen av detta Avtal ska avgöras av svensk domstol.<br/><br/>

								<strong>8. Kundens godkännande</strong><br/>
								Kunden har fått information om Citroneers Riktlinjer för bästa utförande vid mottagande
								och vidarebefordran av order genom Citroneers Förköpsinformation som finns tillgänglig
								på www.citroneer.com/forkopsinformation. Kunden godtar Citroneers Riktlinjer och
								godkänner att Citroneer får medverka till att Kundens order genom- förs utanför en
								reglerad marknad eller en handelsplattform. Kunden godkänner att ändringar i och tillägg
								till Citroneers Förköpsinformation tillkännages på www.citroneer.com/forkopsinformation.
								Kunden godkänner vidare också att Citroneer kan uppdatera Citroneers Allmänna Villkor
								genom att göra änd- ringar i och tillägg till dem genom att publicera en ny version på
								www.citroneer.com/allmannavillkor senast 2 veckor innan sådan ny version träder i kraft.
								Om Kunden inte accepterar den nya versionen har Kunden rätt att säga upp Avtalet senast
								dagen innan den träder i kraft. Om kunden inte sagt upp Avtalet innan den nya versionen
								trätt i kraft anses Kunden ha accepterat den nya versionen av Citroneers Allmänna
								Villkor.

							</p>
							<div className="expand">
								<div className="first"></div>
								<div className="second"></div>
								<div className="third" onClick={this._onExpandText}>Expandera texten</div>
							</div>
							<div className="contract" onClick={this._onExpandText}>
								Minimera texten
							</div>
						</div>


						<div className="h-line spacing"/>

						<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
							<div>
								<Checkbox
									name="uppdragsavtal_confirm_terms"
									label="Jag har tagit del av Citroneers Allmänna Villkor."
									value="Ja"
									checked={customerState.uppdragsavtal_confirm_terms === "Ja"}
									onChange={checked => this.setCustomerState({uppdragsavtal_confirm_terms: checked ? "Ja" : "Nej"})}
									highlightErrors={hasFormValidationErrors}
								/>

								<a className={"external-link small"} href="https://citroneer.com/allmannavillkor/"
								   rel="noreferrer"
								   target={"_blank"}>Se Allmänna Villkor på citroneer.com</a>
							</div>

							<div>
								<Checkbox
									name="uppdragsavtal_confirm_forkop"
									label="Jag har tagit del av Citroneers Förköpsinformation."
									value="Ja"
									checked={customerState.uppdragsavtal_confirm_forkop === "Ja"}
									onChange={checked => this.setCustomerState({uppdragsavtal_confirm_forkop: checked ? "Ja" : "Nej"})}
									highlightErrors={hasFormValidationErrors}
								/>

								<a className={"external-link small"} href="https://citroneer.com/forkopsinformation/"
								   rel="noreferrer"
								   target={"_blank"}>Se Förköpsinformation på citroneer.com</a>
							</div>

							<div>
								<Checkbox
									name="uppdragsavtal_confirm_pricelist"
									label="Jag har tagit del av Citroneers prislista."
									value="Ja"
									checked={customerState.uppdragsavtal_confirm_pricelist === "Ja"}
									onChange={checked => this.setCustomerState({uppdragsavtal_confirm_pricelist: checked ? "Ja" : "Nej"})}
									highlightErrors={hasFormValidationErrors}
								/>

								<a className={"external-link small"} href="https://citroneer.com/prislista/"
								   rel="noreferrer"
								   target={"_blank"}>Se prislista på citroneer.com</a>
							</div>
						</StackLayout>

					</div>
				</ContentBox>
			</div>
		);
	}

	_onExpandText = () =>
	{
		this.setState({
			textFieldExpanded: !this.state.textFieldExpanded
		});
	};

	_textValidation = (value) => (value.length > 0);

	_onAccountClearingChange = ({target}) =>
	{
		// Remove all non-numbers
		let value = target.value + "";
		value = value.replace(/[^0-9]+/g, "");
		value = value.replace(/\+/g, "");
		value = value.replace(/-/g, "");

		// Limit clearing numbers to 4 numbers, we'll show a message that says to enter only first four for the
		// banks with longer numbers
		if (value.length >= 4)
		{
			value = value.substring(0, 4);
		}

		this.setCustomerState({bank_clearingno: value});
	};

	_validateClearingNumber = (value) =>
	{
		if (this.state.customerState.bank_name === '-')
		{
			return true;
		}

		return Validator.validateClearingNumber(value, this.state.customerState.bank_name, this.props.availableBanks);
	};

	_numberValidation = (value) =>
	{
		if (this.state.customerState.bank_name === '-')
		{
			return true;
		}

		if (value.length < 5 || isNaN(value))
		{
			return false;
		}

		/**
		 * For the banks where we know the validation rules, check if the number should be validated.
		 */
		let shouldValidateAccountNumber = false;
		const availableBanks = this.props.availableBanks;
		for (const bankName in availableBanks)
		{
			if (bankName === 'length' || !availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}

			if (this.state.customerState.bank_name === bankName)
			{
				const bankInfo = availableBanks[bankName];
				if (bankInfo.length > 0)
				{
					if (bankInfo[0]['validationEnabled'] === "1")
					{
						shouldValidateAccountNumber = true;
					}
				}
				break;
			}
		}

		if (!shouldValidateAccountNumber)
		{
			return true;
		}

		let bankNoToValidate = this.state.customerState.bank_clearingno + ", " + value;
		let kontonummer = window.kontonummer(bankNoToValidate);
		return kontonummer !== false;
	};

	_onAccountNumberChange = ({target}) => !isNaN(target.value) ? this.setCustomerState({bank_accountno: target.value}) : undefined;

	_onFeeDefinitionChange = (name, value, name1, value1) =>
	{
		if (name1 !== "")
		{
			this.setCustomerState({[name]: value, [name1]: value1});
		}
		else
		{
			this.setCustomerState({[name]: value});
		}
	};
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	let selectedBankIndex = -1;
	if (newState.customerState.bank_name && newState.customerState.bank_name.length > 0)
	{
		let bankFoundInList = false;
		for (const bankName in this.props.availableBanks)
		{
			if (bankName === 'length' || !this.props.availableBanks.hasOwnProperty(bankName))
			{
				continue;
			}

			selectedBankIndex++;
			if (bankName === newState.customerState.bank_name)
			{
				bankFoundInList = true;
				break;
			}
		}

		/**
		 * No bank found => Övrig bank
		 */
		if (!bankFoundInList)
		{
			selectedBankIndex++;
			newState.customerState.bank_name_custom = newState.customerState.bank_name;
			newState.customerState.bank_name = "-";
		}
	}

	newState.customerState.checkbox_override_validation = "no";
	newState.customerState.selectedBankIndex = selectedBankIndex;
	newState.availableBanks = this.props.availableBanks;

	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let initialState = {
		pageDisabled: !(customer.getData("checkbox_advice_onboarding") === "yes"),
		textFieldExpanded: false,
		accountNaked: customer.getData("chk_accounttype_naked") === "yes",
		accountIsk: customer.getData("chk_accounttype_isk") === "yes",
		accountKf: customer.getData("chk_accounttype_kf") === "yes",
		accountTp: customer.getData("chk_accounttype_tp") === "yes",
		customerState: {
			uppdragsavtal_confirm_terms: customer.getData("uppdragsavtal_confirm_terms") || "",
			uppdragsavtal_confirm_forkop: customer.getData("uppdragsavtal_confirm_forkop") || "",
			uppdragsavtal_confirm_pricelist: customer.getData("uppdragsavtal_confirm_pricelist") || "",

			bank_name: customer.getData("bank_name") || "",
			bank_clearingno: customer.getData("bank_clearingno") ? customer.getData("bank_clearingno").replaceAll(" ", "") : "",
			bank_accountno: customer.getData("bank_accountno") ? customer.getData("bank_accountno").replaceAll(" ", "") : "",
		}
	};

	for (let i = 1; i <= 3; i++)
	{
		initialState.customerState["uppdragsavtal_af_task_" + i] = customer.getData("uppdragsavtal_af_task_" + i) || "";
		initialState.customerState["uppdragsavtal_af_fee_" + i] = customer.getData("uppdragsavtal_af_fee_" + i) || "";
		initialState.customerState["uppdragsavtal_isk_task_" + i] = customer.getData("uppdragsavtal_isk_task_" + i) || "";
		initialState.customerState["uppdragsavtal_isk_fee_" + i] = customer.getData("uppdragsavtal_isk_fee_" + i) || "";
		initialState.customerState["uppdragsavtal_kf_task_" + i] = customer.getData("uppdragsavtal_kf_task_" + i) || "";
		initialState.customerState["uppdragsavtal_kf_fee_" + i] = customer.getData("uppdragsavtal_kf_fee_" + i) || "";
		initialState.customerState["uppdragsavtal_tp_task_" + i] = customer.getData("uppdragsavtal_tp_task_" + i) || "";
		initialState.customerState["uppdragsavtal_tp_fee_" + i] = customer.getData("uppdragsavtal_tp_fee_" + i) || "";
	}

	if (initialState.customerState["uppdragsavtal_af_task_1"] === "" || initialState.customerState["uppdragsavtal_af_task_1"] === "-")
	{
		initialState.customerState["uppdragsavtal_af_task_1"] = "Depåavgift";
	}

	if (initialState.customerState["uppdragsavtal_isk_task_1"] === "" || initialState.customerState["uppdragsavtal_isk_task_1"] === "-")
	{
		initialState.customerState["uppdragsavtal_isk_task_1"] = "Depåavgift";
	}

	if (initialState.customerState["uppdragsavtal_kf_task_1"] === "" || initialState.customerState["uppdragsavtal_kf_task_1"] === "-")
	{
		initialState.customerState["uppdragsavtal_kf_task_1"] = "Depåavgift";
	}

	if (initialState.customerState["uppdragsavtal_tp_task_1"] === "" || initialState.customerState["uppdragsavtal_tp_task_1"] === "-")
	{
		initialState.customerState["uppdragsavtal_tp_task_1"] = "Depåavgift";
	}

	return initialState;
}

function _getFields()
{
	return [
		"uppdragsavtal_confirm_terms",
		"uppdragsavtal_confirm_forkop",
		"uppdragsavtal_confirm_pricelist",

		"uppdragsavtal_af_task_1",
		"uppdragsavtal_af_fee_1",
		"uppdragsavtal_af_task_2",
		"uppdragsavtal_af_fee_2",
		"uppdragsavtal_af_task_3",
		"uppdragsavtal_af_fee_3",
		"uppdragsavtal_isk_task_1",
		"uppdragsavtal_isk_fee_1",
		"uppdragsavtal_isk_task_2",
		"uppdragsavtal_isk_fee_2",
		"uppdragsavtal_isk_task_3",
		"uppdragsavtal_isk_fee_3",
		"uppdragsavtal_kf_task_1",
		"uppdragsavtal_kf_fee_1",
		"uppdragsavtal_kf_task_2",
		"uppdragsavtal_kf_fee_2",
		"uppdragsavtal_kf_task_3",
		"uppdragsavtal_kf_fee_3",
		"uppdragsavtal_tp_task_1",
		"uppdragsavtal_tp_fee_1",
		"uppdragsavtal_tp_task_2",
		"uppdragsavtal_tp_fee_2",
		"uppdragsavtal_tp_task_3",
		"uppdragsavtal_tp_fee_3",

		"bank_name",
		"bank_clearingno",
		"bank_accountno",
	];
}

class FeeDefinition extends React.PureComponent
{
	state = this._getInitialState.call(this);

	feeTypes = [
		{
			label: this.props.currentWorkflow === "futur" ? "Rådgivningsavgift" : "Investeringsrådgivning",
			value: this.props.currentWorkflow === "futur" ? "Investeringsrådgivning" : "Rådgivningsavgift"
		},
		{label: "Order på kundens initiativ", value: "Order på kundens initiativ (Execution Only)"},
		{label: "Konsulttjänster", value: "Konsulttjänster"},
	];

	render()
	{
		const {state, props} = this;
		const {label, prefix, highlightErrors} = props;

		if (!state.visible)
		{
			return (
				<React.Fragment>
					<input type={"hidden"} name={prefix + "task_1"} value={"-"}/>
					<input type={"hidden"} name={prefix + "fee_1"} value={"-"}/>
					<input type={"hidden"} name={prefix + "task_2"} value={"-"}/>
					<input type={"hidden"} name={prefix + "fee_2"} value={"-"}/>
					<input type={"hidden"} name={prefix + "task_3"} value={"-"}/>
					<input type={"hidden"} name={prefix + "fee_3"} value={"-"}/>
					<input type={"hidden"} name={prefix + "task_4"} value={"-"}/>
					<input type={"hidden"} name={prefix + "fee_4"} value={"-"}/>
				</React.Fragment>
			);
		}

		const feeRows = [];
		for (let i = 1; i <= 3; i++)
		{
			const taskField = prefix + "task_" + i;
			const feeField = prefix + "fee_" + i;

			if (i > state.numberOfActiveFees)
			{
				feeRows.push(
					<React.Fragment key={taskField}>
						<input type={"hidden"} name={prefix + "task_" + i} value={"-"}/>
						<input type={"hidden"} name={prefix + "fee_" + i} value={"-"}/>
					</React.Fragment>
				)
			}
			else
			{
				const firstItemStyle = (i === 1 ? {padding: "5px 10px"} : {
					padding: "5px 10px",
					alignSelf: "flex-start"
				});
				feeRows.push(
					<div className={"fee-row"} key={taskField}>
						<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
							<div style={firstItemStyle}>
								{i === 1 && (
									<span style={{fontSize: "14px", fontWeight: "bold", padding: "0 8px"}}>
										Depåavgift
										<input type={"hidden"} name={taskField} value={"Depåavgift"}/>
									</span>
								)}
								{i > 1 && (
									<Select
										name={taskField}
										label="Typ av avgift*"
										selectedIndex={this.feeTypes.map(c => c.value).indexOf(state[taskField])}
										options={this.feeTypes}
										onChange={(option) => this.setState({[taskField]: option.value}, () =>
										{
											this.props.onChange(taskField, option.value, "", "");
										})}
										highlightErrors={highlightErrors}
									/>
								)}
							</div>
							<div style={{padding: "5px 10px", alignSelf: "flex-start"}}>
								{state[taskField] !== "Konsulttjänster" && (
									<NumberField label={i18n("general", "provide_fee_in_percent") + "*"}
									             unit="%"
									             decimals={3}
									             name={feeField}
									             value={state[feeField] === "-" ? "" : state[feeField]}
									             validation={value => value.length > 0 && !isNaN(value) && value >= 0 && value <= 100}
									             onChange={({target}) =>
									             {
										             const newValue = isNaN(target.value) || target.value.length <= 0 ? "" : target.value + "%";
										             this.setState({[feeField]: newValue}, () =>
										             {
											             this.props.onChange(feeField, newValue, "", "");
										             });
									             }}
									             highlightErrors={highlightErrors}/>
								)}

								{state[taskField] === "Konsulttjänster" && (
									<TextView
										name={feeField}
										label={`${i18n("general", "free_text")} (${120 - state[feeField].length} ${i18n("general", "remaining_chars")})`}
										value={state[feeField]}
										maxLength={120}
										validation={value => value.length > 0}
										resize="vertical"
										onChange={({target}) => this.setState({[feeField]: target.value}, () =>
										{
											this.props.onChange(feeField, target.value, "", "");
										})}
										fullWidth
										minHeight={120}
										highlightErrors={highlightErrors}
									/>
								)}
							</div>
						</StackLayout>
					</div>
				);
			}
		}

		return (
			<div className={"fee-selection"}>
				<span className={"label"}>{label}</span>
				<div className={"contents"}>
					{feeRows}

					<div className={"actions"}>
						{state.numberOfActiveFees < 3 && (
							<span className={"add"}
							      onClick={() => this.setState({numberOfActiveFees: (state.numberOfActiveFees + 1)}, () =>
							      {
								      this.props.onChange(
									      [prefix + "task_" + (state.numberOfActiveFees + 1)], "",
									      [prefix + "fee_" + (state.numberOfActiveFees + 1)], ""
								      );
							      })}>Lägg till avgift</span>
						)}

						<span className={"space"}></span>

						{state.numberOfActiveFees > 1 && (
							<span className={"remove"}
							      onClick={() => this.setState({
								      numberOfActiveFees: (state.numberOfActiveFees - 1),
								      [prefix + "task_" + (state.numberOfActiveFees)]: "",
								      [prefix + "fee_" + (state.numberOfActiveFees)]: ""
							      }, () =>
							      {
								      this.props.onChange(
									      [prefix + "task_" + (state.numberOfActiveFees)], "",
									      [prefix + "fee_" + (state.numberOfActiveFees)], ""
								      );
							      })}>Ta bort sista</span>
						)}

					</div>
				</div>
			</div>
		);
	}

	_getInitialState()
	{
		const {customer, prefix} = this.props;

		const visibilityField = (prefix === "uppdragsavtal_af_" ? "chk_accounttype_naked" :
			(prefix === "uppdragsavtal_isk_" ? "chk_accounttype_isk" :
				(prefix === "uppdragsavtal_kf_" ? "chk_accounttype_kf" :
					(prefix === "uppdragsavtal_tp_" ? "chk_accounttype_tp" : ""))));

		let initialState = {
			visible: customer.getData(visibilityField) === "yes",
			numberOfActiveFees: 2,
		};

		for (let i = 1; i <= 3; i++)
		{
			const taskField = prefix + "task_" + i;
			const feeField = prefix + "fee_" + i;
			initialState[taskField] = customer.getData(taskField) || "";
			initialState[taskField] = initialState[taskField] === "-" ? "" : initialState[taskField];
			initialState[feeField] = customer.getData(feeField) || "";
			initialState[feeField] = initialState[feeField] === "-" ? "" : initialState[feeField];

			if (i > 1 && initialState[taskField].length > 0 && initialState[taskField] !== '-')
			{
				initialState.numberOfActiveFees = i;
			}
		}

		return initialState;
	}
}