// CLASSES
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";

export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (!(customerState.chk_accounttype_naked === "yes" ||
		customerState.chk_accounttype_isk === "yes" ||
		customerState.chk_accounttype_kf === "yes" ||
		customerState.chk_accounttype_tp === "yes"))
	{
		return false;
	}

	if (customerState.account_selection_recommendation.length <= 0)
	{
		return false;
	}

	return true;
}