// CLASSES
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";

export default function (state, prevState)
{
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0")
	{
		return true;
	}

	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	// Rekommendation
	if (customerState.recommendation_for_account.length <= 0 || customerState.recommendation_for_account === "-")
	{
		return false;
	}

	if (customerState.recommendation_advisor_suggestion.length <= 0 ||
		customerState.recommendation_motivation_needs.length <= 0 ||
		customerState.recommendation_motivation_finances.length <= 0 ||
		customerState.recommendation_customer_has_buffer.length <= 0 ||
		customerState.recommendation_customer_suits_risk.length <= 0 ||
		customerState.recommendation_customer_suitability.length <= 0)
	{
		return false;
	}

	if (customerState.recommendation_customer_has_buffer === "no" &&
		customerState.recommendation_customer_has_buffer_motivation.length <= 0)
	{
		return false;
	}

	if (customerState.recommendation_customer_suits_risk === "no" &&
		customerState.recommendation_customer_suits_risk_motivation.length <= 0)
	{
		return false;
	}

	// Beslut
	if (customerState.recommendation_decision_taken.length <= 0 || customerState.recommendation_decision_taken.length > 2)
	{
		return false;
	}

	if (customerState.recommendation_decision_taken === "o" &&
		customerState.recommendation_decision_taken_other.length <= 0)
	{
		return false;
	}

	// Typ av investering needs to be selected
	if (customerState.recommendation_investment_type.length <= 0)
	{
		return false;
	}

	if (customerState.recommendation_investment_type === "Initial investering" &&
		(customerState.recommendation_financing_amount_0.length <= 0 || customerState.recommendation_financing_amount_0 <= 0))
	{
		return false;
	}

	// Kostnader
	if (customerState.recommendation_investment_type === "Initial investering" &&
		customerState.recommendation_costs_advisor_fee.length <= 0)
	{
		return false;
	}

	// Selected products need to be filled out correctly
	for (let i = 0; i < customerState.productsAdded.length; i++)
	{
		const p = customerState.productsAdded[i];
		if (p.type.length <= 0 ||
			p.name.length <= 0 ||
			p.isin.length <= 0 ||
			p.amount.length <= 0 ||
			p.fee.length <= 0 ||
			p.amount <= 0 ||
			p.fee < 0 ||
			p.fee > 100)
		{
			return false;
		}
	}

	if (customerState.financing.length > 1)
	{
		for (let i = 0; i < customerState.financing.length; i++)
		{
			const p = customerState.financing[i];
			if (p.description.length <= 0 ||
				p.amount.length <= 0 ||
				p.amount <= 0)
			{
				return false;
			}
		}
	}
	else if ((customerState.financing[0].description.length > 0 && customerState.financing[0].amount.length <= 0) ||
		(customerState.financing[0].description.length <= 0 && customerState.financing[0].amount.length > 0))
	{
		return false;
	}

	let totalFinancing = 0;
	let financingRequirements = 0;

	for (let i = 0; i < customerState.productsAdded.length; i++)
	{
		const p = customerState.productsAdded[i];
		const prodAmount = parseInt(p.amount.replaceAll(" ", "").replaceAll("kr", ""), 10);
		if (p.type === 'Sälj')
		{
			totalFinancing += isNaN(prodAmount) ? 0 : prodAmount;
		}
		else if (p.type === 'Köp')
		{
			financingRequirements += isNaN(prodAmount) ? 0 : prodAmount;
		}
	}
	for (let i = 0; i < customerState.financing.length; i++)
	{
		const p = customerState.financing[i];
		let amount = parseInt(p.amount.replaceAll(" ", "").replaceAll("kr", ""), 10);
		totalFinancing += isNaN(amount) ? 0 : amount;
	}

	if (financingRequirements > totalFinancing)
	{
		return false;
	}


	return true;
}