import React from "react";

// COMPONENTS
// import Button from "@components/Button/Button";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import NumberField from "../../components/NumberField/NumberField";
import OptionsList from "../../components/OptionsList/OptionsList";
import Select from "@components/Select/Select";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import TextField from "@components/TextField/TextField";

// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
import countries from "@helpers/countries";

// OTHER
import fulfillment from "./MoneyLaunderingQuestions.fulfillment";


export default class MoneyLaunderingQuestions extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);
	countries = countries.map(({label, code}) => ({label, value: code}));

	isFulfilled(state) {
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) callbackFn();
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			},
		});
	}

	render() {
		const {state} = this;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view3", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("cob", "questions_according_to_anti_money_laundering_act")}</h2>

					<div className="Cob-view3-fields">
						<input type="hidden" name="customer_anl"
							   value={customerState.customer_anl}/>

						<OptionsList
							type="checkbox"
							description="Vad är den primära anledningen till en affärsförbindelse med Nord FK?"
							orientation="vertical"
							options={[
								{
									name: "checkbox_customer_reason_business_relationship_saving",
									label: i18n("general", "savings"),
									value: customerState.checkbox_customer_reason_business_relationship_saving === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_reason_business_relationship_saving === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("saving", checked)
								},
								{
									name: "checkbox_customer_reason_business_relationship_assetmanagement",
									label: "Kapitalförvaltning",
									value: customerState.checkbox_customer_reason_business_relationship_assetmanagement === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_reason_business_relationship_assetmanagement === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("assetmanagement", checked)
								},
								{
									name: "checkbox_customer_reason_business_relationship_other",
									label: "Annat (beskriv)",
									value: customerState.checkbox_customer_reason_business_relationship_other === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_reason_business_relationship_other === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("other", checked)
								},
							]}
						/>
						{customerState.checkbox_customer_reason_business_relationship_other === "yes" && (
							<TextField
								name="customer_reason_business_relationship_other"
								label="Vänligen beskriv"
								value={customerState.customer_reason_business_relationship_other}
								validation={this._textValidation}
								onChange={({target}) => this._onSetOtherText("relationship", target.value)}
							/>
						)}

						<OptionsList
							type="checkbox"
							description="Vad är syftet med ditt eller företagets investering?"
							orientation="vertical"
							options={[
								{
									name: "checkbox_customer_kyc_purpose_pension",
									label: i18n("general", "pension"),
									value: customerState.checkbox_customer_kyc_purpose_pension === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_purpose_pension === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("pension", checked)
								},
								{
									name: "checkbox_customer_kyc_purpose_private_consumption",
									label: "Privat konsumtion",
									value: customerState.checkbox_customer_kyc_purpose_private_consumption === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_purpose_private_consumption === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("consumption", checked)
								},
								{
									name: "checkbox_customer_kyc_purpose_financial_security",
									label: "Ekonomisk trygghet",
									value: customerState.checkbox_customer_kyc_purpose_financial_security === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_purpose_financial_security === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("financial_security", checked)
								},
								{
									name: "checkbox_customer_kyc_purpose_beneficiary",
									label: "Till närstående",
									value: customerState.checkbox_customer_kyc_purpose_beneficiary === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_purpose_beneficiary === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("beneficiary", checked)
								},
								{
									name: "checkbox_customer_kyc_purpose_saving",
									label: "Sparande/placering",
									value: customerState.checkbox_customer_kyc_purpose_saving === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_purpose_saving === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("purpose_saving", checked)
								},
								{
									name: "checkbox_customer_kyc_purpose_other",
									label: "Annat (beskriv)",
									value: customerState.checkbox_customer_kyc_purpose_other === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_purpose_other === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("purpose_other", checked)
								},
							]}
						/>
						{customerState.checkbox_customer_kyc_purpose_other === "yes" && (
							<TextField
								name="customer_kyc_purpose_other"
								label="Vänligen beskriv"
								value={customerState.customer_kyc_purpose_other}
								validation={this._textValidation}
								onChange={({target}) => this._onSetOtherText("purpose", target.value)}
							/>
						)}

						<OptionsList
							type="checkbox"
							description="För vems räkning investerar du?"
							orientation="vertical"
							options={[
								{
									name: "checkbox_customer_kyc_inv_own_part",
									label: "För egen del (eller av mig helägt företag)",
									value: customerState.checkbox_customer_kyc_inv_own_part === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_inv_own_part === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("inv_own_part", checked)
								},
								{
									name: "checkbox_customer_kyc_inv_through_poa",
									label: "För någon annan genom fullmakt",
									value: customerState.checkbox_customer_kyc_inv_through_poa === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_inv_through_poa === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("inv_through_poa", checked)
								},
								{
									name: "checkbox_customer_kyc_inv_other",
									label: "Annat (beskriv)",
									value: customerState.checkbox_customer_kyc_inv_other === "yes" ? "yes" : "no",
									checked: customerState.checkbox_customer_kyc_inv_other === "yes",
									onChange: checked => this._onChangeCheckboxForAnl("inv_other", checked)
								},
							]}
						/>
						{customerState.checkbox_customer_kyc_inv_other === "yes" && (
							<TextField
								name="customer_kyc_inv_other"
								label="Vänligen beskriv"
								value={customerState.customer_kyc_inv_other}
								validation={this._textValidation}
								onChange={({target}) =>  this._onSetOtherText("inv_other_text", target.value)}
							/>
						)}

						<OptionsList
							type="radio"
							name="radio_aml_purpose_gains"
							description="Är syftet med att investera i finansiella instrument annat än att nå avkastning?"
							options={[
								{
									label: i18n("general", "yes"),
									value: "yes",
									checked: customerState.purposeGains === "yes"
								},
								{
									label: i18n("general", "no"),
									value: "no",
									checked: customerState.purposeGains === "no"
								},
							]}
							onChange={(checked, purposeGains) => this.setCustomerState({purposeGains})}
						/>
						{customerState.purposeGains === "yes" && (
							<TextField
								name="aml_purpose_gains_text"
								label="Vänligen beskriv"
								value={customerState.purposeGainsText}
								validation={this._textValidation}
								onChange={({target}) => this.setCustomerState({purposeGainsText: target.value})}
							/>
						)}

						<p className="Cob-description">Hur mycket har du/företaget du företräder investerat i
							värdepapper och pengar?</p>
						<NumberField
							name="customer_kyc_sek_invested"
							label={i18n("general", "provide_amount")}
							unit=" SEK"
							value={customerState.customer_kyc_sek_invested || ""}
							onChange={({target}) => this._onSetOtherText("invested_amount", target.value)}
						/>

						<OptionsList
							type="checkbox"
							description="Varifrån kommer huvudsakligen det kapital som du investerar?"
							orientation="vertical"
							options={[
								{
									name: "checkbox_aml_origin_savings",
									label: i18n("general", "savings"),
									value: customerState.originSavings === "yes" ? "yes" : "no",
									checked: customerState.originSavings === "yes",
									onChange: checked => this.setCustomerState({originSavings: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_aml_origin_heritage_gift",
									label: i18n("general", "heritage") + "/" + i18n("general", "gift", true),
									value: customerState.originHeritageGift === "yes" ? "yes" : "no",
									checked: customerState.originHeritageGift === "yes",
									onChange: checked => this.setCustomerState({originHeritageGift: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_aml_origin_salary",
									label: i18n("general", "salary") + "/" + i18n("general", "pension", true),
									value: customerState.originSalary === "yes" ? "yes" : "no",
									checked: customerState.originSalary === "yes",
									onChange: checked => this.setCustomerState({originSalary: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_aml_origin_re_sales",
									label: i18n("general", "real_estate_sales"),
									value: customerState.originRealEstateSales === "yes" ? "yes" : "no",
									checked: customerState.originRealEstateSales === "yes",
									onChange: checked => this.setCustomerState({originRealEstateSales: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_aml_origin_capital_return",
									label: i18n("general", "capital_return"),
									value: customerState.originCapitalReturn === "yes" ? "yes" : "no",
									checked: customerState.originCapitalReturn === "yes",
									onChange: checked => this.setCustomerState({originCapitalReturn: checked ? "yes" : "no"})
								},
								{
									name: "checkbox_aml_origin_other",
									label: "Annat (beskriv)",
									value: customerState.originOther === "yes" ? "yes" : "no",
									checked: customerState.originOther === "yes",
									onChange: checked => this.setCustomerState({originOther: checked ? "yes" : "no"})
								},
							]}
						/>
						{customerState.originOther === "yes" && (
							<TextField
								name="aml_origin_other_desc"
								label={"Vänligen beskriv"}
								value={customerState.originOtherDesc}
								validation={this._textValidation}
								onChange={({target}) => this.setCustomerState({originOtherDesc: target.value})}
							/>
						)}
						{/* <input type="hidden" name="checkbox_aml_origin_other" value={ !!customerState.originOtherDesc ? "yes" : "no" } /> */}

						<OptionsList
							type="checkbox"
							description={"Från vilken bank/försäkringsbolag tar du kapital för dina investeringar?"}
							orientation="vertical"
							options={[
								{
									name: "checkbox_aml_deposit_swe_bank",
									label: "Svensk bank",
									value: customerState.swedishBank === "yes" ? "yes" : "no",
									checked: customerState.swedishBank === "yes",
									onChange: checked => this.setCustomerState({swedishBank: checked ? "yes" : "no"})
								}, {
									name: "checkbox_aml_deposit_swe_insurance",
									label: "Svenskt försäkringsbolag",
									value: customerState.swedishInsurance === "yes" ? "yes" : "no",
									checked: customerState.swedishInsurance === "yes",
									onChange: checked => this.setCustomerState({swedishInsurance: checked ? "yes" : "no"})
								}, {
									label: "Utländsk bank",
									value: customerState.foreignBank === "yes" ? "yes" : "no",
									checked: customerState.foreignBank === "yes",
									onChange: checked => this.setCustomerState({
										foreignBank: checked ? "yes" : "no",
										foreignBankText: checked ? this.state.customerState.foreignBankText : "",
									})
								},
							]}
						/>
						{customerState.foreignBank === "yes" && (
							<TextField
								style={{marginTop: -10, marginBottom: 20, marginLeft: 34}}
								name="aml_deposit_foreign_bank"
								label="Ange utländsk bank"
								value={customerState.foreignBankText}
								validation={this._textValidation}
								onChange={({target}) => this.setCustomerState({foreignBankText: target.value})}
							/>
						)}
						<OptionsList
							style={{marginTop: -10}}
							type="checkbox"
							orientation="vertical"
							options={[
								{
									label: "Utländskt försäkringsbolag",
									value: customerState.foreignInsurance === "yes" ? "yes" : "no",
									checked: customerState.foreignInsurance === "yes",
									onChange: checked => this.setCustomerState({
										foreignInsurance: checked ? "yes" : "no",
										foreignInsuranceText: checked ? this.state.customerState.foreignInsuranceText : "",
									}, () => console.log(this.state.customerState))
								},
							]}
						/>
						{customerState.foreignInsurance === "yes" && (
							<TextField
								style={{marginTop: -10, marginLeft: 34}}
								name="aml_deposit_foreign_insurance"
								label="Ange utländskt försäkringsbolag"
								value={customerState.foreignInsuranceText}
								validation={this._textValidation}
								onChange={({target}) => this.setCustomerState({foreignInsuranceText: target.value})}
							/>
						)}

						<input type="hidden" name="checkbox_customer_kyc_deposit_swedish_bank"
							   value={customerState.swedishBank === "yes" ? "yes" : "no"}/>
						<input type="hidden" name="checkbox_customer_kyc_deposit_swedish_insurance"
							   value={customerState.swedishInsurance === "yes" ? "yes" : "no"}/>
						<input type="hidden" name="checkbox_customer_kyc_deposit_foreign_bank"
							   value={customerState.foreignBank === "yes" ? "yes" : "no"}/>
						<input type="hidden" name="checkbox_customer_kyc_deposit_foreign_insurance"
							   value={customerState.foreignInsurance === "yes" ? "yes" : "no"}/>
						<input type="hidden" name="checkbox_customer_kyc_deposit_other" value={
							!!(customerState.foreignBankText || customerState.foreignInsuranceText) ? "yes" : "no"
						}/>
						{!!(customerState.foreignBankText || customerState.foreignInsuranceText) && (
							<input type="hidden" name="customer_kyc_deposit_other" value={
								`Bank: ${customerState.foreignBankText || '-'} | Försäkringsbolag: ${customerState.foreignInsuranceText || '-'}`
							}/>
						)}

						<StackLayout className="Cob-fields-collection" alignItems="end" rowWrap fullWidth>
							<div>
								<p className="Cob-description">Hur stora enskilda transaktioner i finansiella instrument
									gör du normalt?</p>
								<Select
									name="radio_aml_transaction_size"
									label={i18n("general", "options")}
									selectedIndex={customerState.transactionSizeIndex}
									options={[
										{label: i18n("cob", "max_100000_sek"), value: "max100k"},
										{label: i18n("cob", "100000_500000_sek"), value: "100k-500k"},
										{label: i18n("cob", "over_500000_sek"), value: "over500k"},
									]}
									onChange={(option, transactionSizeIndex) => this.setCustomerState({
										transactionSize: option.value,
										transactionSizeIndex
									})}
								/>
							</div>
						</StackLayout>

						<OptionsList
							type="radio"
							name="radio_customer_pep"
							description={"Har du eller en nära familjemedlem till dig eller en nära medarbetare till dig haft någon mycket hög politisk eller annan offentlig befattning i Sverige eller utomlands, till exempel minister, riksdagsledamot, riksrevisor, hög officer, diplomat, ledningsperson i internationell organisation, tex FN eller Europarådet?"}
							options={[
								{label: i18n("general", "yes"), value: "yes", checked: customerState.isPep === "yes"},
								{label: i18n("general", "no"), value: "no", checked: customerState.isPep === "no"},
							]}
							onChange={(checked, isPep) => this.setCustomerState({isPep})}
						/>

						{customerState.isPep === "yes" && (
							<StackLayout className="Cob-fields-collection" rowWrap fullWidth>
								<div>
									<TextField
										name="kyc_pep_family_relation"
										label={i18n("general", "relation")}
										value={customerState.pepFamilyRelation}
										validation={this._textValidation}
										onChange={({target}) => this.setCustomerState({pepFamilyRelation: target.value})}
									/>
								</div>
								<div>
									<TextField
										name="kyc_pep_family_name"
										label={i18n("general", "name")}
										value={customerState.pepFamilyName}
										validation={this._textValidation}
										onChange={({target}) => this.setCustomerState({pepFamilyName: target.value})}
									/>
								</div>
								<div>
									<TextField
										name="kyc_pep_family_position"
										label={i18n("general", "position")}
										value={customerState.pepFamilyPosition}
										validation={this._textValidation}
										onChange={({target}) => this.setCustomerState({pepFamilyPosition: target.value})}
									/>
								</div>
								<div>
									<Select
										name="kyc_pep_family_country"
										label={i18n("general", "country")}
										selectedIndex={customerState.pepFamilyCountryIndex}
										options={this.countries}
										onChange={({value: pepFamilyCountry}, pepFamilyCountryIndex) => this.setCustomerState({
											pepFamilyCountry,
											pepFamilyCountryIndex
										})}
									/>
								</div>
								<div>
									<TextField
										name="kyc_pep_family_period"
										label={i18n("general", "period")}
										value={customerState.pepFamilyPeriod}
										validation={this._textValidation}
										onChange={({target}) => this.setCustomerState({pepFamilyPeriod: target.value})}
									/>
								</div>
							</StackLayout>
						)}

					</div>
				</ContentBox>
			</div>
		);
	}

	// Internal methods
	_textValidation = (value) => (value.length > 0);

	_numberValidation = (value) => (value.length > 0 && !isNaN(value));

	_onChangeCheckboxForAnl(type, checked) {
		const anlAsString = this._getNewAnlValue(type, checked ? "yes" : "no");

		if (type === 'saving') {
			this.setCustomerState({
				checkbox_customer_reason_business_relationship_saving: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'assetmanagement') {
			this.setCustomerState({
				checkbox_customer_reason_business_relationship_assetmanagement: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'other') {
			this.setCustomerState({
				checkbox_customer_reason_business_relationship_other: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'pension') {
			this.setCustomerState({
				checkbox_customer_kyc_purpose_pension: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'consumption') {
			this.setCustomerState({
				checkbox_customer_kyc_purpose_private_consumption: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'financial_security') {
			this.setCustomerState({
				checkbox_customer_kyc_purpose_financial_security: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'beneficiary') {
			this.setCustomerState({
				checkbox_customer_kyc_purpose_beneficiary: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'purpose_saving') {
			this.setCustomerState({
				checkbox_customer_kyc_purpose_saving: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'purpose_other') {
			this.setCustomerState({
				checkbox_customer_kyc_purpose_other: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'inv_own_part') {
			this.setCustomerState({
				checkbox_customer_kyc_inv_own_part: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'inv_through_poa') {
			this.setCustomerState({
				checkbox_customer_kyc_inv_through_poa: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		} else if (type === 'inv_other') {
			this.setCustomerState({
				checkbox_customer_kyc_inv_other: checked ? "yes" : "no",
				customer_anl: anlAsString
			});
		}
	}

	_onSetOtherText(type, value) {
		if (type === 'relationship') {
			this.setCustomerState({
				customer_reason_business_relationship_other: value,
				customer_anl: this._getNewAnlValue("other_text", value)
			});
		} else if (type === 'purpose') {
			this.setCustomerState({
				customer_kyc_purpose_other: value,
				customer_anl: this._getNewAnlValue("purpose_other_text", value)
			});
		} else if (type === 'invested_amount') {
			this.setCustomerState({
				customer_kyc_sek_invested: value,
				customer_anl: this._getNewAnlValue("invested_amount", value)
			});
		} else if (type === 'inv_other_text') {
			this.setCustomerState({
				customer_kyc_inv_other: value,
				customer_anl: this._getNewAnlValue("inv_other_text", value)
			});
		}
	}

	_getNewAnlValue(keyToChange, valueToChange) {
		let customerAnlObject;
		if (this.state.customerState.customer_anl === "") {
			customerAnlObject = {};
		} else {
			customerAnlObject = JSON.parse(this.state.customerState.customer_anl);
		}

		if (valueToChange === "no" || valueToChange === "") {
			delete customerAnlObject[keyToChange];
		} else {
			customerAnlObject[keyToChange] = valueToChange;
		}

		return JSON.stringify(customerAnlObject);
	}
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, viewNo, hasFormValidationErrors} = this.props;
  const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
  const hasBeenSeen = (seenViews.includes(viewNo));   
  // if it hasn't been seen add the view to local storage for the specific case
  if (customer.caseId && !hasBeenSeen) {
    localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
  }

	let newState = getViewInitialStateForCustomer(customer);  
	newState.isFulfilled = this.isFulfilled(newState);  
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer) {

	const newState = {
		customerState: {
			customer_anl: customer.getData("customer_anl"),
			purposeGains: customer.getData("radio_aml_purpose_gains", null),
			purposeGainsText: customer.getData("aml_purpose_gains_text"),
			originSavings: customer.getData("checkbox_aml_origin_savings", null),
			originHeritageGift: customer.getData("checkbox_aml_origin_heritage_gift", null),
			originSalary: customer.getData("checkbox_aml_origin_salary", null),
			originRealEstateSales: customer.getData("checkbox_aml_origin_re_sales", null),
			originCapitalReturn: customer.getData("checkbox_aml_origin_capital_return", null),
			originOther: customer.getData("checkbox_aml_origin_other"),
			originOtherDesc: customer.getData("aml_origin_other_desc"),
			swedishBank: customer.getData("checkbox_aml_deposit_swe_bank", null),
			swedishInsurance: customer.getData("checkbox_aml_deposit_swe_insurance", null),
			foreignBank: customer.getData("aml_deposit_foreign_bank") ? "yes" : "no",
			foreignInsurance: customer.getData("aml_deposit_foreign_insurance") ? "yes" : "no",
			foreignBankText: customer.getData("aml_deposit_foreign_bank"),
			foreignInsuranceText: customer.getData("aml_deposit_foreign_insurance"),
			transactionSize: customer.getData("radio_aml_transaction_size"),
			transactionSizeIndex: (
				customer.getData("radio_aml_transaction_size") === "max100k" ? 0 :
					customer.getData("radio_aml_transaction_size") === "100k-500k" ? 1 :
						customer.getData("radio_aml_transaction_size") === "over500k" ? 2 :
							-1
			),
			isPep: customer.getData("radio_customer_pep", null),
			pepFamilyRelation: customer.getData("kyc_pep_family_relation"),
			pepFamilyName: customer.getData("kyc_pep_family_name"),
			pepFamilyPosition: customer.getData("kyc_pep_family_position"),
			pepFamilyCountry: customer.getData("kyc_pep_family_country"),
			pepFamilyCountryIndex: countries.map(c => c.code).indexOf(customer.getData("kyc_pep_family_country")),
			pepFamilyPeriod: customer.getData("kyc_pep_family_period"),

			// New fields (CN-32)
			checkbox_customer_reason_business_relationship_saving: customer.getData("checkbox_customer_reason_business_relationship_saving") || "no",
			checkbox_customer_reason_business_relationship_assetmanagement: customer.getData("checkbox_customer_reason_business_relationship_assetmanagement") || "no",
			checkbox_customer_reason_business_relationship_other: customer.getData("checkbox_customer_reason_business_relationship_other") || "no",
			customer_reason_business_relationship_other: customer.getData("customer_reason_business_relationship_other"),
			checkbox_customer_kyc_purpose_pension: customer.getData("checkbox_customer_kyc_purpose_pension") || "no",
			checkbox_customer_kyc_purpose_private_consumption: customer.getData("checkbox_customer_kyc_purpose_private_consumption") || "no",
			checkbox_customer_kyc_purpose_financial_security: customer.getData("checkbox_customer_kyc_purpose_financial_security") || "no",
			checkbox_customer_kyc_purpose_relative: customer.getData("checkbox_customer_kyc_purpose_relative") || "no",
			checkbox_customer_kyc_purpose_beneficiary: customer.getData("checkbox_customer_kyc_purpose_beneficiary") || "no",
			checkbox_customer_kyc_purpose_saving: customer.getData("checkbox_customer_kyc_purpose_saving") || "no",
			checkbox_customer_kyc_purpose_other: customer.getData("checkbox_customer_kyc_purpose_other") || "no",
			customer_kyc_purpose_other: customer.getData("customer_kyc_purpose_other"),
			checkbox_customer_kyc_inv_own_part: customer.getData("checkbox_customer_kyc_inv_own_part") || "no",
			checkbox_customer_kyc_inv_through_poa: customer.getData("checkbox_customer_kyc_inv_through_poa") || "no",
			checkbox_customer_kyc_inv_other: customer.getData("checkbox_customer_kyc_inv_other") || "no",
			customer_kyc_inv_other: customer.getData("customer_kyc_inv_other"),
			customer_kyc_sek_invested: customer.getData("customer_kyc_sek_invested"),
		}
	};

	/**
	 * Some fields are not saved in the template always, these are stored in a hidden field called customer_anl that
	 * we read from to figure out what the form should be pre-filled with in that case.
	 */
	if (newState.customerState.customer_anl !== "") {
		let customerAnlObject = JSON.parse(newState.customerState.customer_anl);

		/**
		 * Checkboxes
		 */
		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_reason_business_relationship_saving", "saving", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_reason_business_relationship_assetmanagement", "assetmanagement", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_reason_business_relationship_other", "other", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_purpose_pension", "pension", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_purpose_private_consumption", "consumption", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_purpose_financial_security", "financial_security", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_purpose_beneficiary", "beneficiary", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_purpose_saving", "purpose_saving", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_purpose_other", "purpose_other", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_inv_own_part", "inv_own_part", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_inv_own_part", "inv_own_part", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_inv_through_poa", "inv_through_poa", "no");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"checkbox_customer_kyc_inv_other", "inv_other", "no");


		/**
		 * Free text fields
		 */
		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"customer_reason_business_relationship_other", "other_text", "");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"customer_kyc_purpose_other", "purpose_other_text", "");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"customer_kyc_inv_other", "inv_other_text", "");

		_setValueFromAnlIfEmpty(newState.customerState, customerAnlObject,
			"customer_kyc_sek_invested", "invested_amount", "");
	}

	newState.isFulfilled = false;

	return newState;
}

function _setValueFromAnlIfEmpty(stateObject, customerAnlObject, stateKey, anlKey, checkValue) {
	if ((stateObject[stateKey] === null || stateObject[stateKey] === checkValue) && anlKey in customerAnlObject) {
		stateObject[stateKey] = customerAnlObject[anlKey];
	}
}

function _getFields() {
	return [
		"customer_anl",

		"radio_aml_purpose_gains",
		"aml_purpose_gains_text",
		"checkbox_aml_origin_savings",
		"checkbox_aml_origin_heritage_gift",
		"checkbox_aml_origin_salary",
		"checkbox_aml_origin_re_sales",
		"checkbox_aml_origin_capital_return",
		"checkbox_aml_origin_other",
		"aml_origin_other_desc",
		"checkbox_aml_deposit_swe_bank",
		"checkbox_aml_deposit_swe_insurance",
		"aml_deposit_foreign_bank",
		"aml_deposit_foreign_insurance",
		"radio_aml_transaction_size",
		"radio_customer_pep",
		"kyc_pep_family_relation",
		"kyc_pep_family_name",
		"kyc_pep_family_position",
		"kyc_pep_family_country",
		"kyc_pep_family_period",

		// New fields (CN-32)
		"checkbox_customer_reason_business_relationship_saving",
		"checkbox_customer_reason_business_relationship_assetmanagement",
		"checkbox_customer_reason_business_relationship_other",
		"customer_reason_business_relationship_other",
		"checkbox_customer_kyc_purpose_pension",
		"checkbox_customer_kyc_purpose_private_consumption",
		"checkbox_customer_kyc_purpose_financial_security",
		"checkbox_customer_kyc_purpose_relative",
		"checkbox_customer_kyc_purpose_beneficiary",
		"checkbox_customer_kyc_purpose_saving",
		"checkbox_customer_kyc_purpose_other",
		"customer_kyc_purpose_other",
		"checkbox_customer_kyc_inv_own_part",
		"checkbox_customer_kyc_inv_through_poa",
		"checkbox_customer_kyc_inv_other",
		"customer_kyc_inv_other",
		"customer_kyc_sek_invested",

		// New fields (CN-85)
		"checkbox_customer_kyc_deposit_swedish_bank",
		"checkbox_customer_kyc_deposit_swedish_insurance",
		"checkbox_customer_kyc_deposit_foreign_bank",
		"checkbox_customer_kyc_deposit_foreign_insurance",
		"checkbox_customer_kyc_deposit_other",
		"customer_kyc_deposit_other",
	];
}