import React from "react";
import PropTypes from "prop-types";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class TextView extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        maxLength: PropTypes.number,
        tooltip: PropTypes.string,
        invalidMessage: PropTypes.string,
        resize: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool.isRequired,
        disabled: PropTypes.bool.isRequired,
        fullWidth: PropTypes.bool.isRequired,
        minHeight: PropTypes.number,
        hideRequiredSymbol: PropTypes.bool.isRequired,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    }

    static defaultProps = {
        resize: "none",
        required: false,
        readOnly: false,
        disabled: false,
        fullWidth: false,
        hideRequiredSymbol: false,
    }

    state = {
        filled: false,
        focused: false,
        success: false,
        invalid: false,
    }
    
    get value() { return this.input.value }

    componentDidMount() {
        _validationAsync.call(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps !== this.props) _validationAsync.call(this);
    }

    render() {
        const { props, state } = this;
        const {
            style, id, name, label, placeholder, required, readOnly, disabled, hideRequiredSymbol,
            value, maxLength, tooltip, resize, fullWidth, minHeight, onChange,
        } = props;
        const classes = createClassName(props.className, {
            "TextView": true,
            "focus": state.focused,
            "disabled": disabled,
            "placeholder": !!placeholder,
            "filled": state.filled || !!value,
            "required": required,
            "tooltip": !!tooltip,
            "invalid": state.invalid,
            "success": state.success,
            "resize-none": resize === "none",
            "resize-both": !resize || resize === "both",
            "resize-horizontal": resize === "horizontal",
            "resize-vertical": resize === "vertical",
            "full-width": fullWidth,
            "custom-height": minHeight,
        });

        return (
            <div className={ classes } style={ style }>
                <label className="TextView-label">{ (state.invalid && props.invalidMessage) || label }</label>
                <textarea
                    style={ minHeight ? { minHeight } : undefined }
                    ref={ ref => this.input = ref }
                    className="TextView-input"
                    id={ id }
                    name={ name }
                    placeholder={ placeholder }
                    value={ value }
                    maxLength={ maxLength }
                    required={ required }
                    disabled={ disabled }
                    readOnly={ readOnly }
                    onFocus={ !disabled ? this._onFocus : undefined }
                    onBlur={ !disabled ? this._onBlur : undefined }
                    onInput={ !disabled ? this._onInput : undefined }
                    onKeyDown={ !disabled ? this._onKeyDown : undefined }
                    onChange={ !disabled ? onChange : undefined }
                />
                { !!tooltip ? 
                    <TextViewTooltip text={ tooltip } />
                : state.success ?
                    <TextViewStatus icon="done" />
                : required === true && !hideRequiredSymbol ?
                    <TextViewStatus requiredSymbol />
                : state.invalid ?
                    <TextViewStatus icon="error" />
                : null }
            </div>
        );
    }

    // Internal methods
    _onFocus = (args) => {
        const { onFocus } = this.props;
        this.setState({ focused: true }, onFocus ? onFocus(args) : null);
    }

    _onBlur = (args) => {
        const { onBlur, validation } = this.props;
        const inputValue = this.input.value;
        const newState = { focused: false };

        if(inputValue && validation) {
            if(validation(inputValue) === true) {
                newState.invalid = false;
                newState.success = true;
            } else {
                newState.invalid = true;
                newState.success = false;
            }
        }

        this.setState(newState, onBlur ? onBlur(args) : null);
    }

    _onKeyDown = (e) => {
        if (e.key === "Enter"){
            e.preventDefault();
        }
    }

    _onInput = (args) => {
        const { onInput } = this.props;
        _validationAsync.call(this, onInput || undefined);
    }
}


// PRIVATE COMPONENTS
function TextViewTooltip({ text, onClick }) {
    return (
        <div className="TextView-tooltip">
            <div className="TextView-tooltip-icon">?</div>
            <div className="TextView-tooltip-text">{ text }</div>
        </div>
    );
}

function TextViewStatus({ icon, requiredSymbol }) {
    return (
        <div className="TextView-status">
            <div className={ createClassName("material-icons", {
                "icon": !!icon,
                "required-symbol": requiredSymbol === true,
            })}>{ requiredSymbol === true ? "*" : icon }</div>
        </div>
    );
}


// PRIVATE FUNCTIONS
function _validationAsync(callback) {
    return new Promise(resolve => {
        const { value, validation, success, invalid, highlightErrors } = this.props;
        const inputValue = this.input.value || value || "";
        const newState = { filled: !!inputValue };

        if(validation) {
            if(validation(inputValue) === true) {
                newState.success = true;
                newState.invalid = false;
            } else {
                newState.success = false;
            }
        } else if(success === true) {
            newState.success = true;
            newState.invalid = false;
        } else if(invalid === true) {
            newState.success = false;
            newState.invalid = true;
        }
        
		    if (!inputValue) newState.invalid = validation && highlightErrors ? true : false;

        this.setState(newState, () => {
            if(callback) callback(inputValue);
            resolve();
        });
    });
}