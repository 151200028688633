import {mergeViewStateForFulfillment} from "../../Cob.helpers";


export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	return !!(
		customerState.radio_advice_customer_risk
		&& (
			customerState.radio_advice_customer_risk !== "partial_risk"
			|| customerState.advice_customer_risk.length > 0
		)
	);
}