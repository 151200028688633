import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./Risk.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import TextView from "../../../../../components/TextView/TextView";
import Select from "../../../../../components/Select/Select";
import i18n from "@helpers/i18n";

export default class Risk extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render()
	{
		const {customerState} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		let riskExpectedReturnOptions = [
			{
				label: i18n("risk", "in_line_bank_deposit_rates"),
				value: "I linje med bankkontoräntan",
				checked: customerState.risk_expected_return === "I linje med bankkontoräntan"
			},
			{
				label: i18n("risk", "above_bank_deposit_rates"),
				value: "Något över bankkontoräntan",
				checked: customerState.risk_expected_return === "Något över bankkontoräntan"
			},
			{
				label: i18n("risk", "in_line_stock_market"),
				value: "I linje med aktiemarknaden",
				checked: customerState.risk_expected_return === "I linje med aktiemarknaden"
			},
			{
				label: i18n("risk", "defined_return_goal"),
				value: "Det finns ett definierat avkastningsmål",
				checked: customerState.risk_expected_return === "Det finns ett definierat avkastningsmål"
			}
		];
		let riskDefinedOptions = [
			{
				label: "1%",
				value: "1%",
				checked: customerState.risk_defined_return === "1%"
			},
			{
				label: "3%",
				value: "3%",
				checked: customerState.risk_defined_return === "3%"
			},
			{
				label: "5%",
				value: "5%",
				checked: customerState.risk_defined_return === "5%"
			},
			{
				label: "7%",
				value: "7%",
				checked: customerState.risk_defined_return === "7%"
			},
			{
				label: "10%",
				value: "10%",
				checked: customerState.risk_defined_return === "10%"
			},
			{
				label: "15%",
				value: "15%",
				checked: customerState.risk_defined_return === "15%"
			},
			{
				label: "20%",
				value: "20%",
				checked: customerState.risk_defined_return === "20%"
			}
		];
		let compareOptions = [
			{
				label: i18n("risk", "compare_swedish_stock_exchange"),
				value: "Jag jämför mitt resultat med svenska aktier/Stockholmsbörsen.",
				checked: customerState.risk_portfolio_compared === "Jag jämför mitt resultat med svenska aktier/Stockholmsbörsen."
			},
			{
				label: i18n("risk", "compare_global_stock_portfolio"),
				value: "Jag jämför mitt resultat med en global aktieportfölj.",
				checked: customerState.risk_portfolio_compared === "Jag jämför mitt resultat med en global aktieportfölj."
			},
			{
				label: i18n("risk", "have_specific_benchmark"),
				value: "Jag har ett specifikt benchmark.",
				checked: customerState.risk_portfolio_compared === "Jag har ett specifikt benchmark."
			},
			{
				label: i18n("risk", "compare_portfolio_fund_manager"),
				value: "Jag jämför min portfölj med en fond/förvaltare.",
				checked: customerState.risk_portfolio_compared === "Jag jämför min portfölj med en fond/förvaltare."
			}
		];
		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("risk", "risk_tolerance")}</h2>

					<div className="Cob-view2-fields">

						<h3>{i18n("risk", "view_of_risk")}</h3>

						<strong style={{marginTop: "20px"}}>{i18n("risk", "expected_return_annual")}?*</strong>

						<Select label={i18n("components", "select")}
								name={"risk_expected_return"}
								selectedIndex={riskExpectedReturnOptions.map(c => c.value).indexOf(customerState.risk_expected_return)}
								options={riskExpectedReturnOptions}
								onChange={(value) => this.setCustomerState({risk_expected_return: value.value})}/>

						<strong style={{marginTop: "20px"}}>{i18n("risk", "return_goal")}*</strong>
						<Select label={i18n("components", "select")}
								name={"risk_defined_return"}
								selectedIndex={riskDefinedOptions.map(c => c.value).indexOf(customerState.risk_defined_return)}
								options={riskDefinedOptions}
								onChange={(value) => this.setCustomerState({risk_defined_return: value.value})}/>

						{this.state.workflow !== "futur" && (
							<React.Fragment>
								<h4>{i18n("risk", "investment_philosophy")}?*</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_investment_philosophy"
									options={[
										{
											label: i18n("risk", "risk_control_focus"),
											value: "Fokus på att kontrollera risken för förlust och accepterar därmed en förhållandevis låg avkastning.",
											checked: customerState.risk_investment_philosophy === "Fokus på att kontrollera risken för förlust och accepterar därmed en förhållandevis låg avkastning."
										},
										{
											label: i18n("risk", "accept_some_risk"),
											value: "Accepterar viss risk för att få högre potential till avkastning.",
											checked: customerState.risk_investment_philosophy === "Accepterar viss risk för att få högre potential till avkastning."
										},
										{
											label: i18n("risk", "focus_on_returns"),
											value: i18n("risk", "risk_control_focus"),
											checked: customerState.risk_investment_philosophy === "Fokus på avkastning och kan därmed acceptera stora fluktuationer i värdet på investeringarna."
										}
									]}
									onChange={(checked, risk_investment_philosophy) => this.setCustomerState({risk_investment_philosophy})}
								/>

								<h4>
                  {i18n("risk", "percepcion_investments_based_on_news")}?*
								</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_affected_by_negative_news"
									options={[
										{
											label: i18n("risk", "not_significantly"),
											value: "Inte nämnvärt och förosakar ingen oro över portföljen.",
											checked: customerState.risk_affected_by_negative_news === "Inte nämnvärt och förosakar ingen oro över portföljen."
										},
										{
											label: i18n("risk", "negative_news_increased_engagement"),
											value: "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.",
											checked: customerState.risk_affected_by_negative_news === "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna."
										},
										{
											label: i18n("risk", "negative_news_portfolio_changes"),
											value: "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.",
											checked: customerState.risk_affected_by_negative_news === "Negativa nyheter innebär att portföljen förändras för att följa experternas tips."
										},
										{
											label: i18n("risk", "increased_attention_acts_calmly"),
											value: "Ökad uppmärksamhet på portföljen men agerar med is i magen.",
											checked: customerState.risk_affected_by_negative_news === "Ökad uppmärksamhet på portföljen men agerar med is i magen."
										}
									]}
									onChange={(checked, risk_affected_by_negative_news) => this.setCustomerState({risk_affected_by_negative_news})}
								/>

								<h4>{i18n("risk", "reaction_portfolio_decrease")}?*</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_reaction_to_drawdown"
									options={[
										{
											label: i18n("risk", "willingness_to_reduce_risk"),
											value: "En vilja att minska risken i portföljen och till exempel sälja aktier.",
											checked: customerState.risk_reaction_to_drawdown === "En vilja att minska risken i portföljen och till exempel sälja aktier."
										},
										{
											label: i18n("risk", "portfolio_remains_intact"),
											value: "Portföljen lämnas intakt, men minska ned på utdelningen från portföljen.",
											checked: customerState.risk_reaction_to_drawdown === "Portföljen lämnas intakt, men minska ned på utdelningen från portföljen."
										},
										{
											label: i18n("risk", "contact_advisor_to_discuss"),
											value: "Kontakta rådgivaren för att diskutera portföljen.",
											checked: customerState.risk_reaction_to_drawdown === "Kontakta rådgivaren för att diskutera portföljen."
										},
										{
											label: i18n("risk", "none_natural"),
											value: "Ingen, det är naturligt med stora svängningar på marknaderna.",
											checked: customerState.risk_reaction_to_drawdown === "Ingen, det är naturligt med stora svängningar på marknaderna."
										},
										{
											label: i18n("risk", "opportunity_capitalize"),
											value: "En möjlighet att ta till vara på och öka risken och exempelvis köpa aktier.",
											checked: customerState.risk_reaction_to_drawdown === "En möjlighet att ta till vara på och öka risken och exempelvis köpa aktier."
										}
									]}
									onChange={(checked, risk_reaction_to_drawdown) => this.setCustomerState({risk_reaction_to_drawdown})}
								/>

								<h4>{i18n("risk", "reaction_25_decrease")}</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_reaction_to_drawdown_40"
									options={[
										{
											label: i18n("risk", "none_natural_25"),
											value: "Ingen, stora och små rörelser är en naturlig del av investeringar.",
											checked: customerState.risk_reaction_to_drawdown_40 === "Ingen, stora och små rörelser är en naturlig del av investeringar."
										},
										{
											label: i18n("risk", "contact_advisor_to_discuss_25"),
											value: "Kontakta rådgivaren för att diskutera portföljen.",
											checked: customerState.risk_reaction_to_drawdown_40 === "Kontakta rådgivaren för att diskutera portföljen."
										},
										{
											label: i18n("risk", "causes_stress"),
											value: "Små svängningar kan jag förstå, men stora fall gör mig klart stressad.",
											checked: customerState.risk_reaction_to_drawdown_40 === "Små svängningar kan jag förstå, men stora fall gör mig klart stressad."
										}
									]}
									onChange={(checked, risk_reaction_to_drawdown_40) => this.setCustomerState({risk_reaction_to_drawdown_40})}
								/>

								<h4>{i18n("risk", "reaction_20_5")}?*
								</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_reaction_to_lower_returns"
									options={[
										{
											label: i18n("risk", "frustration_missed_opportunity"),
											value: "Frustration över den missade möjligheten och av att halka efter börsen.",
											checked: customerState.risk_reaction_to_lower_returns === "Frustration över den missade möjligheten och av att halka efter börsen."
										},
										{
											label: i18n("risk", "no_significant_reaction_20_5"),
											value: "Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj.",
											checked: customerState.risk_reaction_to_lower_returns === "Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj."
										},
										{
											label: i18n("risk", "contact_advisor_to_discuss_20_5"),
											value: "Samtal med rådgivaren för att förstå varför portföljen presterar sämre.",
											checked: customerState.risk_reaction_to_lower_returns === "Samtal med rådgivaren för att förstå varför portföljen presterar sämre."
										},
										{
											label: i18n("risk", "none_natural_20_5"),
											value: "Ingen, portföljen är balanserad och kommer inte att hänga med enskilda marknadsrörelser.",
											checked: customerState.risk_reaction_to_lower_returns === "Ingen, portföljen är balanserad och kommer inte att hänga med enskilda marknadsrörelser."
										}
									]}
									onChange={(checked, risk_reaction_to_lower_returns) => this.setCustomerState({risk_reaction_to_lower_returns})}
								/>
							</React.Fragment>
						)}

						<h4>{i18n("risk", "portfolio_comparison")}?*</h4>
						<Select label={i18n("components", "select")}
								name={"risk_portfolio_compared"}
								selectedIndex={compareOptions.map(c => c.value).indexOf(customerState.risk_portfolio_compared)}
								options={compareOptions}
								onChange={(value) => this.setCustomerState({risk_portfolio_compared: value.value})}/>

						<h4>{i18n("risk", "comment_risk_return_expectations")}</h4>
						<TextView
							name="risk_comment"
							label={`${i18n("general", "enter")} (${200 - customerState.risk_comment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.risk_comment}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({risk_comment: target.value})}
							fullWidth
							minHeight={100}
						/>

					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, currentWorkflow} = this.props;
	let newState = getViewInitialStateForCustomer(customer, currentWorkflow);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer, workflow)
{
	workflow = workflow == null ? "default" : workflow;

	return {
		workflow: workflow,
		customerState: {
			risk_expected_return: customer.getData("risk_expected_return") || "",
			risk_defined_return: customer.getData("risk_defined_return") || "",
			risk_investment_philosophy: customer.getData("risk_investment_philosophy") || "",
			risk_affected_by_negative_news: customer.getData("risk_affected_by_negative_news") || "",
			risk_reaction_to_drawdown: customer.getData("risk_reaction_to_drawdown") || "",
			risk_reaction_to_drawdown_40: customer.getData("risk_reaction_to_drawdown_40") || "",
			risk_reaction_to_lower_returns: customer.getData("risk_reaction_to_lower_returns") || "",
			risk_portfolio_compared: customer.getData("risk_portfolio_compared") || "",
			risk_comment: customer.getData("risk_comment") || "",
		}
	};
}

function _getFields()
{
	return [
		"risk_expected_return",
		"risk_defined_return",
		"risk_investment_philosophy",
		"risk_affected_by_negative_news",
		"risk_reaction_to_drawdown",
		"risk_reaction_to_drawdown_40",
		"risk_reaction_to_lower_returns",
		"risk_portfolio_compared",
		"risk_comment",
	];
}