import React from "react";

// CLASSES
import Validator from "@classes/Validator";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import Button from "@components/Button/Button";
import Checkbox from "@components/Checkbox/Checkbox";
import CompanyReps from "./subcomponents/CompanyReps/CompanyReps";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../components/DefaultFields/DefaultFields";
import Modal from "@components/Modal/Modal";
import OptionsList from "../../components/OptionsList/OptionsList";
import Select from "@components/Select/Select";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
import TextField from "@components/TextField/TextField";

// HELPERS
import {createClassName, loop} from "@helpers/utils";
import i18n from "@helpers/i18n";
import countries from "@helpers/countries";

// OTHER
import fulfillment from "./CustomerInformation.fulfillment";
import Notice from "../../../../components/Notice/Notice";
import PartnerHiddenFields from "../../components/PartnerHiddenFields/PartnerHiddenFields";

export default class CustomerInformation extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);
	countries = countries.map(({label, code}) => ({label, value: code}));

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	setLookupInfo(lookupInfo) {
		const {props, state} = this;
		const {customerState} = state;
		const {isCompany} = props.customer;

		const clientInfoPhone = lookupInfo.client.phone && lookupInfo.client.phone !== "-" ? lookupInfo.client.phone : "";

		this.setCustomerState({
			firstName: customerState.firstName || lookupInfo[isCompany ? "companyName" : "firstName"] || "",
			lastName: isCompany ? "" : (customerState.lastName || lookupInfo.lastName || ""),
			street: customerState.street || lookupInfo.street || "",
			zipCode: customerState.zipCode || lookupInfo.zipCode || "",
			city: customerState.city || lookupInfo.city || "",
			email: customerState.email || lookupInfo.email || lookupInfo.client.email || "",
			phoneNumber: customerState.phoneNumber || lookupInfo.phoneNumber || clientInfoPhone || "",
			leiCode: customerState.leiCode || lookupInfo.client.lei_code || "",
		});
	}

	async componentDidMount() {
		const {props} = this;
		const {customer, history, setAppState} = props;
		const ssnOrg = customer.getData("customer_social_security_number", null);

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {disabled: true},
				next: {}
			}
		});

		if (!!ssnOrg) {
			if (!customer.lookupInfo) {
				try {
					await customer.lookupAsync();
					const lookupNotFound = customer.lookupInfo.type === undefined;
					this.setState({isLoading: false, lookupNotFound}, () => this.setLookupInfo(customer.lookupInfo));
				}
				catch (e) {
					if (e.message === 'FRAUD_FLAG') {
						console.warn("Customer information locked. Case can not be completed.");
						this.setState({isLoading: false, lookupNotFound: true, fraudFlag: true});
					}
					else {
						console.warn("Customer information not found. Information must be entered manually.");
						this.setState({isLoading: false, lookupNotFound: true, fraudFlag: false});
					}
				}
			}
			else {
				this.setState({isLoading: false}, () => this.setLookupInfo(customer.lookupInfo));
			}
		}
		else {
			history.push("/cob/1");
		}
	}

	render() {
		const {props, state} = this;
		const {user, customer, onRestartSession} = props;
		const {customerState, lookupNotFound} = state;
		const classes = createClassName("Cob-view Cob-view2", {});

		/**
		 * The address of the customer is fetched via a lookup service. The address can be changed by the advisor, but
		 * in that case we would want this to be visible in the final documentation (ie the PDF).
		 */
		const haveWeModifiedTheAddress = (
			customer.lookupInfo != null &&
			(customer.lookupInfo.street !== customerState.street ||
				customer.lookupInfo.zipCode !== customerState.zipCode ||
				customer.lookupInfo.city !== customerState.city)
		);

		if (state.isLoading) {
			return <ActivityIndicator busy/>;
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>
				<PartnerHiddenFields customer={customer} user={user} />
				<div style={{display: "none"}}>
					<input type="hidden" name="customer_phonenumber_cell" value={customerState.phoneNumber || "-"}/>
				</div>

				<Modal visible={state.fraudFlag} status="danger" title="Personnummer låst">
					<React.Fragment>
						<p>
							Kundens personnummer är låst via en extern ID-spärr.
							Vänligen kontakta Backoffice.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label="Avsluta rådgivning" appearance="primary" onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<Modal visible={customerState.isNonEuPerson === true || customerState.isUsPerson === "yes"} status="danger" title="Oops!">
					<React.Fragment>
						{!!state.errorMessage && <p>{state.errorMessage}</p>}

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  isNonEuPerson: false,
											  isUsPerson: this.state.customerState.isUsPerson === "yes" ? null : this.state.customerState.isUsPerson
										  })}/>
							<Modal.Button label="Avsluta rådgivning" appearance="primary"
										  onClick={onRestartSession} filled/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>{i18n("cob", "customer_information")}</h2>

					{customer.isCompany && (

						<React.Fragment>
							<div style={{
								textAlign: "left",
								display: "block",
								margin: "0 0 20px 0",
								padding: "20px",
								background: "#F7F7F7",
								borderRadius: "3px",
								maxWidth: "500px"
							}}>
								<strong
									style={{
										display: "block",
										margin: "0 0 5px 0"
									}}>Notera</strong>
								Rådgivningen gäller företag, glöm därför inte att i steg 14 (Övriga dokument) bifoga
								reg. bevis samt vidimerad ID-kopia på verkliga huvudmän.
							</div>
						</React.Fragment>
					)}

					<div className="Cob-view2-fields">
						{lookupNotFound && !customer.isCompany && (
							<React.Fragment>
								<StackLayout orientation="vertical" alignItems="start">
									<Checkbox
										name="checkbox_customer_foreigner"
										label={i18n("cob", "lives_overseas_foreign_citizen")}
										checked={customerState.isForeigner}
										onChange={isForeigner => this.setCustomerState({isForeigner})}
									/>
									{customerState.isForeigner && (
										<Checkbox
											style={{marginTop: 10}}
											label="Är bosatt i EU"
											checked={customerState.isNonEuPerson}
											onChange={
												isNonEuPerson => this.setState({
													errorMessage: "För hantering av personer bosatta utanför EU, var god kontakta backoffice."
												}, () => this.setCustomerState({isNonEuPerson}))
											}
										/>
									)}
								</StackLayout>
							</React.Fragment>
						)}

						<StackLayout
							className={createClassName("Cob-fields-collection", {"per-row-3": !customer.isCompany})}
							rowWrap fullWidth>
							<div>
								<TextField
									name="customer_social_security_number"
									value={customerState.ssnOrg}
									label={i18n("general", customer.isCompany ? "organization_number" : "social_security_number")}
									readOnly
								/>
							</div>
							<div>
								<TextField
									name="customer_firstname"
									value={customerState.firstName}
									label={i18n("general", customer.isCompany ? "organization_name" : "first_name")}
									validation={lookupNotFound ? value => value.length > 0 : undefined}
									onChange={lookupNotFound ? ({target}) => this.setCustomerState({firstName: target.value}) : undefined}
									readOnly={!lookupNotFound}
								/>
							</div>
							{!customer.isCompany && (
								<div>
									<TextField
										name="customer_lastname"
										value={customerState.lastName}
										label={i18n("general", "last_name")}
										validation={lookupNotFound ? value => value.length > 0 : undefined}
										onChange={lookupNotFound ? ({target}) => this.setCustomerState({lastName: target.value}) : undefined}
										readOnly={!lookupNotFound}
									/>
								</div>
							)}
						</StackLayout>
						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div>
								<TextField
									name="customer_address_street"
									value={customerState.street}
									label={i18n("general", "street_address")}
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({street: target.value})}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_zipcode"
									value={customerState.zipCode}
									label={i18n("general", "zip_code")}
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({zipCode: target.value})}
								/>
							</div>
							<div>
								<TextField
									name="customer_address_city"
									value={customerState.city}
									label={i18n("general", "city")}
									validation={value => value.length > 0}
									onChange={({target}) => this.setCustomerState({city: target.value})}
								/>
							</div>
							{!customer.isCompany && (
								<React.Fragment>
									<div>
										<Select
											name="customer_address_country"
											label={i18n("general", "country")}
											selectedIndex={customerState.countryIndex}
											options={this.countries}
											onChange={({value: country}, countryIndex) => this.setCustomerState({
												country,
												countryIndex
											})}
										/>
									</div>
									<div>
										<Select
											name="customer_citizenship"
											label={i18n("general", "citizenship")}
											selectedIndex={customerState.citizenshipIndex}
											options={this.countries}
											onChange={({value: citizenship}, citizenshipIndex) => this.setCustomerState({
												citizenship,
												citizenshipIndex
											})}
										/>
									</div>
									<div>
										<Select
											name="customer_tax_country"
											label={i18n("general", "tax_country")}
											selectedIndex={customerState.taxCountryIndex}
											options={this.countries}
											onChange={({value: taxCountry}, taxCountryIndex) => this.setCustomerState({
												taxCountry,
												taxCountryIndex
											})}
										/>
									</div>
								</React.Fragment>
							)}
							<div>
								<TextField
									name="customer_phonenumber_cell_input"
									value={customerState.phoneNumber === "-" ? "" : customerState.phoneNumber}
									label={i18n("general", "phone_number")}
									validation={Validator.validatePhoneNum}
									placeholder={"+46 XX XXXXXX"}
									onChange={({target}) => this.setCustomerState({phoneNumber: target.value === "" ? "-" : target.value})}
								/>
							</div>
							<div>
								<TextField
									type="email"
									name="customer_email"
									value={customerState.email}
									label={i18n("general", "email_address")}
									validation={Validator.validateEmail}
									onChange={({target}) => this.setCustomerState({email: target.value})}
								/>
							</div>
						</StackLayout>

						{customerState.phoneNumber && customerState.phoneNumber.length > 1 && customerState.phoneNumber.substring(0, 1) !== "+" && (
							<Notice className="no-draft-notice" appearance="warning"
									message="Telefonnummer måste fyllas i med landskod (t.ex. +46 för Sverige)."/>
						)}

						<StackLayout justifyContent="end" fullWidth>
							<span style={{fontSize: 14, fontWeight: "300"}}>Är ovanstående adressuppgifter felaktiga, vänligen kontakta Nord.</span>
						</StackLayout>

						{customer.isCompany && (

							<React.Fragment>
								<input type="hidden" name="checkbox_customer_id_poa" value="yes"/>
								<input type="hidden" name="checkbox_customer_Certificate_of_registration" value="yes"/>

								<div className="h-line spacing"/>

								<p className="Cob-description">Ange LEI-kod (20 tecken)</p>
								<TextField
									name="customer_lei"
									label="LEI-kod"
									value={customerState.leiCode}
									validation={Validator.validateLeiCode}
									onChange={({target}) => this.setCustomerState({leiCode: target.value})}
								/>

								<div className="h-line spacing"/>

								<CompanyReps
									reps={customerState.companyReps}
									onRepChange={this._onCompanyRepChange}
									activeAmount={customerState.companyRepsActiveAmount}
								/>

								{!this._hasAtLeastOneSignatory(customerState.companyReps) && (
									<div style={{
										padding: "20px",
										background: "#FFECEC",
										borderRadius: "3px",
										maxWidth: "500px",
										margin: "0 0 20px 0"
									}}>
										Åtminstone en firmatecknare måste väljas.
									</div>
								)}

								{customerState.companyRepsActiveAmount <= 6 && (
									<StackLayout fullWidth>
										<Button
											size="small"
											label={i18n("general", "remove_last")}
											appearance={customerState.companyRepsActiveAmount <= 1 ? "medium" : "danger"}
											disabled={customerState.companyRepsActiveAmount <= 1}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount - 1})}
										/>
										<Button
											size="small"
											label={i18n("general", "add")}
											appearance={customerState.companyRepsActiveAmount >= 6 ? "medium" : "primary"}
											disabled={customerState.companyRepsActiveAmount >= 6}
											onClick={() => this.setCustomerState({companyRepsActiveAmount: customerState.companyRepsActiveAmount + 1})}
											filled
										/>
									</StackLayout>
								)}

							</React.Fragment>
						)}

						<input type={"hidden"} name={"customer_address_changed"}
							   value={(haveWeModifiedTheAddress) ? "yes" : "no"}/>

						<div className="h-line spacing"/>

						<h3>FATCA</h3>
						<OptionsList
							style={{marginBottom: 10}}
							type="radio"
							name="radio_customer_us_person"
							description="Har du eller eventuellt förekommande verklig huvudman bosättning, green-card, skattskyldighet, adress i eller annan liknande anknytning till USA?"
							options={[
								{label: i18n("general", "no"), value: "no", checked: customerState.isUsPerson === "no"},
								{
									label: i18n("general", "yes"),
									value: "yes",
									checked: customerState.isUsPerson === "yes"
								}
							]}
							onChange={
								(checked, isUsPerson) => this.setState({
									errorMessage: isUsPerson === "yes" ? "Personer som lyder under FATCA måste hanteras manuellt. Vänligen kontakta Nord." : null
								}, () => this.setCustomerState({isUsPerson}))
							}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}

	_hasAtLeastOneSignatory(companyReps) {
		for (let i = 0; i < companyReps.length; i++) {
			const rep = companyReps[i];
			if (rep.types.length > 0 && rep.types.includes("signatory")) {
				return true;
			}
		}
		return false;
	}

	// Internal methods
	_onCompanyRepChange = (rep, index) => {
		const companyReps = this.state.customerState.companyReps.map((r, i) => i === index ? rep : r);
		this.setCustomerState({companyReps});
	};
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	const newState = {
		isLoading: true,
		lookupNotFound: false,
		fraudFlag: false,
		errorMessage: null,
		customerState: {
			isCompany: customer.isCompany,
			ssnOrg: customer.getData("customer_social_security_number"),
			firstName: customer.getData("customer_firstname"),
			lastName: customer.getData("customer_lastname"),
			street: customer.getData("customer_address_street"),
			zipCode: customer.getData("customer_address_zipcode"),
			city: customer.getData("customer_address_city"),
			country: customer.getData("customer_address_country") || "SE",
			countryIndex: countries.map(c => c.code).indexOf(customer.getData("customer_address_country") || "SE"),
			citizenship: customer.getData("customer_citizenship") || "SE",
			citizenshipIndex: countries.map(c => c.code).indexOf(customer.getData("customer_citizenship") || "SE"),
			taxCountry: customer.getData("customer_tax_country") || "SE",
			taxCountryIndex: countries.map(c => c.code).indexOf(customer.getData("customer_tax_country") || "SE"),
			phoneNumber: customer.getData("customer_phonenumber_cell"),
			email: customer.getData("customer_email"),
			leiCode: customer.getData("customer_lei"),
			companyReps: customer.companyReps,
			companyRepsActiveAmount: Math.max(customer.companyReps.reduce((a, b) => a + (b.firstName ? 1 : 0), 0), 1),
			isForeigner: false,
			isNonEuPerson: false,
			isUsPerson: customer.getData("radio_customer_us_person", null),
			checkbox_partner_vp: customer.getData("checkbox_partner_vp") ? "yes" : "no",
			checkbox_partner_fft: customer.getData("checkbox_partner_fft") ? "yes" : "no",
		}
	};

	newState.isFulfilled = false;
	return newState;
}

function _getFields() {
	return [
		"customer_social_security_number",
		"customer_firstname",
		"customer_lastname",
		"customer_address_street",
		"customer_address_zipcode",
		"customer_address_city",
		"customer_address_country",
		"customer_citizenship",
		"customer_tax_country",
		"customer_phonenumber_cell",
		"customer_email",
		"customer_lei",
		"radio_customer_us_person",

		// Get company reps
		...(() =>
		{
			const returnValue = [];
			loop(6, i =>
			{
				returnValue.push("checkbox_signatory_" + i);
				returnValue.push("checkbox_ubo_" + i);
				returnValue.push("checkbox_poa_" + i);
				returnValue.push("checkbox_view_" + i);
				returnValue.push("checkbox_mng_" + i);

				returnValue.push("customer_representative_role_" + i);
				returnValue.push("customer_representative_ssn_" + i);
				returnValue.push("company_representative_firstname_" + i);
				returnValue.push("company_representative_lastname_" + i);
				returnValue.push("company_representative_citizenship_" + i);
				returnValue.push("company_representative_tax_country_" + i);
				returnValue.push("company_representative_phone_" + i);
				returnValue.push("company_representative_email_" + i);
				returnValue.push("company_representative_percentage_" + i);
			});
			return returnValue;
		})(),

		// POAS
		...(() =>
		{
			const returnValue = [];
			loop(6, i =>
			{
				returnValue.push("customer_firstname_poa" + i);
				returnValue.push("customer_lastname_poa" + i);
				returnValue.push("customer_social_security_number_poa" + i);
				returnValue.push("customer_ownershipproc_poa" + i);
			});
			return returnValue;
		})()
	];
}