import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName, findMedian} from "@helpers/utils";
import fulfillment from "./RiskProfil.fulfillment";
import SriRiskTable from "../../../components/SriRiskTable/SriRiskTable";
import Select from "../../../../../components/Select/Select";
import Bugsnag from '@bugsnag/js';

export default class RiskProfil extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	riskLevels = [
		{
			label: "1 - Mycket låg risk",
			value: "1 - Mycket låg risk"
		},
		{
			label: "2 - Låg risk",
			value: "2 - Låg risk"
		},
		{
			label: "3 - Medellåg risk",
			value: "3 - Medellåg risk"
		},
		{
			label: "4 - Medel risk",
			value: "4 - Medel risk"
		},
		{
			label: "5 - Medelhög risk",
			value: "5 - Medelhög risk"
		},
		{
			label: "6 - Hög risk",
			value: "6 - Hög risk"
		},
		{
			label: "7 - Mycket hög risk",
			value: "7 - Mycket hög risk"
		},
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {customerState} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		const riskCalculated = customerState.risk.sriLevel + " - " + customerState.risk.sriLevelDescription;
		const riskLevelSelected = customerState.risk_sri_level_selected.length > 0 ? customerState.risk_sri_level_selected.substring(0, 1) : 0;

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Riskprofil</h2>

					<div className="Cob-view2-fields">
						<h3>Risk</h3>

						<p>
							När du väljer riskklass för ditt sparande är det viktigt att tänka på att du även väljer den
							genomsnittliga avkastning du kan förvänta dig. För ett diversifierat sparande över längre
							tidsperioder gäller normalt sambandet att lägre risk leder till lägre avkastning och att
							högre avkastning kräver högre risk. Notera att sambandet är svagt för enskilda värdepapper
							och/eller kortare tidsperioder. Om din investeringshorisont är kort bör du därför välja
							lägre risk än om din investeringshorisont är lång.
						</p>

						<p>
							På den europeiska marknaden beskrivs risk och avkastning med hjälp av en sjugradig skala som
							kallas sammanfattande riskindikator (MRM). Enkelt beskrivet är det den förväntade
							variationen för avkastningen som avgör värdet för MRM. Tabellen nedan beskriver risk och
							avkastning för ett enskilt år. De övriga siffrorna i tabellen syftar till att relatera den
							sjugradiga skalan till konkreta värden för avkastning och risk.
						</p>

						<SriRiskTable color1={"#78B142"}
						              color2={"#6DAA49"}
						              color3={"#63A65F"}
						              color4={"#5EA272"}
						              color5={"#529E7F"}
						              color6={"#4B9B8C"}
						              color7={"#4497A1"}/>

						<div className="h-line spacing"/>

						<h3>Citroneers riskklassificeringsförslag</h3>

						{this.state.workflow !== "futur" && customerState.risk.sriLevel === 0 && (
							<p>
								Risknivån kunde inte beräknas automatiskt då inte alla riskfrågor besvarats.
								Välj en lämplig risknivå nedan.
							</p>
						)}

						{customerState.risk.sriLevel !== 0 && (
							<p>
								Baserat på tidigare svar angående risktolerans bedömer Citroneer att
								<strong> riskklass {customerState.risk.sriLevel} ({customerState.risk.sriLevelDescription}) </strong>
								och en <strong>home bias på {customerState.risk.homebias} </strong> är lämpligt för
								kundens ändamål.
							</p>
						)}

						<input type={"hidden"} name={"risk_sri_level_calculated"} value={riskCalculated}/>

						<Select
							name="risk_sri_level_selected"
							label="Vald risknivå"
							selectedIndex={this.riskLevels.map(c => c.value).indexOf(customerState.risk_sri_level_selected)}
							options={this.riskLevels}
							onChange={(option) => this.setCustomerState({risk_sri_level_selected: option.value})}
							style={{marginBottom: "40px"}}
						/>

						{customerState.risk.sriLevel !== 0 &&
							riskCalculated !== customerState.risk_sri_level_selected && (
								<div style={{
									width: "100%",
									padding: "0 15px",
									border: "1px solid #df4c43",
									borderRadius: "5px",
									alignItems: "flex-start",
									fontSize: "16px"
								}}>
									<p style={{fontStyle: "italic"}}>
										Citroneer rekommenderar ej att man ändrar risknivån manuellt.
									</p>

									{riskLevelSelected > customerState.risk.sriLevel && (
										<p style={{fontStyle: "italic"}}>
											Vald risknivå innebär att risk och volatilitet i portföljen med stor
											sannolikhet
											blir <strong>högre än önskat</strong>.
										</p>
									)}

									{riskLevelSelected < customerState.risk.sriLevel && (
										<p style={{fontStyle: "italic"}}>
											Vald risknivå innebär att den förväntade avkastningen med stor sannolikhet
											blir <strong>lägre än önskat</strong>.
										</p>
									)}
								</div>
							)}

					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, currentWorkflow, viewNo, hasFormValidationErrors} = this.props;
	let newState = getViewInitialStateForCustomer(customer, currentWorkflow);
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer, workflow)
{
	workflow = workflow == null ? "default" : workflow;

	let initialState = {
		workflow: workflow,
		customerState: {
			risk: calculateRiskResult(customer),
			risk_sri_level_selected: customer.getData("risk_sri_level_selected") || ""
		}
	};

	if (initialState.customerState.risk_sri_level_selected.length === 0 && initialState.customerState.risk.sriLevel > 0)
	{
		initialState.customerState.risk_sri_level_selected = initialState.customerState.risk.sriLevel + " - " + initialState.customerState.risk.sriLevelDescription;
	}

	return initialState;
}

function _getFields()
{
	return [];
}

function calculateRiskResult(customer)
{
	const sriResult = {
		sriLevel: 0,
		homebias: "0%",
		sriLevelDescription: ""
	};

	let sriScoringPoints = [];
	let homeBiasSum = 0;

	// Q Placement horizon
	let riskCap = 0; // The highest risk we'll be allowed to take is based on the placement horizon of the customer
	const answerPlacementHorizon = customer.getData("risk_placement_horizon") || "";
	if (answerPlacementHorizon === "0-1 år")
	{
		riskCap = 1;
	}
	else if (answerPlacementHorizon === "1-3 år")
	{
		riskCap = 2;
	}
	else if (answerPlacementHorizon === "3-5 år")
	{
		riskCap = 3;
	}
	else if (answerPlacementHorizon === "5-10 år")
	{
		riskCap = 4;
	}
	else if (answerPlacementHorizon === "10-15 år")
	{
		riskCap = 4;
	}
	else if (answerPlacementHorizon === "15+ år")
	{
		riskCap = 5;
	}

	// Q
	const answerPhilosophy = customer.getData("risk_investment_philosophy") || "";
	if (answerPhilosophy === "Fokus på att kontrollera risken för förlust och accepterar därmed en förhållandevis låg avkastning." ||
		answerPhilosophy === "Fokus på att kontrollera förlustrisken och accepterar därmed en förhållandevis låg avkastning.")
	{
		sriScoringPoints.push(2);
	}
	else if (answerPhilosophy === "Accepterar viss risk för att få högre potential till avkastning.")
	{
		sriScoringPoints.push(3);
	}
	else if (answerPhilosophy === "Fokus på avkastning och kan därmed acceptera stora fluktuationer i värdet på investeringarna." ||
		answerPhilosophy === "Fokus på avkastning och kan därmed acceptera stora flukttuationer i värdet på investeringarna.")
	{
		sriScoringPoints.push(6);
	}
	else
	{
		console.log(new Error("Cannot calculate MRM risk at this time: Incomplete anwers (risk_investment_philosophy)."));
		return sriResult;
	}

	// Q
	const answerNews = customer.getData("risk_affected_by_market_news") || "";
	if (answerNews === "Inte nämnvärt och förosakar ingen oro över portföljen.")
	{
		sriScoringPoints.push(5);
	}
	else if (answerNews === "Ökad uppmärksamhet på portföljen men agerar med is i magen.")
	{
		sriScoringPoints.push(4);
	}
	else if (answerNews === "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.")
	{
		sriScoringPoints.push(3);
	}
	else if (answerNews === "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.")
	{
		sriScoringPoints.push(2);
	}
	else
	{
		console.log(new Error("Cannot calculate MRM risk at this time: Incomplete anwers (risk_affected_by_market_news)."));
		return sriResult;
	}

	// Q
	const answerNegativeNews = customer.getData("risk_affected_by_negative_news") || "";
	if (answerNegativeNews === "Inte nämnvärt och förosakar ingen oro över portföljen.")
	{
		sriScoringPoints.push(5);
	}
	else if (answerNegativeNews === "Ökad uppmärksamhet på portföljen men agerar med is i magen.")
	{
		sriScoringPoints.push(4);
	}
	else if (answerNegativeNews === "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.")
	{
		sriScoringPoints.push(3);
	}
	else if (answerNegativeNews === "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.")
	{
		sriScoringPoints.push(2);
	}
	else
	{
		console.log(new Error("Cannot calculate MRM risk at this time: Incomplete anwers (risk_affected_by_negative_news)."));
		return sriResult;
	}

	// Q
	const answerReactionDrawdown = customer.getData("risk_reaction_to_drawdown") || "";
	if (answerReactionDrawdown === "0%")
	{
		sriScoringPoints.push(1);
	}
	else if (answerReactionDrawdown === "-5%")
	{
		sriScoringPoints.push(2);
	}
	else if (answerReactionDrawdown === "-10%")
	{
		sriScoringPoints.push(3);
	}
	else if (answerReactionDrawdown === "-20%")
	{
		sriScoringPoints.push(4);
	}
	else if (answerReactionDrawdown === "-30%")
	{
		sriScoringPoints.push(5);
	}
	else if (answerReactionDrawdown === "-40%")
	{
		sriScoringPoints.push(6);
	}
	else
	{
		console.log(new Error("Cannot calculate MRM risk at this time: Incomplete anwers (risk_reaction_to_drawdown)."));
		return sriResult;
	}

	// Q
	const answerReactionLowerReturns = customer.getData("risk_reaction_to_lower_returns") || "";
	if (answerReactionLowerReturns === "Frustration över den missade möjligheten och av att halka efter börsen.")
	{
		homeBiasSum++;
	}
	else if (answerReactionLowerReturns === "Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj.")
	{
		// No home bias points
	}
	else if (answerReactionLowerReturns === "Samtal med rådgivaren för att förstå varför portföjen halkar efter börsen." ||
		answerReactionLowerReturns === "Samtal med rådgivaren för att förstå varför portföljen presterar sämre.")
	{
		homeBiasSum++;
	}
	else if (answerReactionLowerReturns === "Ingen, portföljen är diversifierad och kommer inte att hänga med enskilda marknader." ||
		answerReactionLowerReturns === "Ingen, portföljen är balanserad och kommer inte att hänga med enskilda marknadsrörelser.")
	{
		// No home bias points
	}
	else
	{
		console.log(new Error("Cannot calculate MRM risk at this time: Incomplete anwers (risk_reaction_to_lower_returns)."));
		return sriResult;
	}

	// Q
	const answerPortfolioCompared = customer.getData("risk_portfolio_compared") || "";
	if (answerPortfolioCompared === "Jag jämför mitt resultat med Stockholmsbörsen." ||
		answerPortfolioCompared === "Jag jämför mitt resultat med svenska aktier/Stockholmsbörsen.")
	{
		homeBiasSum++;
	}
	else if (answerPortfolioCompared === "Jag jämför mitt resultat med en global aktieportfölj.")
	{
		// No home bias points
	}
	else if (answerPortfolioCompared === "Jag har inget specifikt utvärderingsmått.")
	{
		// No home bias points
	}
	else
	{
		console.log(new Error("Cannot calculate MRM risk at this time: Incomplete anwers (risk_portfolio_compared)."));
		return sriResult;
	}

	const medianScore = findMedian(sriScoringPoints);
	let sriLevel = Math.min(medianScore, riskCap);
	sriResult.sriLevel = sriLevel;

	if (homeBiasSum === 1)
	{
		sriResult.homebias = "10%";
	}
	else if (homeBiasSum === 2)
	{
		sriResult.homebias = "25%";
	}

	switch (sriLevel)
	{
		case 1:
			sriResult.sriLevelDescription = "Mycket låg risk";
			break;
		case 2:
			sriResult.sriLevelDescription = "Låg risk";
			break;
		case 3:
			sriResult.sriLevelDescription = "Medellåg risk";
			break;
		case 4:
			sriResult.sriLevelDescription = "Medel risk";
			break;
		case 5:
			sriResult.sriLevelDescription = "Medelhög risk";
			break;
		case 6:
			sriResult.sriLevelDescription = "Hög risk";
			break;
		case 7:
			sriResult.sriLevelDescription = "Mycket hög risk";
			break;
		default:
			console.log("Invalid MRM level", sriLevel);
			Bugsnag.notify(new Error("Invalid MRM level " + sriLevel));
			break;
	}

	return sriResult
}