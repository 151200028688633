import {createConstants} from "@helpers/utils";


// CONSTANTS
export const constants = createConstants("APP", [
	"SET_STATE",
	"SET_USER",
	"SET_ADVISOR",
	"SET_CUSTOMER",
]);


// ACTIONS
export const actions = {
	setState: (state) => (dispatch) => {
		dispatch({type: constants.APP_SET_STATE, state});
		return Promise.resolve();
	},
	setAdvisor: (user) => (dispatch) => {
		dispatch({type: constants.APP_SET_ADVISOR, user});
		return Promise.resolve();
	},
	setUser: (user) => (dispatch) => {
		dispatch({type: constants.APP_SET_USER, user});
		return Promise.resolve();
	},
	setCustomer: (customer) => (dispatch) => {
		dispatch({type: constants.APP_SET_CUSTOMER, customer});
		return Promise.resolve();
	}
};


const initialState = {
	busy: false,
	loginGlofitech: false,
	userIsVerified: false,
	user: null,
	advisor: null,
	customer: null,
	customerState: {},
	exchangeRates: null,
	availableBanks: null,
	availableAdvisors: [],
	viewFulfilled: false,
	navigationButtons: {
		sharing: {display: false},
		back: {},
		next: {}
	},
	instruments: null,
  dashboardSelected: "start",
	signModalVisible: false,
	signModalBusy: false,
	signModalData: null,
	errorModalVisible: false,
	errorModalForceRestart: false,
	errorModalMessage: null,
	partner: null,
  hasFormValidationErrors: false
};

export default (state = initialState, action) => {
	const {type} = action;

	switch (type) {
		case constants.APP_SET_STATE:
			return {...state, ...action.state};
		case constants.APP_SET_USER:
			return {...state, user: action.user};
		case constants.APP_SET_ADVISOR:
			return {...state, advisor: action.user};
		case constants.APP_SET_CUSTOMER:
			return {...state, customer: action.customer};
		default:
			return state;
	}
};