import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {ConnectedRouter} from "react-router-redux";
import {Route} from "react-router-dom";
import {unregister} from './registerServiceWorker';
import "./index.css";

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import createStore, {history} from "./store";
import App from "./App";

// Unregister service worker (if already initiated)
unregister();

// Init Bugsnag
Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_SECRET,
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const AppWithRedux = (
  <ErrorBoundary>
    <Provider store={createStore()}>
      <ConnectedRouter history={history}>
        <Route path="/" component={App}/>
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(AppWithRedux, document.getElementById("root"));