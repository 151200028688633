// MODULES
import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DrawIcon from '@mui/icons-material/Draw';

import Card from "@cobComponents/Card";
import UploadAndSign from "../../UploadAndSign/UploadAndSign";

// HELPERS
// import i18n from "@helpers/i18n";

const UploadAndSignCard = props => {
  const { advisor, client } = props;

  return (
    <Card 
      title="Skicka dokument för signering"
      icon={DrawIcon}
    >
      <UploadAndSign
        widget={true}
        partnerSsn={advisor.ssn}
        customer={client}
      />
    </Card>
  );
};

export default UploadAndSignCard;