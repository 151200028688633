import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./Purpose.fulfillment";
import TextView from "../../../../../components/TextView/TextView";
import OptionsList from "../../../components/OptionsList/OptionsList";
import i18n from "@helpers/i18n";

export default class Purpose extends React.PureComponent {
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	static purposeOptions = [
		{
			label: `${i18n("purpose", "risk_return")}.`,
			value: "Mitt huvudfokus är risk och avkastning.",
			checked: false
		},
		{
			label: `${i18n("purpose", "sustainability_and_risk")}.`,
			value: "Jag vill ta hänsyn till hållbarhet men behålla mina risk och avkastningsmål.",
			checked: false
		},
		{
			label: `${i18n("purpose", "sustainability")}.`,
			value: "Hållbarhet är en viktig fråga för mig och jag kan tänka mig att sänka mina krav på risk och avkastning och avstå från investeringar i vissa tillgångslag och företag.",
			checked: false
		}
	];

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			}
		});
	}

	render() {
		const {customerState} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		Purpose.purposeOptions.forEach(function(option) {
			option.checked = customerState.rdo_purpose_invcsr === option.value;
		})

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>{i18n("purpose", "purpose")}</h2>

					<div className="Cob-view2-fields">

						<h3>{i18n("purpose", "purpose_key_considerations")}</h3>

						<h4>{i18n("purpose", "purpose_and_objective")}*</h4>
						<TextView
							name="purpose_investment"
							label={`${i18n("purpose", "enter")} (${800 - customerState.purpose_investment.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.purpose_investment}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({purpose_investment: target.value})}
							fullWidth
							minHeight={120}
							required={true}
						/>

						<h4>{i18n("purpose", "key_considerations")}</h4>

						<TextView
							name="purpose_corevalues"
              label={`${i18n("purpose", "enter")} (${800 - customerState.purpose_corevalues.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.purpose_corevalues}
							maxLength={800}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({purpose_corevalues: target.value})}
							fullWidth
							minHeight={120}
						/>

						<h4>{i18n("purpose", "view_on_investments")}*</h4>
						<OptionsList
							type="radio"
							orientation="vertical"
							name="rdo_purpose_invcsr"
							options={Purpose.purposeOptions}
							onChange={(checked, rdo_purpose_invcsr) => this.setCustomerState({rdo_purpose_invcsr})}
						/>

						<h4>{i18n("purpose", "other_aspects")}?</h4>
						<TextView
							name="purpose_otherconcerns"
              label={`${i18n("purpose", "enter")} (${200 - customerState.purpose_otherconcerns.length} ${i18n("general", "remaining_chars")})`}
							value={customerState.purpose_otherconcerns}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({purpose_otherconcerns: target.value})}
							fullWidth
							minHeight={100}
						/>
					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer} = this.props;
	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer) {
	return {
		customerState: {
			purpose_investment: customer.getData("purpose_investment") || "",

			purpose_corevalues: customer.getData("purpose_corevalues") || "",
			purpose_otherconcerns: customer.getData("purpose_otherconcerns") || "",

			rdo_purpose_invcsr: customer.getData("rdo_purpose_invcsr") || ""
		}
	};
}

function _getFields() {
	return [
		"purpose_investment",
		"purpose_corevalues",
		"purpose_otherconcerns",

	];
}