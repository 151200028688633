import React from "react";

import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import {createClassName} from "@helpers/utils";
import fulfillment from "./Risk.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import TextView from "../../../../../components/TextView/TextView";
import Select from "../../../../../components/Select/Select";
import NumberField from "../../../components/NumberField/NumberField.jsx";

export default class Risk extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);

	static compareOptions = [
		"Jag har inget specifikt utvärderingsmått.",
		"Jag jämför mitt resultat med en global aktieportfölj.",
		"Jag jämför mitt resultat med Stockholmsbörsen."
	];

	static drawdownOptions = [
		"0%",
		"-5%",
		"-10%",
		"-20%",
		"-30%",
		"-40%"
	];

	static placementHorizonOptions = [
		"0-1 år",
		"1-3 år",
		"3-5 år",
		"5-10 år",
		"10-15 år",
		"15+ år"
	];

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}

						this.setState({hasFormValidationErrors: true});
						return setAppState({
							showValidationWarning: true
						});
					}
				}
			}
		});
	}

	render()
	{
		const {customerState, hasFormValidationErrors} = this.state;
		const classes = createClassName("Cob-view Cob-view2", {});

		let riskExpectedReturnOptions = [
			{
				label: "I linje med bankkontoräntan",
				value: "I linje med bankkontoräntan",
				checked: customerState.risk_expected_return === "I linje med bankkontoräntan"
			},
			{
				label: "Något över bankkontoräntan",
				value: "Något över bankkontoräntan",
				checked: customerState.risk_expected_return === "Något över bankkontoräntan"
			},
			{
				label: "En klart högre avkastning än bankkontoränta",
				value: "En klart högre avkastning än bankkontoränta",
				checked: customerState.risk_expected_return === "En klart högre avkastning än bankkontoränta"
			},
			{
				label: "I linje med aktiemarknaden",
				value: "I linje med aktiemarknaden",
				checked: customerState.risk_expected_return === "I linje med aktiemarknaden"
			},
			{
				label: "Det finns ett definierat avkastningsmål",
				value: "Det finns ett definierat avkastningsmål",
				checked: customerState.risk_expected_return === "Det finns ett definierat avkastningsmål"
			}
		];

		let compareOptions = [];
		for (let i = 0; i < Risk.compareOptions.length; i++)
		{
			const optionValue = Risk.compareOptions[i];
			const option = {
				label: optionValue,
				value: optionValue,
				checked: customerState.risk_portfolio_compared === optionValue
			}
			compareOptions.push(option);
		}

		let drawdownOptions = [];
		for (let i = 0; i < Risk.drawdownOptions.length; i++)
		{
			const optionValue = Risk.drawdownOptions[i];
			const option = {
				label: optionValue,
				value: optionValue,
				checked: customerState.risk_reaction_to_drawdown === optionValue
			}
			drawdownOptions.push(option);
		}

		let placementHorizonOptions = [];
		for (let i = 0; i < Risk.placementHorizonOptions.length; i++)
		{
			const optionValue = Risk.placementHorizonOptions[i];
			const option = {
				label: optionValue,
				value: optionValue,
				checked: customerState.risk_placement_horizon === optionValue
			}
			placementHorizonOptions.push(option);
		}

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<ContentBox>
					<h2>Risktolerans</h2>

					<div className="Cob-view2-fields">
						<h3>Avkastningsmål</h3>

						<strong style={{marginTop: "10px"}}>
							Vilken är den årliga förväntade avkastningen på kapitalet?*
						</strong>

						<Select label="Välj"
						        name={"risk_expected_return"}
						        selectedIndex={riskExpectedReturnOptions.map(c => c.value).indexOf(customerState.risk_expected_return)}
						        options={riskExpectedReturnOptions}
						        onChange={(value) => this.setCustomerState({risk_expected_return: value.value})}
						        highlightErrors={hasFormValidationErrors}/>

						{customerState.risk_expected_return === "Det finns ett definierat avkastningsmål" && (
							<React.Fragment>
								<strong style={{marginTop: "20px"}}>Avkastningsmål i procent*</strong>
								<div style={{maxWidth: "120px", display: "block"}}>
									<NumberField
										name="risk_defined_return"
										unit="%"
										value={customerState.risk_defined_return}
										label="Ange"
										validation={value => value.length > 0 && value >= 0 && value <= 100}
										onChange={({target}) => this.setCustomerState({risk_defined_return: target.value})}
										highlightErrors={hasFormValidationErrors}
									/>
								</div>
							</React.Fragment>
						)}

						<div className="h-line spacing"/>
						<h3>Riskkapacitet</h3>
						<h4 style={{marginTop: "10px"}}>Vilken är din placeringshorisont?*</h4>

						<OptionsList
							type="radio"
							orientation="vertical"
							name="risk_placement_horizon"
							value={customerState.risk_placement_horizon}
							options={placementHorizonOptions}
							validation={value => (value && value.length > 0) || false}
							onChange={(checked, risk_placement_horizon) => this.setCustomerState({risk_placement_horizon})}
							highlightErrors={hasFormValidationErrors}
						/>

						{this.state.workflow !== "futur" && (
							<React.Fragment>
								<div className="h-line spacing"/>
								<h3>Riskprofilering</h3>
								<h4 style={{marginTop: "10px"}}>Vilket påstående nedan beskriver investeringsfilosofin
									bäst?*</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_investment_philosophy"
									value={customerState.risk_investment_philosophy}
									options={[
										{
											label: "Fokus på att kontrollera förlustrisken och accepterar därmed en förhållandevis låg avkastning.",
											value: "Fokus på att kontrollera förlustrisken och accepterar därmed en förhållandevis låg avkastning.",
											checked: customerState.risk_investment_philosophy === "Fokus på att kontrollera förlustrisken och accepterar därmed en förhållandevis låg avkastning."
										},
										{
											label: "Accepterar viss risk för att få högre potential till avkastning.",
											value: "Accepterar viss risk för att få högre potential till avkastning.",
											checked: customerState.risk_investment_philosophy === "Accepterar viss risk för att få högre potential till avkastning."
										},
										{
											label: "Fokus på avkastning och kan därmed acceptera stora fluktuationer i värdet på investeringarna.",
											value: "Fokus på avkastning och kan därmed acceptera stora fluktuationer i värdet på investeringarna.",
											checked: customerState.risk_investment_philosophy === "Fokus på avkastning och kan därmed acceptera stora fluktuationer i värdet på investeringarna."
										}
									]}
									validation={value => (value && value.length > 0) || false}
									onChange={(checked, risk_investment_philosophy) => this.setCustomerState({risk_investment_philosophy})}
									highlightErrors={hasFormValidationErrors}
								/>

								<h4>
									I vilken utsträckning påverkas din syn på investeringar av marknadsnyheter och
									media?*
								</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_affected_by_market_news"
									value={customerState.risk_affected_by_market_news}
									options={[
										{
											label: "Inte nämnvärt och förosakar ingen oro över portföljen.",
											value: "Inte nämnvärt och förosakar ingen oro över portföljen.",
											checked: customerState.risk_affected_by_market_news === "Inte nämnvärt och förosakar ingen oro över portföljen."
										},
										{
											label: "Ökad uppmärksamhet på portföljen men agerar med is i magen.",
											value: "Ökad uppmärksamhet på portföljen men agerar med is i magen.",
											checked: customerState.risk_affected_by_market_news === "Ökad uppmärksamhet på portföljen men agerar med is i magen."
										},
										{
											label: "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.",
											value: "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.",
											checked: customerState.risk_affected_by_market_news === "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna."
										},
										{
											label: "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.",
											value: "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.",
											checked: customerState.risk_affected_by_market_news === "Negativa nyheter innebär att portföljen förändras för att följa experternas tips."
										}
									]}
									validation={value => (value && value.length > 0) || false}
									onChange={(checked, risk_affected_by_market_news) => this.setCustomerState({risk_affected_by_market_news})}
									highlightErrors={hasFormValidationErrors}
								/>

								<h4>
									Hur påverkas synen på investeringarna när media fylls av negativa marknadsnyheter
									och experter kommer med investeringstips?*
								</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_affected_by_negative_news"
									value={customerState.risk_affected_by_negative_news}
									options={[
										{
											label: "Inte nämnvärt och förosakar ingen oro över portföljen.",
											value: "Inte nämnvärt och förosakar ingen oro över portföljen.",
											checked: customerState.risk_affected_by_negative_news === "Inte nämnvärt och förosakar ingen oro över portföljen."
										},
										{
											label: "Ökad uppmärksamhet på portföljen men agerar med is i magen.",
											value: "Ökad uppmärksamhet på portföljen men agerar med is i magen.",
											checked: customerState.risk_affected_by_negative_news === "Ökad uppmärksamhet på portföljen men agerar med is i magen."
										},
										{
											label: "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.",
											value: "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna.",
											checked: customerState.risk_affected_by_negative_news === "Negativa nyheter innebär ett större engagemang i hur det går för investeringarna."
										},
										{
											label: "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.",
											value: "Negativa nyheter innebär att portföljen förändras för att följa experternas tips.",
											checked: customerState.risk_affected_by_negative_news === "Negativa nyheter innebär att portföljen förändras för att följa experternas tips."
										}
									]}
									validation={value => (value && value.length > 0) || false}
									onChange={(checked, risk_affected_by_negative_news) => this.setCustomerState({risk_affected_by_negative_news})}
									highlightErrors={hasFormValidationErrors}
								/>

								<h4>
									Hur mycket kan kapitalet på denna placering falla innan det känns riktigt obekvämt?*
								</h4>
								<OptionsList
									type="radio"
									orientation="vertical"
									name="risk_reaction_to_drawdown"
									value={customerState.risk_reaction_to_drawdown}
									options={drawdownOptions}
									validation={value => (value && value.length > 0) || false}
									onChange={(checked, risk_reaction_to_drawdown) => this.setCustomerState({risk_reaction_to_drawdown})}
									highlightErrors={hasFormValidationErrors}/>
							</React.Fragment>
						)}

						<div className="h-line spacing"/>

						<h3>Home Bias</h3>

						<h4 style={{marginTop: "10px"}}>
							Vilken blir reaktionen om Stockholmsbörsen stiger med 20 procent medan portföljen
							bara ökar med 5 procent?*
						</h4>
						<OptionsList
							type="radio"
							orientation="vertical"
							name="risk_reaction_to_lower_returns"
							value={customerState.risk_reaction_to_lower_returns}
							options={[
								{
									label: "Frustration över den missade möjligheten och av att halka efter börsen.",
									value: "Frustration över den missade möjligheten och av att halka efter börsen.",
									checked: customerState.risk_reaction_to_lower_returns === "Frustration över den missade möjligheten och av att halka efter börsen."
								},
								{
									label: "Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj.",
									value: "Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj.",
									checked: customerState.risk_reaction_to_lower_returns === "Ingen nämnvärd, Stockholmsbörsen har högre risk än min portfölj."
								},
								{
									label: "Samtal med rådgivaren för att förstå varför portföjen halkar efter börsen.",
									value: "Samtal med rådgivaren för att förstå varför portföjen halkar efter börsen.",
									checked: customerState.risk_reaction_to_lower_returns === "Samtal med rådgivaren för att förstå varför portföjen halkar efter börsen."
								},
								{
									label: "Ingen, portföljen är diversifierad och kommer inte att hänga med enskilda marknader.",
									value: "Ingen, portföljen är diversifierad och kommer inte att hänga med enskilda marknader.",
									checked: customerState.risk_reaction_to_lower_returns === "Ingen, portföljen är diversifierad och kommer inte att hänga med enskilda marknader."
								}
							]}
							validation={value => (value && value.length > 0) || false}
							onChange={(checked, risk_reaction_to_lower_returns) => this.setCustomerState({risk_reaction_to_lower_returns})}
							highlightErrors={hasFormValidationErrors}
						/>

						<h4>Hur utvärderar du dina investeringar?*</h4>
						<Select label="Välj"
						        name={"risk_portfolio_compared"}
						        selectedIndex={compareOptions.map(c => c.value).indexOf(customerState.risk_portfolio_compared)}
						        options={compareOptions}
						        onChange={(value) => this.setCustomerState({risk_portfolio_compared: value.value})}
						        highlightErrors={hasFormValidationErrors}/>

						<div className="h-line spacing"/>

						<h3>Övrigt</h3>
						<h4 style={{marginTop: "10px"}}>Kommentar: Risk- och avkastningsförväntningar</h4>
						<TextView
							name="risk_comment"
							label={`Ange (${200 - customerState.risk_comment.length} tecken kvar)`}
							value={customerState.risk_comment}
							maxLength={200}
							validation={value => value.length > 0}
							resize="vertical"
							onChange={({target}) => this.setCustomerState({risk_comment: target.value})}
							fullWidth
							minHeight={100}
						/>

					</div>
				</ContentBox>
			</div>
		);
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer, workflow)
{
	workflow = workflow == null ? "default" : workflow;

	let initialState = {
		workflow: workflow,
		customerState: {
			risk_expected_return: customer.getData("risk_expected_return") || "",
			risk_defined_return: customer.getData("risk_defined_return") || "",
			risk_placement_horizon: customer.getData("risk_placement_horizon") || "",
			risk_investment_philosophy: customer.getData("risk_investment_philosophy") || "",
			risk_affected_by_negative_news: customer.getData("risk_affected_by_negative_news") || "",
			risk_affected_by_market_news: customer.getData("risk_affected_by_market_news") || "",
			risk_reaction_to_lower_returns: customer.getData("risk_reaction_to_lower_returns") || "",
			risk_portfolio_compared: customer.getData("risk_portfolio_compared") || "",
			risk_reaction_to_drawdown: customer.getData("risk_reaction_to_drawdown") || "",
			risk_comment: customer.getData("risk_comment") || "",
		}
	};

	if (initialState.customerState.risk_expected_return === "I linje med aktiemarknaden")
	{
		initialState.customerState.risk_expected_return = "En klart högre avkastning än bankkontoränta";
	}

	if (initialState.customerState.risk_defined_return === "1%")
	{
		initialState.customerState.risk_defined_return = "3%";
	}

	if (initialState.customerState.risk_defined_return === "10%")
	{
		initialState.customerState.risk_defined_return = "11%";
	}

	if (initialState.customerState.risk_investment_philosophy === "Fokus på att kontrollera risken för förlust och accepterar därmed en förhållandevis låg avkastning.")
	{
		initialState.customerState.risk_investment_philosophy = "Fokus på att kontrollera förlustrisken och accepterar därmed en förhållandevis låg avkastning.";
	}

	if (!Risk.compareOptions.includes(initialState.customerState.risk_portfolio_compared))
	{
		initialState.customerState.risk_portfolio_compared = "";
	}

	if (!Risk.drawdownOptions.includes(initialState.customerState.risk_reaction_to_drawdown))
	{
		initialState.customerState.risk_reaction_to_drawdown = "";
	}

	return initialState;
}

function _getFields()
{
	return [
		"risk_expected_return",
		"risk_defined_return",
		"risk_investment_philosophy",
		"risk_affected_by_negative_news",
		"risk_affected_by_market_news",
		"risk_reaction_to_drawdown",
		"risk_reaction_to_drawdown_40",
		"risk_reaction_to_lower_returns",
		"risk_portfolio_compared",
		"risk_placement_horizon",
		"risk_comment",
	];
}