import i18n from "@helpers/i18n";

import Start, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_Start } from "./Start/Start";
import StartFulfillment from "./Start/Start.fulfillment";

import StartFair, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_StartFair } from "./Start/Fair/Start";
import StartFairFulfillment from "./Start/Fair/Start.fulfillment";

import CustomerInformation, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_CustomerInformation } from "./CustomerInformation/CustomerInformation";
import CustomerInformationFulfillment from "./CustomerInformation/CustomerInformation.fulfillment";

import CustomerInformationFair, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_CustomerInformationFair } from "./CustomerInformation/Fair/CustomerInformation";
import CustomerInformationFairFulfillment from "./CustomerInformation/Fair/CustomerInformation.fulfillment";

import MoneyLaunderingQuestions, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_MoneyLaunderingQuestions } from "./MoneyLaunderingQuestions/MoneyLaunderingQuestions";
import MoneyLaunderingQuestionsFulfillment from "./MoneyLaunderingQuestions/MoneyLaunderingQuestions.fulfillment";

import MoneyLaunderingQuestionsFair, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_MoneyLaunderingQuestionsFair } from "./MoneyLaunderingQuestions/Fair/MoneyLaunderingQuestions";
import MoneyLaunderingQuestionsFairFulfillment from "./MoneyLaunderingQuestions/Fair/MoneyLaunderingQuestions.fulfillment";

import FamilyAndAssets, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FamilyAndAssets } from "./FamilyAndAssets/FamilyAndAssets";
import FamilyAndAssetsFulfillment from "./FamilyAndAssets/FamilyAndAssets.fulfillment";

import FamilyAndAssetsFair from "./FamilyAndAssets/Fair/FamilyAndAssets";
import {getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FamilyAndAssetsFair} from "./FamilyAndAssets/Fair/FamilyAndAssets";
import FamilyAndAssetsFairFulfillment from "./FamilyAndAssets/Fair/FamilyAndAssets.fulfillment";

import AssetsView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_AssetsView } from "./AssetsAndDebts/Assets";
import AssetsFulfillment from "./AssetsAndDebts/Assets.fulfillment";

import DebtsView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_DebtsView } from "./AssetsAndDebts/Debts";
import DebtsFulfillment from "./AssetsAndDebts/Debts.fulfillment";

import PreviousExperienceView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_PreviousExperienceView } from "./PreviousExperience/PreviousExperience";
import PreviousExperienceFulfillment from "./PreviousExperience/PreviousExperience.fulfillment";

import FairPreviousExperienceView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FairPreviousExperienceView } from "./PreviousExperience/Fair/PreviousExperience";
import FairPreviousExperienceFulfillment from "./PreviousExperience/Fair/PreviousExperience.fulfillment";

import RiskView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_RiskView } from "./Risk/Risk";
import RiskFulfillment from "./Risk/Risk.fulfillment";

import FairRiskView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FairRiskView } from "./Risk/Fair/Risk";
import FairRiskFulfillment from "./Risk/Fair/Risk.fulfillment";

import MeetingNotesView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_MeetingNotesView } from "./MeetingNotes/MeetingNotes";
import MeetingNotesFulfillment from "./MeetingNotes/MeetingNotes.fulfillment";

import FairMeetingNotesView, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_FairMeetingNotesView } from "./MeetingNotes/Fair/MeetingNotes";
import FairMeetingNotesFulfillment from "./MeetingNotes/Fair/MeetingNotes.fulfillment";

import UploadDocuments, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_UploadDocuments } from "./UploadDocuments/View14";
import UploadDocumentsFulfillment from "./UploadDocuments/View14.fulfillment";

import UploadDocumentsFair, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_UploadDocumentsFair } from "./UploadDocuments/Fair/View14";
import UploadDocumentsFairFulfillment from "./UploadDocuments/Fair/View14.fulfillment";

import BasicProductSelectionFair, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_BasicProductSelectionFair } from "./BasicProductSelection/Fair/BasicProductSelection";
import BasicProductSelectionFairFulfillment from "./BasicProductSelection/Fair/BasicProductSelection.fulfillment";

import AutogiroFair, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_AutogiroFair } from "./Autogiro/Fair/Autogiro";
import AutogiroFairFulfillment from "./Autogiro/Fair/Autogiro.fulfillment";

import SignAgreement from "./SignAgreement/SignAgreement";
import SignAgreementFulfillment from "./SignAgreement/SignAgreement.fulfillment";

import SignAgreementFair, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_SignAgreementFair } from "./SignAgreement/Fair/SignAgreement";
import SignAgreementFairFulfillment from "./SignAgreement/Fair/SignAgreement.fulfillment";

import StartGlofidemo, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_StartGlofidemo } from "./Start/Glofidemo/Start";
import StartGlofidemoFulfillment from "./Start/Glofidemo/Start.fulfillment";

import StartCitroneer, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_StartCitroneer } from "./Start/Citroneer/Start";
import StartCitroneerFulfillment from "./Start/Citroneer/Start.fulfillment";

import CustomerInformationGlofidemo, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_CustomerInformationGlofidemo } from "./CustomerInformation/Glofidemo/CustomerInformation";
import CustomerInformationGlofidemoFulfillment from "./CustomerInformation/Glofidemo/CustomerInformation.fulfillment";

import PreviousExperienceGlofidemo, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_PreviousExperienceGlofidemo } from "./PreviousExperience/Glofidemo/PreviousExperience";
import PreviousExperienceGlofidemoFulfillment from "./PreviousExperience/Glofidemo/PreviousExperience.fulfillment";

import MeetingNotesGlofidemo, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_MeetingNotesGlofidemo } from "./MeetingNotes/Glofidemo/MeetingNotes";
import MeetingNotesGlofidemoFulfillment from "./MeetingNotes/Glofidemo/MeetingNotes.fulfillment";

import AutogiroGlofidemo, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_AutogiroGlofidemo } from "./Autogiro/Glofidemo/Autogiro";
import AutogiroGlofidemoFulfillment from "./Autogiro/Glofidemo/Autogiro.fulfillment";

import SignAgreementGlofidemo, { getViewInitialStateForCustomer as GetViewInitialStateForCustomer_SignAgreementGlofidemo } from "./SignAgreement/Glofidemo/SignAgreement";
import SignAgreementGlofidemoFulfillment from "./SignAgreement/Glofidemo/SignAgreement.fulfillment";

export default class ViewMapping {
  static availableViews = [
    {
      id: "Start",
      label: i18n("general", "welcome"),
      View: Start,
      getInitialState: GetViewInitialStateForCustomer_Start,
      isViewFulfilled: StartFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "Start-Fair",
      label: i18n("general", "welcome"),
      View: StartFair,
      getInitialState: GetViewInitialStateForCustomer_StartFair,
      isViewFulfilled: StartFairFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "CustomerInformation",
      label: i18n("cob", "customer_information"),
      View: CustomerInformation,
      getInitialState: GetViewInitialStateForCustomer_CustomerInformation,
      isViewFulfilled: CustomerInformationFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "CustomerInformation-Fair",
      label: "1:A / " + i18n("cob", "customer_information"),
      View: CustomerInformationFair,
      getInitialState: GetViewInitialStateForCustomer_CustomerInformationFair,
      isViewFulfilled: CustomerInformationFairFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "MoneyLaunderingQuestions",
      label: i18n("cob", "questions_according_to_anti_money_laundering_act"),
      View: MoneyLaunderingQuestions,
      getInitialState: GetViewInitialStateForCustomer_MoneyLaunderingQuestions,
      isViewFulfilled: MoneyLaunderingQuestionsFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "MoneyLaunderingQuestions-Fair",
      label: "1:B Översyn / " + i18n("cob", "questions_according_to_anti_money_laundering_act"),
      View: MoneyLaunderingQuestionsFair,
      getInitialState: GetViewInitialStateForCustomer_MoneyLaunderingQuestionsFair,
      isViewFulfilled: MoneyLaunderingQuestionsFairFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "FamilyAndAssets",
      label: i18n("cob", "savings_profile"),
      View: FamilyAndAssets,
      getInitialState: GetViewInitialStateForCustomer_FamilyAndAssets,
      isViewFulfilled: FamilyAndAssetsFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "FamilyAndAssets-Fair",
      label: "1:B Översyn / " + i18n("nav_labels", "family_and_assets"),
      View: FamilyAndAssetsFair,
      getInitialState: GetViewInitialStateForCustomer_FamilyAndAssetsFair,
      isViewFulfilled: FamilyAndAssetsFairFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "Assets",
      label: "1:B Forts / " + i18n("nav_labels", "assets"),
      View: AssetsView,
      getInitialState: GetViewInitialStateForCustomer_AssetsView,
      isViewFulfilled: AssetsFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: true,
    },
    {
      id: "Debts",
      label: i18n("nav_labels", "debts"),
      View: DebtsView,
      getInitialState: GetViewInitialStateForCustomer_DebtsView,
      isViewFulfilled: DebtsFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: true,
    },
    {
      id: "CustomerPreviousExperience",
      label: i18n("nav_labels", "previous_experience"),
      View: PreviousExperienceView,
      getInitialState: GetViewInitialStateForCustomer_PreviousExperienceView,
      isViewFulfilled: PreviousExperienceFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false,
    },
    {
      id: "CustomerPreviousExperience-Fair",
      label: "1:B Forts / " + i18n("nav_labels", "previous_experience_fair"),
      View: FairPreviousExperienceView,
      getInitialState: GetViewInitialStateForCustomer_FairPreviousExperienceView,
      isViewFulfilled: FairPreviousExperienceFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false,
    },
    {
      id: "CustomerRiskConfirmation",
      label: i18n("risk", "risk_tolerance"),
      View: RiskView,
      getInitialState: GetViewInitialStateForCustomer_RiskView,
      isViewFulfilled: RiskFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false,
    },
    {
      id: "CustomerRiskConfirmation-Fair",
      label: "1:B Forts / " + i18n("nav_labels", "risk_confirmation"),
      View: FairRiskView,
      getInitialState: GetViewInitialStateForCustomer_FairRiskView,
      isViewFulfilled: FairRiskFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false,
    },
    {
      id: "MeetingNotes",
      label: i18n("nav_labels", "meeting_notes"),
      View: MeetingNotesView,
      getInitialState: GetViewInitialStateForCustomer_MeetingNotesView,
      isViewFulfilled: MeetingNotesFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false,
    },
    {
      id: "MeetingNotes-Fair",
      label: "Inför Fas 2 / " + i18n("nav_labels", "fair_meeting_notes"),
      View: FairMeetingNotesView,
      getInitialState: GetViewInitialStateForCustomer_FairMeetingNotesView,
      isViewFulfilled: FairMeetingNotesFulfillment,
      useAlternativeViewFulfillment: false,
    },
    {
      id: "UploadDocuments",
      label: i18n("nav_labels", "view_14"),
      View: UploadDocuments,
      getInitialState: GetViewInitialStateForCustomer_UploadDocuments,
      isViewFulfilled: UploadDocumentsFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "UploadDocuments-Fair",
      label: "Inför Fas 2 / " + i18n("nav_labels", "upload_documents"),
      View: UploadDocumentsFair,
      getInitialState: GetViewInitialStateForCustomer_UploadDocumentsFair,
      isViewFulfilled: UploadDocumentsFairFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "BasicProductSelection-Fair",
      label: "Inför Fas 2 / " + i18n("nav_labels", "basic_product_selection"),
      View: BasicProductSelectionFair,
      getInitialState: GetViewInitialStateForCustomer_BasicProductSelectionFair,
      isViewFulfilled: BasicProductSelectionFairFulfillment,
      useAlternativeViewFulfillment: true,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "Autogiro-Fair",
      label: "Inför Fas 2 / " + i18n("nav_labels", "autogiro"),
      View: AutogiroFair,
      getInitialState: GetViewInitialStateForCustomer_AutogiroFair,
      isViewFulfilled: AutogiroFairFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "SignAgreement",
      label: i18n("nav_labels", "sign_agreement"),
      View: SignAgreement,
      // getInitialState: GetViewInitialStateForCustomer_SignAgreement,
      isViewFulfilled: SignAgreementFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "SignAgreement-Fair",
      label: i18n("nav_labels", "sign_agreement"),
      View: SignAgreementFair,
      getInitialState: GetViewInitialStateForCustomer_SignAgreementFair,
      isViewFulfilled: SignAgreementFairFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "Start-Glofidemo",
      label: i18n("general", "welcome"),
      View: StartGlofidemo,
      getInitialState: GetViewInitialStateForCustomer_StartGlofidemo,
      isViewFulfilled: StartGlofidemoFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "Citroneer-Start",
      label: i18n("general", "welcome"),
      View: StartCitroneer,
      getInitialState: GetViewInitialStateForCustomer_StartCitroneer,
      isViewFulfilled: StartCitroneerFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "CustomerInformation-Glofidemo",
      label: i18n("cob", "customer_information"),
      View: CustomerInformationGlofidemo,
      getInitialState: GetViewInitialStateForCustomer_CustomerInformationGlofidemo,
      isViewFulfilled: CustomerInformationGlofidemoFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "CustomerPreviousExperience-Glofidemo",
      label: i18n("nav_labels", "previous_experience"),
      View: PreviousExperienceGlofidemo,
      getInitialState: GetViewInitialStateForCustomer_PreviousExperienceGlofidemo,
      isViewFulfilled: PreviousExperienceGlofidemoFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "MeetingNotes-Glofidemo",
      label: i18n("nav_labels", "meeting_notes"),
      View: MeetingNotesGlofidemo,
      getInitialState: GetViewInitialStateForCustomer_MeetingNotesGlofidemo,
      isViewFulfilled: MeetingNotesGlofidemoFulfillment,
      useAlternativeViewFulfillment: false
    },
    {
      id: "Autogiro-Glofidemo",
      label: i18n("nav_labels", "autogiro"),
      View: AutogiroGlofidemo,
      getInitialState: GetViewInitialStateForCustomer_AutogiroGlofidemo,
      isViewFulfilled: AutogiroGlofidemoFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    },
    {
      id: "SignAgreement-Glofidemo",
      label: i18n("nav_labels", "sign_agreement"),
      View: SignAgreementGlofidemo,
      getInitialState: GetViewInitialStateForCustomer_SignAgreementGlofidemo,
      isViewFulfilled: SignAgreementGlofidemoFulfillment,
      useAlternativeViewFulfillment: false,
      isDisabledForPersons: false,
      isDisabledForCompanies: false
    }
  ];
}