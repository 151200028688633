import React from "react";

import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import fulfillment from "./AccountSelection.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import TextView from "../../../../../components/TextView/TextView";
import i18n from "../../../../../helpers/i18n";

export default class AccountSelection extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = AccountSelection.getFields();

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	componentDidMount()
	{
		// Signal to parent whether this component has been fulfilled or not
		this.props.onUpdated(this.state.isFulfilled);
	}

	setCustomerState(customerState)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.onUpdated(this.state.isFulfilled);
		});
	}

	render()
	{
		const {props, state} = this;
		const {customer, onboardingInfo} = props;
		const {customerState} = state;

		let accountTypeOptions;
		if (state.workflow === "futur")
		{
			accountTypeOptions = [
				{
					name: "chk_accounttype_kf",
					label: "Depå kapitalförsäkring",
					value: "yes",
					checked: true,
					onChange: checked => this.setCustomerState({chk_accounttype_kf: checked ? "yes" : "no"})
				}
			];
		}
		else if (customer.isCompany)
		{
			accountTypeOptions = [
				{
					name: "chk_accounttype_naked",
					label: i18n("account_selection", "share_fund_account"),
					value: "yes",
					checked: !onboardingInfo.has_naked_account || customerState.chk_accounttype_naked === "yes",
					onChange: checked => this.setCustomerState({chk_accounttype_naked: !onboardingInfo.has_naked_account || checked ? "yes" : "no"})
				},
				{
					name: "chk_accounttype_kf",
					label: i18n("account_selection", "depot_capital_insurance"),
					value: "yes",
					checked: customerState.chk_accounttype_kf === "yes",
					onChange: checked => this.setCustomerState({chk_accounttype_kf: checked ? "yes" : "no"})
				},
				{
					name: "chk_accounttype_tp",
					label: i18n("account_selection", "depot_occ_pension_insurance"),
					value: "yes",
					checked: customerState.chk_accounttype_tp === "yes",
					onChange: checked => this.setCustomerState({chk_accounttype_tp: checked ? "yes" : "no"})
				}
			];
		}
		else
		{
			accountTypeOptions = [
				{
					name: "chk_accounttype_naked",
					label: i18n("account_selection", "share_fund_account"),
					value: "yes",
					checked: !onboardingInfo.has_naked_account || customerState.chk_accounttype_naked === "yes",
					onChange: checked => this.setCustomerState({chk_accounttype_naked: !onboardingInfo.has_naked_account || checked ? "yes" : "no"})
				},
				{
					name: "chk_accounttype_isk",
					label: i18n("account_selection", "investment_savings_account"),
					value: "yes",
					checked: customerState.chk_accounttype_isk === "yes",
					onChange: checked => this.setCustomerState({chk_accounttype_isk: checked ? "yes" : "no"})
				},
				{
					name: "chk_accounttype_kf",
					label: i18n("account_selection", "depot_capital_insurance"),
					value: "yes",
					checked: customerState.chk_accounttype_kf === "yes",
					onChange: checked => this.setCustomerState({chk_accounttype_kf: checked ? "yes" : "no"})
				},
				{
					name: "chk_accounttype_tp",
					label: i18n("account_selection", "depot_occ_pension_insurance"),
					value: "yes",
					checked: customerState.chk_accounttype_tp === "yes",
					onChange: checked => this.setCustomerState({chk_accounttype_tp: checked ? "yes" : "no"})
				}
			];
		}

		return (
			<div style={{width: "100%"}}>
				<DefaultFields fields={this.fields}/>

				<div className="Cob-view2-fields">
					<h3>{i18n("account_selection", "account_selection")}</h3>

					<p>{i18n("account_selection", "accounts_to_open")}

						{state.workflow !== "futur" && (
							<span> {i18n("account_selection", "share_fund_account_text")}</span>
						)}

						{state.workflow !== "futur" && !customer.isCompany && (
							<span> {i18n("account_selection", "investments_savings_account_text")}</span>
						)}
					</p>

					<OptionsList
						type="checkbox"
						orientation="horizontal"
						options={accountTypeOptions}
						style={{marginTop: "5px", maxWidth: "none"}}
					/>

					<h4>{i18n("action_selection", "recommendation")}*</h4>

					<TextView
						name="account_selection_recommendation"
            label={`${i18n("general", "free_text")} (${2000 - customerState.account_selection_recommendation.length} ${i18n("general", "remaining_chars")})`}
						value={customerState.account_selection_recommendation}
						maxLength={2000}
						validation={value => value.length > 0}
						resize="vertical"
						onChange={({target}) => this.setCustomerState({account_selection_recommendation: target.value})}
						fullWidth
						minHeight={160}
						required={true}
					/>

				</div>
			</div>
		);
	}

	static getViewInitialStateForCustomer(customer, workflow)
	{
		workflow = workflow == null ? "default" : workflow;

		let initialState = {
			workflow: workflow,
			isFulfilled: false,
			customerState: {
				account_selection_recommendation: customer.getData("account_selection_recommendation") || "",
				chk_accounttype_naked: customer.getData("chk_accounttype_naked"),
				chk_accounttype_isk: customer.getData("chk_accounttype_isk"),
				chk_accounttype_kf: customer.getData("chk_accounttype_kf"),
				chk_accounttype_tp: customer.getData("chk_accounttype_tp")
			}
		};

		if (workflow === "futur")
		{
			initialState.customerState.chk_accounttype_kf = "yes";
		}
		return initialState;
	}

	static getFields()
	{
		return [
			"account_selection_recommendation",

			"chk_accounttype_naked",
			"chk_accounttype_isk",
			"chk_accounttype_kf",
			"chk_accounttype_tp",

			"account_no_depa",
			"account_no_isk",
			"account_no_kf",
			"account_no_tp"
		];
	}
}

// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, currentWorkflow, onboardingInfo} = this.props;

	let newState = AccountSelection.getViewInitialStateForCustomer(customer, currentWorkflow);

	if (!onboardingInfo.has_naked_account)
	{
		customer._formData['chk_accounttype_naked'] = "yes";
		newState.customerState.chk_accounttype_naked = "yes";
	}

	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}