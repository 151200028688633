// CLASSES
import {mergeViewStateForFulfillment} from "../../../Cob.helpers";

export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") {
		return true;
	}


	state = mergeViewStateForFulfillment(state, prevState);
	const {customerState} = state;

	if (customerState.situation_income_work.length <= 0 ||
		customerState.situation_income_other.length <= 0 ||
		customerState.situation_expenses.length <= 0) {
		return false;
	}

	return true;
}