import {mergeViewStateForFulfillment} from "../../../Cob.helpers";


export default function (state, prevState) {
	if (import.meta.env.VITE_CHECK_VIEW_FULFILLMENT === "0") return true;
	state = mergeViewStateForFulfillment(state, prevState);

	const {customerState} = state;

	// Hur länge har vi handlat med finansiella instrument måste vara vald
	if (customerState.yearsExperience.length <= 0) {
		return false;
	}

	// Högsta utbildningsnivå måste vara ifylld
	if (customerState.highestEducationIndex === -1) {
		return false;
	}

	// Om man valt universitet måste inriktning anges
	if (customerState.highestEducationIndex === 2 && customerState.customer_university.length <= 0) {
		return false;
	}

	// Nuvarande eller tidigare befattning måste vara vald
	if (customerState.currentOrPreviousCareerIndex === -1) {
		return false;
	}

	return true;
}