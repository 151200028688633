import React from "react";
// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";
import ContentBox from "@components/ContentBox/ContentBox";
import DefaultFields from "../../../components/DefaultFields/DefaultFields";
import StackLayout from "@components/layouts/StackLayout/StackLayout";
// HELPERS
import {createClassName} from "@helpers/utils";
import i18n from "@helpers/i18n";
// OTHER
import fulfillment from "./Risk.fulfillment";
import OptionsList from "../../../components/OptionsList/OptionsList";
import Select from "../../../../../components/Select/Select";
import TextField from "../../../../../components/TextField/TextField";
import Modal from "../../../../../components/Modal/Modal";
import SimpleTextField from "../../../components/SimpleTextField/SimpleTextField";
import TextView from "../../../../../components/TextView/TextView";


export default class RiskView extends React.PureComponent
{
	state = _getInitialState.call(this);
	fields = _getFields.call(this);
	saveHorizonValues = [
		{label: "Kortare än 3 år", value: "<3"},
		{label: "Minst 3 år", value: "3+"},
		{label: "Minst 4 år", value: "4+"},
		{label: "Minst 5 år", value: "5+"},
		{label: "6 till 10 år", value: "6-10"},
		{label: "Över 10 år", value: ">10"}
	];

	changedPrefsText = "Rådgivaren har meddelat kunden att Fair inte fullt ut kan uppfylla kundens angivna " +
		"hållbarhetspreferenser. Efter att ha övervägt detta har kunden beslutat att ändra sina hållbarhetspreferenser " +
		"och specificerat att denna inte längre har några hållbarhetspreferenser. Nu, när kundens hållbarhetspreferenser " +
		"har ändrats, bedömer rådgivaren att Fair kan uppfylla kundens mål och preferenser.";

	isFulfilled(state)
	{
		return fulfillment(state, this.state)
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {},
				next: {
					onClick: () =>
					{
						if (this.state.isFulfilled === true)
						{
							return;
						}
						this.setState({hasFormValidationErrors: true});
						return setAppState({showValidationWarning: true});
					}
				}
			}
		});
	}

	render()
	{
		const {state} = this;
		const {customerState} = state;
		const hasFormValidationErrors = !this.state.isFulfilled && this.state.hasFormValidationErrors;
		const {customer} = this.props;
		const classes = createClassName("Cob-view Cob-view6", {});

		return (
			<div className={classes}>
				<DefaultFields fields={this.fields}/>

				<Modal visible={customerState.radio_advice_customer_riskrev === "no"} status="danger" title="Varning">
					<React.Fragment>
						<p>
							Kunden måste göras införstådd med att ökade krav på avkastning även leder till ökad risk.
						</p>

						<Modal.ButtonsContainer>
							<Modal.Button label={i18n("general", "close")} appearance="primary"
										  onClick={() => this.setCustomerState({
											  radio_advice_customer_riskrev: customerState.radio_advice_customer_riskrev === "no" ? "" : this.state.customerState.radio_advice_customer_riskrev
										  })}/>
						</Modal.ButtonsContainer>
					</React.Fragment>
				</Modal>

				<ContentBox>
					<h2>Lämplighetsbedömning</h2>

					<div className="Cob-view6-fields">
						<div className="question" style={{width: "100%", display: "block"}}>
							<Checkbox name="checkbox_advice_skip_placement"
									  label="Hoppa över lämplighetsbedömning - ingen placering vid detta rådgivningstillfälle"
									  value="yes"
									  checked={customerState.checkbox_advice_skip_placement === "yes"}
									  onChange={checked => this.setCustomerState({checkbox_advice_skip_placement: checked ? "yes" : "no"})}
							/>

							{customerState.checkbox_advice_skip_placement !== "yes" && (
								<React.Fragment>
									<div className="h-line spacing"/>

									<h3 style={{paddingBottom: "10px"}}>Risk på kundens portfölj</h3>

									<Checkbox
										type="checkbox"
										name="checkbox_advice_riskinfo"
										label='Kunden har tagit del av och förstått bilagan "Information om riskklasser"'
										value={customerState.checkbox_advice_riskinfo === "Ja" ? "Ja" : "Nej"}
										checked={customerState.checkbox_advice_riskinfo === "Ja"}
										onChange={checked => this.setCustomerState({checkbox_advice_riskinfo: checked ? "Ja" : "Nej"})}
										style={{paddingRight: "15px"}}
										highlightErrors={hasFormValidationErrors}
									/>

									<h4 style={{padding: "30px 0 0 0", margin: "0 0 10px 0"}}>
										Önskad risknivå, totala portföljen
									</h4>
									<div className="numbers-with-colors">
										<OptionsList
											type="radio"
											name="radio_advice_portfolio_risk"
											value={customerState.radio_advice_portfolio_risk}
											options={[
												{
													label: "1",
													value: "1",
													checked: customerState.radio_advice_portfolio_risk === "1"
												},
												{
													label: "2",
													value: "2",
													checked: customerState.radio_advice_portfolio_risk === "2"
												},
												{
													label: "3",
													value: "3",
													checked: customerState.radio_advice_portfolio_risk === "3"
												},
												{
													label: "4",
													value: "4",
													checked: customerState.radio_advice_portfolio_risk === "4"
												},
												{
													label: "5",
													value: "5",
													checked: customerState.radio_advice_portfolio_risk === "5"
												},
												{
													label: "6",
													value: "6",
													checked: customerState.radio_advice_portfolio_risk === "6"
												},
												{
													label: "7",
													value: "7",
													checked: customerState.radio_advice_portfolio_risk === "7"
												},
											]}
											onChange={(checked, radio_advice_portfolio_risk) => this.setCustomerState({radio_advice_portfolio_risk})}
											validation={this._optionsValidation}
											style={{maxWidth: "unset"}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<h4 style={{padding: "30px 0 0 0", margin: "0 0 10px 0"}}>
										Vilken risknivå stämmer bäst överrens med kundens riskbenägenhet vid just det
										här investeringstillfället?
									</h4>
									<div className="numbers-with-colors">
										<OptionsList
											type="radio"
											name="radio_advice_riskpropensity"
											value={customerState.radio_advice_riskpropensity}
											options={[
												{
													label: "1",
													value: "1",
													checked: customerState.radio_advice_riskpropensity === "1"
												},
												{
													label: "2",
													value: "2",
													checked: customerState.radio_advice_riskpropensity === "2"
												},
												{
													label: "3",
													value: "3",
													checked: customerState.radio_advice_riskpropensity === "3"
												},
												{
													label: "4",
													value: "4",
													checked: customerState.radio_advice_riskpropensity === "4"
												},
												{
													label: "5",
													value: "5",
													checked: customerState.radio_advice_riskpropensity === "5"
												},
												{
													label: "6",
													value: "6",
													checked: customerState.radio_advice_riskpropensity === "6"
												},
												{
													label: "7",
													value: "7",
													checked: customerState.radio_advice_riskpropensity === "7"
												},
											]}
											onChange={(checked, radio_advice_riskpropensity) => this.setCustomerState({radio_advice_riskpropensity})}
											validation={this._optionsValidation}
											style={{maxWidth: "unset"}}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									{customerState.radio_advice_riskpropensity && customerState.radio_advice_portfolio_risk &&
										customerState.radio_advice_riskpropensity !== customerState.radio_advice_portfolio_risk && (
											<div style={{
												background: "#F7F7F7",
												padding: "20px",
												borderRadius: "6px",
												margin: "30px 0 0 0",
												alignItems: "flex-start",
												maxWidth: "600px"
											}}>
												<strong style={{
													lineHeight: "18px",
													paddingBottom: "20px"
												}}>
													Om risknivån vid nuvarande investeringstillfälle inte stämmer
													överrens
													med kundens önskade totala portföljrisk, motivera varför.
												</strong>
												<Checkbox
													type="checkbox"
													name="checkbox_advice_invrisk_same"
													label="Investeringen vid detta tillfälle utgör en så liten del av kundens samlade portfölj att den totala risknivån påverkas minimalt"
													value={customerState.checkbox_advice_invrisk_same === "Ja" ? "Ja" : "Nej"}
													checked={customerState.checkbox_advice_invrisk_same === "Ja"}
													onChange={checked => this.setCustomerState({checkbox_advice_invrisk_same: checked ? "Ja" : "Nej"})}
													highlightErrors={hasFormValidationErrors}
													groupValid={
														customerState.checkbox_advice_invrisk_same === "Ja" ||
														customerState.checkbox_advice_invrisk_changed === "Ja" ||
														customerState.checkbox_advice_invrisk_other === "Ja"
													}
												/>

												<Checkbox
													type="checkbox"
													name="checkbox_advice_invrisk_changed"
													label="Kunden önskar en annan riskprofil med den här investeringen än den totala portföljrisken"
													value={customerState.checkbox_advice_invrisk_changed === "Ja" ? "Ja" : "Nej"}
													checked={customerState.checkbox_advice_invrisk_changed === "Ja"}
													onChange={checked => this.setCustomerState({checkbox_advice_invrisk_changed: checked ? "Ja" : "Nej"})}
													style={{marginTop: "20px"}}
													highlightErrors={hasFormValidationErrors}
													groupValid={
														customerState.checkbox_advice_invrisk_same === "Ja" ||
														customerState.checkbox_advice_invrisk_changed === "Ja" ||
														customerState.checkbox_advice_invrisk_other === "Ja"
													}
												/>

												<Checkbox
													type="checkbox"
													name="checkbox_advice_invrisk_other"
													label="Annat (Berätta vad)"
													value={customerState.checkbox_advice_invrisk_other === "Ja" ? "Ja" : "Nej"}
													checked={customerState.checkbox_advice_invrisk_other === "Ja"}
													onChange={checked => this.setCustomerState({checkbox_advice_invrisk_other: checked ? "Ja" : "Nej"})}
													style={{marginTop: "20px"}}
													highlightErrors={hasFormValidationErrors}
													groupValid={
														customerState.checkbox_advice_invrisk_same === "Ja" ||
														customerState.checkbox_advice_invrisk_changed === "Ja" ||
														customerState.checkbox_advice_invrisk_other === "Ja"
													}
												/>

												{customerState.checkbox_advice_invrisk_other === "Ja" && (
													<TextField
														style={{marginTop: "10px"}}
														name="advice_invrisk_other_expl"
														type="text"
														label="Ange"
														validation={value => value.length > 0}
														value={customerState.advice_invrisk_other_expl}
														onChange={({target}) => this.setCustomerState({advice_invrisk_other_expl: target.value})}
														highlightErrors={hasFormValidationErrors}
													/>
												)}
											</div>
										)}

									<StackLayout className="Cob-fields-collection per-row-2" rowWrap fullWidth
												 style={{marginTop: "40px"}}>

										<div style={{alignSelf: "flex-start"}}>
											<strong style={{display: "block", padding: "0 0 10px 0"}}>
												Placeringshorisont, detta investeringstillfälle
											</strong>
											<Select
												name="checkbox_advice_savehoriz_placement"
												label="Ange år"
												selectedIndex={customerState.text_savehoriz_placementIndex}
												options={this.saveHorizonValues}
												onChange={(option, index) => {
													this.setCustomerState({checkbox_advice_savehoriz_placement: option.value, text_savehoriz_placementIndex: index});
												}}
												highlightErrors={hasFormValidationErrors}
											/>
											<input type={"hidden"} name={"text_savehoriz_placement"}
											       value={customerState.text_savehoriz_placementIndex !== -1 ?
												       this.saveHorizonValues[customerState.text_savehoriz_placementIndex].label :
												       ""}/>
										</div>

										<div style={{alignSelf: "flex-start"}}>
											<strong
												style={{display: "block", padding: "0 0 10px 0"}}>
												Beskriv kundens avkastningsmål
											</strong>
											<TextView
												name="customer_kyc_purpose_savinggoal_description"
												label={`Beskriv (${200 - customerState.customer_kyc_purpose_savinggoal_description.length} tecken kvar)`}
												value={customerState.customer_kyc_purpose_savinggoal_description}
												maxLength={200}
												validation={value => value.length > 0}
												resize="vertical"
												onChange={({target}) => this.setCustomerState({customer_kyc_purpose_savinggoal_description: target.value})}
												fullWidth
												minHeight={100}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>
									</StackLayout>

									<strong style={{display: "block", padding: "10px 0"}}>
										Är kunden införstådd med att ett högre avkastningsmål också innebär ett
										högre risktagande
									</strong>
									<div style={{alignItems: "flex-start", maxWidth: "240px"}}>
										<OptionsList
											type="radio"
											name="radio_advice_customer_riskrev"
											style={{maxWidth: "none"}}
											value={customerState.radio_advice_customer_riskrev}
											options={[
												{
													label: "Nej",
													value: "no",
													checked: customerState.radio_advice_customer_riskrev === "no"
												},
												{
													label: "Ja",
													value: "Ja",
													checked: customerState.radio_advice_customer_riskrev === "Ja"
												}
											]}
											onChange={(checked, radio_advice_customer_riskrev) => this.setCustomerState({radio_advice_customer_riskrev})}
											validation={this._optionsValidation}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									<OptionsList
										type="radio"
										description="Hur sannolikt är det att kunden har behov av hälften eller mer av de investerade pengarna tidigare än planerat?"
										name="radio_advice_needing_investment"
										orientation="horizontal"
										style={{marginTop: "20px"}}
										value={customerState.radio_advice_needing_investment}
										options={[
											{
												label: "Mycket osannolikt",
												value: "ul",
												checked: customerState.radio_advice_needing_investment === "ul",
											},
											{
												label: "Sannolikt",
												value: "l",
												checked: customerState.radio_advice_needing_investment === "l",
											},
											{
												label: "Mycket sannolikt",
												value: "vl",
												checked: customerState.radio_advice_needing_investment === "vl"
											}
										]}
										onChange={(checked, radio_advice_needing_investment) => this.setCustomerState({radio_advice_needing_investment})}
										validation={this._optionsValidation}
										highlightErrors={hasFormValidationErrors}
									/>


									<strong style={{display: "block", padding: "20px 0 5px 0"}}>
										Finns det några andra specifika mål eller önskemål som kunden vill ska tas
										hänsyn till i samband med detta investeringstillfälle?
									</strong>
									<div style={{alignItems: "flex-start", maxWidth: "240px"}}>
										<OptionsList
											type="radio"
											name="radio_investment_preferences"
											style={{maxWidth: "none"}}
											value={customerState.radio_investment_preferences}
											options={[
												{
													label: "Nej",
													value: "no",
													checked: customerState.radio_investment_preferences === "no"
												},
												{
													label: "Ja",
													value: "yes",
													checked: customerState.radio_investment_preferences === "yes"
												}
											]}
											onChange={(checked, radio_investment_preferences) => this.setCustomerState({radio_investment_preferences})}
											validation={this._optionsValidation}
											highlightErrors={hasFormValidationErrors}
										/>
									</div>

									{customerState.radio_investment_preferences === "yes" && (
										<React.Fragment>
											<TextView
												name="investment_preferences_text"
												label={`Beskriv (${200 - customerState.investment_preferences_text.length} tecken kvar)`}
												value={customerState.investment_preferences_text}
												maxLength={200}
												validation={value => value.length > 0}
												resize="vertical"
												onChange={({target}) => this.setCustomerState({investment_preferences_text: target.value})}
												fullWidth
												minHeight={100}
												highlightErrors={hasFormValidationErrors}
												style={{marginTop: "10px"}}
											/>
										</React.Fragment>
									)}

									<div className="h-line spacing" style={{marginTop: "30px"}}/>

									<div style={{width: "100%", display: "block", marginTop: "0"}}>
										<h4>Har kunden några hållbarhetspreferenser?</h4>
										<p style={{marginBottom: "0"}}>Enligt bilagan <em>Information om hållbarhetspreferenser</em></p>

										<div style={{alignItems: "flex-start", maxWidth: "240px", width: "100%"}}>
											<OptionsList
												type="radio"
												name="radio_hallbarhet_has_preferences"
												style={{maxWidth: "none"}}
												value={customerState.radio_hallbarhet_has_preferences}
												options={[
													{
														label: "Nej",
														value: "no",
														checked: customerState.radio_hallbarhet_has_preferences === "no"
													},
													{
														label: "Ja",
														value: "yes",
														checked: customerState.radio_hallbarhet_has_preferences === "yes"
													}
												]}
												onChange={(checked, radio_hallbarhet_has_preferences) => this.setCustomerState({radio_hallbarhet_has_preferences})}
												validation={this._optionsValidation}
												highlightErrors={hasFormValidationErrors}
											/>
										</div>

										{customerState.radio_hallbarhet_has_preferences === "yes" && (
											<React.Fragment>
												<p className={"Cob-OptionsList-description"} style={{marginTop: "20px"}}>
													Vilken/vilka av följande instrumenttyper avser kundens
													hållbarhetspreferenser?
												</p>

												<Checkbox style={{maxWidth: "740px", width: "100%", marginTop: "20px"}}
														  name="checkbox_hallbarhet_852"
														  label="Ett finansiellt instrument där en viss minimiandel ska investeras i miljömässigt hållbara verksamheter enligt artikel 2.1 Europaparlamentets och rådets förordning (EU) 2020/852 (Taxonomiförordningen)."
														  value="yes"
														  checked={customerState.checkbox_hallbarhet_852 === "yes"}
														  onChange={checked => this.setCustomerState({checkbox_hallbarhet_852: checked ? "yes" : "no"})}
														  highlightErrors={hasFormValidationErrors}
														  groupValid={
															  customerState.checkbox_hallbarhet_852 === "yes" ||
															  customerState.checkbox_hallbarhet_2088 === "yes" ||
															  customerState.checkbox_hallbarhet_customer === "yes"
														  }
												/>

												{customerState.checkbox_hallbarhet_852 === "yes" && (
													<React.Fragment>
														<div style={{
															paddingLeft: "35px",
															paddingBottom: "20px",
															display: "block"
														}}>
															<p style={{
																marginBottom: "5px",
																fontSize: "13px",
																fontWeight: "bold"
															}}>
																Minimiandel
															</p>
															<div style={{flexDirection: "row"}}>
																<SimpleTextField
																	type="number"
																	name={"customer_hallbarhet_852_minimum"}
																	placeholder="Ange i %"
																	value={customerState.customer_hallbarhet_852_minimum || ""}
																	textAlign="left"
																	validation={value => (value.length > 0 && value > 0 && value <= 100)}
																	style={{width: "100px", marginRight: "5px"}}
																	onChange={({target}) => this.setCustomerState({customer_hallbarhet_852_minimum: target.value})}
																	highlightErrors={hasFormValidationErrors}
																/> %
															</div>
														</div>
													</React.Fragment>
												)}

												<Checkbox style={{maxWidth: "740px", width: "100%", marginTop: "20px"}}
														  name="checkbox_hallbarhet_2088"
														  label="Ett finansiellt instrument där en viss minimiandel ska investeras i hållbara investeringar enligt artikel 2.17 Europaparlamentets och rådets förordning (EU) 2019/2088 (Disclosureförordningen)."
														  value="yes"
														  checked={customerState.checkbox_hallbarhet_2088 === "yes"}
														  onChange={checked => this.setCustomerState({checkbox_hallbarhet_2088: checked ? "yes" : "no"})}
														  highlightErrors={hasFormValidationErrors}
														  groupValid={
															  customerState.checkbox_hallbarhet_852 === "yes" ||
															  customerState.checkbox_hallbarhet_2088 === "yes" ||
															  customerState.checkbox_hallbarhet_customer === "yes"
														  }
												/>

												{customerState.checkbox_hallbarhet_2088 === "yes" && (
													<React.Fragment>
														<div style={{
															paddingLeft: "35px",
															paddingBottom: "20px",
															display: "block"
														}}>
															<p style={{
																marginBottom: "5px",
																fontSize: "13px",
																fontWeight: "bold"
															}}>
																Minimiandel
															</p>
															<div style={{flexDirection: "row"}}>
																<SimpleTextField
																	type="number"
																	name={"customer_hallbarhet_2088_text"}
																	placeholder="Ange i %"
																	value={customerState.customer_hallbarhet_2088_text || ""}
																	textAlign="left"
																	validation={value => (value.length > 0 && value > 0 && value <= 100)}
																	style={{width: "100px", marginRight: "5px"}}
																	onChange={({target}) => this.setCustomerState({customer_hallbarhet_2088_text: target.value})}
																	highlightErrors={hasFormValidationErrors}
																/>
																%
															</div>
														</div>
													</React.Fragment>
												)}

												<Checkbox style={{maxWidth: "740px", width: "100%", marginTop: "20px"}}
														  name="checkbox_hallbarhet_customer"
														  label="Ett finansiellt instrument som beaktar negativa konsekvenser för hållbarhetsfaktorer (miljörelaterade, sociala och personalrelaterade frågor, respekt för mänskliga rättigheter samt bekämpning av korruption och mutor)."
														  value="yes"
														  checked={customerState.checkbox_hallbarhet_customer === "yes"}
														  onChange={checked => this.setCustomerState({checkbox_hallbarhet_customer: checked ? "yes" : "no"})}
														  highlightErrors={hasFormValidationErrors}
														  groupValid={
															  customerState.checkbox_hallbarhet_852 === "yes" ||
															  customerState.checkbox_hallbarhet_2088 === "yes" ||
															  customerState.checkbox_hallbarhet_customer === "yes"
														  }
												/>

												{customerState.checkbox_hallbarhet_customer === "yes" && (
													<div style={{
														paddingLeft: "35px",
														paddingBottom: "20px",
														paddingTop: "20px",
														maxWidth: "740px",
														width: "100%",
														display: "block"
													}}>
														<TextView
															name="hallbarhet_customer_text"
															label={`Beskriv vilket (${500 - customerState.hallbarhet_customer_text.length} tecken kvar)`}
															value={customerState.hallbarhet_customer_text}
															maxLength={500}
															validation={value => value.length > 0}
															resize="vertical"
															onChange={({target}) => this.setCustomerState({hallbarhet_customer_text: target.value})}
															fullWidth
															minHeight={250}
														/>
													</div>
												)}

												<h4 style={{marginTop: "40px"}}>Beskrivning av kundens hållbarhetspreferenser</h4>

												<TextView
													name="customer_hallbarhet_customer_text"
													label={`Ange (${1500 - customerState.customer_hallbarhet_customer_text.length} tecken kvar)`}
													value={customerState.customer_hallbarhet_customer_text}
													maxLength={1500}
													validation={value => value.length > 0}
													resize="vertical"
													onChange={({target}) => this.setCustomerState({customer_hallbarhet_customer_text: target.value})}
													fullWidth
													minHeight={250}
													highlightErrors={hasFormValidationErrors}
												/>

												<h4 style={{marginTop: "40px"}}>Har rådgivaren kunnat beakta kundens hållbarhetspreferenser i rådgivningen?</h4>
												<div style={{alignItems: "flex-start", maxWidth: "240px", width: "100%"}}>
													<OptionsList
														type="radio"
														name="checkbox_hallbarhet_considered"
														style={{maxWidth: "none"}}
														value={customerState.checkbox_hallbarhet_considered}
														options={[
															{
																label: "Nej",
																value: "no",
																checked: customerState.checkbox_hallbarhet_considered === "no"
															},
															{
																label: "Ja",
																value: "yes",
																checked: customerState.checkbox_hallbarhet_considered === "yes"
															}
														]}
														onChange={(checked, checkbox_hallbarhet_considered) => this.setCustomerState({checkbox_hallbarhet_considered})}
														validation={this._optionsValidation}
														highlightErrors={hasFormValidationErrors}
													/>
												</div>

												{customerState.checkbox_hallbarhet_considered === "no" && (
													<div style={{alignItems: "flex-start", width: "100%", marginTop: "20px"}}>
														<OptionsList
															description={"Har kunden valt att ändra sina hållbarhetspreferenser?"}
															type="radio"
															name="checkbox_hallbarhet_changed"
															style={{maxWidth: "none"}}
															value={customerState.checkbox_hallbarhet_changed}
															options={[
																{
																	label: "Nej",
																	value: "no",
																	checked: customerState.checkbox_hallbarhet_changed === "no"
																},
																{
																	label: "Ja",
																	value: "yes",
																	checked: customerState.checkbox_hallbarhet_changed === "yes"
																}
															]}
															onChange={(checked, checkbox_hallbarhet_changed) => this.setCustomerState({checkbox_hallbarhet_changed})}
															validation={this._optionsValidation}
															highlightErrors={hasFormValidationErrors}
														/>
													</div>
												)}

												{customerState.checkbox_hallbarhet_considered === "no" &&
													customerState.checkbox_hallbarhet_changed === "no" && (
													<TextView
														name="customer_hallbarhet_not_considered_text"
														label={`Motivera eventuell avvikelse från hållbarhetspreferenserna (${800 - customerState.customer_hallbarhet_not_considered_text.length} tecken kvar)`}
														value={customerState.customer_hallbarhet_not_considered_text}
														maxLength={800}
														validation={value => value.length > 0}
														resize="vertical"
														onChange={({target}) => this.setCustomerState({customer_hallbarhet_not_considered_text: target.value})}
														fullWidth
														minHeight={250}
														style={{marginTop: "10px"}}
														highlightErrors={hasFormValidationErrors}
													/>
												)}

												{customerState.checkbox_hallbarhet_considered === "no" &&
													customerState.checkbox_hallbarhet_changed === "yes" && (
														<React.Fragment>
															<TextView
																name="customer_hallbarhet_not_considered_text"
																label={`Motivera eventuell avvikelse från hållbarhetspreferenserna (ej editerbart)`}
																value={this.changedPrefsText}
																maxLength={800}
																validation={value => value.length > 0}
																resize="vertical"
																onChange={({target}) => this.setCustomerState({customer_hallbarhet_not_considered_text: target.value})}
																fullWidth
																minHeight={250}
																style={{marginTop: "10px"}}
																readOnly={true}
															/>
															<input type={"hidden"}
															       name={"customer_hallbarhet_not_considered_text"}
															       value={this.changedPrefsText}/>
														</React.Fragment>
													)}
											</React.Fragment>
										)}
									</div>
								</React.Fragment>
							)}
						</div>
					</div>
				</ContentBox>
			</div>
		);
	}

	_textValidation = (value) => (value.length > 0);

	_optionsValidation = (value) => ((value && value.length > 0) || false);
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, viewNo, hasFormValidationErrors} = this.props;
	const seenViews = JSON.parse(localStorage.getItem(`SEEN_VIEWS_${btoa(customer.caseId)}`)) || [];
	const hasBeenSeen = (seenViews.includes(viewNo));
	// if it hasn't been seen add the view to local storage for the specific case
	if (customer.caseId && !hasBeenSeen)
	{
		localStorage.setItem(`SEEN_VIEWS_${btoa(customer.caseId)}`, JSON.stringify([...seenViews, viewNo]));
	}

	let newState = getViewInitialStateForCustomer(customer);
	newState.isFulfilled = this.isFulfilled(newState);
	newState.hasFormValidationErrors = hasBeenSeen || hasFormValidationErrors;
	return newState;
}

export function getViewInitialStateForCustomer(customer)
{
	let initialState = {
		isFulfilled: false,
		customerState: {
			checkbox_advice_skip_placement: customer.getData("checkbox_advice_skip_placement") || "no",

			isCompany: customer.isCompany,
			checkbox_advice_riskinfo: customer.getData("checkbox_advice_riskinfo"),
			radio_advice_portfolio_risk: customer.getData("radio_advice_portfolio_risk"),
			radio_advice_riskpropensity: customer.getData("radio_advice_riskpropensity"),
			text_savehoriz_placement: customer.getData("text_savehoriz_placement"),
			radio_advice_customer_riskrev: customer.getData("radio_advice_customer_riskrev"),

			radio_advice_needing_investment: customer.getData("radio_advice_needing_investment"),

			checkbox_advice_invrisk_same: customer.getData("checkbox_advice_invrisk_same"),
			checkbox_advice_invrisk_changed: customer.getData("checkbox_advice_invrisk_changed"),
			checkbox_advice_invrisk_other: customer.getData("checkbox_advice_invrisk_other"),
			advice_invrisk_other_expl: customer.getData("advice_invrisk_other_expl"),

			checkbox_advice_savehoriz_placement: customer.getData("checkbox_advice_savehoriz_placement"),
			text_savehoriz_placementIndex: (
				customer.getData("checkbox_advice_savehoriz_placement") === "<1" ? 0 :
					customer.getData("checkbox_advice_savehoriz_placement") === "<3" ? 0 :
						customer.getData("checkbox_advice_savehoriz_placement") === "1-2" ? 0 :
							customer.getData("checkbox_advice_savehoriz_placement") === "3+" ? 1 :
								customer.getData("checkbox_advice_savehoriz_placement") === "4+" ? 2 :
									customer.getData("checkbox_advice_savehoriz_placement") === "5+" ? 3 :
										customer.getData("checkbox_advice_savehoriz_placement") === "4-6" ? 2 :
											customer.getData("checkbox_advice_savehoriz_placement") === "6-10" ? 4 :
												customer.getData("checkbox_advice_savehoriz_placement") === ">10" ? 5 :
													-1
			),

			radio_hallbarhet_has_preferences: customer.getData("radio_hallbarhet_has_preferences"),
			checkbox_hallbarhet_considered: customer.getData("checkbox_hallbarhet_considered"),
			checkbox_hallbarhet_852: customer.getData("checkbox_hallbarhet_852"),
			customer_hallbarhet_852_minimum: customer.getData("customer_hallbarhet_852_minimum"),
			checkbox_hallbarhet_2088: customer.getData("checkbox_hallbarhet_2088"),
			customer_hallbarhet_2088_text: customer.getData("customer_hallbarhet_2088_text"),
			checkbox_hallbarhet_customer: customer.getData("checkbox_hallbarhet_customer"),
			customer_hallbarhet_customer_text: customer.getData("customer_hallbarhet_customer_text"),
			hallbarhet_customer_text: customer.getData("hallbarhet_customer_text"),
			checkbox_hallbarhet_changed: customer.getData("checkbox_hallbarhet_changed"),
			customer_hallbarhet_not_considered_text: customer.getData("customer_hallbarhet_not_considered_text"),

			customer_kyc_purpose_savinggoal_description: customer.getData("customer_kyc_purpose_savinggoal_description"),
			radio_investment_preferences: customer.getData("radio_investment_preferences"),
			investment_preferences_text: customer.getData("investment_preferences_text")
		}
	};

	// Translate legacy values into new equivalents
	if (initialState.customerState.checkbox_advice_savehoriz_placement === "<1" ||
		initialState.customerState.checkbox_advice_savehoriz_placement === "1-2")
	{
		initialState.customerState.checkbox_advice_savehoriz_placement = "<3";
	}
	else if (initialState.customerState.checkbox_advice_savehoriz_placement === "4-6")
	{
		initialState.customerState.checkbox_advice_savehoriz_placement = "4+";
	}

	return initialState;
}

function _getFields()
{
	return [
		"checkbox_advice_skip_placement",
		"checkbox_advice_riskinfo",
		"radio_advice_customer_risk",
		"radio_advice_portfolio_risk",
		"advice_profitgoal",

		"aml_origin_other_desc",

		"checkbox_advice_invrisk_same",
		"checkbox_advice_invrisk_changed",
		"checkbox_advice_invrisk_other",
		"advice_invrisk_other_expl",

		"radio_advice_riskpropensity",
		"checkbox_advice_savehoriz_placement",
		"text_savehoriz_placement",
		"radio_advice_customer_riskrev",

		"radio_hallbarhet_has_preferences",
		"checkbox_hallbarhet_considered",
		"checkbox_hallbarhet_852",
		"customer_hallbarhet_852_minimum",
		"checkbox_hallbarhet_2088",
		"customer_hallbarhet_2088_text",
		"checkbox_hallbarhet_customer",
		"customer_hallbarhet_customer_text",
		"hallbarhet_customer_text",
		"checkbox_hallbarhet_changed",
		"customer_hallbarhet_not_considered_text",
		"radio_advice_needing_investment",
		"customer_kyc_purpose_savinggoal_description",
		"radio_investment_preferences",
		"investment_preferences_text",
	];
}