// MODULES
import React from "react";
import fulfillment from "./AddonPlacement.fulfillment";
import Checkbox from "../../../../../../components/Checkbox/Checkbox";
import TextField from "../../../../../../components/TextField/TextField";
import NumberField from "../../../../components/NumberField/NumberField";
import DefaultFields from "../../../../components/DefaultFields/DefaultFields";
import {localeNumber} from "../../../../../../helpers/utils";
import TextView from "../../../../../../components/TextView/TextView";
import StackLayout from "../../../../../../components/layouts/StackLayout/StackLayout";
import Select from "../../../../../../components/Select/Select";
import SimpleTextField from "../../../../components/SimpleTextField/SimpleTextField";
import BasicProductSelectionView from "../BasicProductSelection.jsx";

export default class AddonPlacement extends React.PureComponent {

	state = _getInitialState(this.props);
	fields = _getFields.call(this);

	isFulfilled(state) {
		return fulfillment.call(this, state, this.state)
	}

	setCustomerState(customerState, callbackFn)
	{
		if (customerState.radio_placement_spets)
		{
			customerState.radio_placement_guru = false;
			customerState.radio_placement_other = false;
		}
		else if (customerState.radio_placement_guru)
		{
			customerState.radio_placement_spets = false;
			customerState.radio_placement_other = false;
		}
		else if (customerState.radio_placement_other)
		{
			customerState.radio_placement_guru = false;
			customerState.radio_placement_spets = false;
		}

		customerState = {...this.state.customerState, ...customerState};
		updateInvestedProductFields(customerState);

		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			this.props.onChange(this.isFulfilled({customerState}));
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			navigationButtons: {
				sharing: {},
				back: {},
				next: {}
			},
		});

		this.props.onChange(this.isFulfilled(this.state.customerState));
	}

	render() {
		const {customer, highlightErrors, depositedAmount, depositedAmountFormatted} = this.props;
		const {customerState} = this.state;

		let totalAmountInvested = 0;
		totalAmountInvested += (customerState.radio_placement_spets && customerState.allocation_amount_spets !== "") ? parseInt(customerState.allocation_amount_spets.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += (customerState.radio_placement_guru && customerState.allocation_amount_guru !== "") ? parseInt(customerState.allocation_amount_guru.replace(" SEK", "").split(' ').join(''), 10) : 0;
		totalAmountInvested += (customerState.radio_placement_other && customerState.allocation_amount_other !== "") ? parseInt(customerState.allocation_amount_other.replace(" SEK", "").split(' ').join(''), 10) : 0;
		const totalAmountInvestedFormatted = localeNumber(totalAmountInvested, 0);

		let amountIsLessOrEqualToDeposited = true;
		if (totalAmountInvested > 0)
		{
			amountIsLessOrEqualToDeposited = totalAmountInvested <= depositedAmount;
		}

		let isIskTypeSelected =
			(customerState.radio_placement_guru && customerState.allocation_amount_guru.length > 0 && customerState.checkbox_accounttype_guru === "isk") ||
			(customerState.radio_placement_guru && customerState.allocation_amount_guru.length > 0 && customerState.checkbox_accounttype_guru === 1) ||
			(customerState.radio_placement_spets && customerState.allocation_amount_spets.length > 0 && customerState.checkbox_accounttype_spets === "isk") ||
			(customerState.radio_placement_spets && customerState.allocation_amount_spets.length > 0 && customerState.checkbox_accounttype_spets === 1);

		let accountTypeOptions = [
			{label: "Depå hos Fair", value: "naked"},
			{label: "ISK hos Fair", value: "isk"},
			{label: "Stiftelse", value: "trust"},
			{label: "P-försäkring", value: "pinsurance"},
			{label: "T-försäkring", value: "tinsurance"},
			{label: "K-försäkring", value: "kinsurance"}
		];

		let totalCostSpets = "";
		let totalCostGuru = "";

		if (customerState.radio_placement_spets)
		{
			if (customerState.allocation_fee_spets_rebate.length > 0 &&
				customerState.allocation_amount_spets.length > 0 &&
				customerState.allocation_fee_spets_revenueshare.length > 0)
			{
				const spetsAmount = parseInt(customerState.allocation_amount_spets.replace(" SEK", "").split(' ').join(''), 10)
				const spetsRebateInSekRaw = (customerState.allocation_fee_spets_rebate / 100) * spetsAmount;
				const spetsRebateInSek = localeNumber(spetsRebateInSekRaw, 0);
				const spetsMinus5 = localeNumber(((spetsAmount - spetsRebateInSekRaw) * 0.05), 0);
				const spetsPlus5Raw = (spetsAmount - spetsRebateInSekRaw) * 0.05;
				const spetsPlus5 = localeNumber(spetsPlus5Raw, 0);
				const spetsPlus15Raw = (spetsAmount - spetsRebateInSekRaw) * 0.15;
				const spetsPlus15 = localeNumber(spetsPlus15Raw, 0);

				const revSharePct = (customerState.allocation_fee_spets_revenueshare / 100);
				const revShare5 = localeNumber((spetsPlus5Raw * revSharePct), 2);
				const revShare15 = localeNumber((spetsPlus15Raw * revSharePct), 2);

				totalCostSpets = BasicProductSelectionView.feesTextTemplate;
				totalCostSpets = totalCostSpets.replaceAll("[FR]", spetsRebateInSek);
				totalCostSpets = totalCostSpets.replaceAll("[AMNT-5%]", spetsMinus5);
				totalCostSpets = totalCostSpets.replaceAll("[AMNT+5%]", spetsPlus5);
				totalCostSpets = totalCostSpets.replaceAll("[AMNT+15%]", spetsPlus15);
				totalCostSpets = totalCostSpets.replaceAll("[RS+5%]", revShare5);
				totalCostSpets = totalCostSpets.replaceAll("[RS+15%]", revShare15);
			}
			else
			{
				totalCostSpets = "-";
			}
		}
		else if (customerState.radio_placement_guru)
		{
			if (customerState.allocation_fee_guru_rebate.length > 0 &&
				customerState.allocation_amount_guru.length > 0 &&
				customerState.allocation_fee_guru_revenueshare.length > 0)
			{
				const guruAmount = parseInt(customerState.allocation_amount_guru.replace(" SEK", "").split(' ').join(''), 10)
				const guruRebateInSekRaw = (customerState.allocation_fee_guru_rebate / 100) * guruAmount;
				const guruRebateInSek = localeNumber(guruRebateInSekRaw, 0);
				const minus5 = localeNumber(((guruAmount - guruRebateInSekRaw) * 0.05), 0);
				const plus5Raw = (guruAmount - guruRebateInSekRaw) * 0.05;
				const plus5 = localeNumber(plus5Raw, 0);
				const plus15Raw = (guruAmount - guruRebateInSekRaw) * 0.15;
				const plus15 = localeNumber(plus15Raw, 0);

				const revSharePct = (customerState.allocation_fee_guru_revenueshare / 100);
				const revShare5 = localeNumber((plus5Raw * revSharePct), 2);
				const revShare15 = localeNumber((plus15Raw * revSharePct), 2);

				totalCostGuru = BasicProductSelectionView.feesTextTemplate;
				totalCostGuru = totalCostGuru.replaceAll("[FR]", guruRebateInSek);
				totalCostGuru = totalCostGuru.replaceAll("[AMNT-5%]", minus5);
				totalCostGuru = totalCostGuru.replaceAll("[AMNT+5%]", plus5);
				totalCostGuru = totalCostGuru.replaceAll("[AMNT+15%]", plus15);
				totalCostGuru = totalCostGuru.replaceAll("[RS+5%]", revShare5);
				totalCostGuru = totalCostGuru.replaceAll("[RS+15%]", revShare15);
			}
			else
			{
				totalCostGuru = "-";
			}
		}


		// ISK not available for companies
		if (customer.isCompany) {
			isIskTypeSelected = false;
			accountTypeOptions = [
				{label: "Depå hos Fair", value: "naked"},
				{label: "Stiftelse", value: "trust"},
				{label: "P-försäkring", value: "pinsurance"},
				{label: "T-försäkring", value: "tinsurance"},
				{label: "K-försäkring", value: "kinsurance"}
			];
		}

		const allocationSpets = customerState.allocation_amount_spets ? customerState.allocation_amount_spets.replace("SEK", "") : 0;
		const allocationGuru = customerState.allocation_amount_guru ? customerState.allocation_amount_guru.replace("SEK", "") : 0;
		const allocationOther = customerState.allocation_amount_other ? customerState.allocation_amount_other.replace("SEK", "").replace(" ", "") : 0;

		return (
			<div className="Cob-basic-product-selection-fields">
				<DefaultFields fields={this.fields}/>

				<h4>Tilläggsplacering</h4>

				<p style={{margin: "0 0 20px 0"}}>
					<strong>Investerat belopp: </strong> {depositedAmountFormatted}*
					<em style={{fontSize: "11px", marginTop: "3px", display: "block"}}>
						*Beloppet anges på sid 3 i avsnittet "Frågor enligt penningtvättslagen".
					</em>
				</p>

				<div className="fee-model-change">
					<Checkbox
						style={{marginTop: "20px", maxWidth: "none"}}
						type="radio"
						name="radio_allocation_fee_spets"
						label="Tilläggsplacering i Fair Globalförvaltning"
						checked={customerState.radio_placement_spets}
						onChange={checked => this.setCustomerState({radio_placement_spets: checked})}
            			highlightErrors={highlightErrors}
						groupValid={
							customerState.radio_placement_spets ||
							customerState.radio_placement_guru ||
							customerState.radio_placement_other ||
							customerState.radio_allocation_fee_spets ||
							customerState.radio_allocation_fee_guru ||
							customerState.radio_allocation_fee_other
						}
					/>

					{customerState.radio_placement_spets && (
						<React.Fragment>
							<p style={{marginTop: "20px"}}>
								Kunden förbinder sig härmed att enligt tidigare avtalade villkor investera ytterligare
								<span className={(allocationSpets > 0) ? "" : "empty"}>
									{allocationSpets > 0 && (
										localeNumber(allocationSpets, 0)
									)}
								</span>
								kronor enligt Fair Globalförvaltning samt betala in investeringsbeloppet i sin helhet till
								Bolaget enligt ovan till depå
								<span className={customerState.customer_spets_depa.length === 0 ? "empty" : ""}>
									{customerState.customer_spets_depa}
								</span>
								där Bolaget kommer förvalta Kundens tillgångar enligt Fair Globalförvaltning.
							</p>

							<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
								<div style={{alignSelf: "flex-start"}}>
									<p className="fieldLabel">Depå</p>
									<TextField
										name="customer_spets_depa"
										label="Ange depå"
										autocomplete="nope"
										value={customerState.customer_spets_depa}
										validation={value => value.length > 0 && value.length <= 9}
										onChange={({target}) => this.setCustomerState({
											customer_spets_depa: target.value,
											assets_spets_depa: target.value
										})}
                    					highlightErrors={highlightErrors}
									/>
									{customerState.customer_spets_depa.length > 9 && (
										<p style={{background: "#ffecec", marginTop: "10px", width: "100%"}}>
											Var god ange enbart depånummer.
										</p>
									)}
								</div>
								<div style={{alignSelf: "flex-start"}}>
									<p className="fieldLabel">Belopp att investera</p>
									<NumberField
										name="allocation_amount_spets"
										label="Ange i SEK"
										unit="SEK"
										value={customerState.allocation_amount_spets}
										validation={value => amountIsLessOrEqualToDeposited && value.length > 0 && value.split(' ').join('') > 0}
										showSeparators={true}
										onChange={({target}) => {
											this.setCustomerState({allocation_amount_spets: target.value.split(' ').join('')});
										}}
                    					highlightErrors={highlightErrors}
									/>

									{!amountIsLessOrEqualToDeposited && customerState.allocation_amount_spets.length > 0 && (
										<p style={{fontSize: "12px", lineHeight: "14px", margin: "0", color: "red", background: "none"}}>
											Beloppet överskrider av kunden investerad summa ({depositedAmountFormatted}).
										</p>
									)}

									<input type="hidden" name="allocation_amount_spets"
										   value={localeNumber(customerState.allocation_amount_spets.replace("SEK", "").replace("kr", ""), 0)}/>
								</div>
							</StackLayout>
						</React.Fragment>
					)}
				</div>

				<div className="fee-model-change">
					<Checkbox
						style={{marginTop: "20px", maxWidth: "none"}}
						type="radio"
						name="radio_placement_guru"
						label="Tilläggsplacering i Fair Flexibel"
						checked={customerState.radio_placement_guru}
						disabled={customerState.guru_disabled}
						onChange={checked => this.setCustomerState({radio_placement_guru: checked})}
            			highlightErrors={highlightErrors}
						groupValid={
							customerState.radio_placement_spets ||
							customerState.radio_placement_guru ||
							customerState.radio_placement_other ||
							customerState.radio_allocation_fee_spets ||
							customerState.radio_allocation_fee_guru ||
							customerState.radio_allocation_fee_other
						}
					/>

					{customerState.radio_placement_guru && (
						<React.Fragment>
							<p style={{marginTop: "20px"}}>
								Kunden förbinder sig härmed att enligt tidigare avtalade villkor investera ytterligare
								<span
									className={(allocationGuru > 0) ? "" : "empty"}>
									{allocationGuru > 0 && (
										localeNumber(allocationGuru, 0)
									)}
								</span>
								kronor enligt Fair Flexibel samt betala in investeringsbeloppet i sin helhet till
								Bolaget enligt ovan till depå
								<span className={customerState.customer_guru_depa.length === 0 ? "empty" : ""}>
									{customerState.customer_guru_depa}
								</span>
								där Bolaget kommer förvalta Kundens tillgångar enligt Fair Flexibel.
							</p>

							<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
								<div style={{alignSelf: "flex-start"}}>
									<p className="fieldLabel">Depå</p>
									<TextField
										name="customer_guru_depa"
										label="Ange depå"
										autocomplete="nope"
										value={customerState.customer_guru_depa}
										validation={value => value.length > 0 && value.length <= 9}
										onChange={({target}) => this.setCustomerState({
											customer_guru_depa: target.value,
											assets_guru_depa: target.value
										})}
                    					highlightErrors={highlightErrors}
									/>
									{customerState.customer_guru_depa.length > 9 && (
										<p style={{background: "#ffecec", marginTop: "10px", width: "100%"}}>
											Var god ange enbart depånummer.
										</p>
									)}
								</div>
								<div style={{alignSelf: "flex-start"}}>
									<p className="fieldLabel">Belopp att investera</p>
									<NumberField
										name="allocation_amount_guru"
										label="Ange i SEK"
										unit="SEK"
										value={customerState.allocation_amount_guru}
										validation={value => amountIsLessOrEqualToDeposited && value.length > 0 && value.split(' ').join('') > 0}
										showSeparators={true}
										onChange={({target}) => {
											this.setCustomerState({allocation_amount_guru: target.value.split(' ').join('')});
										}}
                    					highlightErrors={highlightErrors}
									/>

									{!amountIsLessOrEqualToDeposited && customerState.allocation_amount_guru.length > 0 && (
										<p style={{fontSize: "12px", lineHeight: "14px", margin: "0", color: "red", background: "none"}}>
											Beloppet överskrider av kunden investerad summa ({depositedAmountFormatted}).
										</p>
									)}

									<input type="hidden" name="allocation_amount_guru"
										   value={localeNumber(customerState.allocation_amount_guru.replace("SEK", "").replace("kr", ""), 0)}/>
								</div>
							</StackLayout>
						</React.Fragment>
					)}
				</div>

				<div className="fee-model-change">
					<Checkbox
						style={{marginTop: "20px", maxWidth: "none"}}
						type="radio"
						name="radio_placement_other"
						label="Tilläggsplacering i Fair Ädelmetaller"
						checked={customerState.radio_placement_other}
						onChange={checked => this.setCustomerState({radio_placement_other: checked})}
            			highlightErrors={highlightErrors}
						groupValid={
							customerState.radio_placement_spets ||
							customerState.radio_placement_guru ||
							customerState.radio_placement_other ||
							customerState.radio_allocation_fee_spets ||
							customerState.radio_allocation_fee_guru ||
							customerState.radio_allocation_fee_other
						}
					/>

					{customerState.radio_placement_other && (
						<React.Fragment>
							<p style={{marginTop: "20px"}}>
								Kunden förbinder sig härmed att enligt tidigare avtalade villkor investera ytterligare
								<span className={(allocationOther > 0) ? "" : "empty"}>
									{allocationOther > 0 && (
										localeNumber(allocationOther, 0)
									)}
								</span>
								kronor enligt Fair Ädelmetaller samt betala in investeringsbeloppet i sin helhet till
								Bolaget enligt ovan till depå
								<span className={customerState.customer_other_depa.length === 0 ? "empty" : ""}>
									{customerState.customer_other_depa}
								</span>
								där Bolaget kommer förvalta Kundens tillgångar enligt Fair Ädelmetaller.
							</p>

							<input type={"hidden"} name={"allocation_name_other"} value={"Fair Ädelmetaller"} />

							<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
								<div style={{alignSelf: "flex-start"}}>
									<p className="fieldLabel">Depå</p>
									<TextField
										name="customer_other_depa"
										label="Ange depå"
										autocomplete="nope"
										value={customerState.customer_other_depa}
										validation={value => value.length > 0 && value.length <= 9}
										onChange={({target}) => this.setCustomerState({customer_other_depa: target.value})}
                    					highlightErrors={highlightErrors}
									/>
									{customerState.customer_other_depa.length > 9 && (
										<p style={{background: "#ffecec", marginTop: "10px", width: "100%"}}>
											Var god ange enbart depånummer.
										</p>
									)}
								</div>
								<div style={{alignSelf: "flex-start"}}>
									<p className="fieldLabel">Belopp att investera</p>
									<NumberField
										name="allocation_amount_other"
										label="Ange i SEK"
										unit="SEK"
										value={customerState.allocation_amount_other}
										validation={value => amountIsLessOrEqualToDeposited && value.length > 0 && value.split(' ').join('') > 0}
										showSeparators={true}
										onChange={({target}) => this.setCustomerState({allocation_amount_other: target.value})}
                    					highlightErrors={highlightErrors}
									/>

									{!amountIsLessOrEqualToDeposited && customerState.allocation_amount_other.length > 0 && (
										<p style={{fontSize: "12px", lineHeight: "14px", margin: "0", color: "red", background: "none"}}>
											Beloppet överskrider av kunden investerad summa ({depositedAmountFormatted}).
										</p>
									)}

									<input type="hidden" name="allocation_amount_other"
										   value={localeNumber(customerState.allocation_amount_other.replace("SEK", "").replace("kr", ""), 0)}/>
								</div>
							</StackLayout>
						</React.Fragment>
					)}
				</div>

				<div className="h-line spacing"/>
				<div className="Cob-basic-product-selection-fields">

					<h4>Erbjudande om reducerat arvode</h4>

					<div className="fee-model-change">
						<Checkbox
							style={{marginTop: "20px", maxWidth: "none"}}
							type="checkbox"
							name="radio_allocation_fee_spets"
							label="Kunden har valt att ta del av erbjudande om reducerat förvaltningsarvode i Fair Globalförvaltning"
							value={customerState.radio_allocation_fee_spets ? "reduced" : ""}
							checked={customerState.radio_allocation_fee_spets}
							onChange={checked => this.setCustomerState({radio_allocation_fee_spets: checked})}
							highlightErrors={highlightErrors}
							groupValid={
								customerState.radio_placement_spets ||
								customerState.radio_placement_guru ||
								customerState.radio_placement_other ||
								customerState.radio_allocation_fee_spets ||
								customerState.radio_allocation_fee_guru ||
								customerState.radio_allocation_fee_other
							}
						/>

						{customerState.radio_allocation_fee_spets && (
							<React.Fragment>
								<p style={{marginTop: "20px"}}>
									Kunden har valt att uppdatera villkoren i det tidigare avtalet om diskretionär
									förvaltning i stycket ”Avgifter och kostnader” i Bilaga “Fair Globalförvaltning” för
									tillgångarna som förvaltas på depå
									<span className={customerState.assets_spets_depa.length === 0 ? "empty" : ""}>
										{customerState.assets_spets_depa}
									</span>.
									<br/><br/>
									Den tidigare prestationsbaserade avgiften om
									<span className={customerState.previous_fee_spets.length === 0 ? "empty" : ""}>
										{customerState.previous_fee_spets.length === 0 ? "" : customerState.previous_fee_spets + "%"}
									</span>
									ersätts med
									<span
										className={customerState.allocation_fee_spets_revenueshare.length === 0 ? "empty" : ""}>
										{customerState.allocation_fee_spets_revenueshare.length === 0 ? "" : customerState.allocation_fee_spets_revenueshare + "%"}
									</span>*
									mot en förvaltningsrabatt om totalt
									<span className={customerState.onetime_fee_spets.length === 0 ? "empty" : ""}>
										{customerState.onetime_fee_spets.length === 0 ? "" : customerState.onetime_fee_spets + "%"}
									</span>*.
									<br/>
									Avgiften kommer dras från angiven depå och beräknas som en andel av depåvärdet vid
									tilläggsavtalets tecknande.
									<br/><br/>
									På framtida nyinsättningar till Depån kommer en förvaltningsrabatt om
									<span
										className={customerState.future_onetime_fee_spets.length === 0 ? "empty" : ""}>
										{customerState.future_onetime_fee_spets.length === 0 ? "" : customerState.future_onetime_fee_spets + "%"}
									</span>
									debiteras för att bibehålla den angivna vinstdelningsnivån. Undantaget är interna
									överföringar från andra portföljförvaltningsstrategier hos Bolaget ifall Kunden
									tagit del av erbjudandet om reducerat arvode även där.
									<br/><br/>
									Den rabatterade prestationsbaserade avgiften börjar gälla vid nästkommande kvartalsskifte.

									<br/><br/>
									<span className="small">* Där Bolagets standarderbjudande är 0,5% i kostnad för att sänka vinstdelningen med 1%-enhet från nivån 33%</span>
								</p>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Depå</p>
										<TextField
											name="assets_spets_depa"
											label="Ange depå"
											autocomplete="nope"
											value={customerState.assets_spets_depa}
											validation={value => value.length > 0 && value.length <= 9}
											onChange={({target}) => this.setCustomerState({
												assets_spets_depa: target.value,
												customer_spets_depa: target.value
											})}
                      						highlightErrors={highlightErrors}
										/>
										{customerState.assets_spets_depa.length > 9 && (
											<p style={{background: "#ffecec", marginTop: "10px", width: "100%"}}>
												Var god ange enbart depånummer.
											</p>
										)}
									</div>
								</StackLayout>

								<h3>Avgifter</h3>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Tidigare avgift</p>
										<NumberField
											name="previous_fee_spets"
											unit="%"
											value={customerState.previous_fee_spets}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({previous_fee_spets: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="previous_fee_spets"
											   value={customerState.previous_fee_spets.replace("%", "")}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Ny avgift</p>
										<NumberField
											name="allocation_fee_spets_revenueshare_new"
											unit="%"
											value={customerState.allocation_fee_spets_revenueshare_new}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({allocation_fee_spets_revenueshare_new: target.value})}
                      						highlightErrors={highlightErrors}
										/>

										<input type="hidden" name="allocation_fee_spets_revenueshare_new"
											   value={customerState.allocation_fee_spets_revenueshare_new.replace("%", "")}/>

										<input type="hidden" name="allocation_fee_spets"
											   value={customerState.allocation_fee_spets_revenueshare
												   ? customerState.allocation_fee_spets_revenueshare
												   : ""}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Förvaltningsrabatt</p>
										<NumberField
											name="onetime_fee_spets"
											unit="%"
											value={customerState.onetime_fee_spets}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({onetime_fee_spets: target.value})}
											highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="onetime_fee_spets"
											   value={customerState.onetime_fee_spets.replace("%", "")}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Framtida insättningar</p>
										<NumberField
											name="future_onetime_fee_spets"
											unit="%"
											value={customerState.future_onetime_fee_spets}
											label="Ange förvaltningsrabatt"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({future_onetime_fee_spets: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="future_onetime_fee_spets"
											   value={customerState.future_onetime_fee_spets.replace("%", "")}/>
									</div>
								</StackLayout>
								<div className="h-line spacing"/>
							</React.Fragment>
						)}
					</div>

					<div className="fee-model-change">
						<Checkbox
							style={{marginTop: "20px", maxWidth: "none"}}
							type="checkbox"
							name="radio_allocation_fee_guru"
							label="Kunden har valt att ta del av erbjudande om reducerat förvaltningsarvode i Fair Flexibel"
							value={customerState.radio_allocation_fee_guru ? "reduced" : ""}
							checked={customerState.radio_allocation_fee_guru}
							disabled={customerState.guru_disabled}
							onChange={checked => this.setCustomerState({radio_allocation_fee_guru: checked})}
							highlightErrors={highlightErrors}
							groupValid={
								customerState.radio_placement_spets ||
								customerState.radio_placement_guru ||
								customerState.radio_placement_other ||
								customerState.radio_allocation_fee_spets ||
								customerState.radio_allocation_fee_guru ||
								customerState.radio_allocation_fee_other
							}
						/>

						{customerState.radio_allocation_fee_guru && (
							<React.Fragment>

								<input type={"hidden"} name={"radio_allocation_fee_extra"} value={"reduced"} />
								<input type={"hidden"} name={"customer_extra_name"} value={"Fair Flexibel"} />
								<input type={"hidden"} name={"assets_extra_depa"} value={customerState.assets_guru_depa} />
								<input type={"hidden"} name={"previous_fee_extra"} value={customerState.previous_fee_guru.length === 0 ? "" : customerState.previous_fee_guru + "%"} />
								<input type={"hidden"} name={"allocation_fee_extra_revenueshare"} value={customerState.allocation_fee_guru_revenueshare.length === 0 ? "" : customerState.allocation_fee_guru_revenueshare + "%"} />
								<input type={"hidden"} name={"onetime_fee_extra"} value={customerState.onetime_fee_guru.length === 0 ? "" : customerState.onetime_fee_guru + "%"} />
								<input type={"hidden"} name={"future_onetime_fee_extra"} value={customerState.future_onetime_fee_guru.length === 0 ? "" : customerState.future_onetime_fee_guru + "%"} />

								<p style={{marginTop: "20px"}}>
									Kunden har valt att uppdatera villkoren i det tidigare avtalet om diskretionär
									förvaltning i stycket ”Avgifter och kostnader” i Bilaga “Fair Flexibel” för
									tillgångarna som förvaltas på depå
									<span className={customerState.assets_guru_depa.length === 0 ? "empty" : ""}>
										{customerState.assets_guru_depa}
									</span>.
									<br/><br/>
									Den tidigare prestationsbaserade avgiften om
									<span className={customerState.previous_fee_guru.length === 0 ? "empty" : ""}>
										{customerState.previous_fee_guru.length === 0 ? "" : customerState.previous_fee_guru + "%"}
									</span>
									ersätts med
									<span
										className={customerState.allocation_fee_guru_revenueshare.length === 0 ? "empty" : ""}>
										{customerState.allocation_fee_guru_revenueshare.length === 0 ? "" : customerState.allocation_fee_guru_revenueshare + "%"}
									</span>*
									mot en förvaltningsrabatt om totalt
									<span className={customerState.onetime_fee_guru.length === 0 ? "empty" : ""}>
										{customerState.onetime_fee_guru.length === 0 ? "" : customerState.onetime_fee_guru + "%"}
									</span>*.
									<br/>
									Avgiften kommer dras från angiven depå och beräknas som en andel av depåvärdet vid
									tilläggsavtalets tecknande.
									<br/><br/>
									På framtida nyinsättningar till Depån kommer en förvaltningsrabatt om
									<span className={customerState.future_onetime_fee_guru.length === 0 ? "empty" : ""}>
										{customerState.future_onetime_fee_guru.length === 0 ? "" : customerState.future_onetime_fee_guru + "%"}
									</span>
									debiteras för att bibehålla den angivna vinstdelningsnivån. Undantaget är interna
									överföringar från andra portföljförvaltningsstrategier hos Bolaget ifall Kunden
									tagit
									del av erbjudandet om reducerat arvode även där.
									<br/><br/>
									Den rabatterade prestationsbaserade avgiften börjar gälla vid nästkommande kvartalsskifte.

									<br/><br/>
									<span className="small">* Där Bolagets standarderbjudande är 0,5% i kostnad för att sänka vinstdelningen med 1%-enhet från nivån 33%</span>
								</p>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Depå</p>
										<TextField
											name="assets_guru_depa"
											label="Ange depå"
											autocomplete="nope"
											value={customerState.assets_guru_depa}
											validation={value => value.length > 0 && value.length <= 9}
											onChange={({target}) => this.setCustomerState({
												assets_guru_depa: target.value,
												customer_guru_depa: target.value
											})}
                      						highlightErrors={highlightErrors}
										/>
										{customerState.assets_guru_depa.length > 9 && (
											<p style={{background: "#ffecec", marginTop: "10px", width: "100%"}}>
												Var god ange enbart depånummer.
											</p>
										)}
									</div>
								</StackLayout>

								<h3>Avgifter</h3>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Tidigare avgift</p>
										<NumberField
											name="previous_fee_guru"
											unit="%"
											value={customerState.previous_fee_guru}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({previous_fee_guru: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="previous_fee_guru"
											   value={customerState.previous_fee_guru.replace("%", "")}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Ny avgift</p>
										<NumberField
											name="allocation_fee_guru_revenueshare_new"
											unit="%"
											value={customerState.allocation_fee_guru_revenueshare_new}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({allocation_fee_guru_revenueshare_new: target.value})}
                      						highlightErrors={highlightErrors}
										/>

										<input type="hidden"
											   name="allocation_fee_guru_revenueshare_new"
											   value={customerState.allocation_fee_guru_revenueshare_new.replace("%", "")}/>

										<input type="hidden" name="allocation_fee_guru"
											   value={customerState.allocation_fee_guru_revenueshare
												   ? customerState.allocation_fee_guru_revenueshare
												   : ""}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Förvaltningsrabatt</p>
										<NumberField
											name="onetime_fee_guru"
											unit="%"
											value={customerState.onetime_fee_guru}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({onetime_fee_guru: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="onetime_fee_guru"
											   value={customerState.onetime_fee_guru.replace("%", "")}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Framtida insättningar</p>
										<NumberField
											name="future_onetime_fee_guru"
											unit="%"
											value={customerState.future_onetime_fee_guru}
											label="Ange förvaltningsrabatt"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({future_onetime_fee_guru: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="future_onetime_fee_guru"
											   value={customerState.future_onetime_fee_guru.replace("%", "")}/>
									</div>
								</StackLayout>

								<div className="h-line spacing"/>
							</React.Fragment>
						)}
					</div>

					<div className="fee-model-change">
						<Checkbox
							style={{marginTop: "20px", maxWidth: "none"}}
							type="checkbox"
							name="radio_allocation_fee_other"
							label="Kunden har valt att ta del av erbjudande om reducerat förvaltningsarvode i Fair Ädelmetaller"
							value={customerState.radio_allocation_fee_other ? "reduced" : ""}
							checked={customerState.radio_allocation_fee_other}
							onChange={checked => this.setCustomerState({radio_allocation_fee_other: checked})}
							highlightErrors={highlightErrors}
							groupValid={
								customerState.radio_placement_spets ||
								customerState.radio_placement_guru ||
								customerState.radio_placement_other ||
								customerState.radio_allocation_fee_spets ||
								customerState.radio_allocation_fee_guru ||
								customerState.radio_allocation_fee_other
							}
						/>

						{customerState.radio_allocation_fee_other && (
							<React.Fragment>

								<input type={"hidden"} name={"radio_allocation_fee_extra"} value={"reduced"} />
								<input type={"hidden"} name={"customer_extra_name"} value={"Fair Ädelmetaller"} />
								<input type={"hidden"} name={"assets_extra_depa"} value={customerState.assets_other_depa} />
								<input type={"hidden"} name={"previous_fee_extra"} value={customerState.previous_fee_other.length === 0 ? "" : customerState.previous_fee_other + "%"} />
								<input type={"hidden"} name={"allocation_fee_extra_revenueshare"} value={customerState.allocation_fee_other_revenueshare.length === 0 ? "" : customerState.allocation_fee_other_revenueshare + "%"} />
								<input type={"hidden"} name={"onetime_fee_extra"} value={customerState.onetime_fee_other.length === 0 ? "" : customerState.onetime_fee_other + "%"} />
								<input type={"hidden"} name={"future_onetime_fee_extra"} value={customerState.future_onetime_fee_other.length === 0 ? "" : customerState.future_onetime_fee_other + "%"} />

								<p style={{marginTop: "20px"}}>
									Kunden har valt att uppdatera villkoren i det tidigare avtalet om diskretionär
									förvaltning i stycket ”Avgifter och kostnader” i
									Bilaga “Fair Ädelmetaller” för tillgångarna som förvaltas på depå
									<span className={customerState.assets_other_depa.length === 0 ? "empty" : ""}>
										{customerState.assets_other_depa}
									</span>.
									<br/><br/>
									Den tidigare prestationsbaserade avgiften om
									<span className={customerState.previous_fee_other.length === 0 ? "empty" : ""}>
										{customerState.previous_fee_other.length === 0 ? "" : customerState.previous_fee_other + "%"}
									</span>
									ersätts med
									<span
										className={customerState.allocation_fee_other_revenueshare.length === 0 ? "empty" : ""}>
										{customerState.allocation_fee_other_revenueshare.length === 0 ? "" : customerState.allocation_fee_other_revenueshare + "%"}
									</span>*
									mot en förvaltningsrabatt om totalt
									<span className={customerState.onetime_fee_other.length === 0 ? "empty" : ""}>
										{customerState.onetime_fee_other.length === 0 ? "" : customerState.onetime_fee_other + "%"}
									</span>*.
									<br/>
									Avgiften kommer dras från angiven depå och beräknas som en andel av depåvärdet vid
									tilläggsavtalets tecknande.
									<br/><br/>
									På framtida nyinsättningar till Depån kommer en förvaltningsrabatt om
									<span
										className={customerState.future_onetime_fee_other.length === 0 ? "empty" : ""}>
										{customerState.future_onetime_fee_other.length === 0 ? "" : customerState.future_onetime_fee_other + "%"}
									</span>
									debiteras för att bibehålla den angivna vinstdelningsnivån. Undantaget är interna
									överföringar från andra portföljförvaltningsstrategier hos Bolaget ifall Kunden
									tagit
									del av erbjudandet om reducerat arvode även där.
									<br/><br/>
									Den rabatterade prestationsbaserade avgiften börjar gälla vid nästkommande kvartalsskifte.

									<br/><br/>
									<span className="small">* Där Bolagets standarderbjudande är 0,5% i kostnad för att sänka vinstdelningen med 1%-enhet från nivån 33%</span>
								</p>

								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Depå</p>
										<TextField
											name="assets_other_depa"
											label="Ange depå"
											autocomplete="nope"
											value={customerState.assets_other_depa}
											validation={value => value.length > 0 && value.length <= 9}
											onChange={({target}) => this.setCustomerState({assets_other_depa: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										{customerState.assets_other_depa.length > 9 && (
											<p style={{background: "#ffecec", marginTop: "10px", width: "100%"}}>
												Var god ange enbart depånummer.
											</p>
										)}
									</div>
								</StackLayout>

								<h3>Avgifter</h3>
								<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Tidigare avgift</p>
										<NumberField
											name="previous_fee_other"
											unit="%"
											value={customerState.previous_fee_other}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({previous_fee_other: target.value})}
                     						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="previous_fee_other"
											   value={customerState.previous_fee_other.replace("%", "")}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Ny avgift</p>
										<NumberField
											name="allocation_fee_other_revenueshare_new"
											unit="%"
											value={customerState.allocation_fee_other_revenueshare_new}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({allocation_fee_other_revenueshare_new: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="allocation_fee_other_revenueshare_new"
											   value={customerState.allocation_fee_other_revenueshare_new.replace("%", "")}/>

										<input type="hidden" name="allocation_fee_other"
											   value={customerState.allocation_fee_other_revenueshare
												   ? customerState.allocation_fee_other_revenueshare
												   : ""}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Förvaltningsrabatt</p>
										<NumberField
											name="onetime_fee_other"
											unit="%"
											value={customerState.onetime_fee_other}
											label="Ange"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({onetime_fee_other: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="onetime_fee_other"
											   value={customerState.onetime_fee_other.replace("%", "")}/>
									</div>
									<div style={{alignSelf: "flex-start"}}>
										<p className="fieldLabel">Framtida insättningar</p>
										<NumberField
											name="future_onetime_fee_other"
											unit="%"
											value={customerState.future_onetime_fee_other}
											label="Ange förvaltningsrabatt"
											validation={value => value.length > 0 && value >= 0 && value <= 100}
											onChange={({target}) => this.setCustomerState({future_onetime_fee_other: target.value})}
                      						highlightErrors={highlightErrors}
										/>
										<input type="hidden" name="future_onetime_fee_other"
											   value={customerState.future_onetime_fee_other.replace("%", "")}/>
									</div>
								</StackLayout>

								<div className="h-line spacing"/>
							</React.Fragment>
						)}
					</div>
				</div>

				<div className="h-line spacing"/>
				<div className="Cob-basic-product-selection-fields">

					<h4>Avtal för Tilläggsplacering Erbjudande Q4 2022</h4>

					<Checkbox
						style={{marginTop: "20px", maxWidth: "none"}}
						type="checkbox"
						name="checkbox_addon_placement_alternative"
						label="Använd avtalet för tilläggsplacering med erbjudande för Q4 2022"
						value={"yes"}
						checked={customerState.checkbox_addon_placement_alternative === "yes"}
						onChange={checked => this.setCustomerState({checkbox_addon_placement_alternative: checked ? "yes" : "no"})}
					/>
				</div>

				{(customerState.radio_placement_spets || customerState.radio_allocation_fee_spets) && (
					<React.Fragment>
						<div className="h-line spacing"/>

						<h3>Mer information om placering i Fair Globalförvaltning</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
								<Select
									name="radio_allocation_perc_spets"
									label="Ange i procent"
									selectedIndex={customerState.radio_allocation_perc_spetsIndex}
									options={[
										{label: "0-5%", value: "0-5"},
										{label: "6-10%", value: "6-10"},
										{label: "11-20%", value: "11-20"},
										{label: ">20%", value: ">20"}
									]}
									onChange={(option) => this.setCustomerState({radio_allocation_perc_spets: option.value})}
                  					highlightErrors={highlightErrors}
								/>
							</div>

							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
								<Select
									name="checkbox_accounttype_spets"
									label="Ange"
									selectedIndex={customerState.checkbox_accounttype_spetsIndex}
									options={accountTypeOptions}
									onChange={(option) => this.setCustomerState({checkbox_accounttype_spets: option.value})}
                  					highlightErrors={highlightErrors}
								/>
							</div>

							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Risk enligt &quot;Information om riskklasser&quot;</p>
								<strong style={{fontSize: "16px"}}>4</strong>
								<input type="hidden" name="radio_allocation_risk_spets" value="4"/>
							</div>
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">
									Vinstdelning
								</p>
								<div style={{flexDirection: "row"}}>
									<SimpleTextField
										type="number"
										placeholder="Ange i %"
										value={customerState.allocation_fee_spets_revenueshare || ""}
										textAlign="left"
										validation={value => value.length > 0}
										style={{width: "100px", marginRight: "5px"}}
										onChange={({target}) => this.setCustomerState({allocation_fee_spets_revenueshare: target.value})}
                    					highlightErrors={highlightErrors}
									/>
									%
								</div>

								<p className="fieldLabel" style={{marginTop: "10px"}}>
									Förvaltningsrabatt
								</p>

								<div style={{flexDirection: "row", marginBottom: "20px"}}>
									<SimpleTextField
										type="number"
										placeholder="Ange i %"
										value={customerState.allocation_fee_spets_rebate || ""}
										textAlign="left"
										validation={value => value.length > 0}
										style={{width: "100px", marginRight: "5px"}}
										onChange={({target}) => this.setCustomerState({allocation_fee_spets_rebate: target.value})}
                    					highlightErrors={highlightErrors}
									/>
									%
								</div>

								<input type="hidden" name="allocation_fee_spets"
									   value={customerState.allocation_fee_spets_revenueshare
										   ? customerState.allocation_fee_spets_revenueshare
										   : ""}/>

								<input type="hidden" name="allocation_fee_spets_revenueshare"
									   value={customerState.allocation_fee_spets_revenueshare
										   ? customerState.allocation_fee_spets_revenueshare
										   : ""}/>

								<input type="hidden" name="allocation_fee_spets_rebate"
									   value={customerState.allocation_fee_spets_rebate
										   ? customerState.allocation_fee_spets_rebate
										   : ""}/>
							</div>

							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Placeringshorisont</p>
								<strong>Minst 5 år</strong>
							</div>
						</StackLayout>
					</React.Fragment>
				)}

				{(customerState.radio_placement_guru || customerState.radio_allocation_fee_guru) && (
					<React.Fragment>
						<div className="h-line spacing"/>

						<h3>Mer information om placering i Fair Flexibel</h3>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Andel av placeringsbara tillgångar</p>
								<Select
									name="radio_allocation_perc_guru"
									label="Ange i procent"
									selectedIndex={customerState.radio_allocation_perc_guruIndex}
									options={[
										{label: "0-5%", value: "0-5"},
										{label: "6-10%", value: "6-10"},
										{label: "11-20%", value: "11-20"},
										{label: ">20%", value: ">20"}
									]}
									onChange={(option) => this.setCustomerState({radio_allocation_perc_guru: option.value})}
                  					highlightErrors={highlightErrors}
								/>
							</div>

							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Vilken skattemiljö gäller för denna placering?</p>
								<Select
									name="checkbox_accounttype_guru"
									label="Ange"
									selectedIndex={customerState.checkbox_accounttype_guruIndex}
									options={accountTypeOptions}
									onChange={(option) => this.setCustomerState({checkbox_accounttype_guru: option.value})}
                  					highlightErrors={highlightErrors}
								/>
							</div>

							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Risk enligt &quot;Information om riskklasser&quot;</p>
								<strong style={{fontSize: "16px"}}>3</strong>
								<input type="hidden" name="radio_allocation_risk_guru" value="3"/>
							</div>
						</StackLayout>

						<StackLayout className="Cob-fields-collection per-row-3" rowWrap fullWidth>
							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">
									Vinstdelning
								</p>
								<div style={{flexDirection: "row"}}>
									<SimpleTextField
										type="number"
										placeholder="Ange i %"
										value={customerState.allocation_fee_guru_revenueshare || ""}
										textAlign="left"
										validation={value => value.length > 0}
										style={{width: "100px", marginRight: "5px"}}
										onChange={({target}) => this.setCustomerState({allocation_fee_guru_revenueshare: target.value})}
                    					highlightErrors={highlightErrors}
									/>
									%
								</div>

								<p className="fieldLabel" style={{marginTop: "10px"}}>
									Förvaltningsrabatt
								</p>

								<div style={{flexDirection: "row", marginBottom: "20px"}}>
									<SimpleTextField
										type="number"
										placeholder="Ange i %"
										value={customerState.allocation_fee_guru_rebate || ""}
										textAlign="left"
										validation={value => value.length > 0}
										style={{width: "100px", marginRight: "5px"}}
										onChange={({target}) => this.setCustomerState({allocation_fee_guru_rebate: target.value})}
                    					highlightErrors={highlightErrors}
									/>
									%
								</div>

								<input type="hidden" name="allocation_fee_guru"
									   value={customerState.allocation_fee_guru_revenueshare
										   ? customerState.allocation_fee_guru_revenueshare
										   : ""}/>

								<input type="hidden" name="allocation_fee_guru_revenueshare"
									   value={customerState.allocation_fee_guru_revenueshare
										   ? customerState.allocation_fee_guru_revenueshare
										   : ""}/>

								<input type="hidden" name="allocation_fee_guru_rebate"
									   value={customerState.allocation_fee_guru_rebate
										   ? customerState.allocation_fee_guru_rebate
										   : ""}/>
							</div>

							<div style={{alignSelf: "flex-start"}}>
								<p className="fieldLabel">Placeringshorisont</p>
								<strong>Minst 3 år</strong>
							</div>
						</StackLayout>
					</React.Fragment>
				)}

				<div className="h-line spacing"/>

				<StackLayout className="Cob-fields-collection" alignItems="start" fullWidth>
					<div className="yes-no">
						<Checkbox
							style={{
								padding: "15px",
								background: "#FFF6F6",
								borderRadius: "6px"
							}}
							className="no"
							type="radio"
							name="checkbox_advice_customer"
							label="Kunden har tagit del av det diskretionära avtalet/avtalen men förstår inte hur förvaltningen/förvaltningarna fungerar eller vilka risker som den/de är förknippade med. Placeringen avråds av rådgivaren. "
							value="dontunderstand"
							checked={customerState.checkbox_advice_customer === "dontunderstand"}
							onChange={checked => this.setCustomerState({checkbox_advice_customer: checked ? "dontunderstand" : "understand"})}
              				highlightErrors={highlightErrors && customerState.checkbox_advice_customer.length < 1}
						/>
					</div>

					<div className="yes-no">
						<Checkbox
							style={{
								padding: "15px",
								background: "#F3FDF5",
								borderRadius: "6px"
							}}
							className="yes"
							type="radio"
							name="checkbox_advice_customer"
							label="Kunden har tagit del av det diskretionära avtalet/avtalen och intygar att den förstår hur förvaltningen/förvaltningarna fungerar och vilka risker som den/de är förknippade med."
							value="understand"
							checked={customerState.checkbox_advice_customer === "understand"}
							onChange={checked => this.setCustomerState({checkbox_advice_customer: checked ? "understand" : "dontunderstand"})}
              				highlightErrors={highlightErrors && customerState.checkbox_advice_customer.length < 1}
						/>
					</div>

					<input type="hidden" name="checkbox_advice_recommendation2" value={customerState.checkbox_advice_customer}/>
				</StackLayout>

				<h4>Övrigt</h4>

				<TextView
					name="notes_tillagg_other"
					label={`${550 - customerState.notes_tillagg_other.length} tecken kvar`}
					value={customerState.notes_tillagg_other}
					maxLength={550}
					validation={value => value.length > 0}
					resize="vertical"
					onChange={({target}) => this.setCustomerState({notes_tillagg_other: target.value})}
					fullWidth
					minHeight={250}
				/>

				{isIskTypeSelected && (
					<input type="hidden" name="checkbox_account_isk" value="Ja"/>
				)}

				{!isIskTypeSelected && (
					<input type="hidden" name="checkbox_account_isk" value="Nej"/>
				)}

				<input type="hidden" name="allocation_amount_total" value={totalAmountInvestedFormatted + " SEK"}/>

				<input type="hidden" name={"investment_product"} value={customerState.investment_product}/>
				<input type="hidden" name={"investment_amount"} value={customerState.investment_amount}/>
				<input type="hidden" name={"investment_share_of_assets"} value={customerState.investment_share_of_assets}/>
				<input type="hidden" name={"investment_time_horizon"} value={customerState.investment_time_horizon}/>
				<input type="hidden" name={"investment_risk_class"} value={customerState.investment_risk_class}/>
				<input type="hidden" name={"investment_product_other_text"} value={customerState.investment_product_other_text}/>
				<input type="hidden" name={"investment_account_type"} value={customerState.investment_account_type}/>
				<input type="hidden" name={"investment_fee_revshare"} value={customerState.investment_fee_revshare}/>
				<input type="hidden" name={"investment_fee_rebate"} value={customerState.investment_fee_rebate}/>
				<input type="hidden" name={"investment_fee_explained"} value={
					customerState.radio_placement_spets ? totalCostSpets : (
						customerState.radio_placement_guru ? totalCostGuru : customerState.investment_fee_explained
					)}/>

				<input type="hidden" name={"investment_fee_other"} value={customerState.investment_fee_other}/>
				<input type="hidden" name={"investment_fee_other_sek"} value={customerState.investment_fee_other_sek}/>
				<input type="hidden" name={"investment_fee_other_text"} value={customerState.investment_fee_other_text}/>
			</div>
		);
	};
}

export function _getInitialState(props)
{
	const {customer} = props;

	let newState = {
		isFulfilled: true,
		customerState: {
			radio_placement_spets: customer.getData("customer_spets_depa") && customer.getData("customer_spets_depa").length > 0,
			customer_spets_depa: customer.getData("customer_spets_depa") || "",
			allocation_amount_spets: customer.getData("allocation_amount_spets") || "",

			radio_placement_guru: customer.getData("customer_guru_depa") && customer.getData("customer_guru_depa").length > 0,
			allocation_amount_guru: customer.getData("allocation_amount_guru") || "",
			customer_guru_depa: customer.getData("customer_guru_depa") || "",

			radio_placement_other: customer.getData("customer_other_depa") && customer.getData("customer_other_depa").length > 0,
			allocation_amount_other: customer.getData("allocation_amount_other") || "",
			customer_other_depa: customer.getData("customer_other_depa") || "",

			checkbox_advice_customer: customer.getData("checkbox_advice_customer") || "",

			radio_allocation_fee_spets: customer.getData("radio_allocation_fee_spets") || false,
			assets_spets_depa: customer.getData("assets_spets_depa") || "",
			previous_fee_spets: customer.getData("previous_fee_spets") || "",
			allocation_fee_spets_revenueshare: customer.getData("allocation_fee_spets_revenueshare") || "",
			allocation_fee_spets_revenueshare_new: customer.getData("allocation_fee_spets_revenueshare_new") || "",
			onetime_fee_spets: customer.getData("onetime_fee_spets") || "",
			future_onetime_fee_spets: customer.getData("future_onetime_fee_spets") || "",

			radio_allocation_fee_guru: customer.getData("radio_allocation_fee_guru") || false,
			assets_guru_depa: customer.getData("assets_guru_depa") || "",
			previous_fee_guru: customer.getData("previous_fee_guru") || "",
			allocation_fee_guru_revenueshare: customer.getData("allocation_fee_guru_revenueshare") || "",
			allocation_fee_guru_revenueshare_new: customer.getData("allocation_fee_guru_revenueshare_new") || "",
			onetime_fee_guru: customer.getData("onetime_fee_guru") || "",
			future_onetime_fee_guru: customer.getData("future_onetime_fee_guru") || "",

			radio_allocation_fee_other: customer.getData("radio_allocation_fee_other") || false,
			assets_other_depa: customer.getData("assets_other_depa") || "",
			previous_fee_other: customer.getData("previous_fee_other") || "",
			allocation_fee_other_revenueshare: customer.getData("allocation_fee_other_revenueshare") || "",
			allocation_fee_other_revenueshare_new: customer.getData("allocation_fee_other_revenueshare_new") || "",
			onetime_fee_other: customer.getData("onetime_fee_other") || "",
			future_onetime_fee_other: customer.getData("future_onetime_fee_other") || "",

			notes_tillagg_other: customer.getData("notes_tillagg_other") || "",

			allocation_fee_guru_rebate: customer.getData("allocation_fee_guru_rebate") || "",
			allocation_fee_spets_rebate: customer.getData("allocation_fee_spets_rebate") || "",

			radio_allocation_perc_guru: customer.getData("radio_allocation_perc_guru") || "",
			radio_allocation_perc_guruIndex:
				customer.getData("radio_allocation_perc_guru") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_guru") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_guru") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_guru") === ">20" ? 3 :
								-1,

			radio_allocation_perc_spets: customer.getData("radio_allocation_perc_spets") || "",
			radio_allocation_perc_spetsIndex:
				customer.getData("radio_allocation_perc_spets") === "0-5" ? 0 :
					customer.getData("radio_allocation_perc_spets") === "6-10" ? 1 :
						customer.getData("radio_allocation_perc_spets") === "11-20" ? 2 :
							customer.getData("radio_allocation_perc_spets") === ">20" ? 3 :
								-1,

			checkbox_accounttype_guru: customer.getData("checkbox_accounttype_guru") || "",
			checkbox_accounttype_guruIndex: -1,

			checkbox_accounttype_spets: customer.getData("checkbox_accounttype_spets") || "",
			checkbox_accounttype_spetsIndex: -1,

			radio_allocation_risk_guru: "3",
			radio_allocation_risk_spets: "4",

			checkbox_addon_placement_alternative: customer.getData("checkbox_addon_placement_alternative") || "no",

			investment_product: customer.getData("investment_product") || "",
			investment_amount: customer.getData("investment_amount") || "",
			investment_share_of_assets: customer.getData("investment_share_of_assets") || "",
			investment_time_horizon: customer.getData("investment_time_horizon") || "",
			investment_risk_class: customer.getData("investment_risk_class") || "",
			investment_product_other_text: customer.getData("investment_product_other_text") || "",
			investment_account_type: customer.getData("investment_account_type") || "",
			investment_fee_revshare: customer.getData("investment_fee_revshare") || "",
			investment_fee_rebate: customer.getData("investment_fee_rebate") || "",
			investment_fee_explained: customer.getData("investment_fee_explained") || "",
			investment_fee_other: customer.getData("investment_fee_other") || "",
			investment_fee_other_sek: customer.getData("investment_fee_other_sek") || "",
			investment_fee_other_text: customer.getData("investment_fee_other_text") || ""
		}
	};

	if (customer.isCompany)
	{
		newState.customerState.checkbox_accounttype_guruIndex =
			customer.getData("checkbox_accounttype_guru") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_guru") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_guru") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_guru") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_guru") === "kinsurance" ? 4 :
								-1;

		newState.customerState.checkbox_accounttype_spetsIndex =
			customer.getData("checkbox_accounttype_spets") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_spets") === "trust" ? 1 :
					customer.getData("checkbox_accounttype_spets") === "pinsurance" ? 2 :
						customer.getData("checkbox_accounttype_spets") === "tinsurance" ? 3 :
							customer.getData("checkbox_accounttype_spets") === "kinsurance" ? 4 :
								-1;
	}
	else
	{
		newState.customerState.checkbox_accounttype_guruIndex =
			customer.getData("checkbox_accounttype_guru") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_guru") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_guru") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_guru") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_guru") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_guru") === "kinsurance" ? 5 :
									-1;

		newState.customerState.checkbox_accounttype_spetsIndex =
			customer.getData("checkbox_accounttype_spets") === "naked" ? 0 :
				customer.getData("checkbox_accounttype_spets") === "isk" ? 1 :
					customer.getData("checkbox_accounttype_spets") === "trust" ? 2 :
						customer.getData("checkbox_accounttype_spets") === "pinsurance" ? 3 :
							customer.getData("checkbox_accounttype_spets") === "tinsurance" ? 4 :
								customer.getData("checkbox_accounttype_spets") === "kinsurance" ? 5 :
									-1;
	}

	newState.customerState.allocation_amount_spets = newState.customerState.allocation_amount_spets.length > 0 ? newState.customerState.allocation_amount_spets.replaceAll(" ", "") : "";
	newState.customerState.allocation_amount_guru = newState.customerState.allocation_amount_guru.length > 0 ? newState.customerState.allocation_amount_guru.replaceAll(" ", "") : "";
	newState.customerState.allocation_amount_other = newState.customerState.allocation_amount_other.length > 0 ? newState.customerState.allocation_amount_other.replaceAll(" ", "") : "";

	newState.customerState.previous_fee_spets = newState.customerState.previous_fee_spets.replace("%", "");
	newState.customerState.allocation_fee_spets_revenueshare = newState.customerState.allocation_fee_spets_revenueshare.replace("%", "");
	newState.customerState.allocation_fee_spets_revenueshare_new = newState.customerState.allocation_fee_spets_revenueshare_new.replace("%", "");
	newState.customerState.onetime_fee_spets = newState.customerState.onetime_fee_spets.replace("%", "");
	newState.customerState.future_onetime_fee_spets = newState.customerState.future_onetime_fee_spets.replace("%", "");

	newState.customerState.previous_fee_guru = newState.customerState.previous_fee_guru.replace("%", "");
	newState.customerState.allocation_fee_guru_revenueshare = newState.customerState.allocation_fee_guru_revenueshare.replace("%", "");
	newState.customerState.allocation_fee_guru_revenueshare_new = newState.customerState.allocation_fee_guru_revenueshare_new.replace("%", "");
	newState.customerState.onetime_fee_guru = newState.customerState.onetime_fee_guru.replace("%", "");
	newState.customerState.future_onetime_fee_guru = newState.customerState.future_onetime_fee_guru.replace("%", "");

	newState.customerState.previous_fee_other = newState.customerState.previous_fee_other.replace("%", "");
	newState.customerState.allocation_fee_other_revenueshare = newState.customerState.allocation_fee_other_revenueshare.replace("%", "");
	newState.customerState.allocation_fee_other_revenueshare_new = newState.customerState.allocation_fee_other_revenueshare_new.replace("%", "");
	newState.customerState.onetime_fee_other = newState.customerState.onetime_fee_other.replace("%", "");
	newState.customerState.future_onetime_fee_other = newState.customerState.future_onetime_fee_other.replace("%", "");

	if (newState.customerState.assets_spets_depa.length <= 0 && newState.customerState.customer_spets_depa.length > 0)
	{
		newState.customerState.assets_spets_depa = newState.customerState.customer_spets_depa;
	}

	if (newState.customerState.customer_spets_depa.length <= 0 && newState.customerState.assets_spets_depa.length > 0)
	{
		newState.customerState.customer_spets_depa = newState.customerState.assets_spets_depa;
	}

	if (newState.customerState.assets_guru_depa.length <= 0 && newState.customerState.customer_guru_depa.length > 0)
	{
		newState.customerState.assets_guru_depa = newState.customerState.customer_guru_depa;
	}

	if (newState.customerState.customer_guru_depa.length <= 0 && newState.customerState.assets_guru_depa.length > 0)
	{
		newState.customerState.customer_guru_depa = newState.customerState.assets_guru_depa;
	}

	newState.customerState.guru_disabled = false;
	newState.customerState = updateInvestedProductFields(newState.customerState);

	return newState;
}

function _getFields()
{
	return [
		"customer_guru_depa",
		"allocation_amount_guru",

		"customer_spets_depa",
		"allocation_amount_spets",

		"customer_other_depa",
		"allocation_amount_other",

		"radio_allocation_fee_spets",
		"assets_spets_depa",
		"previous_fee_spets",
		"allocation_fee_spets_revenueshare",
		"allocation_fee_spets_revenueshare_new",
		"onetime_fee_spets",
		"future_onetime_fee_spets",

		"radio_allocation_fee_guru",
		"assets_guru_depa",
		"previous_fee_guru",
		"allocation_fee_guru_revenueshare",
		"allocation_fee_guru_revenueshare_new",
		"onetime_fee_guru",
		"future_onetime_fee_guru",

		"radio_allocation_fee_other",
		"assets_other_depa",
		"previous_fee_other",
		"allocation_fee_other_revenueshare",
		"allocation_fee_other_revenueshare_new",
		"onetime_fee_other",
		"future_onetime_fee_other",

		"notes_tillagg_other",

		"allocation_fee_spets_rebate",
		"allocation_fee_guru_rebate",

		"checkbox_advice_customer",

		"radio_allocation_perc_spets",
		"radio_allocation_perc_spetsIndex",
		"radio_allocation_perc_guru",
		"radio_allocation_perc_guruIndex",

		"account_advice_partnerfee_guru",
		"account_advice_partnerfee_spets",

		"radio_allocation_risk_guru",
		"radio_allocation_risk_spets",

		"checkbox_addon_placement_alternative",

		"investment_product",
		"investment_amount",
		"investment_share_of_assets",
		"investment_time_horizon",
		"investment_risk_class",
		"investment_product_other_text",
		"investment_account_type",
		"investment_fee_revshare",
		"investment_fee_rebate",
		"investment_fee_explained",
		"investment_fee_other",
		"investment_fee_other_sek",
		"investment_fee_other_text"
	];
}


function updateInvestedProductFields(customerState)
{
	if (customerState.radio_placement_spets)
	{
		customerState.investment_product = "Fair Globalförvaltning";
		customerState.investment_amount = getAmountInFixedFormat(customerState.allocation_amount_spets);
		customerState.investment_share_of_assets = customerState.radio_allocation_perc_spets;
		customerState.investment_time_horizon = "Minst 5 år";
		customerState.investment_risk_class = "4";
		customerState.investment_product_other_text = "";
		customerState.investment_account_type = customerState.checkbox_accounttype_spets;
		customerState.investment_fee_revshare = customerState.allocation_fee_spets_revenueshare;
		customerState.investment_fee_rebate = customerState.allocation_fee_spets_rebate;
		customerState.investment_fee_explained = "-"; // Overwritten by correct cost string later.
		customerState.investment_fee_other = "-";
		customerState.investment_fee_other_sek = "-";
		customerState.investment_fee_other_text = "Avtalet löper minst 5 år. Vid förtida uttag tas en avgift ut som uppgår till 5% av det uttagna beloppet under det första året, 4% av det uttagna beloppet under andra året, osv till 0% efter 5 år förflutit. Vi avråder från att investera i aktiemarknaden med en kortare placeringshorisont än 5 år.";
	}
	else if (customerState.radio_placement_guru)
	{
		customerState.investment_product = "Fair Flexibel";
		customerState.investment_amount = getAmountInFixedFormat(customerState.allocation_amount_guru);
		customerState.investment_share_of_assets = customerState.radio_allocation_perc_guru;
		customerState.investment_time_horizon = "Minst 3 år";
		customerState.investment_risk_class = "3";
		customerState.investment_product_other_text = "";
		customerState.investment_account_type = customerState.checkbox_accounttype_guru;
		customerState.investment_fee_revshare = customerState.allocation_fee_guru_revenueshare;
		customerState.investment_fee_rebate = customerState.allocation_fee_guru_rebate;
		customerState.investment_fee_explained = "-"; // Overwritten by correct cost string later.
		customerState.investment_fee_other = "-";
		customerState.investment_fee_other_sek = "-";
		customerState.investment_fee_other_text = "";
	}
	else if (customerState.radio_placement_other)
	{
		customerState.investment_product = "Fair Ädelmetaller";
		customerState.investment_amount = getAmountInFixedFormat(customerState.allocation_amount_other);
		customerState.investment_share_of_assets = "-";
		customerState.investment_time_horizon = "-";
		customerState.investment_risk_class = "-";
		customerState.investment_product_other_text = "-";
		customerState.investment_account_type = "-";
		customerState.investment_fee_revshare = "-";
		customerState.investment_fee_rebate = "-";
		customerState.investment_fee_explained = "-";
		customerState.investment_fee_other = "-";
		customerState.investment_fee_other_sek = "-";
		customerState.investment_fee_other_text = "-";
	}

	return customerState;
}

function getAmountInFixedFormat(inputAmount)
{
	if (inputAmount && inputAmount.length > 0)
	{
		inputAmount = inputAmount.replace("SEK", "");
		inputAmount = inputAmount.replaceAll(" ", "");
		inputAmount = localeNumber(inputAmount, 0) + " SEK";
	}
	return inputAmount;
}

function getHorizonInFixedFormat(horizon)
{
	if (horizon && horizon.length > 0)
	{
		horizon = horizon.replace(" ", "");
		horizon = horizon.replace("år", "");
		horizon = horizon + " år";
	}
	return horizon;
}