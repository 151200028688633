const getDomain = () => {
  const hostname = window.location.hostname;
  const domain = hostname.split(".").slice(-2).join(".");
  return domain;
};

export const setCookie = (name, value, minutes) => {
  const domain = getDomain();
  const domainString = `; domain=${domain}`;
  var expires = "";
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + domainString + expires + "; path=/";
  // // For mobile debugging
  // document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = name => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name) => {
  const domain = getDomain();
  const domainString = `; domain=${domain}`;
  document.cookie =
    name + "=" + domainString + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

  // // For mobile debugging
  // document.cookie =
  //   name + "=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};