import React from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";

import ContentBox from "@components/ContentBox/ContentBox";
import searchIcon from "@assets/img/search_icon.svg";

import useLogsTable from "./useLogsTable";
import CustomTableFooter from "../../CustomTableFooter";

const LogsTable = props => {
  const {
    loaded,
    copyList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    requestSearch,
  } = useLogsTable(props);

  return (
    <ContentBox
      style={{  
        maxHeight: "70vh",
        overflow: "auto",
        boxShadow: "none",
        marginTop: 0,
        marginBottom: 0
      }}
    >
      {!loaded ? (
        <Box 
          sx={{
            width: "100%", 
            alignItems: "center"
          }}
        >
          <ActivityIndicator color="#4cb7d5"  busy/>
        </Box>

      ) : (
        <Box 
          sx={{
            width: "50%", 
            alignItems: "flex-start"
          }}
        >
          <TextField
            size="small"
            fullWidth
            placeholder="Sök..."
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%",
              }
            }}
            sx={{ 
              width: "40%",
            }}
          />
          <Table aria-label="custom pagination table" sx={{ mt: 1, border: 0, tableLayout: "fixed" }}>
            <TableHead sx={{ 
              borderBottom: "1px solid #000",
            }}>
              <TableRow sx={{ "& th": { borderBottom: 0 } }}>
                <TableCell 
                  sx={{
                    width: "20%",
                    p: 1,
                    pb: 0
                  }}>
                  <Typography variant="tableCellHeader">Händelsedatum</Typography>
                </TableCell>
                <TableCell 
                  sx={{
                    width: "80%",
                    p: 1,
                    pb: 0
                  }}>
                  <Typography variant="tableCellHeader">Handling</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? copyList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : copyList
              )
                .map((log, key) => (
                  <TableRow key={key}>
                    <TableCell
                      sx={{
                        width: "20%",
                        px: 1.2,
                        py: 0.5,
                        borderBottom: "none",
                        color: "secondary.lighter"
                      }}
                    >
                      <Typography variant="tableCell">
                        {moment(log.Created).format("YYYY-MM-DD H:mm")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "80%",
                        px: 1.2,
                        py: 0.5,
                        borderBottom: "none",
                        color: "secondary.lighter",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      <Typography variant="tableCell">
                        {log.EventDescription}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              {copyList.length < 1 && (
                <TableRow>
                  <TableCell sx={{ border: 0 }}>Inga resultat.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <CustomTableFooter
            type="logs"
            data={copyList}
            page={page}
            setPage={setPage}            
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </Box>        
      )}
    </ContentBox>
  );
};

export default LogsTable;
